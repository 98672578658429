import React from 'react';
import styled from 'styled-components';

import markdownIt from 'markdown-it';
import PropTypes from 'prop-types';
import filterXss from '../../gatsby/helpers/filterXss';

import colors from '../../ui/constants/colors';
import { mediaQueries } from '../../ui/constants/grid';
import { pxToRem } from '../../ui/constants/typography';
import WidgetCallToAction from './WidgetCallToAction';
import WidgetFullBleedImage from './WidgetFullBleedImage';

const ImageBannerSection = styled.div`
  color: ${colors.white};
  margin-bottom: -100px;
  ${mediaQueries.desktop`
    height: 740px;
    overflow: visible;
    position: absolute;
    top: 0;
    z-index: 2;
  `};
`;

const ImageBannerRow = styled.div`
  margin: 0 auto;
  padding: 0;
  position: relative;
  ${mediaQueries.desktop`
    padding: 0;
  `};
`;

const ImageBannerColumn = styled.div`
  margin: 0;
  padding: 100px 0 0;
  ${mediaQueries.desktop`
    padding: 188px 0 0;
  `};
`;

// white-space: pre-wrap; tells the browser to honour any newlines (\n) in text
const ImageBannerHeader = styled.h1`
  white-space: pre-wrap;
  font-size: ${pxToRem(20)}rem;
  font-weight: bold;
  line-height: ${pxToRem(24)}rem;
  margin-bottom: 12px;
  text-align: center;
  ${mediaQueries.desktop`
    font-size: ${pxToRem(40)}rem;
    line-height: ${pxToRem(48)}rem;
    text-align: left;
  `};
`;

const ImageBannerDescription = styled.div`
  font-size: ${pxToRem(16)}rem;
  line-height: ${pxToRem(24)}rem;
  margin-bottom: 20px;
  text-align: center;
  ${mediaQueries.desktop`
    font-size: ${pxToRem(18)}rem;
    line-height: ${pxToRem(23.5)}rem;
    margin-bottom: 23px;
    text-align:left;
    width: 475px;
  `};
`;

const ImageBannerNote = styled.div`
  font-size: ${pxToRem(12)}rem;
  color: #7d8ba2;
  margin-top: 10px;
  text-align: center;
  ${mediaQueries.desktop`
    color: #7d8ba2;
    font-size: ${pxToRem(12)}rem;
    margin-left: 5px;
    margin-top: 10px;
    text-align: left;
  `};
`;

const ImageBannerMobileSection = styled.div`
  min-height: 300px;
  ${mediaQueries.desktop`
    display: none;
  `};
`;

const ImageBannerDesktopSection = styled.div`
  height: 740px;
  position: relative;
  width: 100%;

  img {
    display: block;
    height: 740px;
    left: 50%;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }

  ${mediaQueries.mobile`
    display: none;
  `};
`;

const WidgetHomePageCustomBanner = ({
  buttonText,
  disclaimerText,
  linkUrl,
  valuePropBody,
  valuePropHeader,
}) => {
  const headerWithLineBreaks = valuePropHeader.replace('\\n', '\n');
  let html;
  if (valuePropBody) {
    html = markdownIt({
      html: true,
      linkify: true,
      typography: true,
    }).render(valuePropBody);
  }

  return (
    <>
      <ImageBannerDesktopSection>
        <picture>
          <source
            media="(max-width: 800px)"
            srcSet="https://bench-assets.imgix.net/img/illustrations/emptyImageForHiddenElements.png?h=1&w=1"
          />
          <source
            media="(max-width: 1440px)"
            srcSet="https://bench-assets.imgix.net/img/illustrations/simple-hero-desktop-v2-3840.jpg?h=740&amp;w=1440&amp;left=240&amp;right=240&amp;fit=crop, https://bench-assets.imgix.net/img/illustrations/simple-hero-desktop-v2-3840.jpg?h=1480&amp;w=2880&amp;left=480&amp;right=480&amp;fit=crop 2x"
          />
          <source
            media="(max-width: 1920px)"
            srcSet="https://bench-assets.imgix.net/img/illustrations/simple-hero-desktop-v2-3840.jpg?h=740&amp;w=1920, https://bench-assets.imgix.net/img/illustrations/simple-hero-desktop-v2-3840.jpg?h=1480&amp;w=3840 2x"
          />
          <img
            alt="A Bench bookkeeper messaging to a business owner about their business' financial books being complete"
            src="https://bench-assets.imgix.net/img/illustrations/simple-hero-desktop-v2-3840.jpg?h=1480&amp;w=3840"
            srcSet="https://bench-assets.imgix.net/img/illustrations/simple-hero-desktop-v2-3840.jpg?h=740&amp;w=1920, https://bench-assets.imgix.net/img/illustrations/simple-hero-desktop-v2-3840.jpg?h=1480&amp;w=3840 2x"
          />
        </picture>
      </ImageBannerDesktopSection>
      <ImageBannerSection>
        <ImageBannerRow>
          <ImageBannerColumn>
            <ImageBannerHeader>{headerWithLineBreaks}</ImageBannerHeader>
            {valuePropBody && (
              <ImageBannerDescription>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: filterXss.process(html) }} />
              </ImageBannerDescription>
            )}
            <WidgetCallToAction
              backgroundColor={colors.water}
              buttonText={buttonText}
              linkURL={linkUrl}
              textColor={colors.pearl}
            />
            <ImageBannerNote>{disclaimerText}</ImageBannerNote>
          </ImageBannerColumn>
        </ImageBannerRow>
      </ImageBannerSection>
      <ImageBannerMobileSection>
        <WidgetFullBleedImage
          alt="A Bench bookkeeper messaging to a business owner about their business’ financial books being complete"
          mobileNaturalHeight={1816}
          mobileNaturalWidth={1600}
          mobileSrc="/img/illustrations/simple-hero-mobile-1600.jpg"
          naturalHeight={1}
          naturalWidth={1}
          src="/img/illustrations/emptyImageForHiddenElements.png"
        />
      </ImageBannerMobileSection>
    </>
  );
};

WidgetHomePageCustomBanner.propTypes = {
  buttonText: PropTypes.string,
  disclaimerText: PropTypes.string,
  linkUrl: PropTypes.string,
  valuePropBody: PropTypes.string,
  valuePropHeader: PropTypes.string,
};

WidgetHomePageCustomBanner.defaultProps = {
  buttonText: 'Start a Free Trial',
  disclaimerText: 'No credit card required.',
  linkUrl: null,
  valuePropBody: null,
  valuePropHeader: null,
};

WidgetHomePageCustomBanner.noContainer = true;

export default WidgetHomePageCustomBanner;
