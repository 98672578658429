import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { navbarMinHeight } from './nav/NavContainer';
import SectionGrid from './sections/SectionGrid';
import TemplateWrapper from './TemplateWrapper';

const HashDiv = styled.div`
  position: relative;
  top: -${navbarMinHeight}px;
`;

let nextSectionGridKey = 1;
const getNextSectionGridKey = () => {
  const currentSectionGridKey = nextSectionGridKey;
  nextSectionGridKey += 1;
  return `SectionGrid${currentSectionGridKey}`;
};

const Page = ({
  background,
  description,
  enableAbTesting,
  footerBackground,
  footerType,
  hideNavbar,
  navbarAlwaysOpaque,
  navbarType,
  path,
  robotMetaContent,
  socialImage,
  testId,
  title,
  transparentNavbarBenchColor,
  transparentNavbarTextColor,
  sections,
}) => (
  <TemplateWrapper
    background={background}
    description={description}
    enableAbTesting={enableAbTesting}
    footerBackground={footerBackground}
    footerType={footerType}
    hideNavbar={hideNavbar}
    navbarAlwaysOpaque={navbarAlwaysOpaque}
    navbarType={navbarType}
    path={path}
    robotMetaContent={robotMetaContent}
    socialImage={socialImage}
    testId={testId}
    title={title}
    transparentNavbarBenchColor={transparentNavbarBenchColor}
    transparentNavbarTextColor={transparentNavbarTextColor}
  >
    {sections &&
      sections.map((section, sectionIndex) => {
        const sectionKey = getNextSectionGridKey();
        const sectionElement = (
          <SectionGrid
            isFirstSection={sectionIndex === 0}
            {...section}
            key={sectionKey}
            navHidden={hideNavbar}
          />
        );

        if (section.hash) {
          return (
            <div key={sectionKey}>
              <HashDiv id={section.hash} />
              {sectionElement}
            </div>
          );
        }
        return sectionElement;
      })}
  </TemplateWrapper>
);

Page.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.string,
  enableAbTesting: PropTypes.bool,
  footerBackground: PropTypes.string,
  footerType: PropTypes.string,
  hideNavbar: PropTypes.bool,
  navbarAlwaysOpaque: PropTypes.bool,
  navbarType: PropTypes.string,
  path: PropTypes.string.isRequired,
  robotMetaContent: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  socialImage: PropTypes.string,
  testId: PropTypes.string,
  title: PropTypes.string,
  transparentNavbarBenchColor: PropTypes.string,
  transparentNavbarTextColor: PropTypes.string,
};

Page.defaultProps = {
  enableAbTesting: false,
  socialImage: null,
  background: null,
  children: null,
  testId: null,
  description:
    'Bench pairs you with a team of bookkeepers and uses simple software to do your ' +
    'bookkeeping, so you don’t have to. Start a free trial today.',
  footerBackground: 'white',
  footerType: 'simple',
  hideNavbar: false,
  navbarAlwaysOpaque: false,
  navbarType: 'simple',
  robotMetaContent: 'index, follow',
  title: 'Bench — Online Bookkeeping for Your Small Business',
  transparentNavbarBenchColor: null,
  transparentNavbarTextColor: null,
};

export default Page;
