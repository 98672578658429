import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { mediaQueries } from '../constants/grid';

export const HideOnDesktop = styled.div`
  ${mediaQueries.desktop`
    display: none;
  `}
`;

export const HideOnMobile = styled.div`
  ${mediaQueries.mobile`
    display: none;
  `}
`;

const ResponsiveHidden = ({ children, hideOnDesktop, hideOnMobile }) => {
  if (hideOnDesktop) {
    return <HideOnDesktop>{children}</HideOnDesktop>;
  }
  if (hideOnMobile) {
    return <HideOnMobile>{children}</HideOnMobile>;
  }
  return children || null;
};

ResponsiveHidden.propTypes = {
  children: PropTypes.node,
  hideOnDesktop: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
};

ResponsiveHidden.defaultProps = {
  hideOnDesktop: false,
  children: null,
  hideOnMobile: false,
};

export default ResponsiveHidden;
