import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import blogSignupSectionProps from '../helpers/blogSignupSectionProps';
import colors from '../../../ui/constants/colors';
import WidgetByline from '../../../components/widgets/WidgetByline';
import FixedHeightImage from '../../../ui/images/FixedHeightImage';
import makeTitleTagContent from '../../helpers/makeTitleTagContent';
import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';
import Page from '../../../components/Page';
import tryGet from '../../../components/helpers/tryGet';
import translateWidget from '../translations/translateWidget';
import WidgetArticleBody from '../../../components/widgets/WidgetArticleBody';
import WidgetMarkdownTableOfContents from '../../../components/widgets/WidgetMarkdownTableOfContents';
import WidgetSocialShare from '../../../components/widgets/WidgetSocialShare';
import WidgetThematicBreak from '../../../components/widgets/WidgetThematicBreak';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';
import WidgetCanonicalTag from '../../../components/widgets/WidgetCanonicalTag';

import '../../../scss/styles-website.scss';

const ARTICLE_DATE_FORMAT = 'LL';

const Article = (props) => {
  const { pageContext } = props;
  const { articleData } = pageContext;
  const {
    articleContent,
    articleHeaderBackgroundColor,
    articleHeaderImage,
    author,
    canonicalUrl,
    authorBioUrl,
    factChecker,
    factCheckerBioUrl,
    datePublished,
    enableAbTesting,
    enableSidebar,
    isSidebarSticky,
    metaDescription,
    mobileAboveTheFoldContent,
    path,
    resources,
    robotMetaContent,
    title,
    sidebarUpsellCopy,
    sidebarUpsellHeader,
    sidebarUpsellLinkURL,
  } = articleData;
  const resourceWidgetsById = resources
    .map(translateWidget)
    .filter(({ id }) => id)
    .reduce((widgets, widget) => ({ ...widgets, [widget.id]: widget }), {});

  const formattedDatePublished = moment(datePublished).format(ARTICLE_DATE_FORMAT);

  const headerColumns = [];
  if (articleHeaderImage) {
    headerColumns.push({
      span: 12,
      widgets: [
        {
          data: {
            ...normalizeContentfulImageData(articleHeaderImage),
            desktopFixedHeight: 400,
            isAboveFold: true,
            mobileFixedHeight: 300,
          },
          margin: 0,
          WidgetComponent: FixedHeightImage,
        },
      ],
    });
  }

  if (canonicalUrl) {
    headerColumns.push({
      span: 0,
      widgets: [
        {
          data: {
            canonicalUrl,
          },
          WidgetComponent: WidgetCanonicalTag,
        },
      ],
    });
  }

  const bodyColumns = [];
  const markdown = tryGet(['articleContent'], articleContent) || articleContent;
  const aboveTheFoldMarkdown =
    tryGet(['mobileAboveTheFoldContent'], mobileAboveTheFoldContent) || mobileAboveTheFoldContent;
  const articleBodyColumn = {
    span: 7,
    widgets: [],
  };

  const articleBodyStandardWidgets = [
    {
      data: {
        markdown,
        path,
        resources: resourceWidgetsById,
      },
      WidgetComponent: WidgetArticleBody,
    },
    {
      data: {
        color: colors.charcoal,
        thickness: 2,
      },
      WidgetComponent: WidgetThematicBreak,
    },
    {
      data: {
        description:
          'This post is to be used for informational purposes only and does not ' +
          'constitute legal, business, or tax advice. Each person should consult his or her own ' +
          'attorney, business advisor, or tax advisor with respect to matters referenced in this ' +
          'post. Bench assumes no liability for actions taken in reliance upon the information ' +
          'contained herein.',
        fontColor: colors.dust,
        fontSize: 14,
        lineHeight: 22,
        textAlignmentMobile: 'left',
      },
      WidgetComponent: WidgetValueProp,
    },
  ];

  articleBodyColumn.widgets.push(...articleBodyStandardWidgets);

  if (enableSidebar) {
    bodyColumns.push({
      span: 4,
      widgets: [
        {
          data: {
            markdown,
            path,
            isSidebarSticky,
            upsellCopy: sidebarUpsellCopy,
            upsellHeader: sidebarUpsellHeader,
            upsellLinkURL: sidebarUpsellLinkURL,
          },
          WidgetComponent: WidgetMarkdownTableOfContents,
        },
      ],
    });
    bodyColumns.push(articleBodyColumn);
    bodyColumns.push({
      span: 1,
    });
  } else {
    bodyColumns.push(articleBodyColumn);
  }

  const socialImage = tryGet(['file', 'url'], articleHeaderImage);

  const pageData = {
    description: metaDescription,
    enableAbTesting,
    path,
    socialImage,
    title: makeTitleTagContent(title),
    footerBackground: colors.white,
    footerType: 'full',
    hideNavbar: false,
    navbarAlwaysOpaque: true,
    navbarType: 'full',
    robotMetaContent,
    transparentNavbarBenchColor: null,
    transparentNavbarTextColor: null,
    sections: [
      {
        backgroundColor: articleHeaderBackgroundColor,
        columns: headerColumns,
        padding: 0,
      },
      {
        backgroundColor: colors.white,
        columns: [
          {
            span: 12,
            widgets: [
              {
                data: {
                  header: title,
                  headerFontFamily: 'LeituraDisplay',
                  headerFontSize: 40,
                  headerFontSizeMobile: 32,
                  headerFontWeight: 400,
                  headerLineHeight: 50,
                  headerLineHeightMobile: 40,
                  headerTag: 'h1',
                  textAlignment: 'center',
                },
                marginMobile: 2,
                WidgetComponent: WidgetValueProp,
              },
              {
                data: {
                  author,
                  authorBioUrl,
                  factChecker,
                  factCheckerBioUrl,
                  formattedDatePublished,
                },
                marginMobile: 2,
                WidgetComponent: WidgetByline,
              },
              {
                data: {
                  description: aboveTheFoldMarkdown,
                  fontColor: colors.ash,
                  fontSize: 18,
                  lineHeight: 28,
                  textAlignmentMobile: 'left',
                },
                WidgetComponent: WidgetValueProp,
              },
            ],
          },
        ],
        paddingBottom: 3,
        paddingBottomMobile: 0,
        paddingTop: 5,
        paddingTopMobile: 3,
      },
      {
        alignItems: 'stretch',
        backgroundColor: colors.white,
        columns: bodyColumns,
        paddingTop: 0,
        paddingBottom: 0,
      },
      {
        columns: [
          {
            span: 12,
            widgets: [
              {
                data: {
                  path,
                  twitterText: title,
                },
                WidgetComponent: WidgetSocialShare,
              },
            ],
          },
        ],
        paddingBottom: 0,
        paddingTop: 0,
      },
      blogSignupSectionProps,
    ],
  };

  return <Page {...pageData} />;
};

Article.propTypes = {
  /** Provided by `createPage` in gatsby-node-blog.js */
  pageContext: PropTypes.shape({
    articleData: PropTypes.object,
  }).isRequired,
};

export default Article;
