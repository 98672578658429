const defaultCardSources = {
  Accounting: [
    '/img/blog/Accounting-1.png',
    '/img/blog/Accounting-2.png',
    '/img/blog/Accounting-3.png',
    '/img/blog/Accounting-4.png',
    '/img/blog/Accounting-5.png',
    '/img/blog/Accounting-6.png',
  ],
  'Bench News': [
    '/img/blog/Bench_News-1.png',
    '/img/blog/Bench_News-2.png',
    '/img/blog/Bench_News-3.png',
    '/img/blog/Bench_News-4.png',
    '/img/blog/Bench_News-5.png',
    '/img/blog/Bench_News-6.png',
  ],
  Bookkeeping: [
    '/img/blog/Bookkeeping-1.png',
    '/img/blog/Bookkeeping-2.png',
    '/img/blog/Bookkeeping-3.png',
    '/img/blog/Bookkeeping-4.png',
    '/img/blog/Bookkeeping-5.png',
    '/img/blog/Bookkeeping-6.png',
  ],
  Operations: [
    '/img/blog/Operations-1.png',
    '/img/blog/Operations-2.png',
    '/img/blog/Operations-3.png',
    '/img/blog/Operations-4.png',
    '/img/blog/Operations-5.png',
    '/img/blog/Operations-6.png',
  ],
  Resources: [
    '/img/blog/Resources-1.png',
    '/img/blog/Resources-2.png',
    '/img/blog/Resources-3.png',
    '/img/blog/Resources-4.png',
    '/img/blog/Resources-5.png',
    '/img/blog/Resources-6.png',
  ],
  'Tax Tips': [
    '/img/blog/Tax_Tips-1.png',
    '/img/blog/Tax_Tips-2.png',
    '/img/blog/Tax_Tips-3.png',
    '/img/blog/Tax_Tips-4.png',
    '/img/blog/Tax_Tips-5.png',
    '/img/blog/Tax_Tips-6.png',
  ],
};

// Simple hash function to get an Array index from an ID String. Should select placeholder images
// more-or-less at random and hopefully evenly distributed.
const getImageIndex = (id, numOptions) => {
  const idString = String(id);
  // Sum the char code values of the characters in the ID String
  const charVals = idString.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
  return charVals % numOptions;
};

const getDefaultContentCardImage = (category, id) => {
  if (!(category in defaultCardSources)) {
    return null;
  }
  const imageSources = defaultCardSources[category];
  const imageIndex = getImageIndex(id, imageSources.length);
  const src = imageSources[imageIndex];
  return {
    alt: `Placeholder for ${category} category`,
    naturalHeight: 900,
    naturalWidth: 2220,
    src,
  };
};

export default getDefaultContentCardImage;
