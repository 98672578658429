import { loadScript } from '../../components/helpers/loadAsset';
import config from '../../config';

const loadOneTrustScript = () => {
  return loadScript(config.ONETRUST_SCRIPT, false, false, [
    {
      name: 'data-domain-script',
      value: config.ONETRUST_DOMAIN_SCRIPT,
    },
  ]);
};

export default loadOneTrustScript;
