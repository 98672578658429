import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';
import tryGet from '../../../components/helpers/tryGet';
import WidgetBanner from '../../../components/widgets/WidgetBanner';

const imagePositions = {
  'after text': 'after',
  'before text': 'before',
};

const widgetBannerTranslation = ({
  backgroundColor,
  backgroundImage,
  borderRadius,
  boxShadow,
  buttonBackgroundColor,
  buttonBorderColor,
  buttonText,
  buttonTextColor,
  buttonTextAlign,
  ctaTarget,
  description,
  descFontSize,
  descLineHeight,
  descTextAlign,
  header,
  headingFontSize,
  headerLineHeight,
  headerTextAlign,
  foregroundImage,
  foregroundImagePosition,
  linkUrl,
  maxWidth,
  mobileMaxWidth,
  padding,
  textColor,
}) => {
  const translatedData = {
    backgroundColor,
    backgroundImgSrc: tryGet(['file', 'url'], backgroundImage),
    borderRadius,
    boxShadow,
    buttonBackgroundColor,
    buttonBorderColor,
    buttonText,
    buttonTextColor,
    buttonTextAlign,
    ctaTarget,
    description: tryGet(['description'], description) || description,
    descFontSize,
    descLineHeight,
    descTextAlign,
    header,
    headerFontSize: headingFontSize,
    headerLineHeight,
    headerTextAlign,
    image: normalizeContentfulImageData(foregroundImage),
    imagePosition: imagePositions[foregroundImagePosition],
    linkURL: linkUrl,
    maxWidth,
    mobileMaxWidth,
    padding,
    textColor,
  };

  return {
    data: translatedData,
    WidgetComponent: WidgetBanner,
  };
};

export default widgetBannerTranslation;
