import PropTypes from 'prop-types';
import styled from 'styled-components';

import { mediaQueries } from '../../ui/constants/grid';
import { navbarMinHeight } from './NavContainer';

const NavSection = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: ${navbarMinHeight}px;
  position: relative;

  ${mediaQueries.mobile`
    ${({ showOnMobile }) => !showOnMobile && `display: none;`}
    justify-content: center;
  `}

  ${mediaQueries.desktop`
    ${({ alignmentOnDesktop }) => alignmentOnDesktop === 'center' && `justify-content: center;`}
    ${({ alignmentOnDesktop }) => alignmentOnDesktop === 'left' && `justify-content: flex-start;`}
    ${({ alignmentOnDesktop }) => alignmentOnDesktop === 'right' && `justify-content: flex-end;`}
  `}
`;

NavSection.propTypes = {
  alignmentOnDesktop: PropTypes.oneOf(['center', 'left', 'right']),
  showOnMobile: PropTypes.bool,
};

NavSection.defaultProps = {
  alignmentOnDesktop: null,
  showOnMobile: false,
};

export default NavSection;
