import queryStringLib from 'query-string';

class ResponsiveImage {
  constructor(imageProps, queryBuilder, prefix, breakpoints = []) {
    const { maxWidth, naturalHeight, naturalWidth, src } = imageProps;

    this.imageProps = imageProps;
    this.naturalHeight = naturalHeight;
    this.naturalWidth = naturalWidth;
    this.maxWidth = maxWidth || this.naturalWidth;
    this.queryBuilder = queryBuilder;
    this.prefix = prefix;
    this.src = src;
    this.breakpoints = breakpoints;
    this.dimensionsByBreakpoint = this.getDimensionsByBreakpoint();
  }

  getUsableImageWidth(desiredWidth, isRetina = false) {
    const maxWidth = isRetina ? this.maxWidth * 2 : this.maxWidth;
    const adjustedWidth = isRetina ? desiredWidth * 2 : desiredWidth;

    const limitingWidth = Math.min(maxWidth, this.naturalWidth);
    return Math.min(adjustedWidth, limitingWidth);
  }

  getHeightForWidth(width) {
    return (this.naturalHeight / this.naturalWidth) * width;
  }

  getUsableImageDimensions(desiredWidth, isRetina = false) {
    if (isRetina) {
      const smallestPossiblePixelValue = Math.min(this.maxWidth * 2, desiredWidth * 2);

      if (this.naturalWidth < smallestPossiblePixelValue) {
        return null;
      }
    }

    const usableWidth = this.getUsableImageWidth(desiredWidth, isRetina);

    return {
      height: Math.floor(this.getHeightForWidth(usableWidth, isRetina)),
      width: usableWidth,
    };
  }

  getDimensionsByBreakpoint = () => {
    const dimensionsByBreakpoint = [];

    for (let i = 0; i < this.breakpoints.length; i++) {
      const breakpoint = this.breakpoints[i];
      const breakpointPixelMax = breakpoint.pixelMax;
      dimensionsByBreakpoint[i] = {
        ...breakpoint,
        dimensions: this.getUsableImageDimensions(breakpointPixelMax),
        retina: this.getUsableImageDimensions(breakpointPixelMax, true),
      };
    }

    return dimensionsByBreakpoint;
  };

  buildImagePath(width, height, isRetina = false) {
    const query = this.queryBuilder({
      ...this.imageProps,
      height,
      quality: isRetina ? 70 : 90,
      width,
    });
    const stringifiedQuery = queryStringLib.stringify(query);
    const dprString = isRetina ? ' 2x' : '';

    return `${this.prefix}${this.src}${stringifiedQuery ? `?${stringifiedQuery}` : ''}${dprString}`;
  }

  buildSrcset(bpImageData) {
    const base = [this.buildImagePath(bpImageData.dimensions.width, bpImageData.dimensions.height)];

    if (bpImageData.retina && !this.imageProps.noRetina) {
      base.push(this.buildImagePath(bpImageData.retina.width, bpImageData.retina.height, true));
    }

    return base;
  }

  getPictureTagDefinition = () => {
    return {
      srcSets: this.dimensionsByBreakpoint.map((bpImageData) => ({
        breakpoint: bpImageData,
        media: `(min-width: ${bpImageData.pixelMin}px) and (max-width: ${bpImageData.pixelMax}px)`,
        srcSet: this.buildSrcset(bpImageData).join(', '),
      })),
      default: this.buildImagePath(this.maxWidth, this.getHeightForWidth(this.maxWidth)),
    };
  };
}

export default ResponsiveImage;
