import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

import GlossaryTerm from '../GlossaryTerm';
import QuestionMark from '../../ui/icons/QuestionMark';
import { mediaQueries } from '../../ui/constants/grid';
import colors from '../../ui/constants/colors';

const ExpenseSelectorWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mediaQueries.desktop`
      padding-bottom: 60px;
    `};
`;

const DescriptionWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

const DescriptionText = styled.p`
  color: ${colors.malachite};
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 16px;
  padding-right: 15px;
  text-align: center;

  ${mediaQueries.desktop`
    font-size: 17.5px;
    padding-right: 25px;
    text-align: left;
  `};
`;

const DescriptionTip = styled.div`
  bottom: 10px;
  position: absolute;
  right: -2%;

  ${mediaQueries.desktop`
    right: 0;
    top: -5px;
  `};
`;

const SelectorOptions = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${mediaQueries.mobile`
    flex-wrap: wrap;
  `} ${mediaQueries.desktop`
    background-color: #ffffff;
    box-shadow: 1px 1px 18px 0 rgba(0, 0, 0, 0.13);
    height: 50px;
    width: 786px;
  `};
`;

const OptionButton = styled.button`
  background: ${({ isSelected }) => (isSelected ? colors.malachite : 'transparent')};
  border: none;
  color: ${({ isSelected }) => (isSelected ? 'white' : '#4a4b4e')};
  text-align: center;

  &:hover {
    background: ${colors.malachite};
    color: white;
  }

  ${mediaQueries.mobile`
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.18);
    flex-basis: 49%;
    margin-bottom: 2%;
    padding: 8px;
  `};
  ${mediaQueries.desktop`
    flex-basis: 20%;
    padding: 0;

    &:hover {
      border-radius: none;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
  `};
`;

const OptionText = styled.span`
  align-items: center;
  display: flex;
  font-size: 15px;
  height: 32px;
  justify-content: center;

  ${mediaQueries.desktop`
    box-shadow: ${({ addSeparator, isSelected }) =>
      addSeparator && !isSelected ? '1px 0 0 0 #dfdfdf' : 'none'};
    font-size: 16px;

    ${OptionButton}:hover & {
      box-shadow: none;
    }
  `};
`;

/**
 * FIXME: This is an escape hatch!! Since this component is WidgetPricingRecommendation is hidden
 * by default and only rendered on the Pricing page, the styles are not readily available.
 * This is a hack to get the Pricing A/B test shipped.
 */
/* eslint no-unused-expressions: off */
createGlobalStyle`
  ${OptionButton}.selector-btn-selected {
    background: ${colors.malachite};
    border: none;
    color: white;

    @media (max-width: 800px) {
      border-radius: 4px;
      box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.18);
      flex-basis: 49%;
      margin-bottom: 2%;
      padding: 8px;
    }

    @media (min-width: 801px) {
      border-radius: unset;
      flex-basis: 20%;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
  }
`;

const keepUpSelectorText = {
  definition:
    'On average, how much do you spend each month on goods and services that are' +
    ' necessary to your business? Include any payroll or cost of goods sold (COGS) in your' +
    ' estimate. If you’ve paid yourself a draw, do not include this in your expense total.',
  description:
    "Tell us your average monthly expenses and we'll suggest the plan" +
    " that's right for your business.",
};

const selectorText = {
  catchUp: {
    definition:
      'To calculate historical expenses, take the average of how much you spent each' +
      ' month on goods and services that are necessary to your business. Include any payroll or' +
      " cost of goods sold (COGS) in your estimate. If you've paid yourself a draw, do not" +
      ' include this in your expense total.',
    description:
      "Tell us your average historical monthly expenses and we'll suggest the" +
      " plan that's right for your business.",
  },
  keepUp: keepUpSelectorText,
  benchTax: keepUpSelectorText,
};

const SelectorDescription = ({ serviceType }) => (
  <DescriptionWrapper>
    <DescriptionText>{selectorText[serviceType].description}</DescriptionText>
    <DescriptionTip>
      <GlossaryTerm
        definition={<p>{selectorText[serviceType].definition}</p>}
        term={<QuestionMark color={colors.malachite} />}
      />
    </DescriptionTip>
  </DescriptionWrapper>
);

SelectorDescription.propTypes = {
  serviceType: PropTypes.string.isRequired,
};

const ExpenseOption = (props) => (
  <OptionButton
    className={props.isSelected ? 'selector-btn-selected' : ''}
    onClick={() => props.selectOption(props.recommendedPlan)}
    {...props}
  >
    <OptionText {...props}>{props.range}</OptionText>
  </OptionButton>
);

ExpenseOption.propTypes = {
  addSeparator: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  range: PropTypes.string.isRequired,
  recommendedPlan: PropTypes.string.isRequired,
  selectOption: PropTypes.func.isRequired,
};

const ExpenseSelector = ({ recommendedPlan, selectOption, serviceType }) => {
  const ExpenseOptions = [
    {
      range: '$0k-$3k',
      recommendedPlan: 'Starter',
    },
    {
      range: '$3k-$20k',
      recommendedPlan: 'Micro',
    },
    {
      range: '$20k-$50k',
      recommendedPlan: 'Boutique',
    },
    {
      range: '$50k-100k',
      recommendedPlan: 'Venture',
    },
    {
      range: '$100k-$1M',
      recommendedPlan: 'Corporate',
    },
  ];

  return (
    <ExpenseSelectorWrapper>
      <SelectorDescription serviceType={serviceType} />
      <SelectorOptions>
        {ExpenseOptions.map((option, index) => {
          const optionProps = {
            ...option,
            selectOption,
            addSeparator: index < 4,
            isSelected: option.recommendedPlan === recommendedPlan,
          };
          return (
            <ExpenseOption
              key={option.range}
              data-heap-id={`expense-selector-${option.recommendedPlan}`}
              {...optionProps}
            />
          );
        })}
      </SelectorOptions>
    </ExpenseSelectorWrapper>
  );
};

ExpenseSelector.propTypes = {
  recommendedPlan: PropTypes.string,
  selectOption: PropTypes.func.isRequired,
  serviceType: PropTypes.string.isRequired,
};

ExpenseSelector.defaultProps = {
  recommendedPlan: '',
};

export default ExpenseSelector;
