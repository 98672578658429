import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Image from './Image';
import { mediaQueries } from '../constants/grid';

const FixedHeightImageContainer = styled.div`
  overflow: ${({ overflow }) => overflow};
  position: relative;
  width: 100%;

  img {
    display: block;
    left: 50%;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }

  ${mediaQueries.desktop`
    &, & img {
      height: ${({ desktopFixedHeight, fixedHeight }) => desktopFixedHeight || fixedHeight || 0}px;
    }
  `}

  ${mediaQueries.mobile`
    &, & img {
      height: ${({ mobileFixedHeight, fixedHeight }) => mobileFixedHeight || fixedHeight || 0}px;
    }
  `}
`;

FixedHeightImageContainer.propTypes = {
  desktopFixedHeight: PropTypes.number,
  fixedHeight: PropTypes.number,
  mobileFixedHeight: PropTypes.number,
  overflow: PropTypes.string,
};

FixedHeightImageContainer.defaultProps = {
  desktopFixedHeight: null,
  fixedHeight: null,
  mobileFixedHeight: null,
  overflow: 'hidden',
};

// Calculates maximum width values for when a fixed height image ends up wider than the maximum
// width of its container. For example, a landscape image in a square container will be cropped off
// at the sides and its max width should take into account the full width of the image including the
// cropped parts. If the width of the container was used, the queried image would be less tall than
// the fixed height, then be stretched out and appear blurry.
export const getAdjustedMaxWidths = ({
  desktopFixedHeight,
  fixedHeight,
  mobileSrc,
  naturalHeight,
  naturalWidth,
  mobileFixedHeight,
  mobileNaturalHeight,
  mobileNaturalWidth,
}) => {
  const adjustedMaxWidths = {};

  const desktopAspectRatio = naturalWidth / naturalHeight;
  const normalizedDesktopFixedHeight = desktopFixedHeight || fixedHeight;

  if (desktopAspectRatio && normalizedDesktopFixedHeight) {
    adjustedMaxWidths.maxWidth = Math.ceil(desktopAspectRatio * normalizedDesktopFixedHeight);
  }

  const haveMobileImageAndSize = mobileSrc && mobileNaturalHeight && mobileNaturalWidth;
  const mobileAspectRatio = haveMobileImageAndSize
    ? mobileNaturalWidth / mobileNaturalHeight
    : desktopAspectRatio;
  const normalizedMobileFixedHeight = mobileFixedHeight || fixedHeight;

  if (mobileAspectRatio && normalizedMobileFixedHeight) {
    adjustedMaxWidths.mobileMaxWidth = Math.ceil(mobileAspectRatio * normalizedMobileFixedHeight);
  }

  return adjustedMaxWidths;
};

const FixedHeightImage = ({
  desktopFixedHeight,
  fixedHeight,
  mobileFixedHeight,
  overflow,
  alt,
  autoFormat,
  className,
  fromContentful,
  height,
  isAboveFold,
  mobileNaturalHeight,
  mobileNaturalWidth,
  mobileSrc,
  naturalHeight,
  naturalWidth,
  noRetina,
  src,
  webp,
}) => {
  const { maxWidth, mobileMaxWidth } = getAdjustedMaxWidths({
    desktopFixedHeight,
    fixedHeight,
    mobileSrc,
    naturalHeight,
    naturalWidth,
    mobileFixedHeight,
    mobileNaturalHeight,
    mobileNaturalWidth,
  });

  return (
    <FixedHeightImageContainer
      desktopFixedHeight={desktopFixedHeight}
      fixedHeight={fixedHeight}
      mobileFixedHeight={mobileFixedHeight}
      overflow={overflow}
    >
      <Image
        alt={alt}
        autoFormat={autoFormat}
        className={className}
        fromContentful={fromContentful}
        height={height}
        isAboveFold={isAboveFold}
        maxWidth={maxWidth}
        mobileMaxWidth={mobileMaxWidth}
        mobileNaturalHeight={mobileNaturalHeight}
        mobileNaturalWidth={mobileNaturalWidth}
        mobileSrc={mobileSrc}
        naturalHeight={naturalHeight}
        naturalWidth={naturalWidth}
        noRetina={noRetina}
        src={src}
        webp={webp}
      />
    </FixedHeightImageContainer>
  );
};

FixedHeightImage.propTypes = {
  alt: PropTypes.string,
  autoFormat: PropTypes.bool,
  className: PropTypes.string,
  desktopFixedHeight: PropTypes.number,
  fixedHeight: PropTypes.number,
  fromContentful: PropTypes.bool,
  height: PropTypes.number,
  isAboveFold: PropTypes.bool,
  mobileFixedHeight: PropTypes.number,
  mobileNaturalHeight: PropTypes.number,
  mobileNaturalWidth: PropTypes.number,
  mobileSrc: PropTypes.string,
  naturalHeight: PropTypes.number,
  naturalWidth: PropTypes.number,
  noRetina: PropTypes.bool,
  overflow: PropTypes.string,
  src: PropTypes.string.isRequired,
  webp: PropTypes.bool,
};

FixedHeightImage.defaultProps = {
  alt: null,
  autoFormat: false,
  className: null,
  desktopFixedHeight: null,
  fixedHeight: null,
  fromContentful: false,
  height: null,
  isAboveFold: false,
  mobileFixedHeight: null,
  mobileNaturalHeight: null,
  mobileNaturalWidth: null,
  mobileSrc: null,
  naturalHeight: null,
  naturalWidth: null,
  noRetina: false,
  overflow: 'hidden',
  webp: false,
};

export default FixedHeightImage;
