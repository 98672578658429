import markdownIt from 'markdown-it';
import markdownItDefList from 'markdown-it-deflist';
import markdownItLinkAttributes from 'markdown-it-link-attributes';

import filterXss from '../../gatsby/helpers/filterXss';

const renderer = markdownIt({
  html: true,
  linkify: true,
  typographer: true,
})
  .use(markdownItDefList)
  .use(markdownItLinkAttributes, {
    pattern: /^https?:\/\/(?!bench).+$/,
    attrs: {
      target: '_blank',
      rel: 'nofollow',
    },
  });

const markdownToHTML = (markdown) => {
  if (typeof markdown === 'string') {
    const html = renderer.render(markdown);
    return filterXss.process(html);
  }
  return null;
};

export default markdownToHTML;
