import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaQueries } from '../../ui/constants/grid';
import colors from '../../ui/constants/colors';
import WidgetLabel from './WidgetLabel';
import WidgetValueProp from './WidgetValueProp';
import markdownToHTML from '../helpers/markdownToHTML';

const Heading = styled.h2`
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  margin-bottom: 68px;

  ${mediaQueries.mobile`
    text-align: left;
    font-size: 30px;
  `}
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.lunarRays};
  padding-bottom: 33px;
  margin-bottom: 60px;

  ${mediaQueries.mobile`
    overflow-x: auto;
    border-bottom: none;
    justify-content: unset;
    gap: 10px;
    scrollbar-color: ${colors.electricBlue} ${colors.arcLight};
    &::-webkit-scrollbar {
      height: 4px;              
      width: 4px;               
      background: ${colors.arcLight};
    }
    &::-webkit-scrollbar-thumb:horizontal{
      background: ${colors.electricBlue};
      border-radius: 10px;
    }
  `}
`;

const SimpleValuePropWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-top: 50px;

  ${mediaQueries.mobile`
    flex-direction: column;
  `};
`;

const SimpleValuePropHeading = styled.h3`
  margin-bottom: 10px;
  font-weight: bold;
`;

const SimpleValuePropBody = styled.p`
  margin-bottom: 10px;
`;

const SimpleValuePropLink = styled.a`
  cursor: pointer;
  color: ${colors.electricBlue};
  border-bottom: 1px solid ${colors.electricBlue};
`;

const ValuePropWrapper = styled.div`
  width: 50%;

  ${mediaQueries.mobile`
    width: 100%;
    text-align: left;
  `};
`;

const WidgetMultiLayoutTabbedContent = ({ heading, labels, labelColor, tabs }) => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <>
      <Heading>{heading}</Heading>
      <LabelWrapper>
        {labels.map((label, index) => (
          <WidgetLabel
            alignment="left"
            color={currentTab === index ? labelColor : ''}
            hover
            onClick={() => setCurrentTab(index)}
            size="14px"
            style={currentTab === index ? 'fill' : 'transparent'}
            text={label}
          />
        ))}
      </LabelWrapper>
      <>
        <ValuePropWrapper>
          <WidgetValueProp
            description={tabs[currentTab].subHeading}
            header={tabs[currentTab].heading}
            textAlignmentMobile="left"
          />
        </ValuePropWrapper>
        <SimpleValuePropWrapper>
          {tabs[currentTab].valueProps.map((svp) => (
            <div>
              <SimpleValuePropHeading>{svp.heading}</SimpleValuePropHeading>
              <SimpleValuePropBody>{svp.body}</SimpleValuePropBody>
              <SimpleValuePropLink
                dangerouslySetInnerHTML={{
                  __html: markdownToHTML(svp.link),
                }}
              />
            </div>
          ))}
        </SimpleValuePropWrapper>
      </>
    </>
  );
};

WidgetMultiLayoutTabbedContent.propTypes = {
  heading: PropTypes.string.isRequired,
  labelColor: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  labels: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tabs: PropTypes.array.isRequired,
};

export default WidgetMultiLayoutTabbedContent;
