import WidgetSingleLayoutTabbedContent from '../../../components/widgets/WidgetSingleLayoutTabbedContent';
import tryGet from '../../../components/helpers/tryGet';
import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';

const widgetSingleLayoutTabbedContentTranslation = ({
  heading,
  labelColor,
  singleValuePropTabs,
}) => {
  const convertColorOptions = {
    Arctic: 'arctic',
    Sunlight: 'sunlight',
    'Arc Light': 'arcLight',
  };

  const data = {
    heading: heading || '',
    labelColor: convertColorOptions[labelColor] || 'arcLight',
    labels: singleValuePropTabs.map((tab) => tab.label) || [],
    tabs:
      singleValuePropTabs.map((tab) => ({
        label: tab.label,
        heading: tab.heading,
        image: tab.image && normalizeContentfulImageData(tab.image.imageAsset),
        subHeading: tryGet(['subHeading'], tab.subHeading),
        cta: tab.cta,
      })) || [],
  };

  return { data, WidgetComponent: WidgetSingleLayoutTabbedContent };
};

export default widgetSingleLayoutTabbedContentTranslation;
