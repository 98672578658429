import React from 'react';
import styled from 'styled-components';

const InstagramWidgetIFrame = styled.iframe`
  border: none;
  height: 800px;
  width: 100%;
`;

const InstagramFeed = () => (
  <InstagramWidgetIFrame
    id="wallsio-iframe"
    loading="lazy"
    src="https://my.walls.io/s4ccq?nobackground=1&amp;show_header=0"
    title="Bench Instagram Wall"
  />
);

export default InstagramFeed;
