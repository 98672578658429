import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

// The modal root is the element in the DOM where all modals are mounted. By mounting them at a
// separate root from the rest of the app, it's becomes possible to force modals to be above the
// z-index of everything else in the app.
let modalRoot;

const ensureModalRootIsInDOM = () => {
  if (modalRoot || typeof window === 'undefined' || typeof document === 'undefined') {
    return;
  }
  modalRoot = document.createElement('div');
  modalRoot.setAttribute('id', 'modal-root');
  modalRoot.style.position = 'absolute';
  modalRoot.style.top = '0';
  modalRoot.style.left = '0';
  modalRoot.style.zIndex = '2';
  document.body.appendChild(modalRoot);
};

const Modal = ({ children }) => {
  let element;
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    element = document.createElement('div');
  }

  useEffect(() => {
    ensureModalRootIsInDOM();
    modalRoot.appendChild(element);

    return () => {
      ensureModalRootIsInDOM();
      modalRoot.removeChild(element);
    };
  });

  if (element) {
    return createPortal(children, element);
  }
  return null;
};

Modal.propTypes = {
  children: PropTypes.node,
};

Modal.defaultProps = {
  children: undefined,
};

export default Modal;
