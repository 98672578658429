import tryGet from '../../../components/helpers/tryGet';
import WidgetSummaryWithCollapsibleDetails from '../../../components/widgets/WidgetSummaryWithCollapsibleDetails';

const widgetSummaryAndCollapsibleDetailsTranslation = ({
  backgroundColor,
  details,
  fontColor,
  iconColor,
  summary,
}) => {
  const translatedData = {
    backgroundColor,
    details: tryGet(['details'], details) || details,
    fontColor,
    iconColor,
    summary,
  };
  return {
    data: translatedData,
    WidgetComponent: WidgetSummaryWithCollapsibleDetails,
  };
};

export default widgetSummaryAndCollapsibleDetailsTranslation;
