import { BODY_TEXT_SIZES, HEADER_TEXT_SIZES } from '../../../ui/constants/typography';
import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';
import WidgetValuePropWithIcon from '../../../components/widgets/WidgetValuePropWithIcon';
import tryGet from '../../../components/helpers/tryGet';

const widgetValuePropTranslation = ({
  body,
  bodyTextSize,
  bodyTextColor,
  bodyAlignment,
  header,
  headerTextSize,
  headerTextColor,
  headerTag,
  headerAlignment,
  icon,
  mobileTextAlignment,
}) => {
  const translatedData = {
    ...BODY_TEXT_SIZES[bodyTextSize],
    ...HEADER_TEXT_SIZES[headerTextSize],
    // The preview environment doesn't nest long text in the same way
    // that the graphQL queries do. This helps us handle that difference by allowing
    // both `description: { description: 'text' }` and `description: 'text'`
    description: tryGet(['body'], body) || body,
    fontColor: bodyTextColor,
    header,
    headerFontColor: headerTextColor,
    headerTag: headerTag || 'h2',
    headerTextAlignment: headerAlignment,
    icon: icon && normalizeContentfulImageData(icon),
    textAlignment: bodyAlignment,
    textAlignmentMobile: mobileTextAlignment,
  };

  return {
    data: translatedData,
    WidgetComponent: icon ? WidgetValuePropWithIcon : WidgetValueProp,
  };
};

export default widgetValuePropTranslation;
