import tryGet from '../../../components/helpers/tryGet';
import WidgetTestimonial from '../../../components/widgets/WidgetTestimonial';
import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';

const widgetBasicQuoteTranslation = ({
  author,
  authorImage,
  authorTitle,
  fontColor,
  highlightColor,
  linkUrl,
  quoteFontFamily,
  quoteFontSize,
  quoteIcon,
  quoteText,
}) => {
  const translatedData = {
    author,
    authorImage: authorImage && normalizeContentfulImageData(authorImage),
    authorTitle,
    fontColor,
    highlightColor,
    link: linkUrl,
    quoteFontFamily,
    quoteFontSize,
    showQuoteIcon: quoteIcon === null ? true : quoteIcon,
    quote: tryGet(['quoteText'], quoteText) || quoteText,
  };
  return {
    data: translatedData,
    WidgetComponent: WidgetTestimonial,
  };
};

export default widgetBasicQuoteTranslation;
