const path = require('path');
const normalizePathComponent = require('./normalizePathComponent');
const devPagePrefix = require('./devPagePrefix');

const buildArticleUrl = ({ category, slug }) => {
  const normalizedCategory = normalizePathComponent(category);
  const normalizedSlug = normalizePathComponent(slug);
  const pagePrefix = `${devPagePrefix(slug)}/blog`;
  const prettyPath = path.resolve(pagePrefix, normalizedCategory, normalizedSlug);
  return `${prettyPath}/`;
};

module.exports = buildArticleUrl;
