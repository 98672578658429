import PropTypes from 'prop-types';
import React from 'react';

import { BODY_TEXT_SIZES, HEADER_TEXT_SIZES } from '../../../ui/constants/typography';
import colors from '../../../ui/constants/colors';
import Page from '../../../components/Page';
import WidgetSignupForm from '../../../components/widgets/WidgetSignupForm';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';
import formPageLogoWidgetConfig from '../helpers/formPageLogoWidgetConfig';
import copyrightText from '../../../components/helpers/copyrightText';

const pageData = {
  description:
    'Sign up for Bench’s online bookkeeping package. We’ll pair you with a team of ' +
    'bookkeepers, and give you access to our secure accounting software.',
  path: '/signup/',
  footerType: 'hide',
  hideNavbar: true,
  enableAbTesting: true,
  title: 'Sign Up for Bench — Online Bookkeeping Service',
  sections: [
    {
      backgroundColor: colors.white,
      columns: [
        {
          span: 5,
          widgets: [
            formPageLogoWidgetConfig,
            {
              data: {
                ...BODY_TEXT_SIZES.m,
                ...HEADER_TEXT_SIZES.s,
                description:
                  'Start a free trial, and see why thousands of small business owners trust Bench ' +
                  'with their books.',
                header: 'Get started with America’s largest bookkeeping service for entrepreneurs',
              },
              WidgetComponent: WidgetValueProp,
            },
          ],
        },
      ],
      paddingBottom: 2,
      paddingTop: 8,
    },
    {
      backgroundColor: colors.white,
      columns: [
        {
          span: 2,
        },
        {
          span: 7,
          widgets: [
            {
              data: {
                theme: 'sand',
                buttonText: 'Start a Free Trial',
              },
              margin: 0,
              WidgetComponent: WidgetSignupForm,
            },
          ],
        },
      ],
      padding: 0,
    },
    {
      backgroundColor: colors.white,
      columns: [
        {
          span: 5,
          widgets: [
            {
              data: {
                ...BODY_TEXT_SIZES.m,
                description: 'Already have an account? [Log in here.](/login/)',
              },
              WidgetComponent: WidgetValueProp,
              margin: 1,
            },
          ],
        },
      ],
      paddingBottom: 16,
      paddingTop: 0,
    },
    {
      backgroundColor: colors.white,
      columns: [
        {
          span: 12,
          widgets: [
            {
              data: {
                ...BODY_TEXT_SIZES.s,
                description: copyrightText,
                fontColor: '#9D9C9B',
                textAlignment: 'center',
              },
              WidgetComponent: WidgetValueProp,
              margin: 1,
            },
          ],
        },
      ],
      paddingTop: 6,
      paddingMobile: 0,
      paddingBottom: 6,
    },
  ],
};

const SignupPage = ({ pageContext }) => <Page {...pageContext.pageData} {...pageData} />;

SignupPage.propTypes = {
  pageContext: PropTypes.shape({
    pageData: PropTypes.object,
  }).isRequired,
};

export default SignupPage;
