/* eslint-disable */
export const ONE_SIXTIETH_OF_A_SECOND = 16;

/**
 * Throttle a function so that it will not exceed a specified rate
 *
 * USAGE EXAMPLE:
 * $scope.search = throttle(performSearch, 500); // Bind to ng-keyup
 *
 * http://scn.sap.com/community/labs/blog/2014/04/30/debounce-and-throttle-in-javascript
 *
 * @param func [Function] - The function to execute
 * @param wait [int] - How many milliseconds to debounce
 * @param options [object]
 * @return [Function]
 */
export function throttle(func, wait, options) {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  options || (options = {});

  var later = function () {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    context = args = null;
  };

  return function () {
    var now = Date.now();
    if (!previous && options.leading === false) previous = now;

    var remaining = wait - (now - previous);
    context = this;

    args = arguments;
    if (remaining <= 0) {
      clearTimeout(timeout);
      timeout = null;
      previous = now;
      result = func.apply(context, args);
      context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}
