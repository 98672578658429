import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ExpenseSelector from '../pricing/ExpenseSelector';
import generatePricingData from '../pricing/generatePricingData';
import GridColumn from '../../ui/grid/GridColumn';
import GridRow from '../../ui/grid/GridRow';
import PricingPlan from '../pricing/PricingPlan';
import serviceTypes from '../pricing/serviceTypes';

// React currently doesn't recognize imported objects for PropTypes definition,
// object must be defined in file
const serviceTypesForPropTypes = { ...serviceTypes };

class WidgetPricingRecommendation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recommendedPlan: null,
    };
  }

  selectExpenseOption = (option) => {
    this.setState({ recommendedPlan: option });
  };

  render() {
    const { serviceType } = this.props;
    const pricingPlans = generatePricingData(serviceType, {
      starter: this.props.starterDiscountAmount,
      micro: this.props.microDiscountAmount,
      boutique: this.props.boutiqueDiscountAmount,
      venture: this.props.ventureDiscountAmount,
      corporate: this.props.corporateDiscountAmount,
      discountNumMonths: this.props.discountNumMonths,
    });
    const { recommendedPlan } = this.state;

    return (
      <div>
        <GridRow>
          <GridColumn>
            <ExpenseSelector
              recommendedPlan={recommendedPlan}
              selectOption={this.selectExpenseOption}
              serviceType={serviceType}
            />
          </GridColumn>
        </GridRow>
        <GridRow>
          {pricingPlans.map((pricingPlan) => (
            <GridColumn key={`pricingRec-${pricingPlan.name}`} span={2.4}>
              <PricingPlan
                discountNumMonths={this.props.discountNumMonths}
                hideCta={this.props.hideCta}
                isBestFit={recommendedPlan === pricingPlan.name}
                isMuted={recommendedPlan && pricingPlan.name !== recommendedPlan}
                {...pricingPlan}
                serviceType={serviceType}
              />
            </GridColumn>
          ))}
        </GridRow>
      </div>
    );
  }
}

WidgetPricingRecommendation.propTypes = {
  boutiqueDiscountAmount: PropTypes.number,
  corporateDiscountAmount: PropTypes.number,
  discountNumMonths: PropTypes.number,
  hideCta: PropTypes.bool,
  microDiscountAmount: PropTypes.number,
  serviceType: PropTypes.oneOf(Object.values(serviceTypesForPropTypes)).isRequired,
  starterDiscountAmount: PropTypes.number,
  ventureDiscountAmount: PropTypes.number,
};

WidgetPricingRecommendation.defaultProps = {
  boutiqueDiscountAmount: 0,
  corporateDiscountAmount: 0,
  discountNumMonths: 0,
  hideCta: false,
  microDiscountAmount: 0,
  starterDiscountAmount: 0,
  ventureDiscountAmount: 0,
};

export default WidgetPricingRecommendation;
