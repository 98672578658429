import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import LinkButtonV2 from '../../ui/buttons/LinkButtonV2';
import { mediaQueries } from '../../ui/constants/grid';
import useWindowDimensions from '../../ui/responsive/useWindowDimensions';

// Call to action buttons are always center aligned on mobile
const CallToActionContainer = styled.div`
  text-align: ${({ mobileButtonAlignment }) => mobileButtonAlignment || 'center'};
  ${mediaQueries.desktop`
    text-align: ${({ buttonAlignment }) => buttonAlignment || 'inherit'};
  `}
`;

CallToActionContainer.propTypes = {
  buttonAlignment: PropTypes.oneOf(['center', 'left', 'right']),
  mobileButtonAlignment: PropTypes.oneOf(['center', 'left', 'right']),
};

CallToActionContainer.defaultProps = {
  buttonAlignment: null,
  mobileButtonAlignment: null,
};

const WidgetCallToActionV2 = ({
  buttonAlignment,
  buttonText,
  className,
  linkURL,
  mobileButtonAlignment,
  onClick,
  styleType,
  width,
}) => {
  const { isDesktopView } = useWindowDimensions();
  return (
    <CallToActionContainer
      buttonAlignment={buttonAlignment}
      mobileButtonAlignment={mobileButtonAlignment}
    >
      <LinkButtonV2
        className={className}
        linkURL={linkURL}
        onClick={onClick}
        styleType={styleType}
        width={isDesktopView ? width : '100%'}
      >
        {buttonText}
      </LinkButtonV2>
    </CallToActionContainer>
  );
};

WidgetCallToActionV2.propTypes = {
  buttonAlignment: PropTypes.oneOf(['center', 'left', 'right']),
  buttonText: PropTypes.string,
  className: PropTypes.string,
  linkURL: PropTypes.string,
  mobileButtonAlignment: PropTypes.oneOf(['center', 'left', 'right']),
  onClick: PropTypes.func,
  styleType: PropTypes.string,
  width: PropTypes.string,
};

WidgetCallToActionV2.defaultProps = {
  buttonAlignment: null,
  buttonText: null,
  className: null,
  linkURL: null,
  mobileButtonAlignment: null,
  onClick: null,
  styleType: 'primary',
  width: 'standard',
};

export default WidgetCallToActionV2;
