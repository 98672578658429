import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import markdownToHTML from '../helpers/markdownToHTML';
import { mediaQueries } from '../../ui/constants/grid';

const WrapperComponent = styled.div`
  background: ${({ backgroundColor }) => backgroundColor || colors.white};
  border-radius: 3px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  color: ${({ color }) => color || colors.charcoal};
  margin-bottom: 1rem;
`;

const SummaryComponent = styled.div`
  cursor: pointer;
  font-weight: 600;
  padding: 1.5rem 4rem;
  position: relative;

  ${mediaQueries.mobile`
    padding-right: 30px;
  `};

  &::before {
    color: ${({ iconColor }) => iconColor || '#0fa1a2'};
    content: '+';
    font-size: 24px;
    font-weight: bold;
    height: 24px;
    left: 24px;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform-origin: center;
    ${({ isOpen }) =>
      isOpen ? 'transform: translateY(-50%) rotate(-45deg);' : 'transform: translateY(-50%);'};
    width: 24px;
  }
`;

const DetailsComponent = styled.div`
  padding: 0 4rem 1.75rem;
  overflow: hidden;
`;

/**
 * This component behaves similarly to the `<details>` and `<summary>` HTML elements. The details
 * are initially hidden, and are revealed when the summary is clicked. If the summary is clicked
 * again, then the details are hidden once more.
 */
const WidgetSummaryWithCollapsibleDetails = ({
  backgroundColor,
  details,
  fontColor,
  iconColor,
  summary,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <WrapperComponent backgroundColor={backgroundColor} color={fontColor} isOpen={isOpen}>
      <SummaryComponent
        iconColor={iconColor}
        isOpen={isOpen}
        onClick={(e) => {
          toggle();
          e.target.blur();
        }}
      >
        {summary}
      </SummaryComponent>
      {isOpen && (
        <DetailsComponent
          dangerouslySetInnerHTML={{ __html: markdownToHTML(details) }}
          isOpen={isOpen}
        />
      )}
    </WrapperComponent>
  );
};

WidgetSummaryWithCollapsibleDetails.propTypes = {
  backgroundColor: PropTypes.string,
  details: PropTypes.string.isRequired,
  fontColor: PropTypes.string,
  iconColor: PropTypes.string,
  summary: PropTypes.string.isRequired,
};

WidgetSummaryWithCollapsibleDetails.defaultProps = {
  backgroundColor: colors.white,
  fontColor: colors.charcoal,
  iconColor: '#0fa1a2',
};

export default WidgetSummaryWithCollapsibleDetails;
