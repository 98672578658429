import individualFilingStatuses from './individualFilingStatuses';

/**
 * Individual tax schedule values are in cents.
 */
const scheduleX = [
  {
    baseTaxAmount: 15780312,
    minBracketAmount: 52360000,
    percentage: 37,
  },
  {
    baseTaxAmount: 4784222,
    minBracketAmount: 20942500,
    percentage: 35,
  },
  {
    baseTaxAmount: 3360254,
    minBracketAmount: 16492500,
    percentage: 32,
  },
  {
    baseTaxAmount: 1475078,
    minBracketAmount: 8637500,
    percentage: 24,
  },
  {
    baseTaxAmount: 466400,
    minBracketAmount: 4052500,
    percentage: 22,
  },
  {
    baseTaxAmount: 99500,
    minBracketAmount: 995000,
    percentage: 12,
  },
  {
    baseTaxAmount: 0,
    minBracketAmount: 0,
    percentage: 10,
  },
];

export const scheduleY1 = [
  {
    baseTaxAmount: 16899225,
    minBracketAmount: 62830000,
    percentage: 37,
  },
  {
    baseTaxAmount: 9568510,
    minBracketAmount: 41885100,
    percentage: 35,
  },
  {
    baseTaxAmount: 6720542,
    minBracketAmount: 32985100,
    percentage: 32,
  },
  {
    baseTaxAmount: 2950166,
    minBracketAmount: 17275100,
    percentage: 24,
  },
  {
    baseTaxAmount: 932788,
    minBracketAmount: 8105100,
    percentage: 22,
  },
  {
    baseTaxAmount: 199000,
    minBracketAmount: 1990000,
    percentage: 12,
  },
  {
    baseTaxAmount: 0,
    minBracketAmount: 0,
    percentage: 10,
  },
];

const scheduleY2 = [
  {
    baseTaxAmount: 15780312,
    minBracketAmount: 52360000,
    percentage: 37,
  },
  {
    baseTaxAmount: 4784222,
    minBracketAmount: 20942500,
    percentage: 35,
  },
  {
    baseTaxAmount: 3360254,
    minBracketAmount: 16492500,
    percentage: 32,
  },
  {
    baseTaxAmount: 1475078,
    minBracketAmount: 8637500,
    percentage: 24,
  },
  {
    baseTaxAmount: 466400,
    minBracketAmount: 4052500,
    percentage: 22,
  },
  {
    baseTaxAmount: 99500,
    minBracketAmount: 995000,
    percentage: 12,
  },
  {
    baseTaxAmount: 0,
    minBracketAmount: 0,
    percentage: 10,
  },
];

const scheduleZ = [
  {
    baseTaxAmount: 15635375,
    minBracketAmount: 52360000,
    percentage: 37,
  },
  {
    baseTaxAmount: 4638410,
    minBracketAmount: 20940000,
    percentage: 35,
  },
  {
    baseTaxAmount: 3214442,
    minBracketAmount: 16490000,
    percentage: 32,
  },
  {
    baseTaxAmount: 1329266,
    minBracketAmount: 8635000,
    percentage: 24,
  },
  {
    baseTaxAmount: 621988,
    minBracketAmount: 5420000,
    percentage: 22,
  },
  {
    baseTaxAmount: 142000,
    minBracketAmount: 1420000,
    percentage: 12,
  },
  {
    baseTaxAmount: 0,
    minBracketAmount: 0,
    percentage: 10,
  },
];

const individualFilingStatusToScheduleMap = {
  [individualFilingStatuses.HEAD_OF_HOUSEHOLD]: scheduleZ,
  [individualFilingStatuses.MARRIED_FILING_JOINTLY]: scheduleY1,
  [individualFilingStatuses.MARRIED_FILING_SEPARATELY]: scheduleY2,
  [individualFilingStatuses.QUALIFYING_WIDOWER]: scheduleY1,
  [individualFilingStatuses.SINGLE]: scheduleX,
};

export default individualFilingStatusToScheduleMap;
