import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from './constants/colors';
import grid from './constants/grid';

const HorizontalRule = styled.hr`
  background: ${({ color }) => color};
  border: 0 none;
  display: block;
  height: ${({ thickness }) => thickness}px;
  margin: 0 auto;
  max-width: ${grid.containerWidth}px;
  opacity: 0.1;
`;

HorizontalRule.propTypes = {
  color: PropTypes.string,
  thickness: PropTypes.number,
};

HorizontalRule.defaultProps = {
  color: colors.charcoal,
  thickness: 1,
};

export default HorizontalRule;
