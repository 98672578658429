import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaQueries } from '../../ui/constants/grid';
import colors from '../../ui/constants/colors';
import WidgetLabel from './WidgetLabel';
import WidgetValueProp from './WidgetValueProp';
import WidgetCallToActionV2 from './WidgetCallToActionV2';
import Image from '../../ui/images/Image';

const Heading = styled.h2`
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  margin-bottom: 68px;

  ${mediaQueries.mobile`
    text-align: left;
    font-size: 30px;
  `}
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.lunarRays};
  padding-bottom: 33px;
  margin-bottom: 60px;

  ${mediaQueries.mobile`
    overflow-x: auto;
    border-bottom: none;
    justify-content: unset;
    gap: 10px;
    scrollbar-color: ${colors.electricBlue} ${colors.arcLight};
    &::-webkit-scrollbar {
      height: 4px;              
      width: 4px;               
      background: ${colors.arcLight};
    }
    &::-webkit-scrollbar-thumb:horizontal{
      background: ${colors.electricBlue};
      border-radius: 10px;
    }
  `}
`;

const SingleValuePropContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  ${mediaQueries.mobile`
    flex-direction: column;
  `};
`;

const ValuePropCTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  height: 300px;
  width: 50%;

  ${mediaQueries.mobile`
    height: unset;
    width: 100%;
    align-items: flex-start;
  `};
`;

const CTAWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 35px;

  ${mediaQueries.mobile`
    flex-direction: column;
    gap: unset;
    margin-top: 15px;
    width: 100%;
  `};
`;

const WidgetSingleLayoutTabbedContent = ({ heading, labelColor, labels, tabs }) => {
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <>
      <Heading>{heading}</Heading>
      <LabelWrapper>
        {labels.map((label, index) => (
          <WidgetLabel
            alignment="left"
            color={currentTab === index && labelColor}
            hover
            onClick={() => setCurrentTab(index)}
            size="14px"
            style={currentTab === index ? 'fill' : 'transparent'}
            text={label}
          />
        ))}
      </LabelWrapper>
      <SingleValuePropContainer>
        <ValuePropCTAWrapper>
          <WidgetValueProp
            description={tabs[currentTab].subHeading}
            header={tabs[currentTab].heading}
            textAlignmentMobile="left"
          />
          <CTAWrapper>
            {tabs[currentTab].cta.map((cta) => (
              <WidgetCallToActionV2
                buttonText={cta.buttonText}
                linkURL={cta.linkUrl}
                mobileButtonAlignment="left"
                mobileButtonWidth="100%"
                styleType={cta.styleType}
              />
            ))}
          </CTAWrapper>
        </ValuePropCTAWrapper>
        <Image {...tabs[currentTab].image} maxWidth="50%" />
      </SingleValuePropContainer>
    </>
  );
};

WidgetSingleLayoutTabbedContent.propTypes = {
  heading: PropTypes.string.isRequired,
  labelColor: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  labels: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tabs: PropTypes.array.isRequired,
};

export default WidgetSingleLayoutTabbedContent;
