import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import Image from '../../ui/images/Image';
import Link from '../../ui/buttons/Link';
import { mediaQueries } from '../../ui/constants/grid';
import { pxToRem } from '../../ui/constants/typography';

const AuthorWrap = styled.div`
  display: grid;
  padding-right: 16px;
  row-gap: 20px;
`;

const AuthorImage = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 200px;

  img {
    width: 100%;
  }
`;

const SocialIconsListItem = styled.li`
  display: inline-block;
  padding-right: 1.5rem;
`;

const Title = styled.div`
  color: ${colors.dust};
  font-size: ${pxToRem(16)}rem;

  ${mediaQueries.desktop`
    padding-top: 16px;
  `};
`;

const Name = styled.div`
  color: ${colors.water};
  font-size: ${pxToRem(24)}rem;
  font-weight: bold;
  line-height: ${pxToRem(27)}rem;
`;

const Description = styled.div`
  color: ${colors.ash};
  font-size: ${pxToRem(16)}rem;
`;

const WidgetAuthor = ({
  name,
  title,
  biography,
  facebookLink,
  twitterLink,
  instagramLink,
  image,
}) => {
  return (
    <AuthorWrap>
      {image && (
        <AuthorImage>
          <Image {...image} />
        </AuthorImage>
      )}
      <Title>{title}</Title>
      <Name>{name}</Name>
      <Description>{biography}</Description>
      <div>
        {facebookLink && (
          <SocialIconsListItem>
            <Link linkURL={facebookLink} rel="nofollow" target="_blank">
              <Image
                alt="Facebook Icon"
                height={20}
                maxWidth={20}
                src="/img/icons/facebook-icon.svg"
              />
            </Link>
          </SocialIconsListItem>
        )}
        {instagramLink && (
          <SocialIconsListItem>
            <Link linkURL={instagramLink} rel="nofollow" target="_blank">
              <Image
                alt="Instagram Icon"
                height={20}
                maxWidth={20}
                src="/img/icons/instagram-icon.svg"
              />
            </Link>
          </SocialIconsListItem>
        )}
        {twitterLink && (
          <SocialIconsListItem>
            <Link linkURL={twitterLink} rel="nofollow" target="_blank">
              <Image
                alt="Twitter Icon"
                height={20}
                maxWidth={20}
                src="/img/icons/twitter-icon.svg"
              />
            </Link>
          </SocialIconsListItem>
        )}
      </div>
    </AuthorWrap>
  );
};

WidgetAuthor.propTypes = {
  biography: PropTypes.string.isRequired,
  facebookLink: PropTypes.string,
  image: PropTypes.shape({
    ...Image.propTypes,
  }).isRequired,
  instagramLink: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  twitterLink: PropTypes.string,
};

WidgetAuthor.defaultProps = {
  facebookLink: undefined,
  instagramLink: undefined,
  twitterLink: undefined,
};

export default WidgetAuthor;
