import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  fill: currentColor;
  height: 16px;
  width: 16px;
`;

const CaretDown = () => (
  <StyledSVG viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M12.779 16.12655l4.01891-5.09547a.93375.93375 0 0 0-.18263-1.33495A1.00062 1.00062 0 0 0 16.01582 9.5L7.978 9.52438A.96677.96677 0 0 0 7 10.47959a.93444.93444 0 0 0 .20345.57762l4.01891 5.07109a1.001 1.001 0 0 0 1.37578.17418A.96706.96706 0 0 0 12.779 16.12655Z" />
    </g>
  </StyledSVG>
);

export default CaretDown;
