const navbarLinks = [
  {
    linkText: 'Pricing',
    linkURL: '/pricing/',
    linkId: 2,
  },
  {
    linkText: 'Services',
    linkURL: '/how-it-works/',
    linkId: 1,
    dropdownLinks: [
      {
        dropdownLinkText: 'Monthly Bookkeeping',
        dropdownLinkDescription:
          'For business owners who are currently up-to-date on their bookkeeping',
        dropdownLinkURL: '/how-it-works/',
      },
      {
        dropdownLinkText: 'Tax Advisory & Filing',
        dropdownLinkDescription:
          'For business owners who need bookkeeping, tax preparation, filing and tax advisory',
        dropdownLinkURL: '/small-business-tax/',
      },
      {
        dropdownLinkText: 'Catch Up Bookkeeping',
        dropdownLinkDescription:
          'For business owners who are less than two years behind on their bookkeeping',
        dropdownLinkURL: '/catch-up-bookkeeping-services/',
      },
      {
        dropdownLinkText: 'Bench Retro',
        dropdownLinkDescription:
          'For business owners who are more than two years behind on their bookkeeping',
        dropdownLinkURL: '/benchretro/',
      },
    ],
  },
  {
    linkText: 'Resources',
    linkURL: '/blog/resources',
    linkId: 3,
    dropdownLinks: [
      {
        dropdownLinkText: 'Bench Blog',
        dropdownLinkDescription:
          'Accounting, bookkeeping, and tax tips to help you understand your small business finances',
        dropdownLinkURL: '/blog/',
      },
      {
        dropdownLinkText: 'Guides and Templates',
        dropdownLinkDescription:
          'Download free bookkeeping guides, tax checklists, and expert-tested accounting templates',
        dropdownLinkURL: '/blog/resources/',
      },
      {
        dropdownLinkText: 'Tax Resource Hub',
        dropdownLinkDescription:
          'Everything from deadlines to deductions and filing support - all in in one, easy-to-access place',
        dropdownLinkURL: '/small-business-tax-filing/',
      },
      {
        dropdownLinkText: 'Financial Literacy Hub',
        dropdownLinkDescription:
          'Set your business up for success with our curated collection of free resources and guides to support growth',
        dropdownLinkURL: '/financial-literacy/',
      },
      {
        dropdownLinkText: 'Customer Reviews and Stories',
        dropdownLinkDescription:
          'See why thousands of small business owners trust Bench with their books',
        dropdownLinkURL: '/customer-reviews/',
      },
    ],
  },
  {
    linkText: 'Community',
    linkURL: '/#/',
    linkId: 4,
    dropdownLinks: [
      {
        dropdownLinkText: 'Partner Solutions',
        dropdownLinkDescription: 'Run a better business with our network of partners',
        dropdownLinkURL: '/partner-solutions/',
      },
      {
        dropdownLinkText: 'Embedded Bookkeeping',
        dropdownLinkDescription: 'Integrate bookkeeping and tax into your platform',
        dropdownLinkURL: '/embedded-bookkeeping/',
      },
      {
        dropdownLinkText: `What’s New at Bench`,
        dropdownLinkDescription:
          'See how our latest product releases can help you get more out of your finances and grow your business',
        dropdownLinkURL: '/whats-new/',
      },
      {
        dropdownLinkText: 'DEI at Bench',
        dropdownLinkDescription:
          'Learn about Diversity, Equity, and Inclusion at Bench and find more resources for small business owners',
        dropdownLinkURL: '/dei-at-bench/',
      },
    ],
  },
];

export default navbarLinks;
