import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import GridContainer from '../../ui/grid/GridContainer';
import { mediaQueries } from '../../ui/constants/grid';
import { navbarMinHeight } from '../nav/NavContainer';
import Spacer from '../../ui/Spacer';

const SectionSpacer = styled(Spacer)``;

SectionSpacer.propTypes = {
  margin: PropTypes.number,
  marginBottom: PropTypes.number,
  marginBottomDesktop: PropTypes.number,
  marginBottomMobile: PropTypes.number,
  marginDesktop: PropTypes.number,
  marginMobile: PropTypes.number,
  marginTop: PropTypes.number,
  marginTopDesktop: PropTypes.number,
  marginTopMobile: PropTypes.number,
  padding: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingBottomDesktop: PropTypes.number,
  paddingBottomMobile: PropTypes.number,
  paddingDesktop: PropTypes.number,
  paddingMobile: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingTopDesktop: PropTypes.number,
  paddingTopMobile: PropTypes.number,
};

// All sections have 8 units of padding unless overridden
SectionSpacer.defaultProps = {
  margin: null,
  marginBottom: null,
  marginBottomDesktop: null,
  marginBottomMobile: null,
  marginDesktop: null,
  marginMobile: null,
  marginTop: null,
  marginTopDesktop: null,
  marginTopMobile: null,
  padding: 8,
  paddingBottom: null,
  paddingBottomDesktop: null,
  paddingBottomMobile: null,
  paddingDesktop: null,
  paddingMobile: null,
  paddingTop: null,
  paddingTopDesktop: null,
  paddingTopMobile: null,
};

const StyledSection = styled.section`
  box-sizing: border-box;
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  height: ${({ fillViewport }) => (fillViewport ? '100vh' : 'auto')};
  min-height: min-content;
  overflow: hidden;
  padding-top: ${({ isFirstSection, navHidden }) =>
    isFirstSection && !navHidden ? `${navbarMinHeight}px;` : 0};
  position: relative;

  ${mediaQueries.desktop`
    ${({ maxHeightDesktop }) => maxHeightDesktop && `max-height: ${maxHeightDesktop};`}
  `}

  ${mediaQueries.mobile`
    ${({ maxHeightMobile }) => maxHeightMobile && `max-height: ${maxHeightMobile};`}
  `}
`;

StyledSection.propTypes = {
  backgroundColor: PropTypes.string,
  fillViewport: PropTypes.bool,
  isFirstSection: PropTypes.bool,
  maxHeightDesktop: PropTypes.number,
  maxHeightMobile: PropTypes.number,
  navHidden: PropTypes.bool,
};

StyledSection.defaultProps = {
  backgroundColor: null,
  fillViewport: false,
  isFirstSection: false,
  maxHeightDesktop: null,
  maxHeightMobile: null,
  navHidden: false,
};

const SectionContainer = ({
  backgroundColor,
  children,
  fillViewport,
  isFirstSection,
  margin,
  marginBottom,
  marginBottomDesktop,
  marginBottomMobile,
  marginDesktop,
  marginMobile,
  marginTop,
  marginTopDesktop,
  marginTopMobile,
  maxHeightDesktop,
  maxHeightMobile,
  navHidden,
  padding,
  paddingBottom,
  paddingBottomDesktop,
  paddingBottomMobile,
  paddingDesktop,
  paddingMobile,
  paddingTop,
  paddingTopDesktop,
  paddingTopMobile,
}) => (
  <StyledSection
    backgroundColor={backgroundColor}
    fillViewport={fillViewport}
    isFirstSection={isFirstSection}
    maxHeightDesktop={maxHeightDesktop}
    maxHeightMobile={maxHeightMobile}
    navHidden={navHidden}
  >
    <SectionSpacer
      margin={margin}
      marginBottom={marginBottom}
      marginBottomDesktop={marginBottomDesktop}
      marginBottomMobile={marginBottomMobile}
      marginDesktop={marginDesktop}
      marginMobile={marginMobile}
      marginTop={marginTop}
      marginTopDesktop={marginTopDesktop}
      marginTopMobile={marginTopMobile}
      padding={padding}
      paddingBottom={paddingBottom}
      paddingBottomDesktop={paddingBottomDesktop}
      paddingBottomMobile={paddingBottomMobile}
      paddingDesktop={paddingDesktop}
      paddingMobile={paddingMobile}
      paddingTop={paddingTop}
      paddingTopDesktop={paddingTopDesktop}
      paddingTopMobile={paddingTopMobile}
    >
      <GridContainer>{children}</GridContainer>
    </SectionSpacer>
  </StyledSection>
);

SectionContainer.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  fillViewport: PropTypes.bool,
  isFirstSection: PropTypes.bool,
  margin: PropTypes.number,
  marginBottom: PropTypes.number,
  marginBottomDesktop: PropTypes.number,
  marginBottomMobile: PropTypes.number,
  marginDesktop: PropTypes.number,
  marginMobile: PropTypes.number,
  marginTop: PropTypes.number,
  marginTopDesktop: PropTypes.number,
  marginTopMobile: PropTypes.number,
  maxHeightDesktop: PropTypes.number,
  maxHeightMobile: PropTypes.number,
  navHidden: PropTypes.bool,
  padding: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingBottomDesktop: PropTypes.number,
  paddingBottomMobile: PropTypes.number,
  paddingDesktop: PropTypes.number,
  paddingMobile: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingTopDesktop: PropTypes.number,
  paddingTopMobile: PropTypes.number,
};

SectionContainer.defaultProps = {
  backgroundColor: null,
  children: null,
  fillViewport: false,
  isFirstSection: false,
  margin: null,
  marginBottom: null,
  marginBottomDesktop: null,
  marginBottomMobile: null,
  marginDesktop: null,
  marginMobile: null,
  marginTop: null,
  marginTopDesktop: null,
  marginTopMobile: null,
  maxHeightDesktop: null,
  maxHeightMobile: null,
  navHidden: false,
  padding: 8,
  paddingBottom: null,
  paddingBottomDesktop: null,
  paddingBottomMobile: null,
  paddingDesktop: null,
  paddingMobile: null,
  paddingTop: null,
  paddingTopDesktop: null,
  paddingTopMobile: null,
};

export default SectionContainer;
