import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../ui/constants/colors';
import { mediaQueries } from '../../ui/constants/grid';

const getLabelSize = (size) => {
  switch (size) {
    case '14px':
      return { letterSpacing: '2.8px', padding: '2px 16px' };
    case '16px':
      return { letterSpacing: '3.2px', padding: '2px 24px' };
    case '20px':
      return { letterSpacing: '4px', padding: '2px 24px' };
    default:
      return { letterSpacing: '6px', padding: '8px 48px' };
  }
};

const getLabelAlignment = (alignment) => {
  switch (alignment) {
    case 'middle':
      return '0 auto';
    case 'right':
      return '0 0 0 auto';
    default:
      return 'unset';
  }
};

const getMobileLabelAlignment = (mobileAlignment) => {
  switch (mobileAlignment) {
    case 'middle':
      return '0 auto';
    case 'right':
      return '0 0 0 auto';
    default:
      return 'unset';
  }
};

const Label = styled.div`
  cursor: pointer;
  background-color: ${({ isTransparent, color }) => (isTransparent ? 'unset' : colors[color])};
  border: ${({ isTransparent }) =>
    isTransparent ? `2px solid ${colors.white}` : `2px solid ${colors.eerieBlack}`};
  border: ${({ color, isTransparent }) =>
    isTransparent ? `2px solid ${colors[color]}` : `2px solid ${colors.eerieBlack}`};
  border-radius: 50px;
  color: ${({ color, isTransparent }) => (isTransparent ? colors[color] : colors.eerieBlack)}};
  padding: ${({ size }) => size.padding};
  width: max-content;
  letter-spacing: ${({ size }) => size.letterSpacing};
  margin: ${({ alignment }) => alignment};

  ${mediaQueries.mobile`
  ${({ mobileAlignment }) => mobileAlignment && `margin: ${mobileAlignment}`};
`};
`;

const LabelText = styled.p`
  font-size: ${({ size }) => size};
  text-transform: uppercase;
`;

const WidgetLabel = ({ alignment, color, hover, mobileAlignment, onClick, size, style, text }) => {
  const isButton = onClick && typeof onClick === 'function';

  return (
    <>
      {isButton ? (
        <Label
          alignment={getLabelAlignment(alignment)}
          as="button"
          color={color}
          hover={hover}
          isTransparent={style === 'transparent'}
          mobileAlignment={getMobileLabelAlignment(mobileAlignment)}
          onClick={onClick}
          size={getLabelSize(size)}
        >
          <LabelText>{text}</LabelText>
        </Label>
      ) : (
        <Label
          alignment={getLabelAlignment(alignment)}
          color={color}
          isTransparent={style === 'transparent'}
          size={getLabelSize(size)}
        >
          <LabelText size={size}>{text}</LabelText>
        </Label>
      )}
    </>
  );
};

WidgetLabel.propTypes = {
  alignment: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  hover: PropTypes.bool,
  mobileAlignment: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

WidgetLabel.defaultProps = {
  hover: false,
};

export default WidgetLabel;
