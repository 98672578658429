import tryGet from '../../../components/helpers/tryGet';
import WidgetQuoteV2 from '../../../components/widgets/WidgetBasicQuoteV2';
import colors from '../../../ui/constants/colors';
import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';

const widgetBasicQuoteV2Translation = ({
  author,
  authorImage,
  authorTitle,
  authorWrapPadding,
  fontColor,
  highlightColor,
  linkUrl,
  quoteText,
  label,
  quoteTextAlign,
  mobileAttributionFontSize,
  mobileQuoteFontSize,
  mobileAuthorWrapPadding,
}) => {
  const translatedData = {
    author,
    authorImage: authorImage && normalizeContentfulImageData(authorImage),
    authorTitle,
    authorWrapPadding,
    fontColor: fontColor === 'White' ? colors.ghostWhite : colors.eerieBlack,
    highlightColor: highlightColor === 'Electric Blue' ? colors.electricBlue : colors.sunrayGold,
    quoteTextAlign: quoteTextAlign === 'Center' ? 'center' : 'left',
    label,
    link: linkUrl,
    quote: tryGet(['quoteText'], quoteText) || quoteText,
    mobileAttributionFontSize,
    mobileQuoteFontSize,
    mobileAuthorWrapPadding,
  };
  return {
    data: translatedData,
    WidgetComponent: WidgetQuoteV2,
  };
};

export default widgetBasicQuoteV2Translation;
