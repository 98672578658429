import PropTypes from 'prop-types';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import externalAssetLoading, {
  ASSET_STATUS_FAILED,
  ASSET_STATUS_LOADING,
} from './externalAssetLoading';
import { loadScript } from '../helpers/loadAsset';

const WISTIA_EMBED_ENDPOINT = '//fast.wistia.com/embed/medias';
const WISTIA_EXTERNAL_SCRIPT = '//fast.wistia.com/assets/external/E-v1.js';

const loadingPlaceholderKeyframes = keyframes`
  0% {
    background-position: 0 100%;
  }
  25% {
    background-position: 0 0;
  }
`;

const loadingPlaceholderAnimation = css`
  animation: ${loadingPlaceholderKeyframes} 2s infinite;
  background: linear-gradient(
    115deg,
    rgba(0, 0, 0, 0.15) 40%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(0, 0, 0, 0.15) 60%
  );
  background-size: 100% 800%;
`;

const VideoContainer = styled.div`
  ${({ playAnimation }) => playAnimation && loadingPlaceholderAnimation}

  height: 0;
  padding-bottom: ${({ naturalHeight, naturalWidth }) => (naturalHeight / naturalWidth) * 100}%;
  position: relative;
  width: 100%;
`;

VideoContainer.propTypes = {
  naturalHeight: PropTypes.number.isRequired,
  naturalWidth: PropTypes.number.isRequired,
  playAnimation: PropTypes.bool,
};

VideoContainer.defaultProps = {
  naturalHeight: 1080,
  naturalWidth: 1920,
  playAnimation: null,
};

const VideoElement = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const LoadFailure = styled.div`
  left: 30px;
  position: absolute;
  right: 30px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
`;

const WistiaVideo = ({ assetStatus, id, naturalHeight, naturalWidth, retryLoadAssets }) => (
  <VideoContainer
    naturalHeight={naturalHeight}
    naturalWidth={naturalWidth}
    playAnimation={assetStatus === ASSET_STATUS_LOADING}
  >
    <VideoElement className={`wistia_embed wistia_async_${id}`}>&nbsp;</VideoElement>
    {assetStatus === ASSET_STATUS_FAILED && (
      <LoadFailure>
        There was a problem loading the video.
        <br />
        <button onClick={retryLoadAssets} type="button">
          Try again
        </button>
      </LoadFailure>
    )}
  </VideoContainer>
);

WistiaVideo.loadExternalAssets = ({ id }) => {
  const embedURL = `${WISTIA_EMBED_ENDPOINT}/${id}.jsonp`;
  return Promise.all([loadScript(embedURL, true), loadScript(WISTIA_EXTERNAL_SCRIPT, true)]);
};

WistiaVideo.propTypes = {
  assetStatus: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  naturalHeight: PropTypes.number,
  naturalWidth: PropTypes.number,
  retryLoadAssets: PropTypes.func.isRequired,
};

WistiaVideo.defaultProps = {
  naturalHeight: 1080,
  naturalWidth: 1920,
};

export default externalAssetLoading(WistiaVideo);
