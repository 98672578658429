const contentfulConfig = {
  blog: {
    cdn: {
      accessToken: 'GKVm44EjPxe3OwwsTcxz7840XIdUQZDePRUC0-fkjls',
      host: 'cdn.contentful.com',
      spaceId: 'ifu905unnj2g',
    },
    preview: {
      accessToken: 'tHbn2YeY1XvT_sjS6XIRe7-5NCeSk6hDLgmo83f0QXk',
      host: 'preview.contentful.com',
      spaceId: 'ifu905unnj2g',
    },
  },
  website: {
    cdn: {
      accessToken: '43f0b7e9b40c119faf9e1a4ee8add98a1abba79d388de35259158bdee443d61b',
      host: 'cdn.contentful.com',
      spaceId: '88a6qmzs2wdz',
    },
    preview: {
      accessToken: '258f8c1ec3681f1d681de253a44e4899ddcfbf2e5824575b0581944ed7d21706',
      host: 'preview.contentful.com',
      spaceId: '88a6qmzs2wdz',
    },
  },
};

module.exports = contentfulConfig;
