import React from 'react';
import colors from '../../../ui/constants/colors';
import LoginForm from '../helpers/LoginForm';
import MinimalFooter from '../../../components/footer/MinimalFooter';
import Page from '../../../components/Page';
import Spacer from '../../../ui/Spacer';
import WidgetImage from '../../../components/widgets/WidgetImage';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';

const pageData = {
  background: 'linear-gradient(to right, white, 50%, #cbe2e1 50%)',
  testId: 'legacy-login-page',
  description:
    'Log in to the Bench web app to view your financial statements, search for ' +
    'transactions or receipts, and chat with your bookkeeping team.',
  enableAbTesting: false,
  path: '/Legacy-login/',
  title: 'Log In — Bench',
  footerType: 'hide',
  hideNavbar: true,
  sections: [
    {
      columns: [
        {
          span: 4,
          alignItems: 'middle',
          widgets: [
            {
              data: {
                alt: 'Bench Logo',
                linkURL: '/',
                imageAlignment: 'center',
                isAboveFold: true,
                maxWidth: 100,
                mobileNaturalHeight: 1,
                mobileNaturalWidth: 1,
                mobileSrc: '/img/illustrations/emptyImageForHiddenElements.png',
                naturalWidth: 1836,
                naturalHeight: 551,
                src: '/img/logos/bench-logo.png',
              },
              marginBottomDesktop: 10,
              marginTopDesktop: 0,
              WidgetComponent: WidgetImage,
            },
            {
              data: {
                header: 'Welcome back!',
                headerFontSize: 32,
                headerLineHeight: 36,
                headerTag: 'h1',
                headerWidont: true,
                textAlignment: 'center',
              },
              WidgetComponent: WidgetValueProp,
            },
            {
              data: {
                description: 'Don’t have an account? [Get signed up](/signup/)',
                fontColor: colors.charcoal,
                textAlignment: 'center',
              },
              WidgetComponent: WidgetValueProp,
            },
            {
              WidgetComponent: LoginForm,
            },
            {
              data: {
                marginBottomDesktop: 28,
              },
              WidgetComponent: Spacer,
            },
            {
              data: {
                backgroundColor: colors.white,
                noPadding: true,
              },
              WidgetComponent: MinimalFooter,
            },
          ],
        },
        {
          span: 3,
        },
        {
          margin: 0,
          span: 4,
          widgets: [
            {
              data: {
                header: 'Get prepped and filed fast with BenchTax',
                description:
                  'Introducing BenchTax—your start-to-finish and done-for-you tax prep and ' +
                  'filing solution. With BenchTax, you get connected to a licensed tax expert ' +
                  'ready to handle every detail for you.',
                headerFontSize: 26,
                fontColor: colors.charcoal,
                marginTop: 0,
                textAlignment: 'center',
              },
              WidgetComponent: WidgetValueProp,
            },
            {
              data: {
                header: 'Log in and head to the Add-Ons section to learn more.',
                headerFontSize: 16,
                fontColor: colors.charcoal,
                headerFontWeight: 400,
                headerWidont: true,
                marginTop: 0,
                textAlignment: 'center',
              },
              WidgetComponent: WidgetValueProp,
            },
            {
              data: {
                alt: 'Bonnie with a graphic showing our add-ons button in the app',
                imageAlignment: 'center',
                mobileNaturalHeight: 1,
                mobileNaturalWidth: 1,
                mobileSrc: '/img/illustrations/emptyImageForHiddenElements.png',
                naturalHeight: 960,
                naturalWidth: 1480,
                src: '/img/illustrations/benchTaxLoginPage.jpg',
              },
              margin: 0,
              marginTop: 10,
              WidgetComponent: WidgetImage,
            },
          ],
          fillViewport: true,
        },
      ],
      fillViewport: true,
      hideOnMobile: true,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
    },
    {
      backgroundColor: colors.white,
      columns: [
        {
          widgets: [
            {
              data: {
                alt: 'Bench Logo',
                linkURL: '/',
                imageAlignment: 'center',
                isAboveFold: true,
                mobileImageAlignment: 'center',
                mobileMaxWidth: 90,
                naturalWidth: 1836,
                naturalHeight: 551,
                src: '/img/logos/bench-logo.png',
              },
              marginBottomMobile: 9,
              WidgetComponent: WidgetImage,
            },
            {
              data: {
                header: 'Welcome back!',
                headerFontSize: 26,
                headerFontSizeMobile: 26,
                headerLineHeight: 36,
                headerLineHeightMobile: 41,
                headerTag: 'h1',
                headerWidont: true,
                textAlignment: 'center',
              },
              marginBottomMobile: 2,
              WidgetComponent: WidgetValueProp,
            },
            {
              data: {
                description: 'Don’t have an account? [Get signed up](/signup/)',
                fontColor: colors.charcoal,
                fontSizeMobile: 15,
                lineHeightMobile: 22,
                marginTop: 0,
                textAlignment: 'center',
              },
              margin: 0,
              WidgetComponent: WidgetValueProp,
            },
            {
              data: {
                idSuffix: '-mobile',
              },
              WidgetComponent: LoginForm,
            },
            {
              data: {
                marginBottomMobile: 12,
              },
              WidgetComponent: Spacer,
            },
            {
              data: {
                backgroundColor: colors.white,
                noPadding: true,
              },
              WidgetComponent: MinimalFooter,
            },
          ],
        },
      ],
      paddingTopMobile: 2,
      paddingBottomMobile: 4,
      hideOnDesktop: true,
    },
  ],
};

const LegacyLoginPage = () => <Page {...pageData} />;

export default LegacyLoginPage;
