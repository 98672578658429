import PropTypes from 'prop-types';
import React from 'react';

import calculateQuarterlyTaxForCorporation from './quarterlyTaxCalculator/calculateQuarterlyTaxForCorporation';
import colors from '../../../ui/constants/colors';
import CorpDisqualifiedView from '../../../components/qtc/CorpDisqualifiedView';
import GatedResult from '../../../components/qtc/MarketoFormGatedQTCResult';
import Input from '../../../ui/input/Input';
import LinearFlowForm from '../../../components/qtc/LinearFlowForm';
import LinearFormView from '../../../components/qtc/LinearFormView';
import RadioSet from '../../../ui/RadioSet';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';

const inputTheme = '#F6F6F6';

const stringFieldsToNumbers = (formData) => {
  const result = {};
  Object.keys(formData).forEach((key) => {
    const value = formData[key];

    let centsValueStr = value.split('.')[1] || '';
    centsValueStr = `${centsValueStr}00`.substring(0, 2);
    const centsValue = Number(centsValueStr);
    const intValue = Number(value.split('.')[0]);

    result[key] = intValue * 100 + centsValue;
  });
  return result;
};

const positiveDecimal = /^\d*(\.\d+)?$/;

const positiveDecimalValidator = {
  name: 'regex',
  value: positiveDecimal,
  message: 'Valid dollar amount required',
};

const qualifierResponse = {
  NO: 'NO',
  YES: 'YES',
};

const DisqualifyOrContinue = (props) => {
  const {
    formData: { seventyPercentProfitInSixMonths },
  } = props;
  return seventyPercentProfitInSixMonths === qualifierResponse.YES ? (
    <CorpDisqualifiedView {...props} />
  ) : (
    <LinearFormView {...props} />
  );
};

DisqualifyOrContinue.propTypes = {
  formData: PropTypes.object.isRequired,
};

const getQTCSectionConfigCorporate = ({ businessStructure, campaignId, marketoFormId }) => ({
  backgroundColor: '#f6fcfd',
  columns: [
    {
      span: 8,
      widgets: [
        {
          data: {
            defaultFormData: {
              alternativeFuelTaxCredit: '',
              alternativeTaxQualifiedShipping: '',
              bondCredits: '',
              electricVehicleCredit: '',
              expectedTaxableIncome: '',
              foreignTaxCredit: '',
              generalBusinessCredit: '',
              interestDueIncomeForecast: '',
              interestDueLongTermContracts: '',
              priorYearFederalMinimumTaxCredit: '',
              priorYearMinimumTaxCredit: '',
              recaptureLowIncomeHousingCredit: '',
              recaptureOfInvestmentCredit: '',
              seventyPercentProfitInSixMonths: '',
              totalTaxPreviousYear: '',
              undistributedCapitalGains: '',
            },
            views: [
              {
                props: {
                  businessStructure,
                  formConfig: {
                    seventyPercentProfitInSixMonths: {
                      fieldDefinition: {
                        component: RadioSet,
                        errorPropName: 'errorText',
                        props: {
                          options: [
                            {
                              label: 'Yes',
                              value: qualifierResponse.YES,
                            },
                            {
                              label: 'No',
                              value: qualifierResponse.NO,
                            },
                          ],
                        },
                        validators: ['required'],
                      },
                    },
                  },
                  header: 'Do you earn 70% of your profit in six months of the tax year?',
                },
                ViewComponent: LinearFormView,
              },
              {
                props: {
                  businessStructure,
                  formConfig: {
                    expectedTaxableIncome: {
                      fieldDefinition: {
                        component: Input,
                        validators: ['required', positiveDecimalValidator],
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                      },
                      helpContent: `
Your taxable income is all your revenue minus any deductions you claim. Need some more help? Check
out our resource
<a href="https://bench.co/blog/tax-tips/taxable-income/" rel="noopener noreferrer" target="_blank">What Is Taxable Income?</a>
                      `,
                    },
                  },
                  footerText: 'step 1 of 5',
                  header: 'What is your expected taxable income for the 2022 financial year?',
                  isBackButtonShown: true,
                },
                ViewComponent: DisqualifyOrContinue,
              },
              {
                props: {
                  businessStructure,
                  formConfig: {
                    foreignTaxCredit: {
                      fieldDefinition: {
                        component: Input,
                        validators: [positiveDecimalValidator],
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                      },
                      labelProps: {
                        label: 'Foreign Tax Credit',
                        sublabel: 'Form 1118',
                      },
                    },
                    electricVehicleCredit: {
                      fieldDefinition: {
                        component: Input,
                        validators: [positiveDecimalValidator],
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                      },
                      labelProps: {
                        label: 'Qualified Electric Vehicle Credit',
                        sublabel: 'Form 8834',
                      },
                    },
                    generalBusinessCredit: {
                      fieldDefinition: {
                        component: Input,
                        validators: [positiveDecimalValidator],
                        errorPropName: 'errorText',
                        props: { placeholder: 'Amount', theme: inputTheme },
                        debounce: 100,
                      },
                      labelProps: {
                        label: 'General Business Credit',
                        sublabel: 'Form 3800',
                      },
                    },
                    priorYearMinimumTaxCredit: {
                      fieldDefinition: {
                        component: Input,
                        validators: [positiveDecimalValidator],
                        errorPropName: 'errorText',
                        props: { placeholder: 'Amount', theme: inputTheme },
                        debounce: 100,
                      },
                      labelProps: {
                        label: 'Prior Year Minimum Tax Credit',
                        sublabel: 'Form 8827',
                      },
                    },
                    bondCredits: {
                      fieldDefinition: {
                        component: Input,
                        validators: [positiveDecimalValidator],
                        errorPropName: 'errorText',
                        props: { placeholder: 'Amount', theme: inputTheme },
                        debounce: 100,
                      },
                      labelProps: {
                        label: 'Bond Credits',
                        sublabel: 'Form 8912',
                      },
                    },
                  },
                  footerText: 'step 2 of 5',
                  helpContent: `
Not sure if these tax credits apply to you? Check out the IRS tax form listed beside each credit to
learn more.
                  `,
                  isBackButtonShown: true,
                  header: 'Fill in any tax credits you’ll be claiming below',
                },
                ViewComponent: LinearFormView,
              },
              {
                props: {
                  businessStructure,
                  formConfig: {
                    recaptureOfInvestmentCredit: {
                      fieldDefinition: {
                        component: Input,
                        validators: [positiveDecimalValidator],
                        errorPropName: 'errorText',
                        props: { placeholder: 'Amount', theme: inputTheme },
                        debounce: 100,
                      },
                      labelProps: {
                        label: 'Recapture of Investment Credit',
                        sublabel: 'Form 4255',
                      },
                    },
                    recaptureLowIncomeHousingCredit: {
                      fieldDefinition: {
                        component: Input,
                        validators: [positiveDecimalValidator],
                        errorPropName: 'errorText',
                        props: { placeholder: 'Amount', theme: inputTheme },
                        debounce: 100,
                      },
                      labelProps: {
                        label: 'Recapture of Low-Income Housing Credit',
                        sublabel: 'Form 8611',
                      },
                    },
                    interestDueLongTermContracts: {
                      fieldDefinition: {
                        component: Input,
                        validators: [positiveDecimalValidator],
                        errorPropName: 'errorText',
                        props: { placeholder: 'Amount', theme: inputTheme },
                        debounce: 100,
                      },
                      labelProps: {
                        label: 'Interest Due Under Look-Back Method (Long-Term Contracts)',
                        sublabel: 'Form 8697',
                      },
                    },
                    interestDueIncomeForecast: {
                      fieldDefinition: {
                        component: Input,
                        validators: [positiveDecimalValidator],
                        errorPropName: 'errorText',
                        props: { placeholder: 'Amount', theme: inputTheme },
                        debounce: 100,
                      },
                      labelProps: {
                        label: 'Interest Due Under Look-Back Method (Income Forecast)',
                        sublabel: 'Form 8866',
                      },
                    },
                    alternativeTaxQualifiedShipping: {
                      fieldDefinition: {
                        component: Input,
                        validators: [positiveDecimalValidator],
                        errorPropName: 'errorText',
                        props: { placeholder: 'Amount', theme: inputTheme },
                        debounce: 100,
                      },
                      labelProps: {
                        label: 'Alternative Tax on Qualified Shipping',
                        sublabel: 'Form 8902',
                      },
                    },
                  },
                  footerText: 'step 3 of 5',
                  helpContent: `
Not sure if any of these apply to you? Check out the IRS tax form listed beside each credit to learn
more.
                  `,
                  isBackButtonShown: true,
                  header: 'Fill in all accumulated taxes you plan to pay',
                },
                ViewComponent: LinearFormView,
              },
              {
                props: {
                  businessStructure,
                  formConfig: {
                    undistributedCapitalGains: {
                      fieldDefinition: {
                        component: Input,
                        validators: [positiveDecimalValidator],
                        errorPropName: 'errorText',
                        props: { placeholder: 'Amount', theme: inputTheme },
                        debounce: 100,
                      },
                      labelProps: {
                        label: 'Undistributed LT Capital Gains',
                        sublabel: 'Form 2439',
                      },
                    },
                    alternativeFuelTaxCredit: {
                      fieldDefinition: {
                        component: Input,
                        validators: [positiveDecimalValidator],
                        errorPropName: 'errorText',
                        props: { placeholder: 'Amount', theme: inputTheme },
                        debounce: 100,
                      },
                      labelProps: {
                        label: 'Alternative Fuel Tax Credit',
                        sublabel: 'Form 4136',
                      },
                    },
                    priorYearFederalMinimumTaxCredit: {
                      fieldDefinition: {
                        component: Input,
                        validators: [positiveDecimalValidator],
                        errorPropName: 'errorText',
                        props: { placeholder: 'Amount', theme: inputTheme },
                        debounce: 100,
                      },
                      labelProps: {
                        label: 'Prior Year Minimum Tax Credit',
                        sublabel: 'Form 8827',
                      },
                    },
                  },
                  footerText: 'step 4 of 5',
                  helpContent: `
Not sure if these tax credits apply to you? Check out the IRS tax form listed beside each credit to
learn more.
                  `,
                  isBackButtonShown: true,
                  header: 'Fill in all federal tax credits you plan to claim',
                },
                ViewComponent: LinearFormView,
              },
              {
                props: {
                  businessStructure,
                  formConfig: {
                    totalTaxPreviousYear: {
                      fieldDefinition: {
                        component: Input,
                        validators: ['required', positiveDecimalValidator],
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                      },
                    },
                  },
                  footerText: 'step 5 of 5',
                  isBackButtonShown: true,
                  header: 'What was your total tax from last year?',
                  subheader: 'Line 31 on Form 1120',
                },
                ViewComponent: LinearFormView,
              },
              {
                props: {
                  calculateEstimatedQuarterlyTax: (formData) =>
                    calculateQuarterlyTaxForCorporation(stringFieldsToNumbers(formData)),
                  campaignId,
                  marketoFormId,
                },
                ViewComponent: GatedResult,
              },
            ],
          },
          WidgetComponent: LinearFlowForm,
        },
        {
          data: {
            description:
              'Disclaimer: this calculator is for educational purposes only and should not be taken as ' +
              'official accounting or tax advice. Always consult a certified accountant before making financial decisions.',
            fontColor: colors.dust,
            fontSize: 12,
            mobileFontSize: 12,
            textAlignmentMobile: 'left',
          },
          WidgetComponent: WidgetValueProp,
        },
      ],
    },
  ],
});

export default getQTCSectionConfigCorporate;
