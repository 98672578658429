import PropTypes from 'prop-types';
import React from 'react';

import colors from '../../../ui/constants/colors';
import Page from '../../../components/Page';
import tryGet from '../../../components/helpers/tryGet';
import WidgetArticleBody from '../../../components/widgets/WidgetArticleBody';
import WidgetImage from '../../../components/widgets/WidgetImage';
import WidgetMarkdownTableOfContents from '../../../components/widgets/WidgetMarkdownTableOfContents';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';

const FaqPage = (props) => {
  const { pageContext } = props;
  const { pageData } = pageContext;
  const { article, path } = pageData;
  const { articleContent, title } = article;

  const markdown = tryGet(['articleContent'], articleContent) || articleContent;

  const pageProps = {
    description:
      'Have a question about how Bench works? Browse our online help center for answers ' +
      'to frequently asked questions.',
    enableAbTesting: false,
    footerType: 'full',
    navbarType: 'full',
    path: '/faq/',
    title,
    sections: [
      {
        backgroundColor: '#f6fcfd',
        columns: [
          {
            span: 3,
            widgets: [
              {
                data: {
                  alt: 'Decorative bookkeeping themed pattern',
                  isAboveFold: true,
                  mobileNaturalHeight: 182,
                  mobileNaturalWidth: 618,
                  mobileSrc: '/img/illustrations/faq-banner-mobile-top.png',
                  naturalHeight: 340,
                  naturalWidth: 498,
                  src: '/img/illustrations/faq-banner-Left.png',
                },
                margin: 0,
                WidgetComponent: WidgetImage,
              },
            ],
          },
          {
            span: 6,
            widgets: [
              {
                data: {
                  header: 'Frequently Asked Questions',
                  headerFontColor: '#43acb5',
                  headerFontFamily: 'LLCircularWeb',
                  headerFontSize: 40,
                  headerFontSizeMobile: 20,
                  headerFontWeight: 700,
                  headerLineHeight: 50,
                  headerLineHeightMobile: 24,
                  headerTag: 'h1',
                  textAlignment: 'center',
                },
                marginMobile: 6,
                WidgetComponent: WidgetValueProp,
              },
            ],
          },
          {
            span: 3,
            widgets: [
              {
                data: {
                  alt: 'Alternate decorative bookkeeping themed pattern',
                  isAboveFold: true,
                  mobileNaturalHeight: 196,
                  mobileNaturalWidth: 616,
                  mobileSrc: '/img/illustrations/faq-banner-mobile-bottom.png',
                  naturalHeight: 340,
                  naturalWidth: 532,
                  src: '/img/illustrations/faq-banner-right.png',
                },
                margin: 0,
                WidgetComponent: WidgetImage,
              },
            ],
          },
        ],
        alignItems: 'center',
        padding: 9,
        paddingMobile: 2,
      },
      {
        alignItems: 'stretch',
        backgroundColor: colors.white,
        columns: [
          {
            span: 4,
            widgets: [
              {
                data: {
                  markdown,
                  path,
                  isSidebarSticky: true,
                },
                WidgetComponent: WidgetMarkdownTableOfContents,
              },
            ],
            hideOnMobile: true,
          },
          {
            span: 7,
            widgets: [
              {
                data: {
                  markdown,
                  path,
                },
                WidgetComponent: WidgetArticleBody,
              },
            ],
          },
          {
            span: 1,
          },
        ],
        paddingTop: 0,
        paddingBottom: 0,
      },
    ],
  };
  return <Page {...pageProps} />;
};

FaqPage.propTypes = {
  pageContext: PropTypes.shape({
    pageData: PropTypes.object,
  }).isRequired,
};

export default FaqPage;
