import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '../constants/colors';

const getBackgroundColor = (props) => {
  if (props.ghost || props.plain) {
    return 'transparent';
  }

  return props.backgroundColor || colors.water;
};

const getTextColor = (props) => {
  if (!props.plain && !props.ghost && !props.textColor) {
    return colors.white;
  }
  return props.textColor || colors.charcoal;
};

const Button = styled(
  ({
    // Omit styling props from props passed to the button element
    backgroundColor,
    buttonPadding,
    fontSize,
    full,
    ghost,
    onClick,
    plain,
    textColor,
    useInputElement,
    ...otherProps
  }) => {
    const El = useInputElement ? 'input' : 'button';
    return (
      <El
        {...otherProps}
        onClick={(e) => {
          return onClick && onClick(e);
        }}
      />
    );
  },
)`
  display: ${({ full }) => (full ? 'block' : 'inline-block')};
  width: ${({ full }) => full && '100%'};
  box-sizing: border-box;
  padding: ${({ plain, buttonPadding }) => (plain ? 0 : buttonPadding)};
  border: ${({ ghost }) => (ghost ? `solid 1.25px ${colors.charcoal}` : 0)};
  margin-bottom: ${({ plain }) => (plain ? 0 : '0.5rem')};
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: 1.5rem;
  background-color: ${(props) => getBackgroundColor(props)};
  color: ${(props) => getTextColor(props)};
  -moz-appearance: none;
  -webkit-appearance: none;

  &[disabled] {
    opacity: 0.5;
  }

  &:focus {
    box-shadow: 0 0 0 2px #44c2c6;
  }

  ${({ ghost }) =>
    ghost &&
    `
    &:hover {
      background-color: ${colors.charcoal};
      color: ${colors.white};
    }
  `}
`;

Button.propTypes = {
  backgroundColor: PropTypes.string,
  buttonPadding: PropTypes.string,
  disabled: PropTypes.bool,
  fontSize: PropTypes.number,
  full: PropTypes.bool,
  ghost: PropTypes.bool,
  plain: PropTypes.bool,
  textColor: PropTypes.string,
  useInputElement: PropTypes.bool,
};

Button.defaultProps = {
  backgroundColor: colors.water,
  buttonPadding: '1rem',
  disabled: false,
  fontSize: 16,
  full: false,
  ghost: false,
  plain: false,
  textColor: colors.white,
  useInputElement: false,
};

export default Button;
