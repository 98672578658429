import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import blogSignupSectionProps from '../helpers/blogSignupSectionProps';
import colors from '../../../ui/constants/colors';
import makeTitleTagContent from '../../helpers/makeTitleTagContent';
import Page from '../../../components/Page';
import translateWidget from '../translations/translateWidget';
import WidgetCardList from '../../../components/widgets/WidgetCardList';
import WidgetImage from '../../../components/widgets/WidgetImage';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';

import '../../../scss/styles-website.scss';

const RelatedContentHeaderContainer = styled.div`
  text-align: center;

  h3 {
    border-bottom: solid 1.5px #0fa1a2;
    color: #0fa1a2;
    font-size: 1rem;
    display: inline-block;
    letter-spacing: 1.7px;
    padding-bottom: 6px;
    text-align: center;
  }
`;

const RelatedContentHeader = () => (
  <RelatedContentHeaderContainer>
    <h3>YOU MIGHT ALSO LIKE:</h3>
  </RelatedContentHeaderContainer>
);

const GatedAssetThanks = (props) => {
  const { pageContext } = props;
  const { gatedAssetData } = pageContext;
  const { gatedAssetTitle, metaDescription, path, relatedCards } = gatedAssetData;

  const pageData = {
    description: metaDescription,
    enableAbTesting: false,
    path,
    socialImage: null,
    title: makeTitleTagContent(gatedAssetTitle),
    footerBackground: colors.white,
    footerType: 'full',
    hideNavbar: false,
    navbarType: 'full',
    transparentNavbarBenchColor: null,
    transparentNavbarTextColor: null,
    sections: [
      {
        backgroundColor: '#e0eeee',
        paddingTopMobile: 4,
        columns: [
          {
            span: 7,
            widgets: [
              {
                data: {
                  alt: 'A paper airplane flies towards you from the Bench logo',
                  isAboveFold: true,
                  imageAlignment: 'center',
                  maxWidth: 276,
                  mobileMaxWidth: 200,
                  naturalHeight: 300,
                  naturalWidth: 552,
                  src: '/img/illustrations/airplane_flies_from_bench_to_you.png',
                },
                marginBottomDesktop: 0,
                marginTopDesktop: 4,
                WidgetComponent: WidgetImage,
              },
              {
                data: {
                  description: 'Your download is on its way!',
                  fontColor: '#0fa1a2',
                  header: 'Keep an eye on your inbox.',
                  headerFontSize: 40,
                  headerFontSizeMobile: 24,
                  headerFontWeight: 600,
                  textAlignment: 'center',
                },
                marginTopDesktop: 7,
                WidgetComponent: WidgetValueProp,
              },
            ],
          },
        ],
      },
      {
        backgroundColor: '#f6f6f6',
        columns: [
          {
            span: 5,
            widgets: [
              {
                data: {},
                marginBottom: 0,
                marginTop: 0,
                WidgetComponent: RelatedContentHeader,
              },
            ],
          },
        ],
        paddingBottom: 4,
        paddingTop: 4,
      },
      {
        backgroundColor: '#F6F6F6',
        columns: [
          {
            span: 12,
            widgets: [
              {
                data: {
                  cards: relatedCards ? relatedCards.map(translateWidget) : [],
                  layout: [{ numCards: 3 }],
                },
                marginTop: 0,
                WidgetComponent: WidgetCardList,
              },
            ],
          },
        ],
        padding: 0,
      },
      blogSignupSectionProps,
    ],
  };

  return <Page {...pageData} />;
};

GatedAssetThanks.propTypes = {
  /** Provided by `createPage` in gatsby-node-blog.js */
  pageContext: PropTypes.shape({
    gatedAssetData: PropTypes.object,
  }).isRequired,
};

export default GatedAssetThanks;
