import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '../constants/colors';

const ColoredSvg = styled.svg`
  fill: ${({ color }) => color};
  max-height: 48px;
  max-width: 48px;
`;

const BenchLogoSymbol = ({ color }) => (
  <ColoredSvg color={color || colors.mustard} version="1.1" viewBox="0 0 155 155" x="0px" y="0px ">
    <g>
      <g>
        <path d="M77,152c-41.354,0-75-33.647-75-75V2h150v75C152,118.353,118.352,152,77,152z M15.636,15.636V77c0,33.834,27.521,61.363,61.364,61.363c33.834,0,61.363-27.529,61.363-61.363V15.636H15.636z" />
      </g>
      <polygon points="111.092,47.455 42.909,47.455 42.909,61.091 52.231,61.091 47.508,98.875 61.251,98.875 65.974,61.091 88.025,61.091 92.75,98.875 106.492,98.875 101.769,61.091 111.092,61.091" />
    </g>
  </ColoredSvg>
);

BenchLogoSymbol.propTypes = {
  color: PropTypes.string,
};

BenchLogoSymbol.defaultProps = {
  color: null,
};

export default BenchLogoSymbol;
