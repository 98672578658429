import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import FixedHeightImage from '../../ui/images/FixedHeightImage';
import { fontSizingCSS, pxToRem, responsiveFontSizingCSS } from '../../ui/constants/typography';
import { getDesktopColumnWidth, mediaQueries } from '../../ui/constants/grid';
import Image from '../../ui/images/Image';
import Link from '../../ui/buttons/Link';

const horizontalLayoutBreakpoint = 800;

const maxTextWidth = 440;

const getHeaderFontSizeAndLineHeight = (maxWidth) => {
  if (maxWidth >= getDesktopColumnWidth(10)) return [32, 40];
  if (maxWidth >= getDesktopColumnWidth(6)) return [26, 34];
  return [24, 30];
};

const Card = styled(({ horizontal, ...otherProps }) => <Link {...otherProps} />)`
  align-items: stretch;
  background: ${colors.white};
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  height: 100%;
  transition-duration: 150ms;
  transition-timing-function: ease-in;

  &:hover {
    box-shadow: 0 12px 50px -10px rgba(0, 0, 0, 0.1);
    transform: translateY(-1px);
  }

  ${({ horizontal }) =>
    horizontal &&
    mediaQueries.desktop`
    flex-direction: row;
  `}
`;

const ImageContainer = styled.div`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
  overflow: hidden;

  ${mediaQueries.mobile`
    flex: 0 0 300px;
  `}

  ${({ horizontal }) =>
    !horizontal &&
    mediaQueries.desktop`
    flex: 0 0 350px;
  `}

  ${({ horizontal }) =>
    horizontal &&
    mediaQueries.desktop`
    flex: 0 0 42%;
    min-height: 400px;
 `}
`;

const TextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px;
  text-align: center;

  ${({ horizontal }) =>
    horizontal &&
    mediaQueries.desktop`
    flex: 1;
    justify-content: center;
  `}
`;

const CardMeta = styled.div`
  color: ${colors.cloud};
  font-size: ${pxToRem(12)}rem;
  letter-spacing: ${pxToRem(2)}rem;
  margin: ${pxToRem(12)}rem 0;
  max-width: ${maxTextWidth}px;
  text-transform: uppercase;
`;

const DownloadCta = styled.div`
  border-bottom: 1px solid #0fa1a2;
  color: #0fa1a2;
  font-size: ${pxToRem(12)}rem;
  letter-spacing: ${pxToRem(2)}rem;
  margin: ${pxToRem(12)}rem 0;
  max-width: ${maxTextWidth}px;
  text-transform: uppercase;
`;

const CardHeader = styled.h2`
  color: ${colors.charcoal};
  font-family: LeituraDisplay, serif;
  ${({ fontSize, lineHeight }) =>
    responsiveFontSizingCSS({
      fontSizePixels: fontSize,
      lineHeightPixels: lineHeight,
      mobileFontSizePixels: 22,
      mobileLineHeightPixels: 28,
    })};
  margin: ${pxToRem(12)}rem 0;
  max-width: ${maxTextWidth}px;
  width: 100%;
  -webkit-font-smoothing: unset;
  -moz-osx-font-smoothing: auto;
`;

const CardDescription = styled.p`
  color: ${colors.charcoal};
  ${fontSizingCSS(14, 20)};
  margin: ${pxToRem(12)}rem 0;
  max-width: ${maxTextWidth}px;
  width: 100%;
`;

const WidgetContentCard = (props) => {
  const {
    backgroundColor,
    category,
    description,
    header,
    image,
    linkURL,
    maxWidth,
    mobileMaxWidth,
    valueAdd,
  } = props;

  const horizontal = maxWidth >= horizontalLayoutBreakpoint;

  const [headerFontSize, headerLineHeight] = getHeaderFontSizeAndLineHeight(maxWidth);

  return (
    <Card horizontal={horizontal} linkURL={linkURL}>
      <ImageContainer backgroundColor={backgroundColor} horizontal={horizontal}>
        {image && (
          <FixedHeightImage
            {...image}
            desktopFixedHeight={horizontal ? 400 : 350}
            maxWidth={maxWidth}
            mobileFixedHeight={300}
            mobileMaxWidth={mobileMaxWidth}
          />
        )}
      </ImageContainer>
      <TextContainer horizontal={horizontal}>
        <CardMeta>{category}</CardMeta>
        <CardHeader fontSize={headerFontSize} lineHeight={headerLineHeight}>
          {header}
        </CardHeader>
        <CardDescription>{description}</CardDescription>
        <DownloadCta>{valueAdd}</DownloadCta>
      </TextContainer>
    </Card>
  );
};

WidgetContentCard.propTypes = {
  backgroundColor: PropTypes.string,
  category: PropTypes.string.isRequired,
  description: PropTypes.string,
  header: PropTypes.string.isRequired,
  image: PropTypes.shape({
    ...Image.propTypes,
  }),
  linkURL: PropTypes.string.isRequired,
  maxWidth: PropTypes.number,
  mobileMaxWidth: PropTypes.number,
  valueAdd: PropTypes.string,
};

WidgetContentCard.defaultProps = {
  backgroundColor: colors.water,
  description: null,
  image: null,
  maxWidth: null,
  mobileMaxWidth: null,
  valueAdd: null,
};

export default WidgetContentCard;
