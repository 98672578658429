import React from 'react';
import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import Link from '../../ui/buttons/Link';
import { responsiveFontSizingCSS } from '../../ui/constants/typography';

const StyledLink = styled(Link)`
  border-bottom: 2px solid;
  color: ${colors.cloud};
  display: inline-block;
  ${responsiveFontSizingCSS({
    fontSizePixels: 16,
    lineHeightPixels: 28,
    mobileFontSizePixels: 14,
    mobileLineHeightPixels: 24,
  })}
`;

const LeftArrow = styled.span`
  color: ${colors.cloud};
  margin-right: 8px;
`;

const WidgetBackLink = ({ linkText, linkURL }) => (
  <span>
    <LeftArrow>←</LeftArrow>
    <StyledLink linkURL={linkURL}>{linkText}</StyledLink>
  </span>
);

WidgetBackLink.propTypes = {
  ...Link.propTypes,
};

WidgetBackLink.defaultProps = {
  ...Link.defaultProps,
};

export default WidgetBackLink;
