import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import { fontSizingCSS } from '../../ui/constants/typography';
import GridColumn from '../../ui/grid/GridColumn';
import GridRow from '../../ui/grid/GridRow';
import Image from '../../ui/images/Image';
import { mediaQueries } from '../../ui/constants/grid';
import markdownToHTML from '../helpers/markdownToHTML';
import WidgetCallToActionV2 from './WidgetCallToActionV2';

const horizontalLayoutBreakpoint = 500;

const getTextAlignment = (hasImage, horizontal) => {
  if (!hasImage && horizontal) {
    return 'center';
  }
  return 'left';
};

export const BannerContainer = styled.div`
  align-items: center;
  color: ${({ textColor }) => textColor || colors.charcoal};
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-position: 50% 50%;
  background-size: cover;
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
  ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow}`};
  display: flex;
  justify-content: center;
  padding: ${({ padding }) => padding || '45px 15px'};
  transition: 150ms ease-in;
  transition-property: box-shadow, transform;
  text-align: ${({ textAlignment }) => textAlignment};
  width: 100%;

  & > * {
    width: 100%;
  }

  img,
  picture {
    margin: 0 auto;
  }

  ${({ backgroundImgSrc }) => backgroundImgSrc && `background-image: url(${backgroundImgSrc});`};
  ${mediaQueries.mobile`
    background-image: none;
    padding: 30px 0;
    text-align: center;

    img {
      max-height: 300px;
    }
  `};
`;
const BannerHeader = styled.h3`
  ${({ headerFontSize, headerLineHeight }) =>
    fontSizingCSS(headerFontSize || 24, headerLineHeight || 32)};
  font-weight: bold;
  text-align: ${({ headerTextAlign }) => headerTextAlign};
  margin: 1rem 0;

  ${mediaQueries.mobile`
  font-size: ${({ mobileHeaderFontSize }) => mobileHeaderFontSize};
  text-align: ${({ mobileHeaderTextAlign }) => mobileHeaderTextAlign};
`};
`;

const BannerDescription = styled.div`
  ${({ descFontSize, descLineHeight }) => fontSizingCSS(descFontSize || 14, descLineHeight || 20)};
  text-align: ${({ descTextAlign }) => descTextAlign};
  margin: 1rem 0;

  ${mediaQueries.mobile`
  font-size: ${({ mobileDescFontSize }) => mobileDescFontSize};
  text-align: ${({ mobileDescTextAlign }) => mobileDescTextAlign};
`};
`;

const WidgetBannerV2 = ({
  backgroundColor,
  backgroundImgSrc,
  borderRadius,
  boxShadow,
  description,
  descFontSize,
  descLineHeight,
  descTextAlign,
  header,
  headerFontSize,
  headerLineHeight,
  headerTextAlign,
  image,
  imagePosition,
  maxWidth,
  mobileMaxWidth,
  padding,
  textColor,
  callToActionButton,
  mobileDescTextAlign,
  mobileDescFontSize,
  mobileHeaderTextAlign,
  mobileHeaderFontSize,
}) => {
  const horizontal = maxWidth >= horizontalLayoutBreakpoint;

  const hasImage = !!image;

  const { buttonAlignment, buttonText, linkURL, mobileButtonAlignment, styleType, width } =
    callToActionButton;

  return (
    <BannerContainer
      backgroundColor={backgroundColor}
      backgroundImgSrc={backgroundImgSrc}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      horizontal={horizontal}
      padding={padding}
      textAlignment={getTextAlignment(hasImage, horizontal)}
      textColor={textColor}
    >
      <GridRow alignItems="center" justifyContent="center">
        {hasImage && (
          <GridColumn order={imagePosition === 'before' ? 1 : 3} span={horizontal ? 5 : 12}>
            <Image {...image} maxWidth={maxWidth} mobileMaxWidth={mobileMaxWidth} />
          </GridColumn>
        )}
        <GridColumn order={2} span={horizontal ? 7 : 12}>
          {header && (
            <BannerHeader
              headerFontSize={headerFontSize}
              headerLineHeight={headerLineHeight}
              headerTextAlign={headerTextAlign}
              mobileHeaderFontSize={mobileHeaderFontSize}
              mobileHeaderTextAlign={mobileHeaderTextAlign}
            >
              {header}
            </BannerHeader>
          )}
          {description && (
            <BannerDescription
              dangerouslySetInnerHTML={{ __html: markdownToHTML(description) }}
              descFontSize={descFontSize}
              descLineHeight={descLineHeight}
              descTextAlign={descTextAlign}
              mobileDescFontSize={mobileDescFontSize}
              mobileDescTextAlign={mobileDescTextAlign}
            />
          )}
          {callToActionButton && (
            <WidgetCallToActionV2
              buttonAlignment={buttonAlignment}
              buttonText={buttonText}
              linkURL={linkURL}
              mobileButtonAlignment={mobileButtonAlignment}
              styleType={styleType}
              width={width}
            />
          )}
        </GridColumn>
      </GridRow>
    </BannerContainer>
  );
};

WidgetBannerV2.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImgSrc: PropTypes.string,
  borderRadius: PropTypes.number,
  boxShadow: PropTypes.number,
  callToActionButton: PropTypes.string,
  ctaTarget: PropTypes.string,
  descFontSize: PropTypes.number,
  descLineHeight: PropTypes.number,
  description: PropTypes.string,
  descTextAlign: PropTypes.string,
  header: PropTypes.string,
  headerFontSize: PropTypes.number,
  headerLineHeight: PropTypes.number,
  headerTextAlign: PropTypes.string,
  image: PropTypes.shape({
    ...Image.propTypes,
  }),
  imagePosition: PropTypes.oneOf(['after', 'before']),
  linkURL: PropTypes.string,
  maxWidth: PropTypes.number,
  mobileDescFontSize: PropTypes.number,
  mobileDescTextAlign: PropTypes.string,
  mobileHeaderFontSize: PropTypes.number,
  mobileHeaderTextAlign: PropTypes.string,
  mobileMaxWidth: PropTypes.number,
  padding: PropTypes.number,
  textColor: PropTypes.string,
};

WidgetBannerV2.defaultProps = {
  backgroundColor: null,
  backgroundImgSrc: null,
  borderRadius: null,
  boxShadow: null,
  callToActionButton: null,
  ctaTarget: '_blank',
  descFontSize: null,
  descLineHeight: null,
  descTextAlign: null,
  description: null,
  header: null,
  headerFontSize: null,
  headerLineHeight: null,
  headerTextAlign: null,
  image: null,
  imagePosition: null,
  linkURL: null,
  maxWidth: null,
  mobileDescTextAlign: null,
  mobileDescFontSize: null,
  mobileHeaderTextAlign: null,
  mobileHeaderFontSize: null,
  mobileMaxWidth: null,
  padding: null,
  textColor: null,
};

export default WidgetBannerV2;
