import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import { mediaQueries } from '../../ui/constants/grid';

export const navbarMinHeight = 70;

const NavContainer = styled.nav`
  background-color: ${({ isTransparent }) => (isTransparent ? 'transparent' : colors.white)};
  min-height: ${navbarMinHeight}px;
  padding: 0 30px;

  a {
    ${({ linkColor }) => mediaQueries.desktop`
      color: ${linkColor};
    `};
    ${({ linkColorMobile }) => mediaQueries.mobile`
      color: ${linkColorMobile};
    `};
  }

  ${({ openOnMobile }) =>
    openOnMobile &&
    mediaQueries.mobile`
    background-color: ${colors.white};
    bottom: 0;
    overflow: auto;
  `};
`;

NavContainer.propTypes = {
  isTransparent: PropTypes.bool,
  linkColor: PropTypes.string,
  linkColorMobile: PropTypes.string,
  openOnMobile: PropTypes.bool,
};

NavContainer.defaultProps = {
  isTransparent: false,
  linkColor: null,
  linkColorMobile: null,
  openOnMobile: false,
};

export default NavContainer;
