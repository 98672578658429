/**
 * Takes information about a person's corporation's business financials and
 * returns the estimated amount of tax they would pay per quarter.
 *
 * If invalid input is passed to this function it will throw an error with a
 * validation message.
 *
 * All numerical input amounts are in cents.
 *
 * @param {Object} formData
 * @param {number} formData.alternativeFuelTaxCredit
 * @param {number} formData.alternativeTaxQualifiedShipping
 * @param {number} formData.bondCredits
 * @param {number} formData.electricVehicleCredit
 * @param {number} formData.expectedTaxableIncome
 * @param {number} formData.foreignTaxCredit
 * @param {number} formData.generalBusinessCredit
 * @param {number} formData.interestDueIncomeForecast
 * @param {number} formData.interestDueLongTermContracts
 * @param {number} formData.priorYearFederalMinimumTaxCredit
 * @param {number} formData.priorYearMinimumTaxCredit
 * @param {number} formData.recaptureLowIncomeHousingCredit
 * @param {number} formData.recaptureOfInvestmentCredit
 * @param {number} formData.totalTaxPreviousYear
 * @param {number} formData.undistributedCapitalGains
 * @return {number} estimated quarterly tax in cents
 */
const calculateQuarterlyTaxForCorporation = ({
  alternativeFuelTaxCredit,
  alternativeTaxQualifiedShipping,
  bondCredits,
  electricVehicleCredit,
  expectedTaxableIncome,
  foreignTaxCredit,
  generalBusinessCredit,
  interestDueIncomeForecast,
  interestDueLongTermContracts,
  priorYearFederalMinimumTaxCredit,
  priorYearMinimumTaxCredit,
  recaptureLowIncomeHousingCredit,
  recaptureOfInvestmentCredit,
  totalTaxPreviousYear,
  undistributedCapitalGains,
}) => {
  // Comments refer to the following document:
  // https://docs.google.com/spreadsheets/d/1nenVTALmlaYacBQOySIThlvto25D6S3MWPKNMOHMdjg/edit#gid=418387912

  // A: Taxable income expected for the tax year
  // B: Multiply A by 21%
  const adjustedExpectedTaxableIncome = (expectedTaxableIncome * 21) / 100;

  // C: Add up all inserted tax credits
  const insertedTaxCredits =
    foreignTaxCredit +
    electricVehicleCredit +
    generalBusinessCredit +
    priorYearMinimumTaxCredit +
    bondCredits;

  // D: Subtract C from B
  const incomeMinusInsertedCredits = adjustedExpectedTaxableIncome - insertedTaxCredits;

  // E: Add up all other accumulated taxes
  const otherAccumulatedTaxes =
    recaptureOfInvestmentCredit +
    recaptureLowIncomeHousingCredit +
    interestDueLongTermContracts +
    interestDueIncomeForecast +
    alternativeTaxQualifiedShipping;

  // F: Add up D and E
  const withOtherAccumulatedTaxes = incomeMinusInsertedCredits + otherAccumulatedTaxes;

  // G: Add up all federal tax amounts
  const federalTaxCredits =
    undistributedCapitalGains + alternativeFuelTaxCredit + priorYearFederalMinimumTaxCredit;

  // H: Subtract G from F
  const taxMinusFederalTaxCredits = withOtherAccumulatedTaxes - federalTaxCredits;

  // I: Prior Year Tax amount aka Enter the tax shown on the corporation’s 2018 tax return
  // J: If I is 0 carry forward the outcome with the value of H
  let taxAmountForYear;
  if (totalTaxPreviousYear === 0) {
    taxAmountForYear = taxMinusFederalTaxCredits;
  } else {
    // J: If I is not 0 carry forward the smaller one of I or H
    taxAmountForYear = Math.min(totalTaxPreviousYear, taxMinusFederalTaxCredits);
  }

  // "If it is less than $500.00 the corporation is not expected to make quarterly tax payments"
  if (taxAmountForYear < 50000) {
    return 0;
  }

  // K: Calculate 25% of J
  const oneQuarterOfTaxAmountForYear = (taxAmountForYear * 25) / 100;

  // Round to the nearest cent
  // Return the amount
  return Math.round(oneQuarterOfTaxAmountForYear);
};

export default calculateQuarterlyTaxForCorporation;
