import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../constants/colors';

const InputContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const Label = styled.label`
  position: absolute;
  top: ${({ active }) => (active ? '0.4rem' : '-2rem')};
  left: 1rem;
  font-size: 0.65rem;
  pointer-events: none;
  transition: top 100ms ease-out;
  color: ${({ error }) => error && colors.copper};
`;

export const StyledInput = styled.input`
  width: 100%;
  border: 0;
  background-color: ${({ theme }) => colors[theme] || theme || colors.sand};
  box-shadow: ${({ boxShadow }) => (boxShadow ? '1px 2px 5px 0 #e4e4e4;' : 'none')};
  padding: ${({ active }) => (active ? '1.35rem 1rem .65rem 1rem' : '1rem')};
  border-radius: 4px;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  transition: all 100ms ease-out;
  ${({ theme }) =>
    (theme === 'white' || theme === colors.white) &&
    `
    &::placeholder {
      color: ${colors.cloud};
    }
  `};
`;

const Input = ({
  autoCapitalize,
  autoComplete,
  autoFocus,
  boxShadow,
  className,
  dataTestId,
  errorText,
  id,
  name,
  onChange,
  placeholder,
  required,
  theme,
  type,
  value,
}) => {
  const isActive = !!value || !!errorText;

  return (
    <InputContainer>
      <Label active={isActive} error={!!errorText} htmlFor={id || name}>
        {errorText || placeholder}
      </Label>
      <input id="gclid_field" name="gclid_field" type="hidden" value="" />
      <StyledInput
        active={isActive}
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        boxShadow={boxShadow}
        className={className}
        data-test-id={dataTestId}
        id={id || name}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        theme={theme}
        type={type}
        value={value}
      />
    </InputContainer>
  );
};

Input.propTypes = {
  autoCapitalize: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  boxShadow: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  errorText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  theme: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

Input.defaultProps = {
  autoCapitalize: null,
  autoComplete: null,
  autoFocus: false,
  boxShadow: false,
  className: null,
  dataTestId: null,
  errorText: null,
  id: null,
  name: null,
  onChange: null,
  placeholder: null,
  required: false,
  theme: 'sand',
  type: 'text',
  value: null,
};

export default Input;
