import WidgetSignupForm from '../../../components/widgets/WidgetSignupForm';

const widgetFormTranslation = ({
  buttonBackgroundColor,
  buttonTextColor,
  orientation,
  submitButtonText,
  submitFlow,
  theme,
  signUpFunnel,
}) => {
  const translatedData = {
    buttonBackgroundColor,
    buttonText: submitButtonText,
    buttonTextColor,
    flow: submitFlow,
    horizontal: orientation === 'horizontal',
    signUpFunnel,
    theme,
  };

  return {
    data: translatedData,
    WidgetComponent: WidgetSignupForm,
  };
};

export default widgetFormTranslation;
