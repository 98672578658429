require('./src/resources/vendor/polyfills');
const React = require('react');
const GlobalStyles = require('./src/ui/globalStyles').default;
const ErrorBoundary = require('./src/components/ErrorBoundary').default;

// In the event that the browser fails to scroll to the element referenced by the fragment
// identifier, after a delay, scroll to the element.
const fallbackScrollToHash = () => {
  const idSelector = window.location.hash;
  if (!idSelector) return;

  let scrollToElement;

  try {
    scrollToElement = document.querySelector(idSelector);
  } catch (error) {
    // In this case, we ignore malformed selectors, which cause this function to throw an error
    return;
  }

  if (!scrollToElement) return;

  setTimeout(() => {
    scrollToElement.scrollIntoView();
  }, 1000);
};

const updateAnalyticsPageInfo = () => {
  if (!window.analytics || typeof window.analytics.page !== 'function') {
    // FIXME Does this always fail on the first route load before navigation via an internal link?
    console.warn('Unable to locate analytics.js');
    return;
  }

  if (window.Munchkin) {
    window.Munchkin.munchkinFunction('visitWebPage', {
      params: window.location.search,
      url: window.location.pathname,
    });
  }

  setTimeout(() => {
    // GA will use the first domain it loads as the page domain
    // Because this is a mix of SPA and Static Sites,
    // we need to manually update the domain after the update is complete
    // (Segment has no mechanism for this)
    if (typeof window.ga === 'function') {
      window.ga('set', 'location', window.location.href);
    }

    const pageParams = {
      title: document.title,
      url: window.location.href,
      path: window.location.pathname,
    };

    window.analytics.page(pageParams);
  }, 2000);
};

exports.wrapPageElement = ({ element }) => (
  <ErrorBoundary>
    <GlobalStyles />
    {element}
  </ErrorBoundary>
);

exports.onRouteUpdate = () => {
  fallbackScrollToHash();
  updateAnalyticsPageInfo();
};
