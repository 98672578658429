import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import WidgetCallToAction from './WidgetCallToAction';

import colors from '../../ui/constants/colors';
import { HideOnDesktop, HideOnMobile } from '../../ui/responsive/ResponsiveHidden';
import Link from '../../ui/buttons/Link';
import { mediaQueries } from '../../ui/constants/grid';
import WidgetValueProp from './WidgetValueProp';

const JobBoardWrapper = styled.div`
  min-height: 345px;
`;

const JobLink = styled(Link)`
  color: ${colors.water};
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0;

  &:hover {
    text-decoration: underline;
  }
`;

const JobTeam = styled.div`
  color: ${colors.ash};
  font-size: 16px;
  padding: 5px 0;
`;

const JobLocation = styled(JobTeam)`
  width: 150px;
  margin-right: 10px;
`;

const JobTable = styled.table`
  line-height: 1.5;
  width: 100%;

  td {
    border-top: 0.5px solid #88d1d1;
    padding: 15px 0;
  }

  th {
    border-bottom: 3px solid #0fa1a2;
    color: ${colors.charcoal};
    font-size: 13px;
    letter-spacing: 1.2px;
    padding: 18px 0;
    text-align: left;
    text-transform: uppercase;
  }
`;

const MobileJobContainer = styled.div`
  border-bottom: 0.5px solid #88d1d1;
  padding: 15px 0;
`;

const JobFilterDropdownContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  grid-gap: 20px;
  align-items: center;
  margin-bottom: 20px;

  ${mediaQueries.mobile`
      grid-template-columns: 1fr;
  `};
`;

const NoOpeningsWrapper = styled.div`
  padding: 120px 0;
`;

const reactSelectStyles = {
  control: (provided) => ({
    ...provided,
    background: colors.white,
    borderColor: colors.white,
    borderRadius: '4px',
    boxShadow: 'none',
    color: colors.cloud,
    fontSize: '14px',
    height: '50px',
    paddingLeft: '16px',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menuList: (provided) => ({
    ...provided,
    background: colors.white,
    borderRadius: '4px',
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: isFocused ? '#F6F6F6' : 'transparent',
    color: colors.cloud,
    fontSize: '14px',
    padding: '12px 26px',
    '&:hover': {
      backgroundColor: '#F6F6F6',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
    color: colors.cloud,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.charcoal,
  }),
};

const WidgetJobBoard = () => {
  const [jobs, setJobs] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [location, setLocation] = useState(null);
  const [team, setTeam] = useState(null);

  const setFilterOptions = () => {
    const locations = new Set();
    const teams = new Set();

    jobs.map((item) => {
      locations.add(item.location.name);
      return teams.add(item.team);
    });

    setLocationOptions(
      Array.from(locations)
        .sort()
        .map((loc) => {
          return {
            value: loc,
            label: loc,
          };
        }),
    );

    setTeamOptions(
      Array.from(teams)
        .sort()
        .map((t) => {
          return {
            value: t,
            label: t,
          };
        }),
    );
  };

  const setJobsFromDepartments = (departments) => {
    const sortedJobs = departments.reduce((allJobs, department) => {
      const departmentJobs = department.jobs;
      if (!departmentJobs) {
        return allJobs;
      }
      const departmentJobsWithTeam = departmentJobs.map((job) => ({
        ...job,
        team: department.name,
      }));
      return [...allJobs, ...departmentJobsWithTeam];
    }, []);
    // Multi-sort first by team, then by job title
    sortedJobs.sort((a, b) => {
      const aTeam = a.team.toLowerCase();
      const bTeam = b.team.toLowerCase();
      if (aTeam > bTeam) {
        return 1;
      }
      if (aTeam < bTeam) {
        return -1;
      }
      const aTitle = a.title.toLowerCase();
      const bTitle = b.title.toLowerCase();
      if (aTitle > bTitle) {
        return 1;
      }
      if (aTitle < bTitle) {
        return -1;
      }
      return 0;
    });
    setJobs(sortedJobs);
  };

  const filterAllJobs = () => {
    const filters = [() => true];

    if (location) {
      filters.push((item) => item.location.name === location);
    }

    if (team) {
      filters.push((item) => item.team === team);
    }

    return jobs.filter((job) => filters.every((filter) => filter(job)));
  };

  const handleLocationChange = (option) => {
    setSelectedLocation(option);
    setLocation(option.value);
  };

  const handleTeamChange = (option) => {
    setSelectedTeam(option);
    setTeam(option.value);
  };

  const clearFilters = () => {
    setSelectedLocation('');
    setSelectedTeam('');
    setLocation('');
    setTeam('');
  };

  const fetchJobs = (resetRetryDelay = true) => {
    let retryDelay;
    let retryTimeout;
    if (resetRetryDelay) {
      retryDelay = 1000;
    }
    const fetchJobsRequest = axios.CancelToken.source();
    axios('https://boards-api.greenhouse.io/v1/boards/bench/departments', {
      cancelToken: fetchJobsRequest.token,
    })
      .then((res) => {
        const { departments } = res.data;
        if (!departments) {
          return;
        }
        setJobsFromDepartments(departments);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return;
        }
        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
        if (retryTimeout) {
          clearTimeout(retryTimeout);
        }
        retryTimeout = setTimeout(() => {
          retryDelay *= 2;
          fetchJobs(false);
        }, retryDelay);
      });
  };

  useEffect(() => {
    if (!jobs.length) {
      fetchJobs();
    } else {
      setFilterOptions();
    }
  }, [jobs]);

  if (jobs === null) {
    return 'Loading jobs...';
  }
  return (
    <JobBoardWrapper>
      <JobFilterDropdownContainer>
        <Select
          onChange={handleLocationChange}
          options={locationOptions}
          placeholder="Select Location"
          styles={reactSelectStyles}
          value={selectedLocation}
        />
        <Select
          onChange={handleTeamChange}
          options={teamOptions}
          placeholder="Select Team"
          styles={reactSelectStyles}
          value={selectedTeam}
        />
        <WidgetCallToAction
          backgroundColor={colors.water}
          buttonText="Clear"
          onClick={clearFilters}
          textColor={colors.white}
        />
      </JobFilterDropdownContainer>
      <HideOnMobile>
        {filterAllJobs().length > 0 ? (
          <JobTable>
            <thead>
              <tr>
                <th>Position</th>
                <th>Location</th>
                <th>Team</th>
              </tr>
            </thead>
            <tbody>
              {filterAllJobs().map((job) => (
                <tr key={job.id}>
                  <td>
                    <JobLink linkURL={job.absolute_url}>{job.title}</JobLink>
                  </td>
                  <td>
                    <JobLocation>{job.location.name}</JobLocation>
                  </td>
                  <td>
                    <JobTeam>{job.team}</JobTeam>
                  </td>
                </tr>
              ))}
            </tbody>
          </JobTable>
        ) : (
          <NoOpeningsWrapper>
            <WidgetValueProp
              description="No Current Openings - Please Clear The Current Filter and Try Again."
              textAlignment="center"
            />
          </NoOpeningsWrapper>
        )}
      </HideOnMobile>
      <HideOnDesktop>
        {filterAllJobs().length > 0 ? (
          filterAllJobs().map((job) => (
            <MobileJobContainer key={job.id}>
              <JobLink linkURL={job.absolute_url}>{job.title}</JobLink>
              <JobLocation>{job.location.name}</JobLocation>
              <JobTeam>{job.team}</JobTeam>
            </MobileJobContainer>
          ))
        ) : (
          <NoOpeningsWrapper>
            <WidgetValueProp
              description="No Current Openings - Please clear the filter and try again"
              textAlignment="center"
            />
          </NoOpeningsWrapper>
        )}
      </HideOnDesktop>
    </JobBoardWrapper>
  );
};

export default WidgetJobBoard;
