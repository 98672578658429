import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import LinkButton from '../../ui/buttons/LinkButton';
import { mediaQueries } from '../../ui/constants/grid';

// Call to action buttons are always center aligned on mobile
const CallToActionContainer = styled.div`
  text-align: ${({ mobileButtonAlignment }) => mobileButtonAlignment || 'center'};

  ${mediaQueries.desktop`
    text-align: ${({ buttonAlignment }) => buttonAlignment || 'inherit'};
  `}
`;

CallToActionContainer.propTypes = {
  buttonAlignment: PropTypes.oneOf(['center', 'left', 'right']),
  mobileButtonAlignment: PropTypes.oneOf(['center', 'left', 'right']),
};

CallToActionContainer.defaultProps = {
  buttonAlignment: null,
  mobileButtonAlignment: null,
};

const WidgetCallToAction = ({
  backgroundColor,
  borderColor,
  buttonAlignment,
  buttonText,
  className,
  hoverColor,
  hoverTextColor,
  linkURL,
  mobileButtonAlignment,
  onClick,
  textColor,
}) => (
  <CallToActionContainer
    buttonAlignment={buttonAlignment}
    mobileButtonAlignment={mobileButtonAlignment}
  >
    <LinkButton
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      className={className}
      hoverColor={hoverColor}
      hoverTextColor={hoverTextColor}
      linkURL={linkURL}
      onClick={onClick}
      textColor={textColor}
    >
      {buttonText}
    </LinkButton>
  </CallToActionContainer>
);

WidgetCallToAction.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  buttonAlignment: PropTypes.oneOf(['center', 'left', 'right']),
  buttonText: PropTypes.string,
  className: PropTypes.string,
  hoverColor: PropTypes.string,
  hoverTextColor: PropTypes.string,
  linkURL: PropTypes.string,
  mobileButtonAlignment: PropTypes.oneOf(['center', 'left', 'right']),
  onClick: PropTypes.func,
  textColor: PropTypes.string,
};

WidgetCallToAction.defaultProps = {
  backgroundColor: null,
  borderColor: null,
  buttonAlignment: null,
  buttonText: null,
  className: null,
  hoverColor: null,
  hoverTextColor: null,
  linkURL: null,
  mobileButtonAlignment: null,
  onClick: null,
  textColor: null,
};

export default WidgetCallToAction;
