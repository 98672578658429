import widgetHomePageCustomBanner from '../../../components/widgets/widgetHomePageCustomBanner';
import tryGet from '../../../components/helpers/tryGet';

const widgetHomePageCustomBannerTranslation = ({ valuePropBody, ...props }) => ({
  data: {
    ...props,
    // The preview environment doesn't nest long text in the same way
    // that the graphQL queries do. This helps us handle that difference by allowing
    // both `description: { description: 'text' }` and `description: 'text'`
    valuePropBody: tryGet(['valuePropBody'], valuePropBody) || valuePropBody,
  },
  WidgetComponent: widgetHomePageCustomBanner,
});

export default widgetHomePageCustomBannerTranslation;
