import React from 'react';
import PropTypes from 'prop-types';

import generatePricingData from '../pricing/generatePricingData';
import GridColumn from '../../ui/grid/GridColumn';
import GridRow from '../../ui/grid/GridRow';
import PricingPlan from '../pricing/PricingPlan';
import serviceTypes from '../pricing/serviceTypes';

// React currently doesn't recognize imported objects for PropTypes definition,
// object must be defined in file
const serviceTypesForPropTypes = { ...serviceTypes };

const WidgetPricing = ({
  boutiqueDiscountAmount,
  corporateDiscountAmount,
  discountNumMonths,
  hideCta,
  microDiscountAmount,
  serviceType,
  starterDiscountAmount,
  ventureDiscountAmount,
}) => {
  const pricingPlans = generatePricingData(serviceType, {
    starter: starterDiscountAmount,
    micro: microDiscountAmount,
    boutique: boutiqueDiscountAmount,
    venture: ventureDiscountAmount,
    corporate: corporateDiscountAmount,
    discountNumMonths,
  });

  return (
    <GridRow>
      {pricingPlans.map((pricingPlan) => (
        <GridColumn key={pricingPlan.name} span={2.4}>
          <PricingPlan
            discountNumMonths={discountNumMonths}
            hideCta={hideCta}
            serviceType={serviceType}
            {...pricingPlan}
          />
        </GridColumn>
      ))}
    </GridRow>
  );
};

WidgetPricing.propTypes = {
  boutiqueDiscountAmount: PropTypes.number,
  corporateDiscountAmount: PropTypes.number,
  discountNumMonths: PropTypes.number,
  hideCta: PropTypes.bool,
  microDiscountAmount: PropTypes.number,
  serviceType: PropTypes.oneOf(Object.values(serviceTypesForPropTypes)).isRequired,
  starterDiscountAmount: PropTypes.number,
  ventureDiscountAmount: PropTypes.number,
};

WidgetPricing.defaultProps = {
  boutiqueDiscountAmount: 0,
  corporateDiscountAmount: 0,
  discountNumMonths: 0,
  hideCta: false,
  microDiscountAmount: 0,
  starterDiscountAmount: 0,
  ventureDiscountAmount: 0,
};

export default WidgetPricing;
