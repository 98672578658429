/**
 * The rules:
 *
 * - If the background of the nav is white, show the `defaultColor`
 * - The background is white when `isTransparent` is `false`
 * - The background is white when on mobile and `navOpen` is `true`
 *
 * - If the background of the nav is transparent, show the `colorForTransparentBackground`
 * - The background is transparent when `isTransparent` is `true`
 * - The background is transparent on mobile when `isTransparent` is `true` and `navOpen` is `false`
 *
 * @param navOpen Whether the mobile navigation dropdown is open
 * @param isTransparent Whether the user has scrolled the page making navbar background become white
 * @param colorForTransparentBackground The colour to show when scrolled to the top of the page
 * @param defaultColor The colour to show when on a white background
 * @return {Object} With `color` and `colorMobile` properties
 */
const getDynamicColors = (navOpen, isTransparent, colorForTransparentBackground, defaultColor) => {
  const contrastColor = colorForTransparentBackground || defaultColor;
  return {
    color: isTransparent ? contrastColor : defaultColor,
    colorMobile: isTransparent && !navOpen ? contrastColor : defaultColor,
  };
};

export default getDynamicColors;
