const path = require('path');
const normalizePathComponent = require('./normalizePathComponent');
const devPagePrefix = require('./devPagePrefix');

const buildAuthorUrl = (bio) => {
  if (!bio) return undefined;

  const { slug } = bio;
  const normalizedSlug = normalizePathComponent(slug);
  const pagePrefix = `${devPagePrefix(slug)}/author`;
  const prettyPath = path.resolve(pagePrefix, normalizedSlug);
  return `${prettyPath}/`;
};

module.exports = buildAuthorUrl;
