/* eslint-disable react/prop-types */
import GatsbyLink from 'gatsby-link';
import PropTypes from 'prop-types';
import React from 'react';

import isGatsbyLink from './isGatsbyLink';

const Link = ({
  linkURL,
  onClick,
  // Omit the `href` and `to` props from `otherProps`
  href,
  to,
  ...otherProps
}) => {
  const useLinkElement = isGatsbyLink(linkURL);
  const El = useLinkElement ? GatsbyLink : 'a';
  const linkAttr = useLinkElement ? 'to' : 'href';
  return (
    <El
      {...{
        [linkAttr]: linkURL,
        ...otherProps,
      }}
      onClick={(e) => {
        return onClick && onClick(e);
      }}
    />
  );
};

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  linkURL: PropTypes.string,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  children: null,
  className: null,
  linkURL: null,
  onClick: null,
};

export default Link;
