import getDefaultContentCardImage from './getDefaultContentCardImage';
import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';
import tryGet from '../../../components/helpers/tryGet';
import WidgetContentCard from '../../../components/widgets/WidgetContentCard';

const translateArticleLinkCard = ({
  header,
  category,
  articleLinkCardDescription,
  articleLinkCardHeaderImage,
  articleLinkCardContentImage,
  linkUrl,
  ctaLink,
  id,
}) => {
  const image = articleLinkCardContentImage || articleLinkCardHeaderImage;

  return {
    data: {
      category,
      description:
        tryGet(['articleLinkCardDescription'], articleLinkCardDescription) ||
        articleLinkCardDescription,
      header,
      image:
        image && image.file && image.file.url
          ? normalizeContentfulImageData(image)
          : getDefaultContentCardImage(category, id),
      linkURL: linkUrl,
      valueAdd: ctaLink,
    },
    id,
    WidgetComponent: WidgetContentCard,
  };
};

export default translateArticleLinkCard;
