import React, { Component } from 'react';

export const ASSET_STATUS_LOADING = 0;
export const ASSET_STATUS_LOADED = 1;
export const ASSET_STATUS_FAILED = 2;

/**
 * Returns a wrapped component that manages the status of loading external assets. Takes a component
 * with a `.loadExternalAssets` static function that loads external assets and returns a promise
 * that resolves when all the assets have loaded.
 *
 * Passes two props to the wrapped component: `assetStatus` and `retryLoadAssets`.
 *
 * `assetStatus` is one of the exported `ASSET_STATUS_` constants. These are updated based on the
 * status of the promise returned by `loadExternalAssets`.
 *
 * `retryLoadAssets` is a function that when called will reset the `assetStatus` to
 * `ASSET_STATUS_LOADING` and then call `loadExternalAssets` again. This might be necessary if
 * loading the assets failed and the promise rejected.
 *
 * @param {*} WrappedComponent A React component with a static `loadExternalAssets` function
 * @return {*} Wrapped React component that is passed `assetStatus` and `retryLoadAssets` props
 */
const externalAssetLoading = (WrappedComponent) => {
  class ExternalAssetLoadingComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        assetStatus: ASSET_STATUS_LOADING,
      };
    }

    componentDidMount() {
      this.loadAssets();
    }

    loadAssets = () => {
      // If the caller didn't define a `loadExternalAssets` function, warn the developer that they
      // have not configured the component correctly.
      if (typeof WrappedComponent.loadExternalAssets !== 'function') {
        if (process.env.GATSBY_ENV !== 'production') {
          console.warn('`WrappedComponent` must define a static `loadExternalAssets` function');
        }
        return;
      }
      WrappedComponent.loadExternalAssets(this.props)
        .then(() => {
          this.setState({ assetStatus: ASSET_STATUS_LOADED });
        })
        .catch(() => {
          this.setState({ assetStatus: ASSET_STATUS_FAILED });
        });
    };

    retryLoadAssets = () => {
      this.setState({ assetStatus: ASSET_STATUS_LOADING });
      this.loadAssets();
    };

    render() {
      return (
        <WrappedComponent
          assetStatus={this.state.assetStatus}
          retryLoadAssets={() => this.retryLoadAssets()}
          {...this.props}
        />
      );
    }
  }

  // Ensure users of the wrapper component have access to propTypes and defaultProps so that they
  // can still do propTypes composition.
  ExternalAssetLoadingComponent.propTypes = {
    ...WrappedComponent.propTypes,
  };

  ExternalAssetLoadingComponent.defaultProps = {
    ...WrappedComponent.defaultProps,
  };

  return ExternalAssetLoadingComponent;
};

export default externalAssetLoading;
