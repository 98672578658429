import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import Image from '../../ui/images/Image';
import { fontSizingCSS } from '../../ui/constants/typography';
import Link from '../../ui/buttons/Link';

const DropdownMenu = styled.div`
  ${fontSizingCSS(14, 18)}
`;

const Dropdown = styled.div`
  align-items: center;
  border-radius: 3px;
  background-color: ${colors.white};
  color: ${colors.smoke};
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: space-between;
  margin-bottom: 2px;
  padding: 0 28px;
`;

const DropdownBody = styled.ul`
  background-color: ${colors.white};
  border-radius: 3px;
  display: ${({ open }) => (open ? 'block' : 'none')};
  overflow: hidden;
`;

const DropdownLink = styled(Link)`
  color: ${colors.smoke};
  display: block;
  padding: 12px 28px;

  &:hover {
    background-color: #e3efef;
  }
`;

const ImageContainer = styled.div`
  img {
    transform: ${({ open }) => (open ? 'translateY(0%) rotate(180deg)' : 'initial')};
  }
`;

const WidgetDropdownMenu = ({ items, label }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DropdownMenu>
      <Dropdown onClick={toggle}>
        <div>{label}</div>
        <ImageContainer open={isOpen}>
          <Image src="/img/icons/DropdownArrow.svg" />
        </ImageContainer>
      </Dropdown>
      <DropdownBody open={isOpen}>
        {items &&
          items.map(({ name, url }) => (
            <li key={name}>
              <DropdownLink linkURL={url}>{name}</DropdownLink>
            </li>
          ))}
      </DropdownBody>
    </DropdownMenu>
  );
};

WidgetDropdownMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.node.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  label: PropTypes.string,
};

WidgetDropdownMenu.defaultProps = {
  label: null,
};

export default WidgetDropdownMenu;
