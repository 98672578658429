/*
 * About This File
 * ===============
 *
 * We use bench-www to generate the error pages that are served from our infrastructure. That way
 * they're on brand with the rest of our public facing properties. For more information, see the
 * file UPDATING_ERROR_PAGES.md in the project root.
 */
import { Helmet } from 'react-helmet';
import React from 'react';
import styled from 'styled-components';

import colors from '../../../ui/constants/colors';
import Page from '../../../components/Page';
import Image from '../../../ui/images/Image';

const MaintenancePageHeading = styled.h1`
  color: ${colors.charcoal};
  font-family: LeituraDisplay, serif;
  font-size: 32px;
  font-weight: normal;
  line-height: 40px;
  margin: 0 0 16px;
  text-align: center;
`;

const MaintenancePageDescription = styled.h1`
  color: ${colors.charcoal};
  font-size: 16px;
  font-weight: normal;
  margin: 0 0 8px;
  text-align: center;

  a {
    color: ${colors.water};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const MaintenancePageImage = styled.div`
  display: flex;
  justify-content: center;
  margin: 64px 0 0;
`;

const MaintenancePageMessage = () => (
  <div>
    <Helmet>
      {/* This page might be served from domains other than bench.co so this ensures the links all
       * point to the marketing website */}
      <base href="https://bench.co/" />
      {/* A timeout is set so that the page will reload without the cache. When the site is in
       * maintenance mode, we want it to be replaced by the working app automatically once
       * maintenance is complete */}
      <script>
        {`
          document.addEventListener('DOMContentLoaded', function reloadAfterTimeout() {
            setTimeout(function reloadPage() {
              location.reload(true);
            }, 30000);
          });
        `}
      </script>
    </Helmet>
    <MaintenancePageHeading>Sorry for the inconvenience.</MaintenancePageHeading>
    <MaintenancePageDescription>
      Our app is currently undergoing scheduled maintenance. We’ll be back up and running shortly!
    </MaintenancePageDescription>
    <MaintenancePageDescription>
      In the meantime, check out our latest{' '}
      <a href="https://bench.co/blog/">small business resources</a>.
    </MaintenancePageDescription>
    <MaintenancePageImage>
      <Image
        alt="An analog clock"
        imageAlignment="center"
        isAboveFold
        maxWidth={166}
        mobileMaxWidth={142}
        naturalHeight={1403}
        naturalWidth={1200}
        src="/img/icons/CLOCK.png"
      />
    </MaintenancePageImage>
  </div>
);

const pageData = {
  description: 'Scheduled Maintenance',
  enableAbTesting: false,
  footerType: 'simple',
  navbarAlwaysOpaque: true,
  navbarType: 'full',
  path: '/maintenance/',
  title: 'Scheduled Maintenance — Bench',
  sections: [
    {
      backgroundColor: colors.white,
      columns: [
        {
          span: 5,
          widgets: [
            {
              data: {},
              WidgetComponent: MaintenancePageMessage,
            },
          ],
        },
      ],
    },
  ],
};

const MaintenancePage = () => <Page {...pageData} />;

export default MaintenancePage;
