import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '../constants/colors';

const StyledSVG = styled.svg`
  fill: ${({ color }) => color};
  height: ${({ size }) => size || '24px'};
  width: ${({ size }) => size || '24px'};
`;

const QuestionMark = ({ color, size }) => (
  <StyledSVG color={color} size={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-1112.000000, -273.000000)">
        <g transform="translate(1113.000000, 274.000000)">
          <circle cx="9.5" cy="9.5" fillRule="nonzero" r="9.5" stroke={color} />
          <path
            d="M10.123,11.604 L8.719,11.604 C8.693,11.435 8.667,11.266 8.667,11.071 C8.667,10.382 8.953,9.745 9.694,9.186 L10.318,8.718 C10.708,8.432 10.877,8.081 10.877,7.691 C10.877,7.093 10.448,6.521 9.577,6.521 C8.654,6.521 8.251,7.262 8.251,7.99 C8.251,8.146 8.264,8.289 8.303,8.406 L6.665,8.341 C6.613,8.159 6.6,7.951 6.6,7.756 C6.6,6.352 7.653,5 9.577,5 C11.592,5 12.645,6.274 12.645,7.587 C12.645,8.614 12.125,9.342 11.358,9.888 L10.825,10.265 C10.37,10.59 10.123,10.993 10.123,11.526 L10.123,11.604 Z M8.42,13.489 C8.42,12.917 8.875,12.449 9.447,12.449 C10.019,12.449 10.487,12.917 10.487,13.489 C10.487,14.061 10.019,14.516 9.447,14.516 C8.875,14.516 8.42,14.061 8.42,13.489 Z"
            fill={color}
            id="?-copy-2"
          />
        </g>
      </g>
    </g>
  </StyledSVG>
);

QuestionMark.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

QuestionMark.defaultProps = {
  color: colors.charcoal,
  size: '24px',
};

export default QuestionMark;
