import React, { useState } from 'react';
import pricingData from '../pricing/pricingData';
import PricingCard from '../pricing/PricingCard';
import {
  CardLayout,
  PaymentInterval,
  PaymentIntervalAndSavingsWrapper,
  PaymentIntervalHeader,
  PaymentIntervalOptions,
  PaymentIntervalWrapper,
  PaymentOptionsButton,
  PlanAnnualPaymentOption,
  PlanMonthlyPaymentOption,
  PlanPercentageSavingsText,
  WidgetWrapper,
} from './WidgetPricingCards.styles';

const WidgetPricingCards = () => {
  const [isAnnual, setIsAnnual] = useState(true);

  return (
    <WidgetWrapper>
      <PaymentInterval>
        <PaymentIntervalHeader>Choose Plan</PaymentIntervalHeader>
        <PaymentIntervalWrapper isV1PricingCard>
          <PaymentIntervalAndSavingsWrapper>
            <PaymentIntervalOptions>
              <PaymentOptionsButton
                checked={isAnnual}
                name="plan"
                onChange={() => setIsAnnual(true)}
                type="radio"
                value="Annual contract"
              />
              <PlanAnnualPaymentOption isAnnual={isAnnual}>Annual contract</PlanAnnualPaymentOption>
            </PaymentIntervalOptions>
          </PaymentIntervalAndSavingsWrapper>
          <PlanPercentageSavingsText isAnnual={isAnnual} isV1PricingCard>
            Save up to 29%
          </PlanPercentageSavingsText>
          <PaymentIntervalOptions>
            <PaymentOptionsButton
              name="plan"
              onChange={() => setIsAnnual(false)}
              type="radio"
              value="Pay as you go"
            />
            <PlanMonthlyPaymentOption isAnnual={isAnnual}>Pay as you go</PlanMonthlyPaymentOption>
          </PaymentIntervalOptions>
        </PaymentIntervalWrapper>
      </PaymentInterval>
      <CardLayout>
        {pricingData.map((plan, index) => (
          <PricingCard key={index} isAnnual={isAnnual} planData={plan} />
        ))}
      </CardLayout>
    </WidgetWrapper>
  );
};

export default WidgetPricingCards;
