import WidgetJobBoard from '../../../components/widgets/WidgetJobBoard';

const widgetJobBoardTranslation = () => {
  return {
    data: {},
    WidgetComponent: WidgetJobBoard,
  };
};

export default widgetJobBoardTranslation;
