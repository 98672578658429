// Palette
// ===
export default {
  ash: '#4A4B4E',
  bonsai: '#52AD86',
  charcoal: '#2F3034',
  'bonsai-light': '#5DC095',
  'bonsai-dark': '#378763',
  'bonsai-darkest': '#306E52',
  clay: '#D4D2CE',
  cloud: '#818181',
  copper: '#EF6D44',
  deepSapphire: '#082D5E',
  dust: '#9D9C9B',
  eerieBlack: '#121316',
  ghostWhite: '#F7F8FA',
  gold: '#DBD7D0',
  littleDipper: '#E4E6E9',
  lunarRays: '#CACDD2',
  malachite: '#6CB2A5',
  mustard: '#D2A764',
  neutral: '#8E9198',
  pearl: '#FAF9F7',
  sand: '#EFEDE8',
  shell: '#F4F3F0',
  slateGrey: '#5A5E68',
  smoke: '#666667',
  stone: '#B8B7B5',
  waterLight: '#24A2A6',
  water: '#098B8C',
  'water-dark': '#017172',
  'water-darkest': '#164548',
  white: '#FFFFFF',
  sunrayGold: '#E0B152',
  deepSapphireTwo: '#062D60',
  electricBlue: '#0A5AC2',
  yolkYellow: '#FCD269',
  athensGrey: '#F0F1F4',
  tulleGrey: '#8E9198',
  cyberSpace: '#44474F',
  opalFlame: '#EB5747',
  rosyOutlook: '#F5ABA3',
  silkPink: '#FAD5D1',
  blush: '#FCEAE8',
  crayolaOrange: '#F8793A',
  peachOrange: '#FCBC9C',
  cantaloupe: '#FDDDCD',
  cottonCandy: '#FEEEE6',
  bananaBoat: '#FBC337',
  caramel: '#FDE19B',
  sunlight: '#FEF0CD',
  daffodil: '#FEF7E6',
  pistachio: '#94C072',
  matchaLatte: '#C9DFB8',
  winterGreen: '#E4EFDC',
  greenTea: '#F1F7ED',
  oceanGreen: '#3FA684',
  pearlGreen: '#7ECEB3',
  mint: '#C7EADE',
  seaFoam: '#ECF8F4',
  tiffanyTeal: '#14B8B8',
  icyBlue: '#A3F5F5',
  artic: '#D2F9F',
  calmWater: '#E8FCFC',
  midnightBlue: '#084491',
  skyBlue: '#6EAAF7',
  arctic: '#D2F9F9',
  arcLight: '#CEE2FC',
  solitudeBlue: '#E7F1FE',
  violetStorm: '#6D6EB9',
  orchidPurple: '#BABADE',
  lilac: '#DDDDEE',
  lightLavender: '#EEEEF7',
};
