import markdownIt from 'markdown-it';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import filterXss from '../../gatsby/helpers/filterXss';
import { mediaQueries } from '../../ui/constants/grid';
import {
  pxToRem,
  responsiveFontSizingCSS,
  TEXT_ALIGNMENTS,
  TEXT_ALIGNMENT_CENTER,
} from '../../ui/constants/typography';
import widont from '../helpers/widont';

const getHeaderText = (header, headerWidont) => {
  const headerWithLineBreaks = header.replace('\\n', '\n');
  return headerWidont ? widont(headerWithLineBreaks) : headerWithLineBreaks;
};

// white-space: pre-wrap; tells the browser to honour any newlines (\n) in text
const ValuePropHeader = styled.header`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${({ headerFontWeight }) => headerFontWeight};
    margin: ${({ marginAfterHeader }) => {
      const isMarginProvided = typeof marginAfterHeader === 'number';
      const marginBottom = isMarginProvided ? `${marginAfterHeader}px` : '0.5em';
      return `0 0 ${marginBottom}`;
    }};
    white-space: pre-wrap;

    ${({ headerFontColor, fontColor }) =>
      (headerFontColor || fontColor) && `color: ${headerFontColor || fontColor};`}
    ${({ headerFontColor, fontColor }) =>
      !headerFontColor && !fontColor && `color: ${colors.charcoal};`}

      ${({ headerFontFamily }) => headerFontFamily && `font-family: ${headerFontFamily};`}

    ${({ headerFontSize, headerLineHeight, headerFontSizeMobile, headerLineHeightMobile }) =>
      responsiveFontSizingCSS({
        fontSizePixels: headerFontSize,
        lineHeightPixels: headerLineHeight || headerFontSize * 1.25,
        mobileFontSizePixels: headerFontSizeMobile,
        mobileLineHeightPixels: headerLineHeightMobile,
      })}

    ${({ headerTextAlignment, textAlignment }) =>
      (headerTextAlignment || textAlignment) &&
      `text-align: ${headerTextAlignment || textAlignment};
    `}

    ${mediaQueries.mobile`
      text-align: ${({ textAlignmentMobile }) => textAlignmentMobile || TEXT_ALIGNMENT_CENTER};
    `}
  }
`;

ValuePropHeader.propTypes = {
  fontColor: PropTypes.string,
  headerFontColor: PropTypes.string,
  headerFontFamily: PropTypes.string,
  headerFontSize: PropTypes.number,
  headerFontSizeMobile: PropTypes.number,
  headerFontWeight: PropTypes.number,
  headerLineHeight: PropTypes.number,
  headerLineHeightMobile: PropTypes.number,
  headerTextAlignment: PropTypes.oneOf(TEXT_ALIGNMENTS),
  marginAfterHeader: PropTypes.number,
  textAlignment: PropTypes.oneOf(TEXT_ALIGNMENTS),
  textAlignmentMobile: PropTypes.oneOf(TEXT_ALIGNMENTS),
};

ValuePropHeader.defaultProps = {
  fontColor: null,
  headerFontColor: null,
  headerFontFamily: null,
  headerFontSize: 32,
  headerFontSizeMobile: null,
  headerFontWeight: 700,
  headerLineHeight: null,
  headerLineHeightMobile: null,
  headerTextAlignment: null,
  marginAfterHeader: null,
  textAlignment: null,
  textAlignmentMobile: null,
};

const ValuePropBody = styled.main`
  font-weight: ${({ fontWeight }) => fontWeight};
  text-transform: ${({ bodyTextUpperCase }) => (bodyTextUpperCase ? 'uppercase' : 'none')};
  ${({ fontColor }) => fontColor && `color: ${fontColor};`}
  ${({ fontColor }) => !fontColor && ` color: ${colors.ash};`}

  a {
    color: ${colors.water};

    &:hover {
      text-decoration: underline;
    }
  }

  hr {
    background: rgba(0, 0, 0, 0.1);
    border: 0 none;
    display: block;
    height: 1px;
    margin: ${pxToRem(28)}rem auto;
  }

  p {
    margin: 0.5rem 0 0;
    ${({ fontSize, lineHeight, fontSizeMobile, lineHeightMobile }) =>
      responsiveFontSizingCSS({
        fontSizePixels: fontSize,
        lineHeightPixels: lineHeight || fontSize * 1.5,
        mobileFontSizePixels: fontSizeMobile,
        mobileLineHeightPixels: lineHeightMobile,
      })}
    ${({ textAlignment }) => textAlignment && `text-align: ${textAlignment};`}
    ${mediaQueries.mobile`
      text-align: ${({ textAlignmentMobile }) => textAlignmentMobile || TEXT_ALIGNMENT_CENTER};
    `}
  }

  ul {
    display: block;
    padding-left: 16px;
    margin-bottom: 0.5rem;

    li {
      &::before {
        border-radius: 50%;
        content: '';
        display: block;
        height: 6px;
        ${({ fontColor }) => fontColor && `background: ${fontColor};`}
        ${({ fontColor }) => !fontColor && `background: ${colors.water};`}
        left: -16px;
        margin: 0;
        position: relative;
        top: 16px;
        width: 6px;
      }
    }
  }
`;

ValuePropBody.propTypes = {
  bodyTextUpperCase: PropTypes.bool,
  fontColor: PropTypes.string,
  fontSize: PropTypes.number,
  fontSizeMobile: PropTypes.number,
  fontWeight: PropTypes.number,
  lineHeight: PropTypes.number,
  lineHeightMobile: PropTypes.number,
  textAlignment: PropTypes.oneOf(TEXT_ALIGNMENTS),
  textAlignmentMobile: PropTypes.oneOf(TEXT_ALIGNMENTS),
};

ValuePropBody.defaultProps = {
  bodyTextUpperCase: null,
  fontColor: null,
  fontSize: 16,
  fontSizeMobile: null,
  fontWeight: 500,
  lineHeight: null,
  lineHeightMobile: null,
  textAlignment: null,
  textAlignmentMobile: null,
};

const WidgetValueProp = ({
  bodyTextUpperCase,
  description,
  descriptionWidont,
  fontColor,
  fontSize,
  fontSizeMobile,
  fontWeight,
  header,
  headerFontColor,
  headerFontFamily,
  headerFontSize,
  headerFontSizeMobile,
  headerFontWeight,
  headerLineHeight,
  headerLineHeightMobile,
  headerTag,
  headerTextAlignment,
  headerWidont,
  lineHeight,
  lineHeightMobile,
  marginAfterHeader,
  textAlignment,
  textAlignmentMobile,
}) => {
  let html;

  if (description) {
    html = markdownIt({
      html: true,
      linkify: true,
      typography: true,
    }).render(descriptionWidont ? widont(description) : description);
  }

  return (
    <section>
      {header && (
        <ValuePropHeader
          fontColor={fontColor}
          headerFontColor={headerFontColor}
          headerFontFamily={headerFontFamily}
          headerFontSize={headerFontSize}
          headerFontSizeMobile={headerFontSizeMobile}
          headerFontWeight={headerFontWeight}
          headerLineHeight={headerLineHeight}
          headerLineHeightMobile={headerLineHeightMobile}
          headerTextAlignment={headerTextAlignment}
          marginAfterHeader={marginAfterHeader}
          textAlignment={textAlignment}
          textAlignmentMobile={textAlignmentMobile}
        >
          {React.createElement(headerTag, null, getHeaderText(header, headerWidont))}
        </ValuePropHeader>
      )}
      {description && (
        <ValuePropBody
          bodyTextUpperCase={bodyTextUpperCase}
          fontColor={fontColor}
          fontSize={fontSize}
          fontSizeMobile={fontSizeMobile}
          fontWeight={fontWeight}
          lineHeight={lineHeight}
          lineHeightMobile={lineHeightMobile}
          textAlignment={textAlignment}
          textAlignmentMobile={textAlignmentMobile}
        >
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: filterXss.process(html) }} />
        </ValuePropBody>
      )}
    </section>
  );
};

WidgetValueProp.propTypes = {
  bodyTextUpperCase: PropTypes.bool,
  description: PropTypes.string,
  descriptionWidont: PropTypes.bool,
  fontColor: PropTypes.string,
  fontSize: PropTypes.number,
  fontSizeMobile: PropTypes.number,
  fontWeight: PropTypes.number,
  header: PropTypes.string,
  headerFontColor: PropTypes.string,
  headerFontFamily: PropTypes.string,
  headerFontSize: PropTypes.number,
  headerFontSizeMobile: PropTypes.number,
  headerFontWeight: PropTypes.number,
  headerLineHeight: PropTypes.number,
  headerLineHeightMobile: PropTypes.number,
  headerTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  headerTextAlignment: PropTypes.oneOf(TEXT_ALIGNMENTS),
  headerWidont: PropTypes.bool,
  lineHeight: PropTypes.number,
  lineHeightMobile: PropTypes.number,
  marginAfterHeader: PropTypes.number,
  textAlignment: PropTypes.oneOf(TEXT_ALIGNMENTS),
  textAlignmentMobile: PropTypes.oneOf(TEXT_ALIGNMENTS),
};

WidgetValueProp.defaultProps = {
  bodyTextUpperCase: null,
  description: null,
  descriptionWidont: false,
  fontColor: null,
  fontSize: 16,
  fontSizeMobile: null,
  fontWeight: 500,
  header: '',
  headerFontColor: null,
  headerFontFamily: null,
  headerFontSize: 32,
  headerFontSizeMobile: null,
  headerFontWeight: 700,
  headerLineHeight: null,
  headerLineHeightMobile: null,
  headerTag: 'h3',
  headerTextAlignment: null,
  headerWidont: false,
  lineHeight: null,
  lineHeightMobile: null,
  marginAfterHeader: null,
  textAlignment: null,
  textAlignmentMobile: null,
};

export default WidgetValueProp;
