import PropTypes from 'prop-types';
import styled from 'styled-components';
import grid, { mediaQueries } from '../constants/grid';

const columnPercent = (span) => {
  return (span / grid.columns) * 100;
};

const GridColumn = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border-radius: ${({ borderRadius }) => borderRadius || '0'};
  box-sizing: border-box;
  flex-basis: ${({ spanMobile }) => (spanMobile ? columnPercent(spanMobile) : 100)}%;
  max-width: ${({ spanMobile }) => (spanMobile ? columnPercent(spanMobile) : 100)}%;
  ${({ height }) => height && `height: ${height};`}
  ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow};`}

  ${({ order }) => order && `order: ${order};`}
  padding: 0 ${grid.gutterWidth / 2}px;

  // column padding in contentful will apply to all sides if section column gutter is selected yes in Contentful
  ${({ padding }) => padding && `padding: ${padding};`}

  ${mediaQueries.desktop`
    flex-basis: ${({ span }) => columnPercent(span)}%;
    max-width: ${({ span }) => columnPercent(span)}%;
    ${({ orderDesktop }) => orderDesktop !== null && `order: ${orderDesktop};`}
  `}

  ${mediaQueries.mobile`
    ${({ orderMobile }) => orderMobile && `order: ${orderMobile};`}
  `}
`;

GridColumn.propTypes = {
  order: PropTypes.number,
  orderDesktop: PropTypes.number,
  orderMobile: PropTypes.number,
  span: PropTypes.number,
  spanMobile: PropTypes.number,
};

GridColumn.defaultProps = {
  order: null,
  orderDesktop: null,
  orderMobile: null,
  span: grid.columns,
  spanMobile: null,
};

export default GridColumn;
