import WidgetEmbed from '../../../components/widgets/WidgetEmbed';

const widgetEmbedTranslation = ({ embedType, embedId, marketoFormNumberOfFields }) => {
  const translatedData = {
    embedType,
    embedId,
    placeholderFields: marketoFormNumberOfFields,
  };

  return {
    data: translatedData,
    WidgetComponent: WidgetEmbed,
  };
};

export default widgetEmbedTranslation;
