import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import ArrowNarrow from '../ui/icons/ArrowNarrow';
import BenchSwiper from './BenchSwiper';
import { BODY_TEXT_SIZES, HEADER_TEXT_SIZES, pxToRem } from '../ui/constants/typography';
import colors from '../ui/constants/colors';
import GridColumn from '../ui/grid/GridColumn';
import GridContainer from '../ui/grid/GridContainer';
import GridRow from '../ui/grid/GridRow';
import { mediaQueries } from '../ui/constants/grid';
import WidgetButton from './widgets/WidgetButton';
import WidgetImage from './widgets/WidgetImage';
import WidgetValueProp from './widgets/WidgetValueProp';

const ImageSwitcherContainer = styled.div`
  ${mediaQueries.mobile`
    display: none;
  `};
`;

const SwiperContainer = styled.div`
  ${mediaQueries.desktop`
    display: none;
  `};
`;

const StyledButtonContainer = styled.div`
  align-items: center;
  display: flex;

  button {
    background-color: transparent;
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-align: left;

    &:hover {
      color: ${({ hoverColor }) => hoverColor || '#52a77c'};
    }

    &:focus {
      box-shadow: none;
    }
  }

  position: relative;
`;

const slideInFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const ArrowContainer = styled.div`
  animation: ${slideInFromLeft} 0.5s ease-out forwards;
  left: -6%;
  margin-right: ${pxToRem(8)}rem;
  position: absolute;
  height: 12px;
`;

const ValuePropContainer = styled.div`
  margin: ${pxToRem(32)}rem 0;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ImageContainer = styled.div`
  picture {
    img {
      animation: ${fadeIn} 0.5s linear;
    }
  }
`;

const SideTabViewComponent = ({
  activeTabColor,
  descriptionFontColor,
  descriptionFontSize,
  headerFontColor,
  headerFontSize,
  tabData,
}) => {
  const [currentImage, setCurrentImage] = useState(tabData[0].desktopImage);
  const [currentTab, setCurrentTab] = useState(tabData[0].tabId);

  const handleTabClick = (desktopImage, tabId) => {
    setCurrentImage(desktopImage);
    setCurrentTab(tabId);
  };
  const currentTabData = tabData.find((tab) => tab.id === currentTab);

  return (
    <GridContainer>
      <ImageSwitcherContainer>
        <GridRow alignItems="center">
          <GridColumn span={5}>
            {tabData.map(({ description, header, desktopImage, tabId }) => (
              <ValuePropContainer key={tabId}>
                <StyledButtonContainer hoverColor={activeTabColor}>
                  <ArrowContainer key={currentTab}>
                    {currentTab === tabId && <ArrowNarrow color={activeTabColor || '#52a77c'} />}
                  </ArrowContainer>
                  <WidgetButton
                    buttonText={header}
                    fontSize={headerFontSize || 16}
                    onClick={() => handleTabClick(desktopImage, tabId)}
                    textColor={
                      currentTab === tabId
                        ? activeTabColor || '#52a77c'
                        : headerFontColor || colors.charcoal
                    }
                  />
                </StyledButtonContainer>
                <WidgetValueProp
                  description={description}
                  fontColor={descriptionFontColor || colors.ash}
                  fontSize={descriptionFontSize || 16}
                />
              </ValuePropContainer>
            ))}
          </GridColumn>
          <GridColumn span={1} />
          <GridColumn alignItems="center" span={6}>
            <ImageContainer>
              <WidgetImage key={currentTab} {...currentTabData} {...currentImage} />
            </ImageContainer>
          </GridColumn>
        </GridRow>
      </ImageSwitcherContainer>
      <SwiperContainer>
        <BenchSwiper>
          {tabData.map(({ description, header, mobileImage, tabId }) => (
            <React.Fragment key={tabId}>
              <WidgetImage {...mobileImage} />
              <ValuePropContainer>
                <WidgetValueProp
                  {...HEADER_TEXT_SIZES.xs}
                  {...BODY_TEXT_SIZES.lg}
                  description={description}
                  fontColor={descriptionFontColor || colors.ash}
                  header={header}
                  headerFontColor={headerFontColor || colors.charcoal}
                />
              </ValuePropContainer>
            </React.Fragment>
          ))}
        </BenchSwiper>
      </SwiperContainer>
    </GridContainer>
  );
};

SideTabViewComponent.propTypes = {
  activeTabColor: PropTypes.string,
  descriptionFontColor: PropTypes.string,
  descriptionFontSize: PropTypes.number,
  headerFontColor: PropTypes.string,
  headerFontSize: PropTypes.number,
  tabData: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      desktopImage: PropTypes.shape({
        alt: PropTypes.string,
        autoFormat: PropTypes.bool,
        className: PropTypes.string,
        fromContentful: PropTypes.bool,
        height: PropTypes.number,
        isAboveFold: PropTypes.bool,
        maxWidth: PropTypes.number,
        mobileMaxWidth: PropTypes.number,
        mobileNaturalHeight: PropTypes.number,
        mobileNaturalWidth: PropTypes.number,
        mobileSrc: PropTypes.string,
        naturalHeight: PropTypes.number,
        naturalWidth: PropTypes.number,
        noRetina: PropTypes.bool,
        src: PropTypes.string.isRequired,
        webp: PropTypes.bool,
      }).isRequired,
      header: PropTypes.string.isRequired,
      mobileImage: PropTypes.shape({
        alt: PropTypes.string,
        autoFormat: PropTypes.bool,
        className: PropTypes.string,
        fromContentful: PropTypes.bool,
        height: PropTypes.number,
        isAboveFold: PropTypes.bool,
        maxWidth: PropTypes.number,
        mobileMaxWidth: PropTypes.number,
        mobileNaturalHeight: PropTypes.number,
        mobileNaturalWidth: PropTypes.number,
        mobileSrc: PropTypes.string,
        naturalHeight: PropTypes.number,
        naturalWidth: PropTypes.number,
        noRetina: PropTypes.bool,
        src: PropTypes.string.isRequired,
        webp: PropTypes.bool,
      }).isRequired,
      tabId: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

SideTabViewComponent.defaultProps = {
  activeTabColor: '#52a77c',
  descriptionFontColor: colors.ash,
  descriptionFontSize: 16,
  headerFontColor: colors.charcoal,
  headerFontSize: 16,
};

export default SideTabViewComponent;
