import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSwipe from 'react-swipe';
import styled from 'styled-components';

import colors from '../ui/constants/colors';
import { mediaQueries } from '../ui/constants/grid';

const SwiperContainer = styled.div`
  text-align: center;
`;

const SwiperContent = styled.div`
  position: relative;
`;

const SwiperArrow = styled.div.attrs({
  children: (
    <svg viewBox="0 0 29 44" xmlns="http://www.w3.org/2000/svg">
      <polyline className="a" points="18.97 35.56 7.12 22.3 19 9" />
    </svg>
  ),
})`
  align-items: center;
  bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 30px;
  z-index: 1;
  ${({ direction }) => direction === 'previous' && `left: 0;`}
  ${({ direction }) =>
    direction === 'next' &&
    `
    right: 0;
    transform: rotate(180deg);
  `}

  ${mediaQueries.mobile`
    display: none;
  `}

  svg {
    fill: none;
    stroke: ${({ controlColor }) => controlColor || colors.tulleGrey};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2.5px;
    width: 30px;
  }
`;

const SwiperBullet = styled.span`
  cursor: pointer;
  margin: 0 5px;
  background: ${({ controlColor }) => controlColor || colors.tulleGrey};
  opacity: 1;
  transition: all 0.35s ease;
  position: relative;
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 100%;

  &::after {
    width: 8px;
    height: 8px;
    position: absolute;
    background: ${({ activeColor }) => activeColor || colors.electricBlue};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
    transition: all 0.35s ease;
    content: '';

    ${({ active }) => active && `transform: translate(-50%,-50%) scale(1);`}
  }
`;

const BenchSwiper = ({ activeColor, children, controlColor }) => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const [reactSwipe, setReactSwipe] = useState(null);

  const handleTransitionEnd = () => {
    if (window.heap) {
      window.heap.track('Swipe - Carousel');
    }
    setCurrentPosition(reactSwipe.getPos());
  };

  return (
    <SwiperContainer>
      <SwiperContent>
        <SwiperArrow
          controlColor={controlColor}
          direction="previous"
          onClick={() => {
            reactSwipe.prev();
            handleTransitionEnd();
          }}
        />
        <ReactSwipe
          ref={(el) => {
            setReactSwipe(el);
          }}
          className="carousel"
        >
          {children.map((child) => (
            <div key={child.key}>{child}</div>
          ))}
        </ReactSwipe>
        <SwiperArrow
          controlColor={controlColor}
          direction="next"
          onClick={() => {
            reactSwipe.next();
            handleTransitionEnd();
          }}
        />
      </SwiperContent>
      <div>
        {children.map((child, index) => (
          <SwiperBullet
            key={child.key}
            active={index === currentPosition}
            activeColor={activeColor}
            controlColor={controlColor}
            onClick={() => {
              reactSwipe.slide(index);
              handleTransitionEnd();
            }}
          />
        ))}
      </div>
    </SwiperContainer>
  );
};

BenchSwiper.propTypes = {
  activeColor: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  controlColor: PropTypes.string,
};

BenchSwiper.defaultProps = {
  activeColor: null,
  controlColor: null,
};

export default BenchSwiper;
