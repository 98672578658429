import { css } from 'styled-components';

// Use: ${breakpoint.<breakpoint>.<query-type>`<css>`}

export const breakpoints = {
  base: 0,
  sm: 320,
  md: 640,
  lg: 960,
  xlg: 1280,
};

const buildMediaQuery =
  (breakpointName, direction) =>
  (...args) =>
    css`
      @media (${direction}-width: ${breakpoints[breakpointName]}px) {
        ${css(...args)}
      }
    `;

const Breakpoint = Object.keys(breakpoints).reduce((result, breakpointName) => {
  return {
    ...result,
    [breakpointName]: {
      min: buildMediaQuery(breakpointName, 'min'),
      max: buildMediaQuery(breakpointName, 'max'),
    },
  };
}, {});

export default Breakpoint;
