import PropTypes from 'prop-types';
import React from 'react';

import MarketoForm from '../embeds/MarketoForm';
import WistiaVideo from '../embeds/WistiaVideo';
import YouTubeVideo from '../embeds/YouTubeVideo';
import { WebsitePageContext } from '../WebsitePage';

const WidgetEmbed = ({
  customHiddenValues,
  onFormSuccess,
  placeholderFields,
  embedId,
  embedType,
  allowFullScreen,
  controls,
  naturalHeight,
  naturalWidth,
}) => {
  if (embedType === 'Wistia Video') {
    return <WistiaVideo id={embedId} naturalHeight={naturalHeight} naturalWidth={naturalWidth} />;
  }
  if (embedType === 'YouTube Video') {
    return (
      <YouTubeVideo
        allowFullScreen={allowFullScreen}
        controls={controls}
        id={embedId}
        naturalHeight={naturalHeight}
        naturalWidth={naturalWidth}
      />
    );
  }
  if (embedType === 'Marketo Form') {
    return (
      <WebsitePageContext.Consumer>
        {(pageData) => (
          <MarketoForm
            campaignId={pageData && pageData.campaignId}
            customHiddenValues={customHiddenValues}
            formId={embedId}
            onFormSuccess={onFormSuccess}
            placeholderFields={placeholderFields}
          />
        )}
      </WebsitePageContext.Consumer>
    );
  }
  return null;
};

WidgetEmbed.propTypes = {
  allowFullScreen: PropTypes.bool,
  controls: PropTypes.bool,
  customHiddenValues: PropTypes.object,
  embedId: PropTypes.string.isRequired,
  embedType: PropTypes.string.isRequired,
  naturalHeight: PropTypes.number,
  naturalWidth: PropTypes.number,
  onFormSuccess: PropTypes.func,
  placeholderFields: PropTypes.number,
};

WidgetEmbed.defaultProps = {
  allowFullScreen: false,
  controls: false,
  customHiddenValues: {},
  naturalHeight: 1080,
  naturalWidth: 1920,
  onFormSuccess: null,
  placeholderFields: 3,
};

export default WidgetEmbed;
