import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import Image from '../../ui/images/Image';
import WistiaVideo from '../embeds/WistiaVideo';
import MarketoForm from '../embeds/MarketoForm';
import { mediaQueries } from '../../ui/constants/grid';

const GatedVideoContainer = styled.div`
  ${mediaQueries.desktop`
    background-color: ${colors.ash};
    height: 0;
    overflow: auto;
    padding-bottom: ${({ naturalHeight, naturalWidth }) => (naturalHeight / naturalWidth) * 100}%;
    position: relative;
  `}
`;

const MarketoFormContainer = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  left: 10%;
  padding: 24px 15px;
  position: absolute;
  right: 10%;
  top: 50px;

  ${mediaQueries.mobile`
    position: static;
  `}
`;

const FormHeader = styled.p`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 16px;
`;
const ImageContainer = styled.div`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  ${mediaQueries.mobile`
    display: none;
  `}
`;

const WidgetGatedVideo = ({
  campaignId,
  customHiddenValues,
  formHeader,
  formId,
  naturalHeight,
  naturalWidth,
  placeholderFields,
  videoId,
  videoPlaceholderImage,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onFormSuccess = () => {
    setFormSubmitted(true);
  };

  if (formSubmitted) {
    return <WistiaVideo id={videoId} naturalHeight={naturalHeight} naturalWidth={naturalWidth} />;
  }

  return (
    <GatedVideoContainer naturalHeight={naturalHeight} naturalWidth={naturalWidth}>
      <ImageContainer>
        <Image {...videoPlaceholderImage} />
      </ImageContainer>
      <MarketoFormContainer>
        {formHeader && <FormHeader>{formHeader}</FormHeader>}
        <MarketoForm
          campaignId={campaignId}
          customHiddenValues={customHiddenValues}
          formId={formId}
          onFormSuccess={onFormSuccess}
          placeholderFields={placeholderFields}
        />
      </MarketoFormContainer>
    </GatedVideoContainer>
  );
};

WidgetGatedVideo.propTypes = {
  assetStatus: PropTypes.number.isRequired,
  campaignId: PropTypes.string,
  customHiddenValues: PropTypes.object,
  formHeader: PropTypes.string,
  formId: PropTypes.string.isRequired,
  naturalHeight: PropTypes.number,
  naturalWidth: PropTypes.number,
  onFormSuccess: PropTypes.func,
  placeholderFields: PropTypes.number,
  retryLoadAssets: PropTypes.func.isRequired,
  videoId: PropTypes.string.isRequired,
  videoPlaceholderImage: PropTypes.shape({
    alt: PropTypes.string,
    autoFormat: PropTypes.bool,
    className: PropTypes.string,
    fromContentful: PropTypes.bool,
    height: PropTypes.number,
    isAboveFold: PropTypes.bool,
    maxWidth: PropTypes.number,
    mobileMaxWidth: PropTypes.number,
    mobileNaturalHeight: PropTypes.number,
    mobileNaturalWidth: PropTypes.number,
    mobileSrc: PropTypes.string,
    naturalHeight: PropTypes.number,
    naturalWidth: PropTypes.number,
    noRetina: PropTypes.bool,
    src: PropTypes.string.isRequired,
    webp: PropTypes.bool,
  }).isRequired,
};

WidgetGatedVideo.defaultProps = {
  campaignId: undefined,
  formHeader: undefined,
  customHiddenValues: {},
  naturalHeight: 1080,
  naturalWidth: 1920,
  onFormSuccess: null,
  placeholderFields: 3,
};

export default WidgetGatedVideo;
