import PropTypes from 'prop-types';
import styled from 'styled-components';
import grid from '../constants/grid';
import GridColumn from './GridColumn';

const GridRow = styled.div`
  align-items: ${({ alignItems }) => alignItems};
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent};
  ${({ noGutter }) => !noGutter && `margin: 0 -${grid.gutterWidth}px;`}
  ${({ noGutter }) => !noGutter && `padding: 0 ${grid.gutterWidth / 2}px;`}

  & > ${GridColumn} {
    ${({ noGutter }) =>
      noGutter &&
      `
      padding-left: 0;
      padding-right: 0;
    `}
  }
`;

GridRow.propTypes = {
  alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch']),
  flexDirection: PropTypes.oneOf(['row', 'row-reverse']),
  justifyContent: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'space-between',
    'center',
    'space-around',
  ]),
  noGutter: PropTypes.bool,
};

GridRow.defaultProps = {
  alignItems: 'flex-start',
  flexDirection: 'row',
  justifyContent: 'center',
  noGutter: false,
};

export default GridRow;
