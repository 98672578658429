import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../../ui/constants/colors';
import Page from '../../../components/Page';
import WidgetArticleBody from '../../../components/widgets/WidgetArticleBody';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';

const md = `
Like many web-based service providers, Bench (Bench Accounting, Inc. and
its affiliates, collectively “**Bench**”) utilizes “cookies” in
connection with the use of our “Services”. This cookie notice provides
information about how and when we use cookies and other similar tracking
technologies on our platform and our website (collectively, our
“**Services**”). To better understand how we use the data collected
through these technologies, please read our 
[<u>Privacy Policy</u>](http://bench.co/privacy/).

## What are cookies?

A cookie is a small text file that is transferred by a web server and
stored on the hard drive of your computer or mobile device when you
visit certain websites. Generally, cookies are used to make your
browsing experience better by allowing a website to remember your
actions and preferences (for example, your authentication status).
Cookies also provide information on how people may use a website (for
instance, whether it’s their first time visiting or if they are a
frequent visitor).

## What cookies do we use and why?

We use a number of different cookies to provide you with our Services,
to monitor usage and performance, to customize and improve your
experience, and to facilitate our marketing, social and advertising
activities.

### Strictly Necessary Cookies

We use a small number of cookies that are necessary to allow you to use
our Services. The use of these cookies is essential for our Services to
work. For example, we use session cookies for the duration of a session
to keep track of a user’s identity and authentication status.

### Functional Cookies

We use third party tools to help customize our website and to remember
choices you’ve made, some of which may use cookies or similar technology
in order to function.

### Analytics and Reporting Cookies

We use many tools to help us improve our Services and the overall user
experience for our customers and website visitors. To this end, we use
reporting and analytics cookies to collect information about how you use
our website, and how often.

### Marketing and Advertising Cookies

We use a number of tools to help us with our marketing efforts, some of
which may use cookies or similar technology. Marketing and advertising
cookies are used on our marketing website to tailor marketing to you and
your interests and provide you with a more personalized service in the
future. These cookies remember that you visited our website and we may
verify this information with third-parties, such as advertisers.
Although these cookies can track your device’s visits to our website and
other sites, they typically cannot personally identify you.

### Social and Content Cookies

Social and content cookies are placed by many social media plugins (for
example, the Facebook “like” button), and other tools meant to provide
or improve the content on a website (for example, services that allow
the playing of video files, or that create comments sections). We
integrate some of these modules into our website to improve the
experience of browsing and interacting with our content. Some of these
third party services may place cookies that are also used for things
like behavioural advertising, analytics, and/or market research.

## How to opt-out, remove or prevent the setting of cookies

Most browsers give you the ability to manage cookies to suit your preferences. 
In some browsers you can set up rules to manage cookies on a site-by-site basis, 
giving you more fine-grained control over your privacy. This means you can disallow 
cookies from all sites except those that you trust. To customize your cookie 
management preferences for your browser, please consult the 
documentation that your browser manufacturer provides.

For display advertising, you may opt out at any time by visiting your
[<u>Google Ads Settings page</u>](https://www.google.com/settings/ads)
or by installing and running the [<u>Google Analytics Opt-out Browser
Add-on</u>](https://tools.google.com/dlpage/gaoptout/). The Network
Advertising Initiative has also developed [<u>a tool available
here</u>](https://optout.networkadvertising.org/?c=1) that may help you
understand which third parties have currently enabled cookies for your
browser and opt-out of those cookies. Please note that this is your responsibility 
and would have no direct affiliation with Bench.

In addition, on your iPhone, iPad or Android, you can change your device
settings to control whether you see online interest-based ads.

You can adjust your cookie settings by clicking the button below.
Please keep in mind that removing or blocking cookies can negatively
impact your user experience and parts of our website or Services may no
longer be fully accessible.
`;

const belowButtonMarkdown = `Please be advised that cookies are dynamic and there are instances 
where you may be required to update your consent preferences again, 
including but not limited to, when you clear your cache or cookies, 
and when using an alternate web browser or device.`;

const CookiePolicyBodyContainer = styled.div`
  && {
    dt::before,
    dt:after,
    dd::before {
      display: none;
    }

    dt {
      font-weight: normal;
    }

    dd {
      font-weight: 700;
    }

    ol {
      list-style-type: lower-alpha;

      ol li {
        list-style-type: decimal;
      }
    }
  }
`;

// using the important keyword here to override the default styles from the OneTrust SDK.
const OneTrustCookieSettingsButton = styled.button`
  background: ${colors.water};
  color: ${colors.white} !important;
  border: none !important;
  font-size: 15px !important;

  &:hover {
    background: ${colors['water-dark']} !important;
    border-color: ${colors['water-dark']} !important;
  }
`;

const CookiePolicyBody = ({ markdown, path }) => {
  return (
    <CookiePolicyBodyContainer>
      <WidgetArticleBody markdown={markdown} path={path} />
      <OneTrustCookieSettingsButton className="ot-sdk-show-settings" id="ot-sdk-btn">
        Cookie Settings
      </OneTrustCookieSettingsButton>
      <br />
      <br />
      <WidgetArticleBody markdown={belowButtonMarkdown} />
    </CookiePolicyBodyContainer>
  );
};

CookiePolicyBody.propTypes = {
  markdown: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

const pageData = {
  description:
    'Bench provides online bookkeeping for your business. Our cookie policy ' +
    'provides information about how and when we use cookies and other similar tracking ' +
    'technologies on our platform.',
  enableAbTesting: false,
  footerType: 'full',
  navbarType: 'full',
  path: '/cookie-policy/',
  title: 'Cookie Policy — Bench',
  sections: [
    {
      columns: [
        {
          span: 5,
          widgets: [
            {
              data: {
                description: 'Last updated: April 13, 2023',
                header: 'Bench Accounting, Inc.\nCookie Policy',
                textAlignment: 'center',
              },
              WidgetComponent: WidgetValueProp,
            },
          ],
        },
      ],
      paddingBottom: 0,
      margin: 0,
    },
    {
      backgroundColor: colors.white,
      columns: [
        {
          span: 9,
          widgets: [
            {
              data: {
                markdown: md,
                path: '/cookie-policy/',
              },
              WidgetComponent: CookiePolicyBody,
            },
          ],
        },
      ],
      paddingTop: 0,
    },
  ],
};

const CookiePolicyPage = () => <Page {...pageData} />;

export default CookiePolicyPage;
