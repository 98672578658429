import WidgetLabel from '../../../components/widgets/WidgetLabel';

const widgetLabelTranslation = ({ alignment, mobileAlignment, color, size, style, text }) => {
  const convertColorOptions = {
    Arctic: 'arctic',
    'Arc Light': 'arcLight',
    'Eerie Black': 'eerieBlack',
    'Electric Blue': 'electricBlue',
    'Ghost White': 'ghostWhite',
    'Little Dipper': 'littleDipper',
    Sunlight: 'sunlight',
    'Sunray Gold': 'sunrayGold',
    'Winter Green': 'winterGreen',
  };

  const data = {
    alignment: alignment || 'left',
    mobileAlignment: mobileAlignment || 'left',
    color: convertColorOptions[color] || 'arctic',
    size: size || '14px',
    style: style || 'fill',
    text: text || '',
  };

  return { data, WidgetComponent: WidgetLabel };
};

export default widgetLabelTranslation;
