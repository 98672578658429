import React from 'react';
import Gandalf from 'gandalf-validator';
import storage from 'local-storage-fallback';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import breakpoint from '../../ui/constants/breakpoints';
import Button from '../../ui/buttons/Button';
import colors from '../../ui/constants/colors';
import GridColumn from '../../ui/grid/GridColumn';
import GridRow from '../../ui/grid/GridRow';
import Input from '../../ui/input/Input';
import Link from '../../ui/buttons/Link';
import { mediaQueries } from '../../ui/constants/grid';
import { pxToRem, fontSizingCSS } from '../../ui/constants/typography';

const getFields = (fieldNames, theme) => {
  const fields = {
    fullName: {
      name: 'fullName',
      component: Input,
      validators: ['required'],
      errorPropName: 'errorText',
      props: {
        autoComplete: 'given-name',
        placeholder: 'Full Name',
        theme,
      },
      debounce: 300,
    },
    email: {
      name: 'email',
      component: Input,
      validators: ['required', 'email'],
      errorPropName: 'errorText',
      props: {
        autoComplete: 'email',
        placeholder: 'Email',
        type: 'email',
        theme,
      },
      debounce: 700,
    },
    phone: {
      name: 'phone',
      component: Input,
      validators: ['required'],
      errorPropName: 'errorText',
      props: {
        autoComplete: 'tel',
        placeholder: 'Phone',
        type: 'tel',
        theme,
      },
      debounce: 700,
    },
  };

  return fieldNames.map((name) => fields[name]);
};

const StyledForm = styled.form`
  ${breakpoint.lg.min`
    ${({ horizontal }) => !horizontal && `max-width: 75%;`}
  `}
`;

const FormInputContainer = styled.div`
  ${mediaQueries.desktop`
    ${({ horizontal }) =>
      horizontal &&
      `
      padding-right: 1rem
    `}
  `}
`;

const DisclaimerContainer = styled.div`
  color: black;
  ${fontSizingCSS(12, 18)};
  margin-top: ${pxToRem(16)}rem;
  opacity: 0.35;
  padding-left: ${pxToRem(2)}rem;
  text-align: ${({ horizontal }) => (horizontal ? 'center' : 'left')};
  width: 100%;

  span {
    font-weight: bold;
  }

  a {
    text-decoration: underline;
  }

  div {
    max-width: ${({ horizontal }) => (horizontal ? 'inherit' : '400px')};
  }
`;

class WidgetForm extends Gandalf {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { fields, theme } = this.props;
    this.buildFields(getFields(fields, theme));
  }

  handleSubmit = () => {
    const { handleSubmit, pageData } = this.props;

    const data = this.getCleanFormData();

    if (!data) {
      return null;
    }

    return handleSubmit({
      ...data,
      // Empty values much be exactly `undefined` because any other value (even `null`) will be
      // included as a query string parameter
      partnerAccount: pageData.partnerAccount || storage.getItem('partnerAccount') || undefined,
      partnerId: pageData.partnerId || storage.getItem('partnerId') || undefined,
      campaignId: pageData.campaignId || storage.getItem('campaignId') || undefined,
    });
  };

  render() {
    const { buttonBackgroundColor, buttonText, buttonTextColor, horizontal, fields } = this.props;
    const spanSize = horizontal ? Math.floor(12 / (fields.length + 1)) : 12;

    return (
      <StyledForm horizontal={horizontal}>
        <GridRow noGutter>
          {fields.map((name) => (
            <GridColumn key={name} span={spanSize}>
              <FormInputContainer horizontal={horizontal}>
                {this.state.fields[name].element}
              </FormInputContainer>
            </GridColumn>
          ))}
          <GridColumn span={spanSize}>
            <Button
              backgroundColor={buttonBackgroundColor}
              full
              onClick={() => this.handleSubmit()}
              textColor={buttonTextColor}
              type="button"
            >
              {buttonText}
            </Button>
          </GridColumn>
          <DisclaimerContainer horizontal={horizontal}>
            <div>
              <span>No credit card required.</span> By clicking ‘Start a Free Trial’, I agree to
              Bench’s&nbsp;
              <Link linkURL="/terms/">Terms</Link>&nbsp;&&nbsp;
              <Link linkURL="/privacy/">Privacy Policy</Link>.
            </div>
          </DisclaimerContainer>
        </GridRow>
      </StyledForm>
    );
  }
}

WidgetForm.propTypes = {
  buttonBackgroundColor: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonTextColor: PropTypes.string,
  campaignId: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  horizontal: PropTypes.bool,
  pageData: PropTypes.shape({
    campaignId: PropTypes.string,
    partnerAccount: PropTypes.string,
    partnerId: PropTypes.string,
  }),
  theme: PropTypes.string,
};

WidgetForm.defaultProps = {
  buttonBackgroundColor: colors.water,
  buttonTextColor: colors.pearl,
  horizontal: false,
  pageData: {
    partnerAccount: undefined,
    partnerId: undefined,
    campaignId: undefined,
  },
  theme: 'white',
};

export default WidgetForm;
