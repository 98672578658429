import React from 'react';
import styled from 'styled-components';

import Image from '../../ui/images/Image';
import Link from '../../ui/buttons/Link';

const SocialIconsListItem = styled.li`
  display: inline-block;
  padding-left: 1rem;
`;

const FooterSocialIcons = () => {
  return (
    <ul>
      <SocialIconsListItem>
        <Link linkURL="https://www.youtube.com/channel/UCKY3_ojf5qQt0VjvML3p4ng" rel="nofollow">
          <Image alt="Youtube Icon" height={20} src="/img/icons/youtube-minimal-icon.svg" />
        </Link>
      </SocialIconsListItem>
      <SocialIconsListItem>
        <Link linkURL="https://twitter.com/bench" rel="nofollow">
          <Image alt="Twitter Icon" height={20} src="/img/icons/twitter-icon.svg" />
        </Link>
      </SocialIconsListItem>
      <SocialIconsListItem>
        <Link linkURL="https://www.facebook.com/BenchAccounting/" rel="nofollow">
          <Image alt="Facebook Icon" height={20} src="/img/icons/facebook-icon.svg" />
        </Link>
      </SocialIconsListItem>
      <SocialIconsListItem>
        <Link linkURL="https://www.instagram.com/benchaccounting/" rel="nofollow">
          <Image alt="Instagram Icon" height={20} src="/img/icons/instagram-icon.svg" />
        </Link>
      </SocialIconsListItem>
    </ul>
  );
};

export default FooterSocialIcons;
