/*
 * Schema Markup to help our ratings
 * https://schema.org/SoftwareApplication
 */

const schemaDotOrgSnippet = () =>
  JSON.stringify({
    '@type': 'SoftwareApplication',
    image: 'https://bench-assets.imgix.net/img/illustrations/simple-hero-desktop-v2-3840.jpg',
    name: 'Bench Accounting',
    description:
      'America’s largest bookkeeping service for small businesses. Bench does your ' +
      'small business books for you. We’ll connect you with one of our in-house bookkeepers. ' +
      'Then, they’ll prepare your monthly and year-end financials—so you don’t have to. Real ' +
      'humans. Perfect books. Your bookkeeping team imports bank statements, categorizes ' +
      'transactions, and prepares financial statements every month. Questions? Bench bookkeepers ' +
      'work in-house, and they respond within one business day. Monthly financial statements and ' +
      'expense overviews keep you in control of your money. At-a-glance visual reports help you ' +
      'see the big picture and give you actionable insights to help you grow your business. ' +
      'You’ll never be in the dark again. Less time bookkeeping means more time to focus on what ' +
      'you really care about — like helping your business succeed.',
    applicationCategory: 'Business Application',
    applicationSubCategory:
      'Accounting Software, Expense Tracking, Business Reporting, Bookkeeping Software, Sales ' +
      'Tax Software',
    author: {
      '@type': 'Organization',
      name: 'Bench Accounting',
      logo: 'https://bench-assets.imgix.net/img/logos/bench-logo-symbol.svg',
    },
    address: {
      '@type': 'PostalAddress',
      streetAddress: '200 - 545 Robson Street',
      addressLocality: 'Vancouver',
      addressRegion: 'BC',
      postalCode: 'V6B 1A6',
    },
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+1 888-760-1940',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.8',
      bestRating: '5',
      ratingCount: '535',
    },
  });

export default schemaDotOrgSnippet;
