import translateColumnData from './translateColumnData';
import SectionGrid from '../../../components/sections/SectionGrid';
import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';
import WidgetFullBleedImage from '../../../components/widgets/WidgetFullBleedImage';

export const HORIZONTAL_ALIGNMENT = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
};

export const PADDING_UNITS = {
  FULL: 'full',
  HALF: 'half',
  NONE: 'none',
};

export const VERTICAL_ALIGNMENT = {
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',
};

const getFlexAlign = (humanReadableDirection) => {
  switch (humanReadableDirection) {
    case HORIZONTAL_ALIGNMENT.RIGHT:
    case VERTICAL_ALIGNMENT.BOTTOM:
      return 'flex-end';
    case HORIZONTAL_ALIGNMENT.LEFT:
    case VERTICAL_ALIGNMENT.TOP:
      return 'flex-start';
    default:
      return 'center';
  }
};

const getUnitsFromString = (humanReadableSize, mobile) => {
  switch (humanReadableSize) {
    case PADDING_UNITS.FULL:
      return mobile ? 6 : 10;
    case PADDING_UNITS.HALF:
      return mobile ? 4 : 6;
    default:
      return 0;
  }
};

const translateSectionData = ({
  anchorLink,
  backgroundColor,
  className,
  columnGutter,
  columnHorizontalAlignment,
  columns,
  columnVerticalAlignment,
  createCarouselFromColumns,
  swipeColumnsOnMobile,
  fullScreenSectionDesktopBackgroundImage,
  fullScreenSectionMobileAlternateImage,
  hideByDefault,
  maxHeightDesktop,
  maxHeightMobile,
  overrideNavbarPadding,
  paddingBottom,
  paddingTop,
  reverseOrderOnDesktop,
}) => {
  const translatedSectionData = {
    alignItems: getFlexAlign(columnVerticalAlignment),
    backgroundColor,
    columns: columns ? columns.map(translateColumnData) : [],
    flexDirection: reverseOrderOnDesktop ? 'row-reverse' : 'row',
    hash: anchorLink,
    hideOnTest: hideByDefault,
    justifyContent: getFlexAlign(columnHorizontalAlignment),
    noGutter: !columnGutter,
    overrideNavbarPadding,
    paddingBottom: getUnitsFromString(paddingBottom),
    paddingBottomMobile: getUnitsFromString(paddingBottom, true),
    paddingTop: getUnitsFromString(paddingTop),
    paddingTopMobile: getUnitsFromString(paddingTop, true),
    SectionComponent: SectionGrid,
    swipeColumns: createCarouselFromColumns,
    swipeColumnsOnMobile,
    testId: className,
    maxHeightDesktop,
    maxHeightMobile,
  };

  // If a full screen section desktop background image has been set, that is the signal to modify
  // this section to be a full bleed section.
  if (fullScreenSectionDesktopBackgroundImage) {
    // Add an invisible (zero width, zero margin) column with a full bleed background image in it
    translatedSectionData.columns.push({
      span: 0,
      widgets: [
        {
          data: {
            // If a mobile alternate image is provided, add its props to the widget data
            ...normalizeContentfulImageData(fullScreenSectionMobileAlternateImage, true),
            ...normalizeContentfulImageData(fullScreenSectionDesktopBackgroundImage),
            maxWidth: 1920,
            mobileMaxWidth: 800,
          },
          margin: 0,
          WidgetComponent: WidgetFullBleedImage,
        },
      ],
    });
    // Attempt to fill the viewport of the browser
    translatedSectionData.fillViewport = true;

    if (!translatedSectionData.maxHeightDesktop) {
      // Don't allow the section to become too large on desktop
      translatedSectionData.maxHeightDesktop = 768;
    }
    if (!translatedSectionData.maxHeightMobile) {
      // Give mobile a max height so that portrait tablet doesn't look silly
      translatedSectionData.maxHeightMobile = 667;
    }

    // Remove the padding bottom on mobile so that there isn't a gap below the image
    translatedSectionData.paddingBottomMobile = 0;
  }
  return translatedSectionData;
};

export default translateSectionData;
