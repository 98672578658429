/* eslint-disable object-curly-newline */
import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import config from '../config';
import schemaDotOrgSnippet from '../resources/vendor/schemaDotOrgSnippet';

export const normalizePath = (path) => {
  let normalizedPath = path;

  // Add leading slash if not present
  if (!/^\//.test(normalizedPath)) {
    normalizedPath = `/${normalizedPath}`;
  }

  // Add trailing slash if not present
  if (!/\/$/.test(normalizedPath)) {
    normalizedPath = `${normalizedPath}/`;
  }

  return normalizedPath;
};

const meta = (path, description, socialImage, title, robotMetaContent) => {
  let socialImageUrl;
  if (socialImage) {
    // Contentful images don't have schema
    socialImageUrl = /^\/\//.test(socialImage) ? `https:${socialImage}` : socialImage;
  } else if (path.indexOf('/blog/') === 0) {
    // Default social image for blog pages
    socialImageUrl = `${config.IMGIX_ORIGIN}/img/social/default-social.png`;
  } else {
    // Fallback default social image
    socialImageUrl = `${config.IMGIX_ORIGIN}/img/social/benchco_thumbnail_image.jpg`;
  }

  return [
    { name: 'description', content: description },
    { name: 'facebook-domain-verification', content: 'lmkytwedo0gtph86853ussgdc8rae2' },
    { name: 'msapplication-config', content: '' },
    { name: 'msapplication-config', content: '/browserconfig.xml' },
    { name: 'theme-color', content: '#098b8c' },
    { name: 'robots', content: robotMetaContent || 'index, follow' },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: '@bench' },
    { name: 'twitter:creator', content: '@bench' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:url', content: '//bench.co/' },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: socialImageUrl },
    { name: 'google-site-verification', content: '8ZG1s7y54N9XAVv5GFtIgd1zKytWY7e7-5OSS8sRBWo' },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, shrink-to-fit=no user-scalable=no',
    },
    { property: 'og:site_name', content: 'Bench' },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
    { property: 'og:image', content: socialImageUrl },
  ];
};
const link = (path) => [
  { rel: 'canonical', href: `${config.CANONICAL_ORIGIN}${normalizePath(path)}` },
  {
    rel: 'apple-touch-icon',
    type: 'image/png',
    size: '180x180',
    href: '/icons/apple-touch-icon.png',
  },
  { rel: 'icon', type: 'image/png', size: '16x16', href: '/icons/favicon-16x16.png' },
  { rel: 'icon', type: 'image/png', size: '32x32', href: '/icons/favicon-32x32.png' },
  { rel: 'manifest', href: '/icons/manifest.json' },
  {
    rel: 'mask-icon',
    href: `${config.IMGIX_ORIGIN}/img/icons/safari-pinned-tab.svg`,
    color: '#098b8c',
  },
  { rel: 'shortcut icon', href: '/favicon.ico' },
  { rel: 'preconnect', href: config.IMGIX_ORIGIN, crossorigin: 'anonymous' },
  { rel: 'preconnect', href: config.CONTENTFUL_IMAGE_CDN_ORIGIN, crossorigin: 'anonymous' },
  {
    rel: 'preload',
    href: `${config.FONT_ORIGIN}/fonts/circular/lineto-circular-book-s.woff2`,
    as: 'font',
    crossorigin: 'anonymous',
  },
  {
    rel: 'preload',
    href: `${config.FONT_ORIGIN}/fonts/circular/lineto-circular-bold-s.woff2`,
    as: 'font',
    crossorigin: 'anonymous',
  },
];

const BenchHelmet = ({ description, path, socialImage, title, robotMetaContent }) => (
  <Helmet
    bodyAttributes={{ class: 'sticky-header' }}
    htmlAttributes={{ lang: 'en' }}
    link={link(path)}
    meta={meta(path, description, socialImage, title, robotMetaContent)}
    title={title}
  >
    {path === '/' && <script type="application/ld+json">{schemaDotOrgSnippet()}</script>}
  </Helmet>
);

BenchHelmet.propTypes = {
  description: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  robotMetaContent: PropTypes.string.isRequired,
  socialImage: PropTypes.string,
  title: PropTypes.string.isRequired,
};

BenchHelmet.defaultProps = {
  socialImage: null,
};

export default BenchHelmet;
