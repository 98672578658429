import config from '../../config';
import { loadScript } from '../../components/helpers/loadAsset';

const MUNCHKIN_SCRIPT = '//munchkin.marketo.net/munchkin.js';
const MUNCHKIN_GLOBAL = 'Munchkin';

let munchkinInitialized = false;

const loadAndInitMunchkin = () => {
  return loadScript(MUNCHKIN_SCRIPT, true)
    .then(() => {
      if (munchkinInitialized) {
        return;
      }
      if (!(MUNCHKIN_GLOBAL in window)) {
        return;
      }
      window[MUNCHKIN_GLOBAL].init(config.MARKETO_ID);
      munchkinInitialized = true;
    })
    .catch(console.warn.bind(console));
};

export default loadAndInitMunchkin;
