import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import { mediaQueries } from '../../ui/constants/grid';
import GridRow from '../../ui/grid/GridRow';
import GridColumn from '../../ui/grid/GridColumn';
import Image from '../../ui/images/Image';
import LinkButton from '../../ui/buttons/LinkButton';
import Spacer from '../../ui/Spacer';
import MarketoForm from '../embeds/MarketoForm';
import Card from './Card';
import formatCentsForDisplay from '../../lib/formatCentsForDisplay';

const SpaciousCard = styled(Card)`
  ${mediaQueries.desktop`
    padding: 48px 70px;
  `};
`;

const FormHeader = styled.h2`
  color: ${colors.charcoal};
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const FormDescription = styled.p`
  color: ${colors.ash};
  font-size: 14px;
  margin-bottom: 40px;
`;

const ResultWrapper = styled.div`
  color: #43acb5;
  padding-bottom: 40px;
  text-align: center;
`;

const ResultHeader = styled.h1`
  font-size: 18px;
  font-weight: bold;

  ${mediaQueries.desktop`
    font-size: 28px;
  `};
`;

const ResultAmount = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin: 16px auto;

  ${mediaQueries.desktop`
    font-size: 80px;
  `};
`;

const ResultDescription = styled.p`
  margin: 0 auto 20px;
  max-width: 440px;

  ${mediaQueries.desktop`
    margin-bottom: 40px;
  `};
`;

const GatedResult = ({ campaignId, marketoFormId, calculateEstimatedQuarterlyTax, formData }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onFormSuccess = () => {
    setFormSubmitted(true);
  };

  const quarterlyTaxInCents = calculateEstimatedQuarterlyTax(formData);
  const estimatedQuarterlyTax = formatCentsForDisplay(quarterlyTaxInCents);
  if (!formSubmitted) {
    return (
      <SpaciousCard>
        <GridRow>
          <GridColumn span={5}>
            <Spacer paddingTop={14} paddingTopMobile={0} />
            <Image
              alt="Person climbing a giant book"
              maxWidth={339}
              naturalHeight={678}
              naturalWidth={460}
              src="/img/illustrations/climbing-books.png"
            />
            <Spacer paddingBottomMobile={4} />
          </GridColumn>
          <GridColumn span={1} />
          <GridColumn span={6}>
            <FormHeader>You’re almost there!</FormHeader>
            <FormDescription>
              Enter your info below and we’ll email you your estimated quarterly tax amount.
            </FormDescription>
            <MarketoForm
              campaignId={campaignId}
              customHiddenValues={{ taxesOwed: estimatedQuarterlyTax }}
              formId={marketoFormId}
              onFormSuccess={onFormSuccess}
              placeholderFields={4}
            />
          </GridColumn>
        </GridRow>
      </SpaciousCard>
    );
  }

  return (
    <ResultWrapper>
      <ResultHeader>Your quarterly tax estimate is</ResultHeader>
      <ResultAmount>{estimatedQuarterlyTax}</ResultAmount>
      <ResultDescription>
        Remember: estimated tax payments are due on the 15th of April, June, September, and January
        (or the next business day if the 15th is a weekend or holiday).
      </ResultDescription>
      <ResultDescription>
        If you’re a C Corp, the deadlines are the 15th of April, June, September, and December.
      </ResultDescription>
      <ResultDescription>
        <strong>
          Ready to pass the year end tax stress to someone else? From prep to filing, Bench Tax
          handles the tax season to-dos for you.
        </strong>
      </ResultDescription>
      <LinkButton backgroundColor="#1AADA2" linkURL="/small-business-tax/" textColor={colors.white}>
        Learn More About Bench Tax
      </LinkButton>
    </ResultWrapper>
  );
};

GatedResult.propTypes = {
  calculateEstimatedQuarterlyTax: PropTypes.func.isRequired,
  campaignId: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  marketoFormId: PropTypes.string.isRequired,
};

export default GatedResult;
