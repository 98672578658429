import PropTypes from 'prop-types';
import React from 'react';
import Section from './Section';
import TemplateWrapper from './TemplateWrapper';

export const WebsitePageContext = React.createContext({
  partnerAccount: undefined,
  partnerId: undefined,
  campaignId: undefined,
});

const WebsitePage = ({
  background,
  buttonText,
  buttonUrl,
  description,
  enableAbTesting,
  footerBackground,
  footerType,
  hideNavbar,
  robotMetaContent,
  navbarAlwaysOpaque,
  navbarType,
  path,
  socialImage,
  testId,
  title,
  transparentNavbarBenchColor,
  transparentNavbarTextColor,
  sections,
  partnerAccount,
  partnerId,
  campaignId,
}) => {
  const pageContextValues = {
    partnerAccount,
    partnerId,
    campaignId,
  };

  return (
    <WebsitePageContext.Provider value={pageContextValues}>
      <TemplateWrapper
        background={background}
        buttonText={buttonText}
        buttonUrl={buttonUrl}
        description={description}
        enableAbTesting={enableAbTesting}
        footerBackground={footerBackground}
        footerType={footerType}
        hideNavbar={hideNavbar}
        navbarAlwaysOpaque={navbarAlwaysOpaque}
        navbarType={navbarType}
        path={path}
        robotMetaContent={robotMetaContent}
        socialImage={socialImage}
        testId={testId}
        title={title}
        transparentNavbarBenchColor={transparentNavbarBenchColor}
        transparentNavbarTextColor={transparentNavbarTextColor}
      >
        {sections &&
          sections.map((section, sectionIndex) => (
            <Section
              key={sectionIndex}
              data={section}
              isFirstSection={section.overrideNavbarPadding ? false : sectionIndex === 0}
            />
          ))}
      </TemplateWrapper>
    </WebsitePageContext.Provider>
  );
};

WebsitePage.propTypes = {
  background: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  campaignId: PropTypes.string,
  description: PropTypes.string,
  enableAbTesting: PropTypes.bool,
  footerBackground: PropTypes.string,
  footerType: PropTypes.string,
  hideNavbar: PropTypes.bool,
  navbarAlwaysOpaque: PropTypes.bool,
  navbarType: PropTypes.string,
  partnerAccount: PropTypes.string,
  partnerId: PropTypes.string,
  path: PropTypes.string.isRequired,
  robotMetaContent: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object),
  socialImage: PropTypes.string,
  testId: PropTypes.string,
  title: PropTypes.string,
  transparentNavbarBenchColor: PropTypes.string,
  transparentNavbarTextColor: PropTypes.string,
};

WebsitePage.defaultProps = {
  buttonText: 'Start a Free Trial',
  buttonUrl: '/signup/',
  enableAbTesting: false,
  socialImage: null,
  background: null,
  campaignId: undefined,
  testId: null,
  description:
    'Bench pairs you with a team of bookkeepers and uses simple software to do your ' +
    'bookkeeping, so you don’t have to. Start a free trial today.',
  footerBackground: 'white',
  footerType: 'simple',
  hideNavbar: false,
  robotMetaContent: 'index, follow',
  navbarAlwaysOpaque: false,
  navbarType: 'simple',
  partnerAccount: undefined,
  partnerId: undefined,
  sections: null,
  title: 'Bench — Online Bookkeeping for Your Small Business',
  transparentNavbarBenchColor: null,
  transparentNavbarTextColor: null,
};

export default WebsitePage;
