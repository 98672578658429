import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import colors from './constants/colors';
import { mediaQueries } from './constants/grid';

const RadioSetLayout = styled.div`
  text-align: left;

  ${mediaQueries.desktop`
    text-align: center;
  `}
`;

const RadioButtonLabel = styled.label`
  display: block;
  line-height: 30px;
  margin: 8px 16px;
  position: relative;

  input {
    margin-right: 8px;
    width: 30px;
  }

  ${mediaQueries.desktop`
    display: inline-block;
  `}
`;

const CheckableBox = styled.div`
  border: 1px solid ${colors.sand};
  border-radius: 4px;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
`;

const checkmarkSVG = encodeURIComponent(`
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="#FFFFFF" d="M21.60938,6.76562A1.11264,1.11264,0,0,1,22,7.625a1.15479,1.15479,0,0,1-.39062.89844l-10.70313,10.625a1.169,1.169,0,0,1-.85937.35156,1.21667,1.21667,0,0,1-.89844-.35156L2.35156,12.42969A1.21671,1.21671,0,0,1,2,11.53125a1.40214,1.40214,0,0,1,.35156-.89844l1.91406-1.875a1.19251,1.19251,0,0,1,.87891-.35156,1.19249,1.19249,0,0,1,.87891.35156l3.98437,3.94531,7.96875-7.85156a1.27443,1.27443,0,0,1,1.75782,0Z" />
  </svg>
`);

const CheckedBox = styled(CheckableBox)`
  background: ${colors.bonsai} url('data:image/svg+xml;utf8,${checkmarkSVG}') no-repeat 50% 50%;
  background-size: 16px;
`;

const UncheckedBox = styled(CheckableBox)`
  background: ${colors.white};
`;

const ErrorText = styled.p`
  color: ${colors.copper};
  font-size: 12px;
`;

const RadioSet = ({ value, onChange, errorText, name, options }) => {
  const [currentValue, setCurrentValue] = useState(value);

  const handleChange = (event) => {
    if (typeof onChange === 'function') {
      onChange(event);
    }
    setCurrentValue(event.target.value);
  };

  return (
    <RadioSetLayout>
      {options.map(({ label, value: optionValue }) => (
        <RadioButtonLabel key={optionValue}>
          <input
            checked={currentValue === optionValue}
            name={name}
            onChange={handleChange}
            type="radio"
            value={optionValue}
          />
          {currentValue === optionValue ? <CheckedBox /> : <UncheckedBox />}
          {label}
        </RadioButtonLabel>
      ))}
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </RadioSetLayout>
  );
};

RadioSet.propTypes = {
  errorText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  value: PropTypes.string,
};

RadioSet.defaultProps = {
  errorText: undefined,
  name: undefined,
  onChange: undefined,
  value: undefined,
};

export default RadioSet;
