import WidgetAccordion from '../../../components/widgets/WidgetAccordion';
import tryGet from '../../../components/helpers/tryGet';

const widgetAccordionTranslation = ({ accordionRows, heading }) => {
  const data = {
    accordionRows:
      accordionRows.length > 0
        ? accordionRows.map(({ title, button, body }) => ({
            title: title || '',
            body: tryGet(['body'], body) || body || '',
            button,
          }))
        : [],
    heading: heading || '',
  };
  return { data, WidgetComponent: WidgetAccordion };
};

export default widgetAccordionTranslation;
