import sideTabViewComponentTranslation from './sideTabViewComponentTranslation';
import widgetPricingTableTranslation from './widgetPricingTableTranslation';
import widgetValuePropTranslation from './widgetValuePropTranslation';
import widgetFormTranslation from './widgetFormTranslation';
import widgetBasicImageTranslation from './widgetBasicImageTranslation';
import widgetCallToActionButtonTranslation from './widgetCallToActionButtonTranslation';
import widgetEmbedTranslation from './widgetEmbedTranslation';
import widgetBasicQuoteTranslation from './widgetBasicQuoteTranslation';
import widgetHomePageCustomBannerTranslation from './widgetHomePageCustomBannerTranslation';
import widgetSummaryWithCollapsibleDetailsTranslation from './widgetSummaryWIthCollapsibleDetailsTranslation';
import widgetTrustPilotTranslation from './widgetTrustPilotTranslation';
import widgetBannerTranslation from './widgetBannerTranslation';
import widgetJobBoardTranslation from './widgetJobBoardTranslation';
import widgetStaticPricingTranslation from './widgetStaticPricingTranslation';
import widgetInstagramFeedTranslation from './widgetInstagramFeedTranslation';
import widgetLabelTranslation from './widgetLabelTranslation';
import widgetCallToActionV2Translation from './widgetCallToActionV2Translation';
import widgetAccordionTranslation from './widgetAccordionTranslation';
import widgetSingleLayoutTabbedContentTranslation from './widgetSingleLayoutTabbedContentTranslation';
import widgetMultiLayoutTabbedContentTranslation from './widgetMultiLayoutTabbedContentTranslation';
import widgetBasicQuoteV2Translation from './widgetBasicQuoteV2Translation';
import widgetBannerV2Translation from './widgetBannerV2Translation';
import widgetPricingCardsTranslationV2 from './widgetPricingCardsTranslationV2';
import widgetPricingCardsTranslationV3 from './widgetPricingCardsTranslationV3';

const CONTENTFUL_WIDGETNAME_TRANSLATOR_MAP = {
  ContentfulBasicImage: widgetBasicImageTranslation,
  ContentfulBasicQuote: widgetBasicQuoteTranslation,
  ContentfulCallToActionButton: widgetCallToActionButtonTranslation,
  ContentfulCallToActionRevamp: widgetCallToActionV2Translation,
  ContentfulEmbed: widgetEmbedTranslation,
  ContentfulForm: widgetFormTranslation,
  ContentfulPricingTable: widgetPricingTableTranslation,
  ContentfulValueProp: widgetValuePropTranslation,
  ContentfulHomePageCustomBanner: widgetHomePageCustomBannerTranslation,
  ContentfulSideTabViewContainer: sideTabViewComponentTranslation,
  ContentfulSummaryAndDetails: widgetSummaryWithCollapsibleDetailsTranslation,
  ContentfulTrustPilot: widgetTrustPilotTranslation,
  ContentfulBannerWidget: widgetBannerTranslation,
  ContentfulBannerWidgetV2: widgetBannerV2Translation,
  ContentfulJobBoard: widgetJobBoardTranslation,
  ContentfulStaticPricingWidget: widgetStaticPricingTranslation,
  ContentfulInstagramFeed: widgetInstagramFeedTranslation,
  ContentfulLabel: widgetLabelTranslation,
  ContentfulAccordion: widgetAccordionTranslation,
  ContentfulMultiLayoutTabbedContent: widgetMultiLayoutTabbedContentTranslation,
  ContentfulSingleLayoutTabbedContent: widgetSingleLayoutTabbedContentTranslation,
  ContentfulBasicQuoteV2: widgetBasicQuoteV2Translation,
  ContentfulPricingCards: widgetPricingCardsTranslationV2,
  ContentfulPromoPricingCards: widgetPricingCardsTranslationV3,
};

const translateWidgetData = (data) => {
  const translator = CONTENTFUL_WIDGETNAME_TRANSLATOR_MAP[data.typeName];
  if (translator) {
    return translator(data);
  }
  throw new Error(`No translation file found for ${data.typeName}`);
};

export default translateWidgetData;
