import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import calculateQuarterlyTaxForIndividual from './quarterlyTaxCalculator/calculateQuarterlyTaxForIndividual';
import colors from '../../../ui/constants/colors';
import GatedResult from '../../../components/qtc/MarketoFormGatedQTCResult';
import individualDeductionTypes from './quarterlyTaxCalculator/individualDeductionTypes';
import individualFilingStatuses from './quarterlyTaxCalculator/individualFilingStatuses';
import Input from '../../../ui/input/Input';
import LinearFlowForm from '../../../components/qtc/LinearFlowForm';
import LinearFormView from '../../../components/qtc/LinearFormView';
import RadioSet from '../../../ui/RadioSet';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';

const inputTheme = '#F6F6F6';

const reactSelectStyles = {
  control: (provided) => ({
    ...provided,
    background: inputTheme,
    borderColor: inputTheme,
    borderRadius: '4px',
    boxShadow: 'none',
    color: colors.cloud,
    fontSize: '14px',
    height: '50px',
    paddingLeft: '16px',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menuList: (provided) => ({
    ...provided,
    background: inputTheme,
    borderRadius: '4px',
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: isFocused ? colors.white : 'transparent',
    color: colors.cloud,
    fontSize: '14px',
    padding: '12px 26px',
    '&:hover': {
      backgroundColor: colors.white,
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
    color: colors.cloud,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.charcoal,
  }),
};

const formDataToCalculatorAdaptor = (formData) => {
  const result = {};
  Object.keys(formData).forEach((key) => {
    if (key === 'filingStatus') {
      // filingStatus is a ReactSelect so its value is an option object with a value
      result[key] = formData[key].value;
    } else if (key === 'deductionType') {
      // deductionType is a RadioSet so its value is text
      result[key] = formData[key];
    } else {
      const value = formData[key];

      let centsValueStr = value.split('.')[1] || '';
      centsValueStr = `${centsValueStr}00`.substring(0, 2);
      const centsValue = Number(centsValueStr);
      const intValue = Number(value.split('.')[0]);

      result[key] = intValue * 100 + centsValue;
    }
  });
  return result;
};

const DropDownErrorWrapper = styled.p`
  color: ${colors.copper};
  font-size: 12px;
`;

const ReactSelectWithErrorMessage = ({ errorText, ...props }) => {
  return (
    <div>
      <Select {...props} />
      <DropDownErrorWrapper>{errorText}</DropDownErrorWrapper>
    </div>
  );
};

ReactSelectWithErrorMessage.propTypes = {
  errorText: PropTypes.string,
};

ReactSelectWithErrorMessage.defaultProps = {
  errorText: undefined,
};

const StandardOrItemizedConfigView = ({ itemizedProps, standardizedProps, ...commonProps }) =>
  commonProps.formData.deductionType === individualDeductionTypes.STANDARD ? (
    <LinearFormView {...standardizedProps} {...commonProps} />
  ) : (
    <LinearFormView {...itemizedProps} {...commonProps} />
  );

StandardOrItemizedConfigView.propTypes = {
  itemizedProps: PropTypes.object.isRequired,
  standardizedProps: PropTypes.object.isRequired,
};

const positiveDecimal = /^\d*(\.\d+)?$/;

const positiveDecimalValidator = {
  name: 'regex',
  value: positiveDecimal,
  message: 'Valid dollar amount required',
};

const filingStatusOptions = [
  {
    label: 'Head of household',
    value: individualFilingStatuses.HEAD_OF_HOUSEHOLD,
  },
  {
    label: 'Married filing jointly',
    value: individualFilingStatuses.MARRIED_FILING_JOINTLY,
  },
  {
    label: 'Married filing separately',
    value: individualFilingStatuses.MARRIED_FILING_SEPARATELY,
  },
  {
    label: 'Qualifying widower',
    value: individualFilingStatuses.QUALIFYING_WIDOWER,
  },
  {
    label: 'Single',
    value: individualFilingStatuses.SINGLE,
  },
];

const deductionTypeOptions = [
  {
    label: 'Standard',
    value: individualDeductionTypes.STANDARD,
  },
  {
    label: 'Itemize',
    value: individualDeductionTypes.ITEMIZED,
  },
];

const getQTCSectionConfigIndividual = ({ businessStructure, campaignId, marketoFormId }) => ({
  backgroundColor: '#f6fcfd',
  columns: [
    {
      span: 8,
      widgets: [
        {
          data: {
            defaultFormData: {
              childTaxCredit: '',
              deductionType: '',
              dependentCareTaxCredit: '',
              educationTaxCredit: '',
              estimatedAlternativeMinimumTax: '',
              estimatedItemizedDeduction: '',
              estimatedQualifiedBusinessIncomeDeduction: '',
              expectedAdjustmentsToIncome: '',
              expectedTotalIncome: '',
              filingStatus: null,
              foreignIncomeTaxCredit: '',
              generalBusinessTaxCredit: '',
              priorYearMinimumTaxCredit: '',
              residentialTaxCredit: '',
              retirementSavingsContributionCredit: '',
              selfEmploymentTax: '',
              standardDeductionAmount: '',
            },
            views: [
              {
                props: {
                  businessStructure,
                  formConfig: {
                    filingStatus: {
                      fieldDefinition: {
                        component: ReactSelectWithErrorMessage,
                        errorPropName: 'errorText',
                        props: {
                          isSearchable: false,
                          menuPortalTarget:
                            typeof document === 'undefined' ? undefined : document.body,
                          options: filingStatusOptions,
                          placeholder: 'Select',
                          styles: reactSelectStyles,
                        },
                        validators: ['required'],
                        getValueInOnChange: (optionObject) => optionObject,
                      },
                      helpContent: `
Your tax filing status dictates your standard deduction amount for the year.
                      `,
                    },
                  },
                  footerText: 'step 1 of 8',
                  header: 'What’s your expected tax filing status for 2023?',
                },
                ViewComponent: LinearFormView,
              },
              {
                props: {
                  businessStructure,
                  formConfig: {
                    expectedTotalIncome: {
                      fieldDefinition: {
                        component: Input,
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                        validators: ['required', positiveDecimalValidator],
                      },
                      helpContent: `
Income sources include:

- Wages, Salaries, and Tips
- Taxable Interest
- Taxable Dividends
- Business Income
- Capital Gains
- Taxable IRA and Pensions
- Rental Income
- Farming Income
- Unemployment Benefits
- Alimony Received
- Taxable Social Security Received
- All other sources of income
                      `,
                      labelProps: {
                        label: 'Expected total income for 2023',
                      },
                    },
                    expectedAdjustmentsToIncome: {
                      fieldDefinition: {
                        component: Input,
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                        validators: ['required', positiveDecimalValidator],
                      },
                      helpContent: `
Adjustments to income include:

- Self Employed Health Insurance
- HSA Contributions
- Retirement Plan Contributions for Self Employed
- IRA Contributions
- <a href="https://bench.co/blog/tax-tips/independent-contractor-tax-guide/" rel="noopener noreferrer" target="_blank">Deductible portion of Self Employment Tax (50%)</a>
- Penalty on Early Savings Withdrawal
- Student Loan Interest up to $2,500
- Tuition and Fees up to $4,000
- Alimony Paid
- Moving Expenses for Armed Forces Members
                      `,
                      labelProps: {
                        label: 'Expected adjustments to income for 2023',
                      },
                    },
                  },
                  helpContent: `
Adjusted Gross Income is your total income for the year, minus any adjustments to income. You can
learn more with our resource
<a href="https://bench.co/blog/tax-tips/adjusted-gross-income" rel="noopener noreferrer" target="_blank">What Is Adjusted Gross Income</a>?
                  `,
                  isBackButtonShown: true,
                  footerText: 'step 2 of 8',
                  header: 'Next, let’s figure out your Adjusted Gross Income for 2023',
                },
                ViewComponent: LinearFormView,
              },
              {
                props: {
                  businessStructure,
                  formConfig: {
                    deductionType: {
                      fieldDefinition: {
                        component: RadioSet,
                        errorPropName: 'errorText',
                        props: {
                          options: deductionTypeOptions,
                        },
                        validators: ['required'],
                      },
                    },
                  },
                  footerText: 'step 3 of 8',
                  helpContent: `
You can choose to itemize your deductions individually, or take a standard lump deduction. For single taxpayers, the standard deduction for 2023 is $13,850. If your itemized tax deductions will be more than that, you’ll probably want to itemize. <a href="https://bench.co/blog/tax-tips/standard-deduction/" rel="noopener noreferrer" target="_blank">Learn more here</a>.
                  `,
                  isBackButtonShown: true,
                  header: 'Will you itemize your deductions, or claim the standard deduction?',
                },
                ViewComponent: LinearFormView,
              },
              {
                props: {
                  standardizedProps: {
                    businessStructure,
                    formConfig: {
                      standardDeductionAmount: {
                        fieldDefinition: {
                          component: Input,
                          errorPropName: 'errorText',
                          props: {
                            placeholder: 'Amount',
                            theme: inputTheme,
                          },
                          debounce: 100,
                          validators: ['required', positiveDecimalValidator],
                        },
                        helpContent: `
Your standard deduction—the portion of your income that is protected from taxes—gets adjusted every year to keep up with inflation. The standard deduction amounts for the 2023 tax year (and their increases from the 2022 tax year) are:

$13,850 for single filers (up $900)

$13,850 for married taxpayers who file their taxes separately (up $900)

$20,800 for heads of households (up $1,400)

$27,700 for married taxpayers who file jointly (up $1,800)

$27,700 for qualifying widows or widowers (up $1,800)
                        `,
                      },
                    },
                    isBackButtonShown: true,
                    footerText: 'step 4 of 8',
                    header: 'Enter the standard deduction amount you’ll be claiming this year.',
                  },
                  itemizedProps: {
                    businessStructure,
                    formConfig: {
                      estimatedItemizedDeduction: {
                        fieldDefinition: {
                          component: Input,
                          errorPropName: 'errorText',
                          props: {
                            placeholder: 'Amount',
                            theme: inputTheme,
                          },
                          debounce: 100,
                          validators: ['required', positiveDecimalValidator],
                        },
                        helpContent: `
For a full list of itemized deductions, check out
<a href="https://www.irs.gov/pub/irs-pdf/f1040sa.pdf" rel="noopener noreferer" target="_blank">Schedule A, Form 1040</a>.
                        `,
                      },
                    },
                    isBackButtonShown: true,
                    footerText: 'step 4 of 8',
                    header: 'What do you estimate your itemized deductions will be for 2023?',
                  },
                },
                ViewComponent: StandardOrItemizedConfigView,
              },
              {
                props: {
                  businessStructure,
                  formConfig: {
                    estimatedQualifiedBusinessIncomeDeduction: {
                      fieldDefinition: {
                        component: Input,
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                        validators: [positiveDecimalValidator],
                      },
                      helpContent: `
The QBI deduction allows owners of pass-through businesses to claim a tax deduction worth up to 20 percent of their qualified business income. Read if you qualify and how to calculate your deduction in our <a href="https://bench.co/blog/tax-tips/qbi-deduction/" rel="noopener noreferrer" target="_blank">QBI guide</a>.
                      `,
                    },
                  },
                  isBackButtonShown: true,
                  footerText: 'step 5 of 8',
                  header:
                    'Enter the estimated deduction you’ll claim for Qualified Business Income (QBI)',
                },
                ViewComponent: LinearFormView,
              },
              {
                props: {
                  businessStructure,
                  formConfig: {
                    estimatedAlternativeMinimumTax: {
                      fieldDefinition: {
                        component: Input,
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                        validators: [positiveDecimalValidator],
                      },
                      helpContent: `
The alternative minimum tax (AMT) ensures that high earners pay at least a minimum amount of federal income tax. Find out if this applies to you in our <a href="https://bench.co/blog/tax-tips/alternative-minimum-tax/" rel="noopener noreferrer" target="_blank">AMT Guide</a>.
                      `,
                    },
                  },
                  isBackButtonShown: true,
                  footerText: 'step 6 of 8',
                  header: 'Enter your estimated alternative minimum tax for 2023 (if applicable)',
                },
                ViewComponent: LinearFormView,
              },
              {
                props: {
                  businessStructure,
                  formConfig: {
                    foreignIncomeTaxCredit: {
                      fieldDefinition: {
                        component: Input,
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                        validators: [positiveDecimalValidator],
                      },
                      labelProps: {
                        label: 'Foreign Income Tax Credit',
                        sublabel: 'Form 1116',
                      },
                    },
                    dependentCareTaxCredit: {
                      fieldDefinition: {
                        component: Input,
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                        validators: [positiveDecimalValidator],
                      },
                      labelProps: {
                        label: 'Child and Dependent Care Tax Credit',
                        sublabel: 'Form 2441',
                      },
                    },
                    educationTaxCredit: {
                      fieldDefinition: {
                        component: Input,
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                        validators: [positiveDecimalValidator],
                      },
                      labelProps: {
                        label: 'Education Tax Credit',
                        sublabel: 'Form 8863',
                      },
                    },
                    retirementSavingsContributionCredit: {
                      fieldDefinition: {
                        component: Input,
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                        validators: [positiveDecimalValidator],
                      },
                      labelProps: {
                        label: 'Retirement Savings Contribution Credit',
                        sublabel: 'Form 8880',
                      },
                    },
                    childTaxCredit: {
                      fieldDefinition: {
                        component: Input,
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                        validators: [positiveDecimalValidator],
                      },
                      labelProps: {
                        label: 'Additional Child Tax Credit',
                        sublabel: 'Schedule 8812',
                      },
                    },
                    residentialTaxCredit: {
                      fieldDefinition: {
                        component: Input,
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                        validators: [positiveDecimalValidator],
                      },
                      labelProps: {
                        label: 'Residential Tax Credit',
                        sublabel: 'Form 5695',
                      },
                    },
                    generalBusinessTaxCredit: {
                      fieldDefinition: {
                        component: Input,
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                        validators: [positiveDecimalValidator],
                      },
                      labelProps: {
                        label: 'General Business Credit',
                        sublabel: 'Form 3800',
                      },
                    },
                    priorYearMinimumTaxCredit: {
                      fieldDefinition: {
                        component: Input,
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                        validators: [positiveDecimalValidator],
                      },
                      labelProps: {
                        label: 'Prior Year Minimum Tax',
                        sublabel: 'Form 8801',
                      },
                    },
                  },
                  helpContent: `
Not sure if these tax credits apply to you? Check out the IRS tax form listed beside each credit to learn more.
                  `,
                  isBackButtonShown: true,
                  footerText: 'step 7 of 8',
                  header: 'Enter any tax credits you’ll be claiming below',
                },
                ViewComponent: LinearFormView,
              },
              {
                props: {
                  businessStructure,
                  footerText: 'step 8 of 8',
                  formConfig: {
                    selfEmploymentTax: {
                      fieldDefinition: {
                        component: Input,
                        errorPropName: 'errorText',
                        props: {
                          placeholder: 'Amount',
                          theme: inputTheme,
                        },
                        debounce: 100,
                        validators: [positiveDecimalValidator],
                      },
                      helpContent: `
Self-employment tax is 15.3% of your net earnings from self-employment. You can learn more from our
<a href="https://bench.co/blog/tax-tips/independent-contractor-tax-guide/" rel="noopener noreferrer" target="_blank">Independent Contractor’s Guide</a>.
                      `,
                    },
                  },
                  header: 'Enter your self-employment tax for 2023',
                  isBackButtonShown: true,
                },
                ViewComponent: LinearFormView,
              },
              {
                props: {
                  calculateEstimatedQuarterlyTax: (formData) =>
                    calculateQuarterlyTaxForIndividual(formDataToCalculatorAdaptor(formData)),
                  campaignId,
                  marketoFormId,
                },
                ViewComponent: GatedResult,
              },
            ],
          },
          WidgetComponent: LinearFlowForm,
        },
        {
          data: {
            description:
              'Disclaimer: this calculator is for educational purposes only and should not be taken as ' +
              'official accounting or tax advice. Always consult a certified accountant before making financial decisions.',
            fontColor: colors.dust,
            fontSize: 12,
            mobileFontSize: 12,
            textAlignmentMobile: 'left',
          },
          WidgetComponent: WidgetValueProp,
        },
      ],
    },
  ],
});

export default getQTCSectionConfigIndividual;
