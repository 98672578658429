import PropTypes from 'prop-types';
import React from 'react';

import blogSignupSectionProps from '../helpers/blogSignupSectionProps';
import colors from '../../../ui/constants/colors';
import Page from '../../../components/Page';
import WidgetBlogSearchForm from '../../../components/widgets/WidgetBlogSearchForm';
import WidgetCardList from '../../../components/widgets/WidgetCardList';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';

import '../../../scss/styles-website.scss';
import translateArticleContentCard from '../translations/translateArticleContentCard';

const getPageProps = (path, query, searchError, searchInProgress, searchResults) => {
  const widgets = [];

  if (query) {
    // "Search results for" header
    widgets.push({
      data: {
        description: 'for',
        fontColor: colors.charcoal,
        fontSize: 24,
        fontSizeMobile: 18,
        fontWeight: 700,
        header: 'Search results',
        headerFontSize: 40,
        headerFontSizeMobile: 32,
        headerLineHeight: 40,
        headerLineHeightMobile: 32,
        lineHeight: 24,
        lineHeightMobile: 18,
        textAlignment: 'center',
      },
      WidgetComponent: WidgetValueProp,
    });
  } else {
    // "Search" header
    widgets.push({
      data: {
        fontColor: colors.charcoal,
        header: 'Search',
        headerFontSize: 40,
        headerFontSizeMobile: 32,
        headerLineHeight: 40,
        headerLineHeightMobile: 32,
        lineHeight: 24,
        textAlignment: 'center',
      },
      WidgetComponent: WidgetValueProp,
    });
  }

  // Search form
  widgets.push({
    data: {
      query,
    },
    WidgetComponent: WidgetBlogSearchForm,
  });

  if (searchInProgress) {
    // Loading indicator
    widgets.push({
      data: {
        description: 'Loading...',
        textAlignment: 'center',
      },
      WidgetComponent: WidgetValueProp,
    });
  }

  if (searchError) {
    // Error message
    widgets.push({
      data: {
        description: 'We encountered a problem while performing the search.',
        textAlignment: 'center',
      },
      WidgetComponent: WidgetValueProp,
    });
  }

  if (searchResults) {
    if (searchResults.length) {
      // Search results card list
      widgets.push({
        data: {
          cards: searchResults ? searchResults.map(translateArticleContentCard) : [],
          layout: [{ numCards: 3 }, { numCards: 3 }, { numCards: 3 }, { numCards: 3 }],
        },
        WidgetComponent: WidgetCardList,
      });
    } else {
      // "No results found" message
      widgets.push({
        data: {
          description:
            'It looks like we haven’t written about that yet!\n\nIf you ' +
            '[email us](mailto:editor@bench.co) your question, we’ll get you an answer within one ' +
            'business day.',
          textAlignment: 'center',
        },
        WidgetComponent: WidgetValueProp,
      });
    }
  }

  return {
    description: 'Search results on the Bench Accounting blog.',
    footerType: 'full',
    navbarType: 'full',
    path,
    sections: [
      {
        backgroundColor: '#F6F6F6',
        columns: [
          {
            span: 12,
            widgets,
          },
        ],
        paddingTopMobile: 0,
      },
      blogSignupSectionProps,
    ],
    title: query
      ? `Search results for “${query}” | Bench Blog | Bench Accounting`
      : 'Search | Bench Blog | Bench Accounting',
  };
};

const SearchResults = ({ path, query, searchError, searchInProgress, searchResults }) => {
  const pageProps = getPageProps(path, query, searchError, searchInProgress, searchResults);
  return (
    <Page
      background={pageProps.background}
      description={pageProps.description}
      enableAbTesting={pageProps.enableAbTesting}
      footerBackground={pageProps.footerBackground}
      footerType={pageProps.footerType}
      hideNavbar={pageProps.hideNavbar}
      navbarAlwaysOpaque={pageProps.navbarAlwaysOpaque}
      navbarType={pageProps.navbarType}
      path={pageProps.path}
      sections={pageProps.sections}
      socialImage={pageProps.socialImage}
      testId={pageProps.testId}
      title={pageProps.title}
      transparentNavbarBenchColor={pageProps.transparentNavbarBenchColor}
      transparentNavbarTextColor={pageProps.transparentNavbarTextColor}
    />
  );
};

SearchResults.propTypes = {
  path: PropTypes.string.isRequired,
  query: PropTypes.string,
  searchError: PropTypes.bool,
  searchInProgress: PropTypes.bool,
  searchResults: PropTypes.arrayOf(PropTypes.object),
};

SearchResults.defaultProps = {
  query: null,
  searchError: false,
  searchInProgress: false,
  searchResults: null,
};

export default SearchResults;
