import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Image from '../../ui/images/Image';
import Link from '../../ui/buttons/Link';
import { mediaQueries } from '../../ui/constants/grid';

const IMAGE_ALIGNMENT_CENTER = 'center';
const IMAGE_ALIGNMENT_LEFT = 'left';
const IMAGE_ALIGNMENT_RIGHT = 'right';

const ImageContainer = styled.div`
  ${({ imageAlignment }) => imageAlignment === IMAGE_ALIGNMENT_CENTER && 'margin: 0 auto;'}
  ${({ imageAlignment }) => imageAlignment === IMAGE_ALIGNMENT_LEFT && 'margin: 0 auto 0 0;'}
  ${({ imageAlignment }) => imageAlignment === IMAGE_ALIGNMENT_RIGHT && 'margin: 0 0 0 auto;'}

  a, img, picture {
    display: block;
  }

  ${mediaQueries.mobile`
    ${({ mobileMaxWidth }) => mobileMaxWidth && `max-width: ${mobileMaxWidth}px;`}
    ${({ mobileImageAlignment }) =>
      mobileImageAlignment === IMAGE_ALIGNMENT_CENTER && 'margin: 0 auto;'}
    ${({ mobileImageAlignment }) =>
      mobileImageAlignment === IMAGE_ALIGNMENT_LEFT && 'margin: 0 auto 0 0;'}
    ${({ mobileImageAlignment }) =>
      mobileImageAlignment === IMAGE_ALIGNMENT_RIGHT && 'margin: 0 0 0 auto;'}
  `}

  ${mediaQueries.desktop`
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
  `}
`;

ImageContainer.propTypes = {
  imageAlignment: PropTypes.oneOf([
    IMAGE_ALIGNMENT_CENTER,
    IMAGE_ALIGNMENT_LEFT,
    IMAGE_ALIGNMENT_RIGHT,
  ]),
  maxWidth: PropTypes.number,
  mobileImageAlignment: PropTypes.oneOf([
    IMAGE_ALIGNMENT_CENTER,
    IMAGE_ALIGNMENT_LEFT,
    IMAGE_ALIGNMENT_RIGHT,
  ]),
  mobileMaxWidth: PropTypes.number,
};

ImageContainer.defaultProps = {
  imageAlignment: IMAGE_ALIGNMENT_CENTER,
  mobileImageAlignment: null,
  maxWidth: null,
  mobileMaxWidth: null,
};

const WidgetImage = ({
  alt,
  autoFormat,
  className,
  isFirstSection,
  fromContentful,
  height,
  isAboveFold,
  imageAlignment,
  linkURL,
  maxWidth,
  mobileMaxWidth,
  mobileImageAlignment,
  mobileNaturalHeight,
  mobileNaturalWidth,
  mobileSrc,
  naturalHeight,
  naturalWidth,
  noRetina,
  src,
  webp,
}) => {
  return (
    <ImageContainer
      imageAlignment={imageAlignment}
      maxWidth={maxWidth}
      mobileImageAlignment={mobileImageAlignment}
      mobileMaxWidth={mobileMaxWidth}
    >
      {linkURL && (
        <Link linkURL={linkURL} rel="nofollow">
          <Image
            alt={alt}
            autoFormat={autoFormat}
            className={className}
            fromContentful={fromContentful}
            height={height}
            isAboveFold={isAboveFold || isFirstSection}
            maxWidth={maxWidth}
            mobileMaxWidth={mobileMaxWidth}
            mobileNaturalHeight={mobileNaturalHeight}
            mobileNaturalWidth={mobileNaturalWidth}
            mobileSrc={mobileSrc}
            naturalHeight={naturalHeight}
            naturalWidth={naturalWidth}
            noRetina={noRetina}
            src={src}
            webp={webp}
          />
        </Link>
      )}
      {!linkURL && (
        <Image
          alt={alt}
          autoFormat={autoFormat}
          className={className}
          fromContentful={fromContentful}
          height={height}
          isAboveFold={isAboveFold || isFirstSection}
          maxWidth={maxWidth}
          mobileMaxWidth={mobileMaxWidth}
          mobileNaturalHeight={mobileNaturalHeight}
          mobileNaturalWidth={mobileNaturalWidth}
          mobileSrc={mobileSrc}
          naturalHeight={naturalHeight}
          naturalWidth={naturalWidth}
          noRetina={noRetina}
          src={src}
          webp={webp}
        />
      )}
    </ImageContainer>
  );
};

WidgetImage.propTypes = {
  alt: PropTypes.string,
  autoFormat: PropTypes.bool,
  className: PropTypes.string,
  fromContentful: PropTypes.bool,
  height: PropTypes.number,
  imageAlignment: PropTypes.oneOf([
    IMAGE_ALIGNMENT_CENTER,
    IMAGE_ALIGNMENT_LEFT,
    IMAGE_ALIGNMENT_RIGHT,
  ]),
  isAboveFold: PropTypes.bool,
  isFirstSection: PropTypes.bool,
  linkURL: PropTypes.string,
  maxWidth: PropTypes.number,
  mobileImageAlignment: PropTypes.oneOf([
    IMAGE_ALIGNMENT_CENTER,
    IMAGE_ALIGNMENT_LEFT,
    IMAGE_ALIGNMENT_RIGHT,
  ]),
  mobileMaxWidth: PropTypes.number,
  mobileNaturalHeight: PropTypes.number,
  mobileNaturalWidth: PropTypes.number,
  mobileSrc: PropTypes.string,
  naturalHeight: PropTypes.number,
  naturalWidth: PropTypes.number,
  noRetina: PropTypes.bool,
  src: PropTypes.string.isRequired,
  webp: PropTypes.bool,
};

WidgetImage.defaultProps = {
  alt: null,
  autoFormat: false,
  className: null,
  isFirstSection: false,
  fromContentful: false,
  height: null,
  imageAlignment: IMAGE_ALIGNMENT_CENTER,
  isAboveFold: false,
  linkURL: null,
  maxWidth: null,
  mobileImageAlignment: null,
  mobileMaxWidth: null,
  mobileNaturalHeight: null,
  mobileNaturalWidth: null,
  mobileSrc: null,
  naturalHeight: null,
  naturalWidth: null,
  noRetina: false,
  webp: false,
};

export default WidgetImage;
