import individualFilingStatusToScheduleMap from './individualFilingStatusToScheduleMap';
import individualDeductionTypes from './individualDeductionTypes';

/**
 * Takes information about an individual's business financials and returns the
 * estimated amount of tax they would pay per quarter.
 *
 * If invalid input is passed to this function it will throw an error with a
 * validation message.
 *
 * All numerical input amounts are in cents.
 *
 * @param {Object} formData
 * @param {number} formData.childTaxCredit
 * @param {string} formData.deductionType
 * @param {number} formData.dependentCareTaxCredit
 * @param {number} formData.educationTaxCredit
 * @param {number} formData.estimatedAlternativeMinimumTax
 * @param {number} formData.estimatedItemizedDeduction
 * @param {number} formData.estimatedQualifiedBusinessIncomeDeduction
 * @param {number} formData.expectedAdjustmentsToIncome
 * @param {number} formData.expectedTotalIncome
 * @param {string} formData.filingStatus
 * @param {number} formData.foreignIncomeTaxCredit
 * @param {number} formData.generalBusinessTaxCredit
 * @param {number} formData.priorYearMinimumTaxCredit
 * @param {number} formData.residentialTaxCredit
 * @param {number} formData.retirementSavingsContributionCredit
 * @param {number} formData.selfEmploymentTax
 * @param {number} formData.standardDeductionAmount
 * @return {number} estimated quarterly tax in cents
 * @throws {Error} if filingStatus is invalid
 */
const calculateQuarterlyTaxForIndividual = ({
  childTaxCredit,
  deductionType,
  dependentCareTaxCredit,
  educationTaxCredit,
  estimatedAlternativeMinimumTax,
  estimatedItemizedDeduction,
  estimatedQualifiedBusinessIncomeDeduction,
  expectedAdjustmentsToIncome,
  expectedTotalIncome,
  filingStatus,
  foreignIncomeTaxCredit,
  generalBusinessTaxCredit,
  priorYearMinimumTaxCredit,
  residentialTaxCredit,
  retirementSavingsContributionCredit,
  selfEmploymentTax,
  standardDeductionAmount,
}) => {
  if (!individualFilingStatusToScheduleMap[filingStatus]) {
    throw new Error(
      `filingStatus must be one of: ${Object.keys(individualFilingStatusToScheduleMap).join(', ')}`,
    );
  }

  // Comments refer to the following document:
  // https://docs.google.com/spreadsheets/d/1nenVTALmlaYacBQOySIThlvto25D6S3MWPKNMOHMdjg/edit#gid=1406708577

  // A: Expected Adjusted Gross Income for 2021
  // "Adjusted Gross Income is your total income for the year, minus any adjustments to income."
  const expectedAdjustedGrossIncome = expectedTotalIncome - expectedAdjustmentsToIncome;

  // B: Either itemized or standard deductions are selected.
  // If standard is selected, the value entered manually for standard deductions is used.
  // If itemized is selected, the value entered manually for itemized deductions is used.
  const personalDeductionAmount =
    deductionType === individualDeductionTypes.STANDARD
      ? standardDeductionAmount
      : estimatedItemizedDeduction;

  // C: The estimated amount of the qualified business income deduction
  // D: Add B + C
  const personalAndBusinessDeductions =
    personalDeductionAmount + estimatedQualifiedBusinessIncomeDeduction;

  // E: Subtract D from A
  const inputtedAdjustedIncome = expectedAdjustedGrossIncome - personalAndBusinessDeductions;

  // Income cannot be negative - use zero instead
  const adjustedIncome = Math.max(inputtedAdjustedIncome, 0);

  // Match E to see which tax bracket user falls into based on their filing status
  const { baseTaxAmount, minBracketAmount, percentage } = individualFilingStatusToScheduleMap[
    filingStatus
  ].find((bracket) => adjustedIncome >= bracket.minBracketAmount);

  // F: Multiply E by the tax bracket
  const taxableIncome = adjustedIncome - minBracketAmount;
  const taxableIncomeAmount = (taxableIncome * percentage) / 100;
  const taxAmount = taxableIncomeAmount + baseTaxAmount;

  // G: Alternative minimum tax from Form 6251
  // H: Add F + G
  const taxAmountWithAlternativeMinimumTax = taxAmount + estimatedAlternativeMinimumTax;

  // The following step was removed. That means J = H.
  // I: Any other taxes expected to be include in the total on Form 1040, line 12a
  // J: Add H and I

  // K: Add up all the credits
  const creditsToClaim =
    childTaxCredit +
    dependentCareTaxCredit +
    educationTaxCredit +
    foreignIncomeTaxCredit +
    generalBusinessTaxCredit +
    priorYearMinimumTaxCredit +
    residentialTaxCredit +
    retirementSavingsContributionCredit;

  // L: Subtract K from J
  const taxAmountForYear = taxAmountWithAlternativeMinimumTax - creditsToClaim;

  // M: Self employment tax
  // N: Add L + M
  const taxAmountForYearWithSelfEmploymentTax = taxAmountForYear + selfEmploymentTax;

  // O: Multiply N x 25%
  const oneQuarterOfTaxAmountForYear = (taxAmountForYearWithSelfEmploymentTax * 25) / 100;

  // Round to the nearest cent
  const toNearestCent = Math.round(oneQuarterOfTaxAmountForYear);

  // Return the amount, or zero if it's negative
  return Math.max(toNearestCent, 0);
};

export default calculateQuarterlyTaxForIndividual;
