import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Gandalf from 'gandalf-validator';
import styled from 'styled-components';

import StepProgressLayout from './StepProgressLayout';
import GridRow from '../../ui/grid/GridRow';
import GridColumn from '../../ui/grid/GridColumn';
import Spacer from '../../ui/Spacer';
import WidgetValueProp from '../widgets/WidgetValueProp';
import GlossaryTerm from '../GlossaryTerm';
import QuestionMark from '../../ui/icons/QuestionMark';
import colors from '../../ui/constants/colors';

const LinearFormLabel = styled.div`
  color: ${colors.charcoal};
  font-size: 15px;
`;

const LinearFormSublabel = styled.span`
  color: ${colors.stone};
`;

class LinearFormView extends Gandalf {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { formData, formConfig } = this.props;
    const fieldDefinitionsWithDefaultValues = Object.keys(formConfig).map((key) => {
      const currentConfig = formConfig[key];
      return {
        ...currentConfig.fieldDefinition,
        defaultValue: formData[key] || currentConfig.fieldDefinition.defaultValue,
        name: key,
      };
    });

    this.buildFields(fieldDefinitionsWithDefaultValues);
  }

  handleSubmit(event) {
    event.preventDefault();
    const { goToNextView } = this.props;
    const currentFormData = this.getCleanFormData();

    if (currentFormData) {
      goToNextView(currentFormData);
    }
  }

  render() {
    const {
      businessStructure,
      footerText,
      formConfig,
      goToPreviousView,
      header,
      helpContent,
      isBackButtonShown,
      subheader,
    } = this.props;
    const { fields } = this.state;

    return (
      <StepProgressLayout
        businessStruct={businessStructure}
        footerText={footerText}
        isBackButtonShown={isBackButtonShown}
        onClickBack={() => goToPreviousView(this.getFormData())}
        onClickNext={this.handleSubmit}
      >
        <Spacer paddingBottom={3}>
          <GridRow alignItems="center">
            <GridColumn span={3} spanMobile={2} />
            <GridColumn span={6} spanMobile={8}>
              <WidgetValueProp
                description={subheader}
                header={header}
                headerFontSize={18}
                headerWidont
                textAlignment="center"
              />
            </GridColumn>
            <GridColumn span={3} spanMobile={2}>
              {helpContent && (
                <GlossaryTerm
                  definition={
                    <WidgetValueProp description={helpContent} textAlignmentMobile="left" />
                  }
                  term={<QuestionMark color={colors.malachite} />}
                />
              )}
            </GridColumn>
          </GridRow>
        </Spacer>
        {Object.keys(formConfig).map((fieldKey) => {
          const fieldDefinition = formConfig[fieldKey];
          const { helpContent: fieldHelpContent, labelProps } = fieldDefinition;
          const formField = fields[fieldKey];
          return (
            <Fragment key={fieldKey}>
              {labelProps && (labelProps.label || labelProps.sublabel) && (
                <Spacer padding={2}>
                  <GridRow>
                    <GridColumn span={3} />
                    <GridColumn span={9}>
                      <LinearFormLabel>
                        {labelProps.label}{' '}
                        <LinearFormSublabel>{labelProps.sublabel}</LinearFormSublabel>
                      </LinearFormLabel>
                    </GridColumn>
                  </GridRow>
                </Spacer>
              )}
              <Spacer paddingBottom={1}>
                <GridRow alignItems="center">
                  <GridColumn span={3} />
                  <GridColumn span={6} spanMobile={10}>
                    {formField.element}
                  </GridColumn>
                  <GridColumn span={3} spanMobile={2}>
                    {fieldHelpContent && (
                      <GlossaryTerm
                        definition={
                          <WidgetValueProp
                            description={fieldHelpContent}
                            textAlignmentMobile="left"
                          />
                        }
                        term={<QuestionMark color={colors.malachite} />}
                      />
                    )}
                  </GridColumn>
                </GridRow>
              </Spacer>
            </Fragment>
          );
        })}
      </StepProgressLayout>
    );
  }
}

LinearFormView.propTypes = {
  businessStructure: PropTypes.string,
  footerText: PropTypes.string,
  formConfig: PropTypes.objectOf(PropTypes.object).isRequired,
  formData: PropTypes.object.isRequired,
  goToNextView: PropTypes.func.isRequired,
  goToPreviousView: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  helpContent: PropTypes.string,
  isBackButtonShown: PropTypes.bool,
  subheader: PropTypes.string,
};

LinearFormView.defaultProps = {
  businessStructure: null,
  footerText: null,
  helpContent: null,
  isBackButtonShown: false,
  subheader: null,
};

export default LinearFormView;
