import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../../ui/constants/colors';
import Page from '../../../components/Page';
import WidgetArticleBody from '../../../components/widgets/WidgetArticleBody';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';
import policyCopyrightText from '../helpers/policyCopywriteText';

const md = `
Please read these terms of service (“**Agreement**”) carefully as this
Agreement constitutes a binding contract between the user that accepts
this Agreement (“**Customer**”) and Bench Accounting Inc., a Delaware
company having its registered address at 874 Walker Road, Suite C,
Dover, Delaware 19904 USA (“**Bench**”) governing your use of the
services available through the Bench website at
[<u>https://bench.co</u>](https://bench.co/) (“**Site**”) and any mobile
applications (each an “**App**”) through which they may be made
available (the “**Services**”). By checking an opt-in box, clicking on
“I accept” or otherwise accepting this Agreement on the sign-up page,
installing the App, or otherwise accessing or using the Services,
Customer acknowledges that they have read, understood, and agree to be
bound by and comply with the terms of this Agreement. If Customer is
using the Services on behalf of an entity, partnership, or other
organization, then Customer represents that they: (i) are an authorized
representative of that entity with the authority to bind that entity to
this Agreement and (ii) such entity agrees to be bound by this
Agreement. If Customer does not agree to the terms of this Agreement,
then they are not permitted to use the Services.

**ARBITRATION NOTICE**. Except for certain kinds of disputes described
in the Arbitration provision below, Customer agrees that disputes
arising under this Agreement will be resolved by binding individual
arbitration, and BY ACCEPTING THIS AGREEMENT, CUSTOMER AND BENCH ARE
EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS
ACTION OR REPRESENTATIVE PROCEEDING. CUSTOMER AGREES TO GIVE UP CUSTOMER
RIGHT TO GO TO COURT to assert or defend Customer rights under this
contract (except for matters that may be taken to small claims court).
Your rights will be determined by a single ARBITRATOR and NOT a judge or
jury. See the Arbitration provision below.

### 1.  **Services.**

The Services consist of cloud-based financial tools and services which
may include (i) bookkeeping, accounting data storage and processing services,
(ii) financial information storage and reporting, (iii) tax preparation
and support services (“**Tax Services**”), and (iv) the service of
communicating information to, from and among Third Party Servicers, as
per Customer instructions. Customer may make certain selections of
Services that they wish to procure through the Application or the
Account.

Bench will provide the Services to Customer for the term of this
Agreement, subject to the payment of applicable Fees and compliance with
the terms of this Agreement. As part of the Services, Bench hereby
grants to Customer a non-exclusive, non-transferable, non-assignable
right to use the Services, as per the terms of this Agreement. Customer
acknowledges that the Services are cloud-based and hosted services and
no copies of the Services or Bench System will be delivered to Customer,
other than (where available) the App, subject to the End User License
Agreement (“EULA”). The Services shall be used by Customer solely for
Customer own purposes and Bench does not convey any right, title or
interest in the Services or Bench System to Customer. Customer right to
use the Services shall terminate upon any termination of this Agreement
or any suspension or termination of the supply of the Services to
Customer.

*Financial Transactions*

Bench shall not perform any financial transactions for Customer.
Customer may, however, be able to use the Services to communicate Customer
instructions to a Third Party Servicer of Customer which Third Party
Servicer may perform a financial transaction on behalf of Customer
pursuant to the Third Party Servicer Agreement. Bench is not
liable for any such financial transaction or the results thereof.

*Errors*

Customer shall immediately notify Bench of any errors by Bench or
Customer in the supply or use of the Services. Where practicable, Bench
shall use commercially reasonable efforts to investigate errors, but
makes no representation as to its ability to correct the error. Customer
shall provide Bench with any information necessary to investigate an
error in a Transaction. Some payment transactions, such as wire
transfers, are irreversible, so Customer agrees to exercise extreme
caution when initiating any financial transaction by way of instructions
delivered one of its Third Party Servicers through the Services.

*Tax Services*

Customer acknowledges that Bench will provide Tax Services only if and
upon Customer’s completion of a validly executed Taxpayer
Disclosure Authorization in which Customer consents to allow Bench to
disclose certain tax information as specifically described therein for
such period until Customer is no longer using Bench’s tax return
preparation services. Customer understands and acknowledges that Bench
will not provide Tax Services until such Taxpayer Disclosure
Authorization is executed and provided to Bench.

### 2.  **Bench Account and Customer ID.**

Bench shall provide Customer with a unique and private Account
accessible through the Service. The Account shall be a record of
Customer Transactions and Fees. Bench shall provide Customer with access
codes for the Account. Customer shall not disclose such codes or permit
any third party to use them. Customer has exclusive responsibility for
the use of Customer Account. Bench will invite Customer to enter certain
preferences and specifications within the Application or the Account
that will apply to the Services; Customer assumes exclusive
responsibility for such selections even if they contain errors by
Customer or result in losses to Customer. Any additional terms and
conditions posted to the Site with respect to the Account or specific
Services preferences selected by Customer are incorporated herein by
reference.

Except as required to deliver the Services or as otherwise required by
law, Bench shall not grant any third party access to Customer Account.

Customer shall notify Bench by email to
[<u>help@bench.co</u>](mailto:help@bench.co) immediately of any loss or
disclosure, whether voluntary or otherwise, of any Account password or
access code to a third party.

Upon Customer’s request, Bench will also issue Customer a Customer ID
associated with the Account (“**Customer ID**”). Customer may share
their Customer ID only with officers, directors, bookkeepers,
accountants or other Customer personnel that are directly employed or
engaged by Customer (“**Customer Personnel**”) provided that Customer
binds such third parties to undertakings of confidentiality and to also
honor the terms hereof. Customer Personnel may only access and use the
Services through the Customer ID and in compliance with this Agreement;
Customer will not allow Customer Personnel to share the Customer ID with
third parties. Customer is responsible for all activity occurring under
its Customer ID whether by Customer Personnel or otherwise. Bench
reserves the right to replace the Customer ID in its sole discretion for
any reason or for no reason. Any Customer Personnel who accesses the
Services does so subject to this Agreement.

Customer shall provide, at Customer’s own expense, all necessary
hardware, applications and internet connectivity necessary to access the
Services. Customer acknowledges that the internet can be unpredictable
in performance, unsecure and may, from time to time, impede access to
the Services or performance hereunder. Customer agrees that Bench is not
responsible for any internet outages, unsecure WIFI or other connections
or any other interference with Customer’s use of or access to the
Services or security breaches arising from any Customer Device and
Customer waives any and all claims against Bench in connection
therewith.

### 3.  **Limitations.**

*Prohibited Customers*

The following Persons are prohibited from using the Services: (i)
Persons who appear on the U.S. Department of the Treasury, Office of
Foreign Assets Control (OFAC), Specially Designated Nationals List
(SDN); (ii) Persons who are less than 18 years of age; (iii) Persons, or
their Affiliates who have procured services from Bench and have been
terminated by Bench; and (iv) individual consumers. The Services may not
be used for individual consumer use. Customer must be a business,
charitable organization or not-for-profit organization to use the
Services. Bench reserves the right to decline to provide Services or
terminate Services to one or another type of business. Bench shall
notify Customer of prohibited business types through the Site, the
Account or the App. In any case, any business that is illegal or
operates in support of illegal activity is prohibited from using the
Services.

*Limitations on Use*

Customer shall not itself and shall not permit any Customer Personnel or
any other third party to: (i) permit any party to access or use the
Services other than the Customer Personnel authorized under this
Agreement; (ii) modify, adapt, alter or translate any software of Bench
Systems underlying the Services; (iii) license, lease, rent, loan,
distribute, or otherwise transfer the Services to any third party; (iv)
except if, and solely to the extent that, such a restriction is
impermissible under Law, reverse engineer, decompile, disassemble, or
otherwise derive or determine or attempt to derive or determine the
source code (or algorithms, structure or organization) of any software
or Bench Systems underlying the Services; (v) use or copy the any
software or Bench Systems underlying the Services except as expressly
allowed hereunder; (vi) conduct or promote any illegal activities while
using the Services; (vii) use the Services to generate unsolicited email
advertisements or spam; (viii) use the Services to stalk, harass or harm
another individual; (ix) use any high volume automatic, electronic or
manual process to access, search or harvest information from the
Services (including without limitation robots, spiders or scripts); (x)
except if, and solely to the extent that, such a restriction is
impermissible under Law, interfere in any way with the proper
functioning of the Services or interfere with or disrupt any servers or
networks connected to the Services, or disobey any requirements,
procedures, policies or regulations of networks connected to the
Services; (xi) attempt to gain access to secured portions of the
Services to which it does not possess access rights; (xii) upload or
transmit any form of virus, worm, Trojan horse, or other malicious code;
(xiii) use any robot, spider, other automatic device, or manual process
to extract, “screen scrape”, monitor, “mine”, or copy any static or
dynamic web page on the Services or the content contained on any such
web page for commercial use without our prior express written
permission; (xiv) impersonate any person or entity, or otherwise
misrepresent its affiliation with a person or entity; (xv) mirror or
frame the Services or any content, place pop-up windows over its pages,
or otherwise affect the display of its pages; or (xvi) publicly display
or publicly perform any part of the Services. Customer may not use the
Services for any purpose other than a purpose for which the Services are
expressly designed. If Customer is prohibited under Laws from using the
Services, Customer may not use them.

### 4.  **Bench Intellectual Property Rights.**

Bench expressly reserves all Intellectual Property Rights in the
Services, Bench System and all materials provided by Bench hereunder.
All right, title and interest in the Services and all other materials
provided by Bench hereunder, any update, adaptation, translation,
customization or derivative work thereof, and all Intellectual Property
Rights therein will remain with Bench or its licensors. Bench reserves
the right, in its sole discretion, to change, modify, add, or remove
portions of the Services without prior notice to Customer or consent of
Customer. Certain of the names, logos, trademarks, trade names, service
marks, content, visual interfaces, interactive features, information,
compilation, computer code, products, services, and other materials
displayed on the Services (“**Bench Materials**”), are protected by
Intellectual Property Rights Laws of the United States, Canada, and
other jurisdictions.

### 5.  **Customer Data Consent.**

Customer hereby authorizes Bench to, directly or through third parties,
make any inquiries and conduct any investigation to verify Customer
identity.

The Services requires certain information concerning Customer, including
but not limited to Customer name, address, phone number, email address,
Bank Account information and other Third Party Servicer account
information. Customer agrees that all information it provides to Bench
shall be complete and accurate and Customer shall promptly correct any
errors in the information provided to Bench.

Subject to the Bench Privacy Policy, posted at the Site and incorporated
herein by reference, and any other separate agreement between Customer
and Bench, Customer and each of Customer Personnel hereby grant Bench
the right to collect, store, use and disclose Customer Data for the
purpose of providing the Services and its integration with Third Party
Servicer Services selected by Customer. Where Customer Data includes
data concerning third parties, Customer states that it has obtained the
necessary consents for Bench to collect, process, store such data
hereunder from the relevant data subjects. Data collected by Bench is
subject to the Bench Privacy Policy, posted at the Site and incorporated
herein by reference. Subject to Law, where Bench is subject to a
subpoena request for Customer Data, Bench shall provide Customer with an
opportunity to contest the request, failing which Bench shall cooperate
with the request.

Subject to any separate agreement between Customer and Bench, Customer
hereby authorizes Bench and each Third Party Servicer to each
obtain from the others and disclose to the others Customer Data in so
far as is necessary to supply their respective services either hereunder
or under their respective Third Party Servicer Agreements.

Where required by Law, Bench will disclose Customer Data to law
enforcement agencies. Bench reserves the right to keep Customer Data for
the term of this Agreement and for five (5) years thereafter.

### 6.  **Customer Data.**

*Accuracy*

Customer has sole responsibility for the accuracy, appropriateness and
completeness of all Customer Data. Bench will use the Customer Data it
is provided in performing the Services and is not responsible for
reviewing, validating or otherwise confirming the accuracy,
appropriateness or completeness of Customer Data.

*Security*

Bench will take reasonable steps to help protect Customer Data. However,
Customer understands and agrees that such steps do not guarantee that
the Services are invulnerable to all security breaches or immune from
viruses, security threats or other vulnerabilities. Bench reserves the
right to cooperate with local, state and federal authorities in
investigations of improper or unlawful activities and this may require
the disclosure of Customer’s personal information. Bench may also report
to other organizations about improper or unlawful user activities and
this reporting may include disclosure of personal information relating
to those individuals conducting such improper or unlawful activities.

Customer shall secure Customer Data in its possession or under its
control. Customer assumes exclusive responsibility for ensuring the
security of Customer Device and the Data on it. Bench is not liable for
the operation or failure of Customer Devices or those of any third
party, including but not limited to processors, hosting services,
internet service providers and other Third Party Servicers. Customer
shall not operate Customer Device in a manner that does not meet the
applicable security requirements of Bench, indicated in the Account or
on the Site, or those of Third Party Servicers.

Bench is not responsible for performing, and is not liable for any
failure to perform, any back-up of any Customer Data or other data
provided, transmitted, processed, or stored by Customer in or through
the Services. It is Customer’s responsibility to back-up onto a Customer
Device all Customer Data, including all data and records that Customer
submits to Bench.

*Sharing*

Bench shall disclose Customer Data to those representatives of Customer
identified by Customer for such purposes and also to such Third Party
Servicers as Customer has selected. Bench has no liability for any
collection, processing, storage, use or disclosure of Customer Data by
any Third Party Servicer or any other third party. Bench reserves the
right to decline to share Customer Data with any third party where Bench
believes that such sharing may expose Customer or Bench to excessive
security, financial or reputational risk, provided however that Bench
shall never be liable for any act or omission of any third party with
respect to Customer Data or otherwise. Bench makes no warranty,
representation, endorsement, or guarantee regarding, and accepts no
responsibility or liability for, the quality, content, nature, veracity
or reliability of any Customer Data.

*Feedback*

In the event that Customer provides Bench any ideas, thoughts,
criticisms, suggested improvements or other feedback related to the
Services (collectively “**Feedback**”), Customer agrees that Bench may
use the Feedback to modify the Services and that Customer will not be
due any compensation, including any royalty related to the product or
service that incorporates the Feedback. Customer hereby grants Bench a
worldwide, royalty-free, fully paid, perpetual, irrevocable license to
use, reproduce, modify, translate, distribute, perform, display, import,
sell, offer for sale, make, have made and otherwise exploit the Feedback
in any form, media, or technology, whether now known or hereafter
developed, and to allow others to do the same. This is true whether
Customer provides the Feedback on the Services or through any other
method of communication with Bench.

*Limitations*

Customer shall not take possession of or enter into the Account any
data: (i) that Customer does not have the lawful right to copy,
transmit, distribute, and display (including any Customer Data that
would violate any confidentiality or fiduciary obligations that Customer
might have with respect to the Customer Data); (ii) for which Customer
does not have the consent or permission from the owner of any personally
identifiable information contained in the Customer Data; (iii) that
infringes, misappropriates or otherwise violates any Intellectual
Property Rights or violates any privacy rights of any third party; (iv)
that is false or misleading; (v) that is defamatory, obscene, or
offensive; (vi) that violates, or encourages any conduct that would
violate, any Laws or regulation or would give rise to civil or criminal
liability; or (vii) that contains any viruses, trojan horses, spyware,
malware, worms, time bombs, cancelbots, or other disabling devices or
other harmful component intended to damage, detrimentally interfere
with, surreptitiously intercept or expropriate any system, data or
personal information.

*Financial Data Storage, Not Advice*

The Services enable the storage of Customer Data that is of a financial
nature (e.g., bookkeeping, accounting and other financial information).
Customer acknowledges, however, that the Services (other than the tax
preparation and advisory services) do not include the supply of any
accounting, financial, investment, legal or other professional advice.
Bench is not an accounting or other professional services firm. Bench
does not hold any licenses for the supply of any accounting, financial,
investment, legal or other professional advice, and none of the Services
shall be construed as including any such services. Data presented in the
Services, such as financial information, for example, shall not be
construed as reflective of the financial status of Customer or any third
party nor are they necessarily compatible with any specific accounting
standards, such as GAAP (generally accepted accounting principles).
Customer shall be exclusively responsible for retaining third party any
accounting, financial, investment, legal and other professional advice.

*Customer Call Monitoring*

Bench may monitor and record support-related and other outbound calls to
Customer, as well as inbound calls to Bench by Customer or Customer’s
representatives, for compliance, support, training and other purposes.
Customer agrees that Bench may record any call between Customer and
Bench relating to the Services, and agrees to (i) notify all relevant
Customer employees that calls with Bench may be monitored and (ii)
indemnify and hold harmless Bench from any claim arising as a result of
Bench’s monitoring or recording of calls between Bench and Customer’s
representatives.

### 7.  **Indemnification.**

Customer shall defend, indemnify, and hold harmless Bench, its
employees, officers, directors Affiliates, suppliers, licensors, Third
Party Servicers and other customers against any and all liability
including damages, recoveries, deficiencies, interest, penalties, losses
and reasonable attorney’s fees arising out of or relating to: (i) any
breach of the terms hereof; (ii) any breach of a Third Party Servicer
Agreement; (iii) any violation of any Laws; (iv) any use of Customer
Data by Customer or a Third Party Servicer or other third party; (v)
Customer use of the Services, including in combination with any third
party service; (vi) any Transaction; (vii) any financial transaction
occurring as a result of data communicated via the Services; (viii) any
act or omission of any Third Party Servicer or Client; (ix) costs
incurred by Bench enforcing the terms hereof or responding to any
subpoena relating to Customer, Customer Data or a Third Party Servicer;
(x) any claim by a governmental taxing authority; or (xi) any dispute
between Customer and any third party or Customer Personnel.

### 8.  **Term, Termination and Suspension**

*Term*

The term of this Agreement shall begin as of when Customer accepts this 
Agreement, completes the Application or obtains an Account, shall continue in
effect for an initial period of either one month or one year, depending on 
Customer’s selection at account activation (the **“Initial Term”**), and shall
automatically renew for a period of the same duration as the Initial Term upon
the expiration of the Initial Term or any subsequent term (each, a **“Renewal Term”** and,
together with the Initial Term, the “Term”) unless either party provides the other
with advanced written notice of its election not to renew this Agreement before 
the expiration of the then-current Initial Term or Renewal Term, as applicable.

*Termination*

Customer may cease using the Services by closing their Account or
such other means as the Services may provide, but doing so does not
act to terminate this Agreement. Customer must provide Bench with written
notice in accordance with the immediately preceding paragraph that Customer 
elects not to renew this Agreement when the then-current Initial Term or Renewal Term 
expires. Customer will be responsible for any Fees due during the remainder of the Term.
Bench may terminate this Agreement by notice to Customer through the Account, by email to
the contact information provided in the Application or by other electronic notice to other
contact information provided by Customer to Bench.

*Suspension*

Bench may, at its discretion, suspend Customer access to, or otherwise
modify, the Services and any component thereof, without notice in order 
to: (i) prevent damages to, or degradation of the integrity of, Bench’s 
internet network; (ii) comply with any Law; (iii) otherwise protect Bench
from potential legal liability or harm to its reputation or business; or (iv) 
because Bench has opted to change the Services. Bench will use commercially
reasonable efforts to notify Customer of the reason(s) for such suspension or 
termination action as soon as reasonably practicable. Nothing contained in this
Agreement will be construed to limit Bench’s actions or remedies or act as a waiver
of Bench’s rights in any way with respect to any of the foregoing activities. Bench 
will not be responsible for any loss or damages incurred by Customer as a result of 
any termination or suspension of access to or use of the Services.

### 9.  **Customer Support.**

Bench will use commercially reasonable efforts to provide Customer with
technical support services relating to the Services via its technical
support website, email, or telephone.

Bench may update the Services in its sole discretion which updates may
alter, add or remove functionality of the Services. Bench may also, from
time to time, schedule downtime for maintenance and upgrades to the
Services.

### 10.  **Fees.**

*Fees*

Access to the Services may require Customer to pay Fees, as may be
further described at
[<u>https://bench.co/pricing/</u>](https://bench.co/pricing/) or
otherwise on the Site, the App or in the Account. All Fees are in U.S.
dollars and are non-refundable, unless otherwise provided herein. Bench
reserves the right to amend the Fees my posting new Fees on the Site, in
the App or in the Account; such changes shall take effect within thirty
(30) days unless accepted by Customer earlier or if Customer does not
close their Account within such delay.

*Payment*

Customer shall authorize Bench to collect payment of Fees from a credit card,
debit card or other means of payment; Customer authorizes Bench to charge all
Fees and other amounts owing hereunder, including all applicable taxes, from 
such payment method. If Customer pays any Fees with a credit card, Bench may 
seek pre-authorization of Customer’s credit card account prior to Customer’s
purchase to verify that the credit card is valid and has the necessary funds or 
credit available to cover Customer’s purchase.


The Services may include functionality for activating, updating, or canceling
recurring payments for periodic charges. If Customer activates or updates recurring
payments through the Services, Customer authorizes Bench to periodically charge, 
on a going-forward basis and until expiration or termination of the Term, all 
accrued sums on or before the payment due date for the accrued sums. Customer 
must provide Bench with written notice of its election not to renew the Term 
before it renews to avoid billing and payment for the next recurring Renewal Term. 
For example, if Customer has a one-year long Initial Term and a subscription plan 
providing for 12 monthly payments during the Initial Term, (a) if Customer provides
Bench with written notice of its election not to renew this Agreement before the expiration
of the Initial Term, Customer will be responsible for any remaining monthly payments during
the Initial Term but not for any subsequent Renewal Term, however, (b) if Customer fails
to provide Bench with written notice of its election not to renew this Agreement before 
the end of the Initial Term, this Agreement will renew for another year and Customer will
be responsible to pay the 12 monthly payments during the Renewal Term. If Customer updates
or cancels any existing authorized one-time or recurring payment, it may take up to 10 
business days for the update or cancellation to take effect.

Without limitation, Bench reserves the right to suspend the Services until all Fees or
other amounts owing hereunder are paid in full or terminate this Agreement for late payment.

Fees quoted do not include, and Customer shall pay and hold Bench harmless from
all sales, use, gross receipts, value-added, personal property or other taxes,
and all applicable duties, tariffs, assessments, export and import fees or similar
charges (including interest and penalties imposed thereon) on the transaction 
contemplated herein, other than taxes based on the net income or profits of Bench.

*ACH Consent*

Customer desires to effect settlement of credits and debits from Customer Bank Account(s)
by means of ACH and/or wire transfer in conjunction with the Services for Customer by Bench.
In accordance with this desire, Customer authorizes Bench and/or its affiliates to initiate 
debit and credit entries to Customer Bank Account (the details of which are provided by Customer
through the Account or by other means acceptable to Bench). Customer shall maintain sufficient 
funds in Customer Bank Account to cover such debit transactions. Customer states that Customer 
has the authority to agree to such transactions and that Customer Bank Account indicated is a valid 
and legitimate account for the handling of these transactions. This authority is to remain in effect
until Bench receives written notice from Customer revoking it. This authorization is for the payment
of Fees or any other sums owed to Bench. Customer certifies that the appropriate authorizations are
in place to allow Customer to authorize this method of settlement. All changes to the identification 
of Customer Bank Account under this authorization must be made in writing in accordance with the Agreement.
Customer understands that if the information supplied as to the ABA Routing Number and Account Number of
the Customer Bank Account is incorrect, and funds are incorrectly deposited, Bench will attempt to assist 
Customer in the recovery of such funds but has no liability as to restitution of the same. Bench’s assistance
in recovering the funds, where available, will be billed to Customer at Bench’s current hourly rate for 
such work. Customer acknowledges that the origination of ACH transactions to the Customer Bank Account must
comply with the provisions of U.S. law.

### 11.  **Confidential Information.**

Each party acknowledges that it may directly or indirectly disclose
Confidential Information to the other party in the course of negotiation
of and performance of this Agreement. All such Confidential Information
disclosed hereunder shall remain the sole property of the disclosing
party (or other third party), and the receiving party shall have no
interest in, or rights with respect thereto, except as set forth herein.
Each party agrees to treat such Confidential Information with the same
degree of care and security as it treats its most confidential
information. Each party may disclose such Confidential Information to
employees and agents who require such knowledge to perform services
under this Agreement. Except as otherwise contemplated by this
Agreement, neither party shall disclose the Confidential Information of
the other party to any third party without the prior written consent of
the disclosing party, and the duty of confidentiality created by this
section shall survive any termination of the Agreement. Customer Data
shall, without limitation, be Customer Confidential Information. Bench
Data shall, without limitation, be Bench Confidential Information.

### 12.  **Customer Representations and Warranties.**

Customer represents and warrants to Bench that:

12.1.  Customer has the legal authority to bind Customer organization to
this Agreement and to perform hereunder and under each Third Party
Servicer Agreement to which Customer is a party. Customer is the
exclusive owner of the Account and is not operating the Account on
behalf of any third party.

12.2.  Customer has the legal capacity to enter into this Agreement and
perform Customer obligations hereunder.

12.3.  Customer is a business, charitable organization or not-for-profit
organization and shall use the Services for only business purposes
and not for individual consumer purposes.

12.4.  Customer shall immediately advise Bench of defects in the Services
or any claim or threatened claim against Bench. Customer shall
immediately notify Bench of any defects in a Product for which a
Third Party Servicer has been used as a payment method.

12.5.  Customer use of the Services conforms to all Laws and the terms of
this Agreement.

### 13.  **Not Legal, Tax, or Accounting Advice**

Bench may provide Customer with legal, tax or accounting information as
part of Bench’s tax preparation and advisory services or on the Bench
website. Unless such information was provided by a Bench licensed
professional pursuant to a written agreement between Customer and Bench,
such information is not intended to constitute specific legal, tax,
accounting or other professional advice, and may not reflect recent
developments in the law, may not be complete, and may not be accurate in
or applicable to Customer’s particular circumstances. As such, Customer
agrees not to act based on any information provided by Bench, unless
such information was provided by a Bench licensed professional pursuant
to a written agreement between Customer and Bench, or before obtaining
advice from other professional counsel qualified in the applicable
subject matter and jurisdiction.

### 14.  **No Warranties by Bench.**

*Content; Third Party Servicers*

Content from Third Party Servicers, other users, suppliers, advertisers,
and other third parties may be made available to Customer through the
Services. Bench does not control such content; Customer agrees that
Bench is not responsible for any such content. Bench does not make any
guarantees about the accuracy, currency, suitability, or quality of the
information in such content and Bench assumes no responsibility for
unintended, objectionable, inaccurate, misleading, or unlawful content
made available by other users, advertisers, and other third parties or
violation of any third party rights related to such content. The
Services may contain links to websites not operated by Bench. Bench is
not responsible for the content, products, materials, or practices
(including privacy practices) of such websites. Customer understands
that by using the Services, Customer may be exposed to third-party
websites that Customer finds offensive, indecent or otherwise
objectionable. Bench makes no warranty, representation, endorsement, or
guarantee regarding, and accepts no responsibility for, the quality,
content, nature or reliability of third party websites, products or
services accessible by hyperlink or otherwise from the Site or Services
including but not limited to Third Party Servicer Services. Bench
provides these links for Customer’s convenience only and does not
control such third parties. Bench’s inclusion of links to such links or
integrations does not imply any endorsement of the materials on such
third party services or any association with their operators. The
Services may contain links to sites that are operated by Bench but which
operate under different terms. It is Customer’s responsibility to review
the privacy policies and terms and conditions of any other site Customer
visits. CUSTOMER AGREES THAT IN NO EVENT WILL BENCH BE LIABLE TO
CUSTOMER IN CONNECTION WITH ANY WEBSITES, CONTENT, MATERIALS, OR
PRACTICES OF ANY THIRD PARTY.

*Services*

THE SERVICES AND ALL MATERIAL OR CONTENT AVAILABLE THROUGH THE SERVICE
ARE PROVIDED “AS IS”, “AS AVAILABLE”, WITH ALL FAULTS AND WITHOUT
WARRANTIES, REPRESENTATIONS OR CONDITIONS OF ANY KIND. TO THE MAXIMUM
EXTENT PERMITTED BY APPLICABLE LAW, BENCH HEREBY DISCLAIMS ALL
WARRANTIES, EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, REPRESENTATIONS
OR CONDITIONS, WHETHER WRITTEN OR ORAL, INCLUDING ANY IMPLIED WARRANTIES
OF MERCHANTABILITY, TITLE, NONINFRINGEMENT, SECURITY, RELIABILITY,
COMPLETENESS, QUIET ENJOYMENT, ACCURACY, QUALITY, INTEGRATION OR FITNESS
FOR A PARTICULAR PURPOSE, AND ANY WARRANTY ARISING OUT OF COURSE OF
DEALING, USAGE, OR TRADE. BENCH DOES NOT WARRANT THAT THE SERVICE WILL
OPERATE WITHOUT INTERRUPTION OR BE ERROR FREE. NO ADVICE OR INFORMATION,
WHETHER ORAL OR WRITTEN, OBTAINED BY CUSTOMER FROM THE SERVICES OR ANY
MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICES WILL CREATE ANY
WARRANTY REGARDING ANY OF THE BENCH ENTITIES OR THE SERVICES THAT IS NOT
EXPRESSLY STATED IN THIS AGREEMENT. CUSTOMER ASSUMES ALL RISK FOR ANY
DAMAGE THAT MAY RESULT FROM CUSTOMER’S USE OF OR ACCESS TO THE SERVICES,
CUSTOMER’S DEALING WITH ANY OTHER USER, AND ANY MATERIALS OR CONTENT
AVAILABLE THROUGH THE SERVICES. CUSTOMER UNDERSTANDS AND AGREES THAT
CUSTOMER USES THE SERVICES, AND USES, ACCESSES, DOWNLOADS, OR OTHERWISE
OBTAINS MATERIALS OR CONTENT THROUGH THE SERVICES AND ANY ASSOCIATED
SITES OR SERVICES, AT CUSTOMER’S OWN DISCRETION AND RISK, AND THAT
CUSTOMER IS SOLELY RESPONSIBLE FOR ANY DAMAGE TO CUSTOMER’S PROPERTY
(INCLUDING CUSTOMER’S COMPUTER SYSTEM OR MOBILE DEVICE USED IN
CONNECTION WITH THE SERVICES), OR THE LOSS OF DATA THAT RESULTS FROM THE
USE OF THE SERVICES OR THE DOWNLOAD OR USE OF MATERIAL OR CONTENT.

SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES AND CUSTOMER
MAY HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION

### 15.  **Limitation of Liability.**

IN NO EVENT WILL BENCH BE LIABLE FOR DAMAGES ARISING OUT OF OR IN ANY
WAY CONNECTED TO THIS AGREEMENT EXCEEDING FEES PAID IN RESPECT OF THE
SERVICES DURING THE THREE (3) MONTHS PRIOR TO THE EVENT GIVING RISE TO
LIABILITY. THE EXISTENCE OF ONE OR MORE CLAIMS UNDER THIS AGREEMENT WILL
NOT INCREASE THE MAXIMUM LIABILITY AMOUNT. IN NO EVENT SHALL BENCH’S
SUPPLIERS OR THIRD PARTY SERVICERS HAVE ANY LIABILITY ARISING OUT OF OR
IN ANY WAY CONNECTED TO THIS AGREEMENT.

IN NO EVENT SHALL BENCH BE LIABLE TO CUSTOMER FOR ANY (I) SPECIAL,
INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, (II) LOST
SAVINGS, PROFITS, DATA, USE, OR GOODWILL, (III) BUSINESS INTERRUPTION
EVEN IF NOTIFIED IN ADVANCE OF SUCH POSSIBILITY, OR (IV) PERSONAL OR
PROPERTY DAMAGE ARISING OUT OF OR IN ANY WAY CONNECTED TO THIS
AGREEMENT, REGARDLESS OF CAUSE OF ACTION OR THE THEORY OF LIABILITY,
WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE, GROSS NEGLIGENCE,
FUNDAMENTAL BREACH, BREACH OF A FUNDAMENTAL TERM) OR OTHERWISE. IN NO
EVENT SHALL BENCH BE LIABLE FOR PROCUREMENT OR COSTS OF SUBSTITUTE
PRODUCTS OR SERVICES. THE FOREGOING LIMITATIONS WILL APPLY
NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY
HEREIN.

TO THE EXTENT THAT BENCH MAY NOT, AS A MATTER OF LAW, DISCLAIM ANY
IMPLIED WARRANTY OR LIMIT LIABILITIES, THE SCOPE AND DURATION OF SUCH
WARRANTY AND THE EXTENT OF BENCH’S LIABILITY WILL BE THE MINIMUM
PERMITTED UNDER SUCH LAW.

Bench shall not be liable for any claims, losses or liabilities related
to any Product, Client or Third Party Servicer.

### 16.  **Notices.**

Notices sent to either Party shall be effective when delivered in person
or by email, one (1) day after being sent by overnight courier, or two
(2) days after being sent by first class mail postage prepaid to the
official contact designated below and immediately after being received
by the other party’s server. Notices must be in writing and addressed as
follows:

For Bench, addressed to Customer Care at: (i) Bench Accounting Inc.,
545 Robson St, Vancouver, BC V6B 2B7 Canada; or (ii) help@bench.co;
and

For Customer, to the addresses or email address provided by Customer
on the Application (as such address or email address may be updated by
Customer from time to time in accordance with this Agreement).

Bench may change its contact information by giving notice of such change
to the Customer. Customer may change its contact information by using
the currently available interfaces on Bench’s website. For contractual
purposes, Customer (i) consents to receive communications from Bench in
an electronic form; and (ii) agree that all terms and conditions,
agreements, notices, documents, disclosures, and other communications
(“**Communications**”) that Bench provides to Customer electronically
satisfy any legal requirement that such Communications would satisfy if
it were in writing. Customer’s consent to receive Communications and do
business electronically, and Bench’s agreement to do so, applies to all
of Customer’s interactions and transactions with Bench. The foregoing
does not affect Customer’s non-waivable rights. If Customer withdraws
such consent, from that time forward, Customer must stop using the
Services. The withdrawal of Customer’s consent will not affect the legal
validity and enforceability of any obligations or any electronic
Communications provided or business transacted between Bench prior to
the time Customer withdraws its consent.

By providing Bench with Customer’s mobile telephone number, Customer
consents to receiving text messages at that number as requested for
account verification, invitations, and other purposes related to the
Services. While Bench does not charge a fee for text messages,
Customer’s carrier may charge standard messaging, data, and other fees.
Customer is responsible for these charges. Bench may send and receive
text messages through cellular telephone operators or other networks,
and the level of reliability may vary. Bench is not responsible for the
timeliness or final delivery of the message, as this is out of our
control and is the responsibility of the cellular telephone operator or
other networks.

### 17.  **Governing Law and Arbitration.**

PLEASE READ THIS SECTION CAREFULLY, AS IT LIMITS THE MANNER IN WHICH
CUSTOMER MAY SEEK RELIEF, AND REQUIRES YOU ARBITRATE DISPUTES WITH
BENCH. If Customer has a dispute with Bench, Bench will first seek to
resolve such a dispute through our support team.

All disputes arising under or in connection with the Agreement will be
submitted to binding arbitration in Wilmington, Delaware, USA pursuant
to the Commercial Arbitration Rules of the American Arbitration
Association (“**AAA**”) and the procedures set forth below.

Arbitration. All disputes that cannot be resolved pursuant to the
internal issue resolution process identified above will be submitted to
and settled by final and binding arbitration. The arbitration will take
place in Wilmington, Delaware, USA and will apply the governing law of
this Agreement. The final and binding arbitration will be performed by a
single arbitrator who is a practicing commercial lawyer in English and
in accordance with and subject to the Commercial Arbitration Rules of
the AAA then in effect. The decision of the arbitrator will be final and
binding, and judgment on the award may be entered in any court of
competent jurisdiction. The arbitrator will be bound by the warranties,
limitations of liability, and other provisions of this Agreement.
Notwithstanding the foregoing, each party may seek injunctive relief in
a court of competent jurisdiction, where appropriate, to protect its
rights pending the outcome of the arbitration.

Jurisdiction/Venue; Governing Law. With respect to Customers located in
the U.S., this Agreement shall be governed by and construed in
accordance with the laws of the State of Delaware (irrespective of its
choice of law principles). The parties hereby agree that any suit to
enforce any provision of this Agreement or arising out of or based upon
this Agreement or the business relationship between the parties hereto
shall be brought in federal or state court in Wilmington, Delaware, USA.
Subject to the arbitration provision set out above, each party hereby
agrees that such courts shall have exclusive personal jurisdiction and
venue with respect to such party, and each party hereby submits to the
exclusive personal jurisdiction and venue of such courts.

NOTICE: BOTH CUSTOMER AND Bench AGREE TO HAVE ALL DISPUTES, CLAIMS OR
CONTROVERSIES ARISING OUT OF OR RELATING TO THIS AGREEMENT DECIDED BY
NEUTRAL BINDING ARBITRATION AND ARE GIVING UP ANY RIGHTS IT MIGHT
POSSESS TO HAVE THOSE MATTERS LITIGATED IN A COURT OR JURY TRIAL. BY
AGREEING TO THIS AGREEMENT, BOTH CUSTOMER AND Bench ARE GIVING UP THEIR
RESPECTIVE JUDICIAL RIGHTS TO DISCOVERY AND APPEAL EXCEPT TO THE EXTENT
THAT THEY ARE SPECIFICALLY PROVIDED FOR UNDER THIS AGREEMENT. IF ANY
PARTY REFUSES TO SUBMIT TO ARBITRATION AFTER AGREEING TO THIS PROVISION,
THAT PARTY MAY BE COMPELLED TO ARBITRATE UNDER FEDERAL OR STATE LAW.
BOTH CUSTOMER AND Bench CONFIRM THEIR AGREEMENT TO THIS ARBITRATION
PROVISION IS VOLUNTARY.

### 18.  **General Provisions.**

18.1.  **E-SIGN CONSENT**. Under the Electronic Signatures in Global
and National Commerce Act (“**E-Sign**”), this Agreement and
all electronically executed documents related hereto are
legally binding in the same manner as are hard copy documents
executed by hand signature when (1) your electronic signature
is associated with the Agreement and related documents, (2)
you consent and intend to be bound by the Agreement and
related documents, and (3) the Agreement is delivered in an
electronic record capable of retention by the recipient at the
time of receipt (i.e., print or otherwise store the electronic
record). This Agreement and all related electronic documents
shall be governed by the provisions of E-Sign. You agree (i)
that the Agreement and related documents shall be effective by
electronic means (ii) to be bound by the terms and conditions
of this Agreement and related documents and (iii) that you
have the ability to print or otherwise store the Agreement and
related documents.

18.2.  Assignment. Customer may not assign this Agreement or any of its
rights or obligations hereunder to any third party without
prior written consent of Bench. Any assignment in violation of
this section shall be void. Bench may assign this Agreement
without restriction and without any notice to Customer. The
terms of this Agreement shall be binding upon permitted
successors and assigns. Customer agrees that on a sale of all
or substantively all of the assets of Bench, Bench may include
in the sale the copy of Customer’s payment method (e.g. credit
card information) used to pay Fees hereunder.

18.3.  Right to List as a Customer. Customer agrees that Bench may
utilize Customer’s entity name in listings of current
customers. Use of Customer’s name in any other marketing
materials or press announcements will be submitted to Customer
in advance for approval, and such approval will not be
unreasonably withheld.

18.4.  Compliance with Export Regulations. Customer has or shall obtain
in a timely manner all necessary or appropriate licenses,
permits or other governmental authorizations or approvals;
shall indemnify and hold Bench harmless from, and bear all
expense of, complying with all foreign or domestic laws,
regulations or requirements pertaining to the importation,
exportation, or use of the technology to be developed or
provided herein. Customer shall not directly or indirectly
export or re-export (including by transmission) any regulated
technology to any country to which such activity is restricted
by regulation or statute, without the prior written consent,
if required, of the administrator of export laws.

18.5.  European Union Residents. If Customer resides in the European
Union (EU) or if any transfer of information between Customer
and the Services is governed by the European Union Data
Protection Directive or national laws implementing that
Directive, then Customer consents to the transfer of such
information outside of the European Union to its country and
to such other countries as may be contemplated by the features
and activities provided by the Services.

18.6.  Construction. Except as otherwise provided herein, the Parties
rights and remedies under this Agreement are cumulative. The
term “including” means “including without limitation.” The
headings of sections of this Agreement are for reference
purposes only and have no substantive effect.

18.7.  Force Majeure. Customer acknowledges and understands that if
Bench is unable to provide Services as a result of a force
majeure event Bench will not be in breach of this Agreement
and will not be liable for delays caused by such event. A
force majeure event means any event beyond the control of
Bench.

18.8.  Severable. NOTHING IN THIS AGREEMENT WILL AFFECT ANY
NON-WAIVABLE STATUTORY RIGHTS THAT APPLY TO CUSTOMER. Any
provision hereof found by a tribunal of competent jurisdiction
to be illegal or unenforceable shall be automatically
conformed to the minimum requirements of law and the validity,
legality, and enforceability of all other provisions shall
remain in full force and effect.

18.9.  Waiver. The failure to exercise, or delay in exercising, a
right, power or remedy provided in this Agreement or by law in
one instance shall not preclude enforcement thereof on future
occasions.

18.10. Independent Contractors. Customer’s relationship to Bench is
that of an independent contractor, and neither Party is an
agent or partner of the other. Customer will not have, and
will not represent to any third party that it has, any
authority to act on behalf of Bench.

18.11. Entire Agreement. In the event of any conflict between this
Agreement and the information provided during Application or
otherwise on the Site, in the App or in the EULA, this
Agreement shall control. In the event of any inconsistency
between the body of this Agreement and the Bench Privacy
Policy, the former shall prevail. This Agreement constitutes
the entire agreement between the Parties with respect to the
subject matter hereof and supersedes any and all prior
agreements, negotiations, or other communications, whether
written or oral. This Agreement may be executed in one or more
counterparts, each of which shall be deemed an original and
all of which shall be taken together and deemed to be one
instrument. A printed version of this Agreement and of any
notice given in electronic form shall be admissible in
judicial or administrative proceedings based upon or relating
to this Agreement to the same extent and subject to the same
conditions as other business documents and records originally
generated and maintained in printed form.

18.12. Amendments. Bench reserves the right to change this Agreement
(but not any agreement between the Customer and Bench relating
to the disclosure of the Customer’s tax return information) at
any time and from time to time upon notice by posting
revisions to this Agreement (including the description of the
Services) on the Site. Continued use of the Services after
Customer become aware of any such changes shall constitute
Customer’s consent to such changes. Customer is responsible
for regularly reviewing the most current version of this
Agreement which is available on Bench’s website.

18.13. English Language. It is the express wish of the parties that
this Agreement and all related documents be drawn up in
English. *C’est la volonté expresse des parties que la
présente convention ainsi que les documents qui s’y rattachent
soient rédigés en anglais.*

18.14. Survival. Upon termination or expiration of this Agreement for
any reason: (i) all rights and obligations of both Parties
(except for Customer’s payment of all sums then owing),
including all licenses granted hereunder, shall immediately
terminate except as provided below; (ii) within thirty (30)
days after the effective date of termination, each Party shall
comply with the obligations to return or destroy all
Confidential Information. The following Sections will also
survive expiration or termination of this Agreement for any
reason: the introductory paragraph, Sections 3 Limitations, 4
Bench Intellectual Property Rights, 5 Customer Data Consent, 7
Indemnity, 10 Fees, 11 Confidential Information, 12 Customer
Warranties, 13 No Warranties by Bench, 15 Limitation of
Liability, 16 Notices, 17 Governing Law and Arbitration, 18
General Provisions and 19 Glossary.

### 19.  **Glossary.**

The following words used in this Agreement are defined as follows:

**Account** means an account made available to Customer through which Customer can transmit instructions or receive information in relation to the Services.

**ACH** means Automated Clearing House payment transaction.

**Affiliate** means, in relation to a Person, another Person that
directly or indirectly owns or controls, is owned or controlled by, or
is under common ownership or common control with the Person, or a
Person's principal partners, shareholders, or owners of some other
ownership interest.

**App** means an application available for download to certain mobile
devices through which Customer can access the Services, subject to the
EULA.

**Application** means the on-line sign-up process on the Site completed
by Customer when applying for the Services, all of which is incorporated
herein by reference.

**Bank Account** means Customer account, if any, with a
Third Party Servicer.

**Bench Data** means information concerning Services or provided to
Customer by Bench through the Account or otherwise.

**Bench Privacy Policy** means the privacy policy of Bench posted at
[<u>https://bench.co/privacy/</u>](https://bench.co/privacy/), as
updated from time to time.

**Bench System** means a cloud-based system operated by Bench that allows Customer to access Customer Account and initiate Transactions.

**Client** means a client or customer of Customer.

**Confidential Information** means all proprietary, secret or
confidential information or data relating to either party and its
affiliates, operations, employees, products or services, clients,
customers or potential customers. Confidential Information shall include
customer lists, cardholder account numbers, pricing information,
computer access codes, instruction and/or procedural manuals, and the
terms and conditions of this Agreement. Information shall not be
considered Confidential Information to the extent, but only to the
extent, that such information is: (i) already known to the receiving
party free of any restriction at the time it is obtained; (ii)
subsequently learned from an independent third party free of any
restriction and without breach of this Agreement; (iii) or becomes
publicly available through no wrongful act of the receiving party; (iv)
independently developed by the receiving party without reference to any
Confidential Information of the other; or (v) required to be disclosed
by Law.

**Customer Bank Account** means a bank account of the Customer
identified by Customer as being an account from which Bench may debit
Fees or other amounts owing hereunder.

**Customer Data** means any and all non-public identifiable personal information of Customers.

**Customer Device** means computer system, tablet, phone or other
electronic device used by Customer to access the Services or manage Data
or Customer business.

**Customer** means you and if you are using the Services on behalf of a
company, entity, or organization, the entity, partnership or
organization, then ‘you’ also includes such entity.

**Data** means Customer Data or Bench Data.

**EULA** means an end-user license agreement pursuant to which the App
is licensed to the Customer for the limited purpose of accessing the
Services.

**Fees** means fees payable by Customer for use of the Services, as
disclosed here
[<u>https://bench.co/pricing</u>](https://bench.co/pricing) or otherwise
on the Site, the App or in the Account.

**Intellectual Property Rights** means all patent rights, copyright
rights, mask work rights, moral rights, rights of publicity, trademark,
trade dress, works of authorship, inventions, discoveries and service
mark rights, goodwill, trade secret rights and other intellectual
property rights as may now exist or hereafter come into existence, and
all applications therefore and registrations, renewals and extensions
thereof, under the laws of any state, country, territory or other
jurisdiction.

**Laws** shall mean laws, statutes, codes, ordinances, orders, decrees,
rules, regulations, and municipal by laws, whether domestic, or foreign,
all judgments, orders, writs, injunctions, decisions, rulings, decrees,
and awards of any government authority having jurisdiction.

**Party** means either Customer or Bench and “Parties” means both
Customer and Bench.

**Person** is to be broadly interpreted and includes an individual, a corporation, a partnership, a trust, an unincorporated organization, the government of a country or any political subdivision thereof, or any agency or department of any such government, and the executors, administrators or other legal representatives of an individual in such capacity.

**Product** means any product or service for sale or provided by Customer or for which a Third Party Servicer is used to make payment or for which Services is used to assist in a payment.

**Services** has the meaning set out in the preamble hereof.

**Site** has the meaning set out in the preamble hereof.

**Term** has the meaning set out in Section 8.

**Third Party Servicer Agreement** means an agreement between Customer and a Third Party 
Servicer concerning the supply of Third Party Servicer Services to Customer. 

**Third Party Servicer** means a third party that has entered into Third Party Servicer 
Agreement with Customer pursuant to which the Third Party Servicer provides Third Party
Servicer Services to Customer. 

**Third Party Servicer Services** means the services of a Third Party Servicer
provided pursuant to Third Party Servicer Agreement.

**Transaction** means sending or attempted sending of Data, by way of the Services, 
between any of Customer, Bench and a Third Party Servicer.

${policyCopyrightText}
`;

const TermsBodyContainer = styled.div`
  && {
    dt::before,
    dt:after,
    dd::before {
      display: none;
    }

    dt {
      font-weight: normal;
    }

    dd {
      font-weight: 700;
    }

    ol {
      list-style-type: lower-alpha;

      ol li {
        list-style-type: decimal;
      }
    }
  }
`;

const TermsBody = ({ markdown, path }) => (
  <TermsBodyContainer>
    <WidgetArticleBody markdown={markdown} path={path} />
  </TermsBodyContainer>
);

TermsBody.propTypes = {
  markdown: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

const pageData = {
  description:
    'Bench provides online bookkeeping for your business. Our Terms of Use apply to your usage of Bench services, and our obligations to you.',
  enableAbTesting: false,
  footerType: 'full',
  navbarType: 'full',
  path: '/terms/',
  title: 'Terms of Service — Bench',
  sections: [
    {
      columns: [
        {
          span: 5,
          widgets: [
            {
              data: {
                description: 'Last updated: July 8, 2022',
                header: 'Bench Accounting, Inc.\nTerms of Service',
                textAlignment: 'center',
              },
              WidgetComponent: WidgetValueProp,
            },
          ],
        },
      ],
      paddingBottom: 0,
      margin: 0,
    },
    {
      backgroundColor: colors.white,
      columns: [
        {
          span: 9,
          widgets: [
            {
              data: {
                markdown: md,
                path: '/terms/',
              },
              WidgetComponent: TermsBody,
            },
          ],
        },
      ],
      paddingTop: 0,
    },
  ],
};

const TermsPage = () => <Page {...pageData} />;

export default TermsPage;
