import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from '../../ui/constants/grid';
import LinkButton from '../../ui/buttons/LinkButton';
import Card from './Card';
import colors from '../../ui/constants/colors';
import GridColumn from '../../ui/grid/GridColumn';
import GridRow from '../../ui/grid/GridRow';
import Image from '../../ui/images/Image';
import Spacer from '../../ui/Spacer';

const SpaciousCard = styled(Card)`
  padding: 32px;
  ${mediaQueries.desktop`
    padding: 96px;
  `};
`;

const ImageContainer = styled.div`
  ${mediaQueries.mobile`
    align-items: center;
    display: flex;
    justify-content: center;
  `};
`;

const Header = styled.div`
  color: ${colors.charcoal};
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
`;

const ButtonWrapper = styled.div`
  ${mediaQueries.desktop`
    max-width: 270px;
  `};
`;

const BodyText = styled.div`
  color: ${colors.charcoal};
  font-size: 14px;
  line-height: 1.6;
  ${mediaQueries.desktop`
    max-width: 270px;
  `};
`;

const DisclaimerText = styled(BodyText)`
  color: ${colors.smoke};
`;

const CorpDisqualifiedView = () => (
  <SpaciousCard>
    <GridRow>
      <GridColumn orderMobile={2} span={5} spanMobile={12}>
        <ImageContainer>
          <Spacer paddingTopDesktop={2} paddingTopMobile={4}>
            <Image
              alt="Person climbing a giant book"
              maxWidth={339}
              mobileMaxWidth={180}
              naturalHeight={678}
              naturalWidth={460}
              src="/img/illustrations/climbing-books.png"
            />
          </Spacer>
        </ImageContainer>
      </GridColumn>
      <GridColumn span={1} />
      <GridColumn orderMobile={1} span={6} spanMobile={12}>
        <Header>Sorry&amp;we`re unable to calculate your quarterly taxes.</Header>
        <Spacer paddingTop={1} paddingTopMobile={3} />
        <BodyText>
          {`But, we'd still like to help. Here's how you can figure out exactly what you
          owe.`}
        </BodyText>
        <Spacer paddingBottom={4} paddingTop={2}>
          <BodyText>
            {`Follow the link below to fill out Form 1120-W Schedule A. It's a specialized tax
            form that evens out quarterly tax payments based on profit during the quarter.`}
          </BodyText>
        </Spacer>
        <ButtonWrapper>
          <LinkButton
            backgroundColor="#1AADA2"
            linkURL="https://www.irs.gov/pub/irs-pdf/f1120w.pdf"
            textColor={colors.white}
          >
            See the Form →
          </LinkButton>
        </ButtonWrapper>
        <Spacer mobilePadding={2} paddingTop={4}>
          <DisclaimerText>
            Form 1120-W Schedule A requires profitability by month for the last 3 tax years to
            complete.
          </DisclaimerText>
          <Spacer paddingTop={1} />
          <DisclaimerText>
            {`We recommend consulting a CPA before filling out this form to see if it's right for
            your business.`}
          </DisclaimerText>
        </Spacer>
      </GridColumn>
    </GridRow>
  </SpaciousCard>
);

export default CorpDisqualifiedView;
