import styled from 'styled-components';
import colors from '../../ui/constants/colors';
import { mediaQueries } from '../../ui/constants/grid';

const AnnualContractOnlyText = styled.div`
  background: ${colors.deepSapphire};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  padding: 6px 0;
  font-size: 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: absolute;
  top: 0;
  width: 100%;
  transition: ease 0.2s;
`;

const LinkButtonWrapper = styled.div`
  padding: 25px 40px;

  &:last-child {
    margin-top: auto;
  }
`;

const MonthSubText = styled.span`
  font-size: 18px;
  font-weight: bold;
  padding-top: 10px;

  ${mediaQueries.mobile`
    font-size: 14px;
  `};
`;

const PayAsYouGoPremiumCardDisable = styled.div`
  background: rgba(202, 205, 210, 0.6);
  z-index: 2;
  position: absolute;
  top: 30px;
  width: 100%;
  height: 94.5%;
  border-radius: 0 0 8px 8px;
`;

const PlanDetail = styled.div`
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  line-height: 1;
`;

const PlanDetailsCheckWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.1fr 5fr;
  align-items: flex-start;
  grid-column-gap: 10px;
  grid-row-gap: 7px;
  padding-bottom: 12px;

  &:first-child {
    border-top: 1px solid
      ${({ isPlanOne, isAnnual }) => {
        if (isPlanOne) {
          return colors.lunarRays;
        }
        if (!isAnnual) {
          return colors.charcoal;
        }
        return colors.white;
      }};
    padding-top: 32px;
  }
`;

const PlanDetailsWrapper = styled.div`
  padding: 0 15px 0 33px;
  min-height: 170px;
  color: ${({ isPlanOne, isAnnual }) => {
    if (isPlanOne && (isAnnual || !isAnnual)) {
      return colors.eerieBlack;
    }
    if (!isPlanOne && isAnnual) {
      return colors.white;
    }
    return colors.tulleGrey;
  }};
`;

const PlanPrice = styled.span`
  padding: 0;
`;

const SinglePricingCard = styled.div`
  border-radius: 8px;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.13);
  position: relative;
  height: auto;
  transition: ease 0.2s;
  display: flex;
  flex-direction: column;
  background-color: ${({ isAnnual, isPlanOne }) => {
    if (!isPlanOne && isAnnual) {
      return colors.deepSapphire;
    }
    if (!isPlanOne && !isAnnual) {
      return colors.lunarRays;
    }
    return colors.white;
  }};
  border: ${({ isPlanOne }) => isPlanOne && `1px solid ${colors.littleDipper}`};

  ${mediaQueries.mobile`
    width: unset;
  `};
`;

const PricingCardHeadingWrapper = styled.div`
  background-color: ${({ cardColor }) => cardColor};
  border-radius: 8px 8px 0 0;
  color: ${({ isPlanOne, isAnnual }) => {
    if (isPlanOne) {
      return colors.eerieBlack;
    }
    if (!isPlanOne && isAnnual) {
      return colors.white;
    }
    return colors.tulleGrey;
  }};
  padding: 43px 34px;
`;

const PricingCardPlanDescription = styled.div`
  font-size: 14px;
  line-height: 1.5;
  padding: 0 0 24px;
  font-weight: 700;
`;

const PricingCardPlanName = styled.h1`
  font-size: 32px;
  font-weight: bold;
`;

const PriceWrapper = styled.div`
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 11px;
`;

const PromoCardWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RegularPromoPrice = styled.div`
  background: ${colors.arcLight};
  margin-left: 8px;
  padding: 1px 8px;
  border-radius: 100px;
  width: 83px;
  text-align: center;
  color: ${colors.eerieBlack};
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 11px;
`;

export {
  AnnualContractOnlyText,
  LinkButtonWrapper,
  MonthSubText,
  PayAsYouGoPremiumCardDisable,
  PlanDetail,
  PlanDetailsCheckWrapper,
  PlanDetailsWrapper,
  PlanPrice,
  PricingCardHeadingWrapper,
  PricingCardPlanDescription,
  PricingCardPlanName,
  PriceWrapper,
  PromoCardWrapper,
  RegularPromoPrice,
  SinglePricingCard,
};
