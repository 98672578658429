import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import blogSignupSectionProps from '../helpers/blogSignupSectionProps';
import colors from '../../../ui/constants/colors';
import { mediaQueries } from '../../../ui/constants/grid';
import makeTitleTagContent from '../../helpers/makeTitleTagContent';
import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';
import Page from '../../../components/Page';
import tryGet from '../../../components/helpers/tryGet';
import WidgetArticleBody from '../../../components/widgets/WidgetArticleBody';
import WidgetButton from '../../../components/widgets/WidgetButton';
import WidgetMarketoForm from '../../../components/widgets/WidgetMarketoForm';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';
import WidgetImage from '../../../components/widgets/WidgetImage';

import '../../../scss/styles-website.scss';

const Y_SCROLL_BUFFER_SIZE = 200;

const handleCtaClick = (event, marketoFormId) => {
  const form = document.getElementById(`mktoForm_${marketoFormId}`);

  if (form) {
    event.preventDefault();
    const formRectangle = form.getBoundingClientRect();
    window.scrollTo(0, formRectangle.top + (window.pageYOffset - Y_SCROLL_BUFFER_SIZE));
  }
};

const MarketoForm = styled.div`
  border-radius: 5px;
  box-shadow: 1px 1px 18px 0 rgba(212, 210, 206, 0.31);
  background: #ffffff;
  min-height: 300px;
  padding: 48px 21px 24px;

  ${mediaQueries.desktop`
    max-width: 360px;
    min-height: 400px;
  `}
`;

const MarketoSection = ({ campaignId, description, formId, header, marketoFormFieldCount }) => (
  <MarketoForm>
    <WidgetValueProp
      description={description}
      fontColor="#4a4b4e"
      fontSize={14}
      header={header}
      headerFontSize={24}
      headerFontSizeMobile={22}
      textAlignmentMobile="left"
    />
    <WidgetMarketoForm
      campaignId={campaignId}
      formId={formId}
      placeholderFields={marketoFormFieldCount}
    />
  </MarketoForm>
);

MarketoSection.propTypes = {
  campaignId: PropTypes.string,
  description: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  marketoFormFieldCount: PropTypes.number.isRequired,
};

MarketoSection.defaultProps = {
  campaignId: undefined,
};

const ImageContainer = styled.div`
  box-shadow: 1px 1px 18px 0 rgba(212, 210, 206, 0.31);
  max-width: 360px;
`;

const GatedAssetImage = ({ gatedAssetImage }) =>
  gatedAssetImage && (
    <ImageContainer>
      <WidgetImage
        {...normalizeContentfulImageData(gatedAssetImage)}
        imageAlignment="left"
        isAboveFold
        maxWidth={360}
      />
    </ImageContainer>
  );

GatedAssetImage.propTypes = {
  gatedAssetImage: PropTypes.object.isRequired,
};

const CtaContainer = styled.div`
  border-bottom: 2px solid #0fa1a2;
  display: inline-block;
  padding-bottom: 5px;
`;

const GatedAssetCta = ({ callToActionText, marketoFormId }) => (
  <CtaContainer>
    <WidgetButton
      buttonText={`→ ${callToActionText}`}
      fontSize={20}
      onClick={(event) => handleCtaClick(event, marketoFormId)}
      plain
      textColor="#0fa1a2"
    />
  </CtaContainer>
);

GatedAssetCta.propTypes = {
  callToActionText: PropTypes.string.isRequired,
  marketoFormId: PropTypes.string.isRequired,
};

const buildImagePropGroup = (body, header, image) => {
  return [
    {
      data: {
        ...normalizeContentfulImageData(image),
        imageAlignment: 'left',
        maxWidth: 65,
        mobileMaxWidth: 40,
        mobileImageAlignment: 'center',
      },
      hideOnMobile: false,
      marginBottomDesktop: 2,
      marginTopDesktop: 6,
      WidgetComponent: WidgetImage,
    },
    {
      data: {
        description: body,
        fontSize: 18,
        header,
        headerFontSize: 18,
        headerFontWeight: 600,
      },
      hideOnMobile: false,
      marginDesktop: 0,
      marginMobile: 2,
      WidgetComponent: WidgetValueProp,
    },
  ];
};

const buildValuePropWidgetGroupList = ({
  valueProp1Body,
  valueProp1Header,
  valueProp1Image,
  valueProp2Header,
  valueProp2Body,
  valueProp2Image,
  valueProp3Body,
  valueProp3Header,
  valueProp3Image,
  valuePropSectionHeader,
}) => {
  const list = [];

  if (valuePropSectionHeader) {
    list.push({
      data: {
        header: valuePropSectionHeader,
        headerFontSize: 24,
        headerFontWeight: 600,
      },
      hideOnMobile: false,
      marginBottomDesktop: 6,
      marginMobile: 2,
      WidgetComponent: WidgetValueProp,
    });
  }

  if (valueProp1Body && valueProp1Header && valueProp1Image) {
    list.push(
      ...buildImagePropGroup(
        tryGet(['valueProp1Body'], valueProp1Body) || valueProp1Body,
        valueProp1Header,
        valueProp1Image,
      ),
    );
  }

  if (valueProp2Body && valueProp2Header && valueProp2Image) {
    list.push(
      ...buildImagePropGroup(
        tryGet(['valueProp2Body'], valueProp2Body) || valueProp2Body,
        valueProp2Header,
        valueProp2Image,
      ),
    );
  }

  if (valueProp3Body && valueProp3Header && valueProp3Image) {
    list.push(
      ...buildImagePropGroup(
        tryGet(['valueProp3Body'], valueProp3Body) || valueProp3Body,
        valueProp3Header,
        valueProp3Image,
      ),
    );
  }

  return list;
};

const GatedAsset = (props) => {
  const { pageContext } = props;
  const { gatedAssetData } = pageContext;
  const {
    callToActionText,
    campaignId,
    gatedAssetDescription,
    gatedAssetBody,
    gatedAssetImage,
    gatedAssetSubtitle,
    gatedAssetTitle,
    marketoFormHeader,
    marketoFormId,
    marketoFormFieldCount,
    marketoFormSubheader,
    metaDescription,
    path,
    robotMetaContent,
  } = gatedAssetData;

  const pageData = {
    background:
      'linear-gradient(to bottom, #F6F6F6 0, #F6F6F6 500px, #FFFFFF 500px, #FFFFFF 10000px)',
    description: metaDescription,
    enableAbTesting: false,
    path,
    socialImage: null,
    title: makeTitleTagContent(gatedAssetTitle),
    footerBackground: colors.white,
    footerType: 'full',
    hideNavbar: false,
    navbarType: 'full',
    robotMetaContent,
    transparentNavbarBenchColor: null,
    transparentNavbarTextColor: null,
    sections: [
      {
        paddingTopMobile: 4,
        columns: [
          {
            orderDesktop: 1,
            orderMobile: 3,
            span: 5,
            widgets: [
              {
                data: {
                  description: gatedAssetSubtitle,
                  header: gatedAssetTitle,
                  headerFontSize: 40,
                  headerFontWeight: 600,
                  headerTag: 'h1',
                },
                hideOnMobile: true,
                marginTopDesktop: 7,
                WidgetComponent: WidgetValueProp,
              },
              {
                data: {
                  gatedAssetImage,
                },
                hideOnMobile: true,
                marginDesktop: 7,
                WidgetComponent: GatedAssetImage,
              },
              {
                data: {
                  markdown:
                    tryGet(['gatedAssetDescription'], gatedAssetDescription) ||
                    gatedAssetDescription,
                  path,
                },
                WidgetComponent: WidgetArticleBody,
              },
              {
                data: {
                  callToActionText,
                  marketoFormId,
                },
                WidgetComponent: GatedAssetCta,
              },
            ],
          },
          {
            orderDesktop: 2,
            orderMobile: 2,
            span: 2,
          },
          {
            orderDesktop: 3,
            orderMobile: 1,
            span: 5,
            widgets: [
              {
                data: {
                  description: gatedAssetSubtitle,
                  header: gatedAssetTitle,
                  headerFontSize: 24,
                  headerFontWeight: 600,
                  headerTag: 'h1',
                },
                hideOnDesktop: true,
                marginMobile: 1,
                WidgetComponent: WidgetValueProp,
              },
              {
                data: {
                  backgroundColor: '#FFFFFF',
                  campaignId,
                  description: marketoFormSubheader,
                  formId: marketoFormId,
                  header: marketoFormHeader,
                  marketoFormFieldCount,
                  maxWidth: 360,
                },
                marginBottomDesktop: 9,
                marginMobile: 2,
                WidgetComponent: MarketoSection,
              },
              ...buildValuePropWidgetGroupList(gatedAssetData),
            ],
          },
          {
            orderDesktop: 3,
            orderMobile: 3,
            span: 12,
            widgets: [
              {
                data: {
                  markdown: tryGet(['gatedAssetBody'], gatedAssetBody) || gatedAssetBody,
                  path,
                },
                paddingTopDesktop: 10,
                WidgetComponent: WidgetArticleBody,
              },
            ],
          },
        ],
      },
      blogSignupSectionProps,
    ],
  };

  return <Page {...pageData} />;
};

GatedAsset.propTypes = {
  /** Provided by `createPage` in gatsby-node-blog.js */
  pageContext: PropTypes.shape({
    gatedAssetData: PropTypes.object,
  }).isRequired,
};

export default GatedAsset;
