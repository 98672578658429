import PropTypes from 'prop-types';
import React from 'react';

import blogSignupSectionProps from '../helpers/blogSignupSectionProps';
import Page from '../../../components/Page';
import translateWidget from '../translations/translateWidget';
import WidgetCategoryNav from '../../../components/widgets/WidgetCategoryNav';
import WidgetCardList from '../../../components/widgets/WidgetCardList';
import WidgetImage from '../../../components/widgets/WidgetImage';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';
import colors from '../../../ui/constants/colors';
import blogCategories from '../helpers/blogCategories';

import '../../../scss/styles-website.scss';

const getPageProps = ({
  cards,
  metaDescription,
  pageDescription,
  path,
  title,
  enableAbTesting,
}) => {
  return {
    description: metaDescription,
    footerType: 'full',
    navbarType: 'full',
    path,
    sections: [
      {
        backgroundColor: '#F6F6F6',
        columns: [
          {
            span: 12,
            widgets: [
              {
                data: {
                  alt: 'Bench Blog',
                  isAboveFold: true,
                  imageAlignment: 'center',
                  maxWidth: 300,
                  mobileMaxWidth: 230,
                  src: '/img/logos/Logo-Bench-Blog.svg',
                },
                marginBottomDesktop: 0,
                marginTopDesktop: 12,
                WidgetComponent: WidgetImage,
              },
              {
                data: {
                  header: pageDescription,
                  headerFontColor: colors.ash,
                  headerFontSize: 18,
                  headerFontSizeMobile: 16,
                  headerFontWeight: 400,
                  headerTag: 'h1',
                  textAlignment: 'center',
                },
                marginBottomDesktop: 13,
                marginTopDesktop: 3,
                WidgetComponent: WidgetValueProp,
              },
              {
                data: { categories: blogCategories },
                WidgetComponent: WidgetCategoryNav,
              },
              {
                data: {
                  cards: cards ? cards.map(translateWidget) : [],
                  layout: [{ numCards: 1 }, { numCards: 2 }, { numCards: 3 }, { numCards: 3 }],
                },
                WidgetComponent: WidgetCardList,
              },
            ],
          },
        ],
        padding: 0,
      },
      blogSignupSectionProps,
    ],
    title,
    enableAbTesting,
  };
};

const BlogOverview = ({ pageContext }) => {
  const { pageData } = pageContext;
  const pageProps = getPageProps(pageData);
  const {
    background,
    description,
    enableAbTesting,
    footerBackground,
    footerType,
    hideNavbar,
    navbarAlwaysOpaque,
    navbarType,
    path,
    socialImage,
    testId,
    title,
    transparentNavbarBenchColor,
    transparentNavbarTextColor,
    sections,
  } = pageProps;

  return (
    <Page
      background={background}
      description={description}
      enableAbTesting={enableAbTesting}
      footerBackground={footerBackground}
      footerType={footerType}
      hideNavbar={hideNavbar}
      navbarAlwaysOpaque={navbarAlwaysOpaque}
      navbarType={navbarType}
      path={path}
      sections={sections}
      socialImage={socialImage}
      testId={testId}
      title={title}
      transparentNavbarBenchColor={transparentNavbarBenchColor}
      transparentNavbarTextColor={transparentNavbarTextColor}
    />
  );
};

BlogOverview.propTypes = {
  /** Provided by `createPage` in gatsby-node-blog.js */
  pageContext: PropTypes.shape({
    pageData: PropTypes.object,
  }).isRequired,
};

export default BlogOverview;
