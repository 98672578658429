import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { getGreenhouseId, getJobPagePath } from '../helpers/greenhouse';
import JobPage from './JobPage';
import JobNotFoundPage from './JobNotFoundPage';

// Securely decode HTML
// https://stackoverflow.com/questions/1912501/unescape-html-entities-in-javascript/34064434#34064434
const htmlDecode = (text) => {
  const doc = new DOMParser().parseFromString(text, 'text/html');
  return doc.documentElement.textContent;
};

const JobContainer = ({ location }) => {
  const greenhouseId = getGreenhouseId(location, 'gh_jid');
  const [isGreenhouseIdValid, setIsGreenhouseIdValid] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [jobDescriptionHTML, setJobDescriptionHTML] = useState(null);

  const setJobDataInState = ({ title, content }) => {
    const jobDescriptionDecoded = htmlDecode(content);

    setJobDescriptionHTML(jobDescriptionDecoded);
    setJobTitle(title);
    setIsGreenhouseIdValid(true);
  };

  const jobNotFound = () => {
    isGreenhouseIdValid(false);
  };

  const fetchJob = () => {
    axios(`https://boards-api.greenhouse.io/v1/boards/bench/jobs/${greenhouseId}`)
      .then((res) => {
        const job = res.data;
        // The job title is a litmus test for whether the shape of the returned object looks like
        // a job. This also acts as validation that we have enough data to render the job page.
        if (!job || !job.title) {
          return jobNotFound();
        }
        return setJobDataInState(job);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return;
        }
        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
          return;
        }
        jobNotFound();
      });
  };

  useEffect(() => {
    if (greenhouseId) {
      return fetchJob();
    }
    // Setting the ID to invalid if it's empty _after_ the component mounts optimizes the
    // server-side rendered page for the happy path, where the job page is shown and then filled
    // in after the API request returns.
    return jobNotFound();
  }, [greenhouseId]);

  // Need to check for explicit `false` because it's not known whether it's valid until after the
  // API request has been made.
  if (isGreenhouseIdValid === false) {
    return <JobNotFoundPage />;
  }
  return (
    <JobPage
      greenhouseId={greenhouseId}
      isGreenhouseIdValid={isGreenhouseIdValid}
      jobDescriptionHTML={jobDescriptionHTML}
      jobTitle={jobTitle}
      path={getJobPagePath(greenhouseId)}
    />
  );
};

JobContainer.propTypes = {
  // Provided by Gatsby
  location: PropTypes.shape({
    hash: PropTypes.string,
    search: PropTypes.string,
  }),
};

JobContainer.defaultProps = {
  location: null,
};

export default JobContainer;
