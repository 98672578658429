import PropTypes from 'prop-types';
import React from 'react';
import colors from '../../ui/constants/colors';
import LinkButton from '../../ui/buttons/LinkButton';
import FilledInCheckMark from '../../ui/icons/FilledInCheckMark';
import {
  AnnualContractOnlyText,
  LinkButtonWrapper,
  MonthSubText,
  PlanDetail,
  PayAsYouGoPremiumCardDisable,
  PlanDetailsCheckWrapper,
  PlanDetailsWrapper,
  PlanPrice,
  PricingCardHeadingWrapper,
  PricingCardPlanDescription,
  PricingCardPlanName,
  PriceWrapper,
  SinglePricingCard,
} from './PricingCardStyles.styles';

const PricingCard = ({ isAnnual, planData }) => {
  const {
    discountedPrice,
    isPlanOne,
    payAsYouGoDiscountedPrice,
    planName,
    planDetails,
    planDescription,
    regularPrice,
  } = planData;

  const notAnnualOrPlanOne = !isAnnual && !isPlanOne;

  return (
    <SinglePricingCard isAnnual={isAnnual} isPlanOne={isPlanOne}>
      {notAnnualOrPlanOne && <PayAsYouGoPremiumCardDisable />}
      {notAnnualOrPlanOne && <AnnualContractOnlyText>Annual Contract Only</AnnualContractOnlyText>}
      <PricingCardHeadingWrapper isAnnual={isAnnual} isPlanOne={isPlanOne}>
        <PricingCardPlanName>{planName}</PricingCardPlanName>
        <PricingCardPlanDescription>{planDescription}</PricingCardPlanDescription>
        <PriceWrapper>
          <PlanPrice>
            ${payAsYouGoDiscountedPrice && !isAnnual ? payAsYouGoDiscountedPrice : discountedPrice}
          </PlanPrice>
          <MonthSubText>/mo</MonthSubText>
        </PriceWrapper>
        {!isAnnual && isPlanOne ? (
          <p>billed monthly</p>
        ) : (
          <p>billed annually or ${regularPrice} monthly</p>
        )}
      </PricingCardHeadingWrapper>
      <PlanDetailsWrapper isAnnual={isAnnual} isPlanOne={isPlanOne}>
        {planDetails.map((detail, detailIndex) => (
          <PlanDetailsCheckWrapper key={detailIndex} isAnnual={isAnnual} isPlanOne={isPlanOne}>
            {isPlanOne ? (
              <FilledInCheckMark fill={colors.eerieBlack} />
            ) : (
              <FilledInCheckMark fill={!isAnnual ? colors.tulleGrey : colors.white} />
            )}
            <PlanDetail key={detailIndex}>{detail}</PlanDetail>
          </PlanDetailsCheckWrapper>
        ))}
      </PlanDetailsWrapper>
      <LinkButtonWrapper>
        {notAnnualOrPlanOne ? (
          <LinkButton
            backgroundColor={colors.lunarRays}
            borderColor={colors.charcoal}
            linkURL="/signup/"
            textColor={colors.charcoal}
            width="100%"
          >
            Start a Free Trial
          </LinkButton>
        ) : (
          <LinkButton
            backgroundColor={colors.water}
            linkURL="/signup/"
            textColor={colors.white}
            width="100%"
          >
            Start a Free Trial
          </LinkButton>
        )}
      </LinkButtonWrapper>
    </SinglePricingCard>
  );
};

PricingCard.propTypes = {
  isAnnual: PropTypes.bool.isRequired,
  planData: PropTypes.shape({
    discountedPrice: PropTypes.number,
    isPlanOne: PropTypes.bool,
    payAsYouGoDiscountedPrice: PropTypes.number,
    planDescription: PropTypes.string,
    planDetails: PropTypes.arrayOf(PropTypes.string),
    planName: PropTypes.string,
    regularPrice: PropTypes.number,
  }).isRequired,
};

export default PricingCard;
