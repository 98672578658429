import WidgetMultiLayoutTabbedContent from '../../../components/widgets/WidgetMultiLayoutTabbedContent';
import tryGet from '../../../components/helpers/tryGet';

const widgetMultiLayoutTabbedContentTranslation = ({ heading, labelColor, multiValuePropTabs }) => {
  const convertColorOptions = {
    Arctic: 'arctic',
    Sunlight: 'sunlight',
    'Arc Light': 'arcLight',
  };

  const data = {
    labelColor: convertColorOptions[labelColor] || 'arcLight',
    heading: heading || '',
    labels: multiValuePropTabs.map((tab) => tab.label) || [],
    tabs:
      multiValuePropTabs.map((tab) => ({
        label: tab.label,
        heading: tab.heading,
        subHeading: tryGet(['subHeading'], tab.subHeading),
        valueProps: tab.threeLayoutValueProps.map((valueProp) => ({
          heading: valueProp.heading,
          body: tryGet(['body'], valueProp.body),
          link: tryGet(['link'], valueProp.link),
        })),
      })) || [],
  };

  return { data, WidgetComponent: WidgetMultiLayoutTabbedContent };
};

export default widgetMultiLayoutTabbedContentTranslation;
