import PropTypes from 'prop-types';
import React from 'react';
import storage from 'local-storage-fallback';

import Page from '../../../components/Page';
import getQTCSectionConfigIndividual from '../helpers/getQTCSectionConfigIndividual';
import getQTCSectionConfigCorporate from '../helpers/getQTCSectionConfigCorporate';
import translateBanner from '../../blog/translations/translateBanner';

const basePageData = {
  description: 'How to Calculate and Pay Estimated Taxes',
  footerType: 'full',
  navbarType: 'full',
  title: 'How to Calculate and Pay Estimated Taxes for Small Businesses | Bench Accounting',
  sections: [],
};

const QuarterlyTaxCalculatorPage = ({ pageContext }) => {
  const businessStructure = storage.getItem('qtcBusinessStructure');
  const isSSR = typeof window === 'undefined';
  const { pageData } = pageContext;
  const { banner, campaignId, marketoFormId, path } = pageData;
  const bannerWidgetConfig = translateBanner(banner);
  const showBusinessStructureSelector = !businessStructure;
  const showIndividualForm =
    ['Sole Prop', 'Partnership', 'S Corp'].indexOf(businessStructure) !== -1;
  const showCorporateForm = ['C Corp'].indexOf(businessStructure) !== -1;
  const pageProps = {
    ...basePageData,
    path,
    sections: [
      {
        backgroundColor: '#f6fcfd',
        columns: [],
        isFirstSection: true,
        hideOnTest: !isSSR,
        padding: 36,
      },
      {
        backgroundColor: '#f6fcfd',
        columns: [
          {
            span: 8,
            widgets: [
              {
                ...bannerWidgetConfig,
                data: {
                  ...bannerWidgetConfig.data,
                  ctaTarget: '_self',
                },
              },
            ],
          },
        ],
        isFirstSection: true,
        hideOnTest: isSSR || !showBusinessStructureSelector,
      },
      {
        ...getQTCSectionConfigIndividual({ businessStructure, campaignId, marketoFormId }),
        isFirstSection: true,
        hideOnTest: isSSR || !showIndividualForm,
      },
      {
        ...getQTCSectionConfigCorporate({ businessStructure, campaignId, marketoFormId }),
        isFirstSection: true,
        hideOnTest: isSSR || !showCorporateForm,
      },
      ...basePageData.sections,
    ],
  };
  return <Page {...pageProps} />;
};

QuarterlyTaxCalculatorPage.propTypes = {
  pageContext: PropTypes.shape({
    pageData: PropTypes.shape({
      banner: PropTypes.object,
      campaignId: PropTypes.string,
      marketoFormId: PropTypes.string,
      path: PropTypes.string,
    }),
  }).isRequired,
};

export default QuarterlyTaxCalculatorPage;
