import React from 'react';

import Page from '../../../components/Page';
import colors from '../../../ui/constants/colors';
import WidgetCallToAction from '../../../components/widgets/WidgetCallToAction';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';

const pageData = {
  description: 'Job opening not found.',
  enableAbTesting: false,
  footerType: 'full',
  navbarAlwaysOpaque: true,
  navbarType: 'full',
  path: '/careers/job/',
  title: 'Job Opening Not Found | Careers at Bench',
  sections: [
    {
      backgroundColor: '#F6F6F6',
      columns: [
        {
          span: 12,
          widgets: [
            {
              data: {
                header: 'Job Opening Not Found',
                headerFontSize: 36,
                headerTag: 'h1',
                marginAfterHeader: 0,
                textAlignment: 'center',
              },
              marginBottom: 0,
              WidgetComponent: WidgetValueProp,
            },
            {
              data: {
                backgroundColor: 'transparent',
                buttonAlignment: 'center',
                buttonText: '← All Job Openings',
                linkURL: '/careers/#current_openings',
                textColor: colors.water,
              },
              margin: 0,
              WidgetComponent: WidgetCallToAction,
            },
          ],
        },
      ],
      paddingDesktop: 16,
      paddingMobile: 8,
    },
  ],
};

const JobNotFoundPage = () => <Page {...pageData} />;

export default JobNotFoundPage;
