import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  margin-bottom: -4px;
`;

const ApprovalCheckmark = () => (
  <StyledSvg
    height="22px"
    version="1.1"
    viewBox="0 0 22 22"
    width="22px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Group</title>
    <g fill="none" id="Closing-bio" stroke="none">
      <g id="Link-name" transform="translate(-779.000000, -676.000000)">
        <g id="Group" transform="translate(779.000000, 676.000000)">
          <g id="checkmark-(2)">
            <path
              d="M7.41592809,0.678059775 C6.34638202,1.08343079 5.80164719,2.14611236 4.92169663,2.80588989 C4.05529213,3.45550787 2.81728539,3.55786966 2.16101303,4.4824382 C1.55354112,5.3382382 1.83172989,6.45996629 1.52014809,7.41573034 C1.13685124,8.59146966 0.0534053708,9.5896764 0.0534053708,10.8764045 C0.0534053708,12.0768135 1.01185416,13.0224921 1.43031393,14.0898876 C1.80093236,15.0352449 1.53961191,16.1505461 2.02177775,17.0426337 C2.62482247,18.1583798 3.98271685,18.2428944 4.92169663,18.9469191 C5.80164719,19.6066966 6.34638202,20.6693708 7.41592809,21.0747393 C8.58128539,21.5164449 9.70968764,20.8498697 10.8764045,20.8498697 C12.0431213,20.8498697 13.1715236,21.5164449 14.3368809,21.0747393 C15.406427,20.6693708 15.9511618,19.6066966 16.8311124,18.9469191 C17.7700921,18.2429191 19.1280112,18.1583551 19.7310337,17.0426337 C20.2132045,16.1505213 19.9518742,15.0352449 20.3224876,14.0898876 C20.7409573,13.0224921 21.6994157,12.0768135 21.6994157,10.8764045 C21.6994157,9.5896764 20.6159528,8.59146966 20.2326584,7.41573034 C19.9210742,6.45996629 20.1992629,5.3382382 19.591791,4.4824382 C18.9355236,3.55786966 17.6975169,3.45548315 16.8311124,2.80588989 C15.9511618,2.14611236 15.406427,1.08343079 14.3368809,0.678059775 C13.1715236,0.236371461 12.0431213,0.902934382 10.8764045,0.902934382 C9.70968764,0.902934382 8.58128539,0.236373438 7.41592809,0.678059775 Z"
              fill="#098B8C"
              id="Path-1"
            />
            <path
              d="M18.2921348,3.46067416 L18.5393258,3.70786517 L18.2921348,3.46067416 Z"
              fill="#008080"
              id="Path-2"
            />
            <path
              d="M9.7774424,4.31564499 C6.74534996,4.88075458 4.24688989,7.57850627 4.24688989,10.7229411 C4.24688989,14.4587596 7.96200656,17.829953 11.7163374,17.1302372 C14.7484298,16.5651276 17.2468899,13.8673759 17.2468899,10.7229411 C17.2468899,6.98712262 13.5317732,3.61592916 9.7774424,4.31564499 Z"
              fill="#FFFFFF"
              id="Path-4"
            />
            <path
              d="M4.4494382,8.8988764 L4.69662921,9.9988764 L4.4494382,8.8988764 M12.1123596,8.8988764 L12.3595506,9.9988764 L12.1123596,8.8988764 M17.0561798,8.8988764 L17.3033708,9.9988764 L17.0561798,8.8988764 Z"
              fill="#008080"
              id="Shape-1"
            />
            <path
              d="M0.494382022,9.14606742 L0.741573034,9.39325843 L0.494382022,9.14606742 M21.011236,9.14606742 L21.258427,9.39325843 L21.011236,9.14606742 M7.16853933,9.39325843 L7.41573034,9.64044944 L7.16853933,9.39325843 M13.5955056,10.6292135 L13.8426966,10.8764045 L13.5955056,10.6292135 Z"
              fill="#00AA55"
              id="Shape-2"
            />
            <path
              d="M13.3483146,10.8764045 L13.5955056,11.1235955 L13.3483146,10.8764045 Z"
              fill="#008080"
              id="Path-5"
            />
            <path
              d="M0.494382022,12.3595506 L0.741573034,13.4595506 L0.494382022,12.3595506 M21.011236,12.3595506 L21.258427,13.4595506 L21.011236,12.3595506 Z"
              fill="#00AA55"
              id="Shape-3"
            />
            <path
              d="M4.4494382,12.6067416 L4.69662921,12.8539326 L4.4494382,12.6067416 Z"
              fill="#008080"
              id="Path-6"
            />
            <path
              d="M11.3707865,12.6067416 L11.6179775,12.8539326 L11.3707865,12.6067416 Z"
              fill="#00AA55"
              id="Path-7"
            />
            <path
              d="M17.0561798,12.6067416 L17.3033708,12.8539326 L17.0561798,12.6067416 M6.92134831,16.0674157 L7.16853933,16.3146067 L6.92134831,16.0674157 M14.5842697,16.0674157 L14.8314607,16.3146067 L14.5842697,16.0674157 M1.73033708,16.8089888 L1.97752809,17.0561798 L1.73033708,16.8089888 Z"
              fill="#008080"
              id="Shape-4"
            />
            <path
              d="M8.15730337,16.8089888 L8.40449438,17.9089888 L8.15730337,16.8089888 M13.3483146,16.8089888 L13.5955056,17.9089888 L13.3483146,16.8089888 Z"
              fill="#00AA55"
              id="Shape-5"
            />
            <path
              d="M19.7752809,16.8089888 L20.0224719,17.0561798 L19.7752809,16.8089888 Z"
              fill="#008080"
              id="Path-8"
            />
          </g>
          <polyline
            id="Path-3"
            points="8 10.6292135 9.94745997 12.8539326 14.2045799 9.13483146"
            stroke="#098B8C"
          />
        </g>
      </g>
    </g>
  </StyledSvg>
);

export default ApprovalCheckmark;
