import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';
import storage from 'local-storage-fallback';

import LinkButton from '../../ui/buttons/LinkButton';
import Spacer from '../../ui/Spacer';
import colors from '../../ui/constants/colors';

const reactSelectStyles = {
  control: (provided) => ({
    ...provided,
    background: colors.white,
    borderColor: colors.white,
    borderRadius: '4px',
    boxShadow: 'none',
    color: colors.cloud,
    fontSize: '14px',
    height: '50px',
    paddingLeft: '16px',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menuList: (provided) => ({
    ...provided,
    background: colors.white,
    borderRadius: '4px',
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: isFocused ? '#F6F6F6' : 'transparent',
    color: colors.cloud,
    fontSize: '14px',
    padding: '12px 26px',
    '&:hover': {
      backgroundColor: '#F6F6F6',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
    color: colors.cloud,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.charcoal,
  }),
};

const SelectBusinessStructure = ({
  buttonBackgroundColor,
  buttonBorderColor,
  buttonText,
  buttonTextColor,
  ctaTarget,
  linkURL,
}) => {
  const [qtcBusinessStructure, setQtcBusinessStructure] = useState(undefined);

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setQtcBusinessStructure(selectedOption.value);
    } else {
      setQtcBusinessStructure(undefined);
    }
  };

  const handleCTAClick = () => {
    if (qtcBusinessStructure) {
      storage.setItem('qtcBusinessStructure', qtcBusinessStructure);
    } else {
      storage.removeItem('qtcBusinessStructure');
    }
  };

  return (
    <div>
      <div>What’s your business structure?</div>
      <Spacer marginBottom={2} marginTop={1}>
        <Select
          isSearchable={false}
          menuPortalTarget={typeof document === 'undefined' ? undefined : document.body}
          onChange={handleSelectChange}
          options={[
            { label: 'Sole Prop', value: 'Sole Prop' },
            { label: 'Partnership', value: 'Partnership' },
            { label: 'S Corp', value: 'S Corp' },
            { label: 'C Corp', value: 'C Corp' },
          ]}
          placeholder="Select"
          styles={reactSelectStyles}
        />
      </Spacer>
      <div>
        <LinkButton
          backgroundColor={buttonBackgroundColor}
          borderColor={buttonBorderColor}
          linkURL={linkURL}
          onClick={handleCTAClick}
          target={ctaTarget}
          textColor={buttonTextColor}
        >
          {buttonText}
        </LinkButton>
      </div>
    </div>
  );
};

SelectBusinessStructure.propTypes = {
  buttonBackgroundColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonTextColor: PropTypes.string,
  ctaTarget: PropTypes.string.isRequired,
  linkURL: PropTypes.string.isRequired,
};

SelectBusinessStructure.defaultProps = {
  buttonBackgroundColor: undefined,
  buttonBorderColor: undefined,
  buttonTextColor: undefined,
};

export default SelectBusinessStructure;
