import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import colors from '../../ui/constants/colors';

import config from '../../config';
import WidgetForm from './WidgetForm';
import { WebsitePageContext } from '../WebsitePage';

export const trackSubmit = () => {
  const properties = {
    currentHref: window.location.href,
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    pageXOffset: window.pageXOffset,
    pageYOffset: window.pageYOffset,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
  };

  if (window.analytics) {
    window.analytics.track('Website Signup Form Submitted', properties);
  }
};

export const handleSubmit = (data, flow, signUpFunnel) => {
  if (!data) return;

  const urlData = { ...data, ...queryString.parse(window.location.search) };

  if (flow) {
    urlData.flow = flow;
  }

  if (window.analytics) {
    trackSubmit();
  }

  if (signUpFunnel === 'Airkit - Partners') {
    window.location.href = `${config.SIGNUP_AIRKIT_PARTNER_URL}?${queryString.stringify(urlData)}`;
    return;
  }

  if (signUpFunnel === 'Airkit - Paid') {
    window.location.href = `${config.SIGNUP_AIRKIT_PAID_URL}?${queryString.stringify(urlData)}`;
    return;
  }

  // signup is at signup.10sheet.ca when current location is 10sheet.ca/signup
  const signupAppUrl = `${window.location.protocol}//signup.${window.location.hostname}`;
  window.location.href = `${signupAppUrl}?${queryString.stringify(urlData)}`;
};

const WidgetSignupForm = ({
  flow,
  buttonBackgroundColor,
  buttonText,
  buttonTextColor,
  fields,
  horizontal,
  theme,
  campaignId,
  signUpFunnel,
}) => (
  <WebsitePageContext.Consumer>
    {(pageData) => (
      <WidgetForm
        buttonBackgroundColor={buttonBackgroundColor}
        buttonText={buttonText}
        buttonTextColor={buttonTextColor}
        campaignId={campaignId || (pageData && pageData.campaignId)}
        fields={fields}
        handleSubmit={(data) => handleSubmit(data, flow, signUpFunnel)}
        horizontal={horizontal}
        pageData={pageData}
        theme={theme}
      />
    )}
  </WebsitePageContext.Consumer>
);

WidgetSignupForm.propTypes = {
  buttonBackgroundColor: PropTypes.string,
  buttonText: PropTypes.string,
  buttonTextColor: PropTypes.string,
  campaignId: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.string),
  flow: PropTypes.string,
  horizontal: PropTypes.bool,
  signUpFunnel: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

WidgetSignupForm.defaultProps = {
  buttonBackgroundColor: colors.water,
  buttonText: 'Start a Free Trial',
  buttonTextColor: colors.pearl,
  campaignId: undefined,
  fields: ['fullName', 'email'],
  flow: null,
  horizontal: false,
  theme: 'white',
};

export default WidgetSignupForm;
