import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import Link from '../../ui/buttons/Link';
import { mediaQueries } from '../../ui/constants/grid';

const NavDropdownLinkWrapper = styled.div`
  ${mediaQueries.mobile`
    border-bottom: 1px solid ${colors.stone};
    
    &:last-child {
      border-bottom: none;
    }
  `};

  color: ${colors.charcoal};
  font-size: 15px;
  padding: 9px 0;
  width: 100%;

  // added a class because the only way we can override the a tag styling in the NavLinkContainer
  // was to get more specific or do the horrible !important
  .nav-dropdown-link {
    color: ${colors.water};
    font-weight: bold;
    padding: 0 0 4px 0;
    width: 100%;
  }
`;

const NavDropdownLinks = ({ dropdownLinks }) =>
  dropdownLinks.map(({ dropdownLinkText, dropdownLinkDescription, dropdownLinkURL }) => (
    <NavDropdownLinkWrapper key={dropdownLinkText}>
      <Link className="nav-dropdown-link" linkURL={dropdownLinkURL}>
        {dropdownLinkText}
      </Link>
      <div>{dropdownLinkDescription}</div>
    </NavDropdownLinkWrapper>
  ));

NavDropdownLinks.propTypes = {
  dropdownLinks: PropTypes.arrayOf(
    PropTypes.shape({
      dropdownLinkDescription: PropTypes.string,
      dropdownLinkText: PropTypes.string,
      dropdownLinkURL: PropTypes.string,
    }),
  ).isRequired,
};

export default NavDropdownLinks;
