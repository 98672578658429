import WidgetCallToAction from '../../../components/widgets/WidgetCallToAction';

const widgetCallToActionButtonTranslation = ({
  buttonAlignmentInColumn,
  buttonBackgroundColor,
  buttonBorderColor,
  buttonHoverColor,
  buttonHoverTextColor,
  buttonText,
  buttonTextColor,
  linkUrl,
}) => {
  const translatedData = {
    backgroundColor: buttonBackgroundColor,
    borderColor: buttonBorderColor,
    buttonAlignment: buttonAlignmentInColumn,
    buttonText,
    hoverColor: buttonHoverColor,
    hoverTextColor: buttonHoverTextColor,
    linkURL: linkUrl,
    textColor: buttonTextColor,
  };

  return {
    data: translatedData,
    WidgetComponent: WidgetCallToAction,
  };
};

export default widgetCallToActionButtonTranslation;
