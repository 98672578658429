import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import grid, { mediaQueries } from '../../ui/constants/grid';
import Image from '../../ui/images/Image';

const BACKGROUND_IMAGE_POSITION_LEFT = 'left';
const BACKGROUND_IMAGE_POSITION_RIGHT = 'right';

const FullBleedContainer = styled.div`
  margin: 0 -${grid.gutterWidth}px;
  && img,
  && picture {
    display: block;
  }

  && img {
    max-width: none;
    width: 100%;
  }

  ${mediaQueries.desktop`
    bottom: 0;
    left: ${({ imagePosition }) =>
      imagePosition === BACKGROUND_IMAGE_POSITION_LEFT || !imagePosition ? 0 : '50%'};
    margin: 0;
    overflow: hidden;
    position: absolute;
    right: ${({ imagePosition }) =>
      imagePosition === BACKGROUND_IMAGE_POSITION_RIGHT || !imagePosition ? 0 : '50%'};
    top: 0;

    && img {
      height: 100%;
      left: 50%;
      max-height: none;
      min-height: none;
      min-width: none;
      max-width: none;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: auto;
    }
  `}
`;

FullBleedContainer.propTypes = {
  imagePosition: PropTypes.oneOf([BACKGROUND_IMAGE_POSITION_LEFT, BACKGROUND_IMAGE_POSITION_RIGHT]),
};

FullBleedContainer.defaultProps = {
  imagePosition: null,
};

const WidgetFullBleedImage = ({
  alt,
  autoFormat,
  className,
  fromContentful,
  height,
  imagePosition,
  isAboveFold,
  maxWidth,
  mobileMaxWidth,
  mobileNaturalHeight,
  mobileNaturalWidth,
  mobileSrc,
  naturalHeight,
  naturalWidth,
  noRetina,
  src,
  webp,
}) => (
  <FullBleedContainer imagePosition={imagePosition}>
    <Image
      alt={alt}
      autoFormat={autoFormat}
      className={className}
      fromContentful={fromContentful}
      height={height}
      isAboveFold={isAboveFold}
      maxWidth={maxWidth}
      mobileMaxWidth={mobileMaxWidth}
      mobileNaturalHeight={mobileNaturalHeight}
      mobileNaturalWidth={mobileNaturalWidth}
      mobileSrc={mobileSrc}
      naturalHeight={naturalHeight}
      naturalWidth={naturalWidth}
      noRetina={noRetina}
      src={src}
      webp={webp}
    />
  </FullBleedContainer>
);

WidgetFullBleedImage.noContainer = true;

WidgetFullBleedImage.propTypes = {
  alt: PropTypes.string,
  autoFormat: PropTypes.bool,
  className: PropTypes.string,
  fromContentful: PropTypes.bool,
  height: PropTypes.number,
  imagePosition: PropTypes.oneOf([BACKGROUND_IMAGE_POSITION_LEFT, BACKGROUND_IMAGE_POSITION_RIGHT]),
  isAboveFold: PropTypes.bool,
  maxWidth: PropTypes.number,
  mobileMaxWidth: PropTypes.number,
  mobileNaturalHeight: PropTypes.number,
  mobileNaturalWidth: PropTypes.number,
  mobileSrc: PropTypes.string,
  naturalHeight: PropTypes.number,
  naturalWidth: PropTypes.number,
  noRetina: PropTypes.bool,
  src: PropTypes.string.isRequired,
  webp: PropTypes.bool,
};

WidgetFullBleedImage.defaultProps = {
  alt: null,
  autoFormat: false,
  className: null,
  fromContentful: false,
  height: null,
  imagePosition: null,
  isAboveFold: false,
  maxWidth: null,
  mobileMaxWidth: null,
  mobileNaturalHeight: null,
  mobileNaturalWidth: null,
  mobileSrc: null,
  naturalHeight: null,
  naturalWidth: null,
  noRetina: false,
  webp: false,
};

export default WidgetFullBleedImage;
