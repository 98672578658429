const pricingData = [
  {
    discountedPrice: 249,
    isEssentialPlan: true,
    isPlanOne: true,
    planName: 'Essential',
    planDescription: 'Bookkeeping powered by real humans.',
    planDetails: [
      'Monthly bookkeeping',
      'Dedicated bookkeeping team',
      'Year-end tax ready financial statements',
      'Direct, unlimited communication with  our in-house research team',
    ],
    regularPrice: 299,
    payAsYouGoDiscountedPrice: 349,
  },
  {
    discountedPrice: 399,
    isEssentialPlan: false,
    isPlanOne: false,
    planName: 'Premium',
    planDescription: 'Bookkeeping and taxes done for you.',
    planDetails: [
      'Everything in Essential plan',
      'Unlimited income tax support',
      'Annual income tax filing for businesses (partnerships, S corps, C corps)',
      'Annual income tax filing for individuals (sole proprietors, contractors)',
    ],
    regularPrice: 499,
  },
];

export default pricingData;
