import PropTypes from 'prop-types';
import React from 'react';

import colors from '../../ui/constants/colors';
import footerLinks from './footerLinks';
import FullFooter from './FullFooter';
import MinimalFooter from './MinimalFooter';

const Footer = ({ backgroundColor, footerType }) => {
  if (footerType === 'hide') {
    return null;
  }
  if (footerType === 'minimal') {
    return <MinimalFooter backgroundColor={backgroundColor} />;
  }

  return (
    <FullFooter
      backgroundColor={backgroundColor}
      footerLinks={footerType === 'full' ? footerLinks : []}
    />
  );
};

Footer.propTypes = {
  backgroundColor: PropTypes.string,
  footerType: PropTypes.oneOf(['full', 'simple', 'minimal', 'hide']),
};

Footer.defaultProps = {
  backgroundColor: colors.white,
  footerType: 'simple',
};

export default Footer;
