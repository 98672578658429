const widont = (str) => {
  if (str.indexOf(' ') === -1) {
    return str;
  }
  const words = str.split(' ');
  const allButLastWord = words.slice(0, words.length - 1).join(' ');
  const lastWord = words[words.length - 1];
  return `${allButLastWord}\u00A0${lastWord}`;
};

export default widont;
