import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../ui/constants/colors';
import PricingCardV2 from '../pricing/PricingCardV2';
import SocialIcon from '../SocialIcon';
import {
  AnnualContractIconWrapper,
  CardLayout,
  FreeMonthsText,
  PaymentInterval,
  PaymentIntervalAndSavingsWrapper,
  PaymentIntervalHeader,
  PaymentIntervalOptions,
  PaymentIntervalWrapper,
  PaymentOptionsButton,
  PlanAnnualPaymentOption,
  PlanMonthlyPaymentOption,
  PlanPercentageSavingsText,
  WidgetWrapper,
} from './WidgetPricingCards.styles';

const PlanPercentageSavingsTextV2 = styled(PlanPercentageSavingsText)`
  color: ${({ isAnnual }) => (isAnnual ? colors.deepSapphire : colors.tulleGrey)};
  align-self: flex-start;
`;

const WidgetPricingCardsV2 = ({ planOne, planTwo, savings, numberOfFreeMonths }) => {
  const [isAnnual, setIsAnnual] = useState(true);

  const renderFreeMonthsText = (freeMonths) => {
    if (!freeMonths) return null;
    if (!!freeMonths && freeMonths === 'one') {
      return <FreeMonthsText isAnnual={isAnnual}>+ {freeMonths} month free</FreeMonthsText>;
    }
    return <FreeMonthsText isAnnual={isAnnual}>+ {freeMonths} months free</FreeMonthsText>;
  };

  return (
    <WidgetWrapper>
      <PaymentInterval>
        <PaymentIntervalHeader>Choose Plan</PaymentIntervalHeader>
        <PaymentIntervalWrapper isV2PricingCard>
          <PaymentIntervalOptions isV2OrV3PricingCard>
            <PaymentIntervalAndSavingsWrapper>
              <PaymentOptionsButton
                aria-label="Annual contract button"
                checked={isAnnual}
                name="plan"
                onChange={() => setIsAnnual(true)}
                type="radio"
                value="Annual contract"
              />
              <AnnualContractIconWrapper>
                <PlanAnnualPaymentOption isAnnual={isAnnual}>
                  Annual contract
                </PlanAnnualPaymentOption>
                <SocialIcon
                  color="fff"
                  height={20}
                  type={isAnnual ? 'presentActive' : 'presentInactive'}
                />
              </AnnualContractIconWrapper>
            </PaymentIntervalAndSavingsWrapper>
            {savings && (
              <PlanPercentageSavingsTextV2 isAnnual={isAnnual}>
                Save up to {savings}%
              </PlanPercentageSavingsTextV2>
            )}
          </PaymentIntervalOptions>
          {renderFreeMonthsText(numberOfFreeMonths)}
          <PaymentIntervalOptions>
            <PaymentOptionsButton
              aria-label="Pay as you go button"
              name="plan"
              onChange={() => setIsAnnual(false)}
              type="radio"
              value="Pay as you go"
            />
            <PlanMonthlyPaymentOption isAnnual={isAnnual}>Pay as you go</PlanMonthlyPaymentOption>
          </PaymentIntervalOptions>
        </PaymentIntervalWrapper>
      </PaymentInterval>
      <CardLayout>
        <PricingCardV2 data={planOne} isAnnual={isAnnual} />
        <PricingCardV2 data={planTwo} isAnnual={isAnnual} />
      </CardLayout>
    </WidgetWrapper>
  );
};

WidgetPricingCardsV2.propTypes = {
  numberOfFreeMonths: PropTypes.string.isRequired,
  planOne: PropTypes.object.isRequired,
  planTwo: PropTypes.object.isRequired,
  savings: PropTypes.number.isRequired,
};

export default WidgetPricingCardsV2;
