import PropTypes from 'prop-types';
import React from 'react';
import storage from 'local-storage-fallback';

import translatePageData from '../translations/translatePageData';
import WebsitePage from '../../../components/WebsitePage';

import '../../../scss/styles-website.scss';

const DynamicPage = ({ pageContext }) => {
  const { dynamicPageData } = pageContext;
  const pageData = translatePageData(dynamicPageData);

  if (pageData.partnerId) {
    storage.setItem('partnerId', pageData.partnerId);
  }
  if (pageData.partnerAccount) {
    storage.setItem('partnerAccount', pageData.partnerAccount);
  }
  if (pageData.campaignId) {
    storage.setItem('campaignId', pageData.campaignId);
  }

  return <WebsitePage {...pageData} />;
};

DynamicPage.propTypes = {
  pageContext: PropTypes.shape({
    dynamicPageData: PropTypes.shape({
      campaignId: PropTypes.string,
      description: PropTypes.string,
      enableAbTesting: PropTypes.bool,
      footerType: PropTypes.oneOf(['full', 'simple']),
      id: PropTypes.string,
      navbarType: PropTypes.oneOf(['full', 'simple']),
      partnerAccount: PropTypes.string,
      partnerId: PropTypes.string,
      path: PropTypes.string,
      robotMetaContent: PropTypes.oneOf(['index, follow', 'noindex, follow']),
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          addThematicBreak: PropTypes.bool,
          backgroundColor: PropTypes.string,
          columnGutter: PropTypes.bool,
          columnHorizontalAlignment: PropTypes.oneOf(['left', 'center', 'right']),
          columns: PropTypes.arrayOf(
            PropTypes.shape({
              backgroundColor: PropTypes.string,
              borderRadius: PropTypes.string,
              boxShadow: PropTypes.string,
              height: PropTypes.string,
              padding: PropTypes.string,
              span: PropTypes.number,
              spanMobile: PropTypes.number,
              widgets: PropTypes.arrayOf(PropTypes.object),
            }),
          ),
          columnVerticalAlignment: PropTypes.oneOf(['top', 'middle', 'bottom']),
          hash: PropTypes.string,
          id: PropTypes.string,
          paddingBottom: PropTypes.oneOf(['full', 'half', 'none']),
          paddingTop: PropTypes.oneOf(['full', 'half', 'none']),
          reverseOrderOnDesktop: PropTypes.bool,
          thematicBreakColor: PropTypes.string,
        }),
      ),
      socialImage: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      title: PropTypes.string,
      transparentNavbarBenchColor: PropTypes.string,
      transparentNavbarTextColor: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default DynamicPage;
