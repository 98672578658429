import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const WidgetCanonicalTag = ({ canonicalUrl }) => (
  <Helmet>
    <link href={canonicalUrl} rel="canonical" />
  </Helmet>
);

export default WidgetCanonicalTag;

WidgetCanonicalTag.propTypes = {
  canonicalUrl: PropTypes.string.isRequired,
};
