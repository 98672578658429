import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import { mediaQueries } from '../../ui/constants/grid';

const Card = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  box-shadow: 1px 1px 18px 0rem rgba(0, 0, 0, 0.13);
  padding: 32px 24px 24px;

  ${mediaQueries.desktop`
    padding-left: 40px;
    padding-right: 40px;
  `};
`;

export default Card;
