import WidgetImage from '../../../components/widgets/WidgetImage';

const formPageLogoWidgetConfig = {
  data: {
    alt: 'Bench Logo',
    linkURL: '/',
    imageAlignment: 'left',
    isAboveFold: true,
    mobileImageAlignment: 'center',
    maxWidth: 130,
    mobileMaxWidth: 130,
    naturalWidth: 1836,
    naturalHeight: 551,
    src: '/img/logos/bench-logo.png',
  },
  marginBottomDesktop: 5,
  marginBottomMobile: 3,
  WidgetComponent: WidgetImage,
};

export default formPageLogoWidgetConfig;
