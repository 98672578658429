import PropTypes from 'prop-types';
import React from 'react';

import blogSignupSectionProps from '../helpers/blogSignupSectionProps';
import colors from '../../../ui/constants/colors';
import Page from '../../../components/Page';
import translateWidget from '../translations/translateWidget';
import WidgetBackLink from '../../../components/widgets/WidgetBackLink';
import WidgetCardList from '../../../components/widgets/WidgetCardList';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';
import WidgetCategoryNav from '../../../components/widgets/WidgetCategoryNav';
import blogCategories from '../helpers/blogCategories';

import '../../../scss/styles-website.scss';

const getPageProps = ({ cards, metaDescription, pageDescription, path, title }) => {
  return {
    description: metaDescription,
    footerType: 'full',
    navbarType: 'full',
    path,
    sections: [
      {
        backgroundColor: '#F6F6F6',
        columns: [
          {
            span: 12,
            widgets: [
              {
                data: {
                  linkText: 'Back to Bench Blog',
                  linkURL: '/blog/',
                },
                marginBottomDesktop: 0,
                marginBottomMobile: 6,
                marginTopDesktop: 8,
                marginTopMobile: 0,
                WidgetComponent: WidgetBackLink,
              },
              {
                data: {
                  header: title,
                  headerFontColor: colors.charcoal,
                  headerFontFamily: 'LeituraDisplay',
                  headerFontSize: 50,
                  headerFontSizeMobile: 40,
                  headerFontWeight: 400,
                  headerLineHeightMobile: 50,
                  headerTag: 'h2',
                  marginAfterHeader: 0,
                  textAlignment: 'center',
                },
                marginBottom: 0,
                marginTopDesktop: 0,
                WidgetComponent: WidgetValueProp,
              },
            ],
          },
        ],
        padding: 0,
      },
      {
        backgroundColor: '#F6F6F6',
        columns: [
          {
            span: 12,
            widgets: [
              {
                data: {
                  header: pageDescription,
                  headerFontColor: colors.ash,
                  headerFontSize: 18,
                  headerFontSizeMobile: 16,
                  headerFontWeight: 400,
                  headerLineHeight: 28,
                  headerTag: 'h1',
                  textAlignment: 'center',
                },
                marginBottomDesktop: 12,
                marginBottomMobile: 4,
                marginTop: 2,
                WidgetComponent: WidgetValueProp,
              },
              {
                data: { categories: blogCategories },
                WidgetComponent: WidgetCategoryNav,
              },
            ],
          },
        ],
        padding: 0,
      },
      {
        backgroundColor: '#F6F6F6',
        columns: [
          {
            span: 12,
            widgets: [
              {
                data: {
                  cards: cards ? cards.map(translateWidget) : [],
                  layout: [{ numCards: 3 }, { numCards: 3 }, { numCards: 3 }],
                },
                WidgetComponent: WidgetCardList,
              },
            ],
          },
        ],
        padding: 0,
      },
      blogSignupSectionProps,
    ],
    title: `${title} | Bench Blog | Bench Accounting`,
  };
};

const CategoryOverview = ({ pageContext }) => {
  const { pageData } = pageContext;
  return <Page {...getPageProps(pageData)} />;
};

CategoryOverview.propTypes = {
  /** Provided by `createPage` in gatsby-node-blog.js */
  pageContext: PropTypes.shape({
    pageData: PropTypes.object,
  }).isRequired,
};

export default CategoryOverview;
