import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ArticleBody from './ArticleBody';
import colors from '../ui/constants/colors';
import UnstyledButton from '../ui/buttons/UnstyledButton';
import WidgetDetailsSummary from './widgets/WidgetDetailsSummary';

const QuestionAndAnswerWrapper = styled.div`
  background: #f9f9f9;
  border-radius: 3px;
  box-shadow: 2px 2px 4px #eeedeb;
  margin: 14px 0;
`;

const Question = styled(UnstyledButton)`
  border-radius: 3px;
  box-sizing: border-box;
  color: ${colors.ash};
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  padding: 21px 13px 21px 60px;
  position: relative;
  text-align: left;
  width: 100%;

  &::before {
    color: #0fa1a2;
    content: '+';
    font-size: 24px;
    font-weight: bold;
    height: 24px;
    left: 24px;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform-origin: center;
    transition: transform 50ms ease-out;
    ${({ open }) =>
      open
        ? `
      transform: translateY(-50%) rotate(-45deg);
    `
        : `
      transform: translateY(-50%);
    `};
    width: 24px;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(20, 110, 232, 0.5);
  }

  &:active {
    box-shadow: none;
  }
`;

const Answer = styled(ArticleBody)`
  font-size: 16px;
  line-height: 1.5;
  max-width: 527px;
  padding: 0 13px 5px 60px;

  p {
    margin: 0 0 24px;
  }
`;

const QuestionAndAnswer = ({ questionText, answerMarkdown }) => (
  <WidgetDetailsSummary
    details={answerMarkdown}
    DetailsComponent={Answer}
    summary={questionText}
    SummaryComponent={Question}
    WrapperComponent={QuestionAndAnswerWrapper}
  />
);

QuestionAndAnswer.propTypes = {
  answerMarkdown: PropTypes.string,
  questionText: PropTypes.string,
};

QuestionAndAnswer.defaultProps = {
  answerMarkdown: null,
  questionText: null,
};

export default QuestionAndAnswer;
