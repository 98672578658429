import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const HideOnTest = styled.div`
  display: none;
`;

const ExperimentHidden = ({ children, hideOnTest, testId }) => {
  if (hideOnTest) {
    return <HideOnTest className={testId}>{children}</HideOnTest>;
  }

  if (testId) {
    return <div className={testId}>{children}</div>;
  }
  return children;
};

ExperimentHidden.propTypes = {
  children: PropTypes.node.isRequired,
  hideOnTest: PropTypes.bool,
  testId: PropTypes.string,
};

ExperimentHidden.defaultProps = {
  hideOnTest: false,
  testId: null,
};

export default ExperimentHidden;
