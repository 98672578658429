import React from 'react';

import Page from '../../../components/Page';
import colors from '../../../ui/constants/colors';
import GreenhouseApplication from '../../../components/embeds/GreenhouseApplication';
import WidgetArticleBody from '../../../components/widgets/WidgetArticleBody';
import WidgetCallToAction from '../../../components/widgets/WidgetCallToAction';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';
import WidgetThematicBreak from '../../../components/widgets/WidgetThematicBreak';

const getPageData = ({ greenhouseId, jobDescriptionHTML, jobTitle, path }) => ({
  description:
    'Bench is a design focused tech company that specializes in online bookkeeping ' +
    'for small businesses. Browse and apply for our job openings here.',
  enableAbTesting: false,
  footerType: 'full',
  navbarAlwaysOpaque: true,
  navbarType: 'full',
  path,
  title: jobTitle ? `${jobTitle} | Careers at Bench` : 'Careers at Bench',
  sections: [
    {
      backgroundColor: '#F6F6F6',
      columns: [
        {
          span: 12,
          widgets: [
            {
              data: {
                header: jobTitle,
                headerFontSize: 36,
                headerFontSizeMobile: 24,
                headerLineHeight: 50,
                headerLineHeightMobile: 32,
                headerTag: 'h1',
                marginAfterHeader: 0,
                textAlignment: 'center',
              },
              marginBottom: 0,
              WidgetComponent: WidgetValueProp,
            },
            {
              data: {
                backgroundColor: 'transparent',
                buttonAlignment: 'center',
                buttonText: '← All Job Openings',
                linkURL: '/careers/#current_openings',
                textColor: colors.water,
              },
              margin: 0,
              WidgetComponent: WidgetCallToAction,
            },
          ],
        },
      ],
      paddingDesktop: 8,
      paddingMobile: 4,
    },
    {
      backgroundColor: colors.white,
      columns: [
        {
          span: 7,
          widgets: [
            {
              data: {
                markdown: jobDescriptionHTML,
                path,
              },
              margin: 0,
              WidgetComponent: WidgetArticleBody,
            },
            {
              data: {
                color: 'black',
              },
              margin: 7,
              WidgetComponent: WidgetThematicBreak,
            },
            {
              data: {
                greenhouseId,
              },
              margin: 0,
              WidgetComponent: GreenhouseApplication,
            },
          ],
        },
      ],
      padding: 7,
    },
    {
      backgroundColor: '#E4EFEF',
      columns: [
        {
          span: 12,
          widgets: [
            {
              data: {
                description: 'Take a look at what else is open.',
                fontColor: colors.ash,
                header: 'Looking for something different?',
                headerFontSize: 32,
                headerFontSizeMobile: 24,
                headerLineHeight: 40,
                headerLineHeightMobile: 32,
                textAlignment: 'center',
              },
              marginBottom: 0,
              WidgetComponent: WidgetValueProp,
            },
            {
              data: {
                backgroundColor: 'transparent',
                buttonAlignment: 'center',
                buttonText: '← All Job Openings',
                linkURL: '/careers/#current_openings',
                textColor: colors.water,
              },
              margin: 0,
              WidgetComponent: WidgetCallToAction,
            },
          ],
        },
      ],
      paddingDesktop: 5,
      paddingMobile: 3,
    },
  ],
});

const JobPage = (props) => <Page {...getPageData(props)} />;

export default JobPage;
