import buildArticleUrl from '../helpers/buildArticleUrl';
import colors from '../../../ui/constants/colors';
import getDefaultContentCardImage from './getDefaultContentCardImage';
import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';
import tryGet from '../../../components/helpers/tryGet';
import WidgetContentCard from '../../../components/widgets/WidgetContentCard';

const translateGatedAssetContentCard = ({
  category,
  contentCardDescription,
  contentCardImage,
  id,
  slug,
  gatedAssetTitle,
}) => ({
  data: {
    backgroundColor: colors.sand,
    category,
    description:
      tryGet(['contentCardDescription'], contentCardDescription) || contentCardDescription,
    header: gatedAssetTitle,
    image:
      contentCardImage && contentCardImage.file && contentCardImage.file.url
        ? normalizeContentfulImageData(contentCardImage)
        : getDefaultContentCardImage(category, id),
    linkURL: buildArticleUrl({ category, slug }),
    valueAdd: 'Free Download',
  },
  id,
  WidgetComponent: WidgetContentCard,
});

export default translateGatedAssetContentCard;
