import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../ui/constants/colors';
import Navbar from './Navbar';

const StickyNavContainer = styled.div`
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
`;

const PersonyzeBanner = styled.div`
  height: ${(props) => (props.isScrolling ? 'unset' : 0)};
  overflow: ${(props) => (props.isScrolling ? 'unset' : 'hidden')};
`;

const StickyNav = ({
  buttonText,
  buttonUrl,
  navbarAlwaysOpaque,
  path,
  simpleNavbar,
  transparentNavbarBenchColor,
  transparentNavbarTextColor,
}) => {
  const [isScrolling, setIsPageScrolling] = useState(false);

  useEffect(() => {
    const onScroll = () => setIsPageScrolling(true);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <StickyNavContainer>
      {/* allows marketing to insert a top banner using Personyze */}
      <PersonyzeBanner id="personyze-top-banner" isScrolling={isScrolling} />
      <Navbar
        alwaysOpaque={navbarAlwaysOpaque}
        buttonText={buttonText}
        buttonUrl={buttonUrl}
        email={simpleNavbar ? 'help@bench.co' : null}
        full={!simpleNavbar}
        login={!simpleNavbar}
        path={path}
        transparentNavbarLogoSymbolColor={
          colors[transparentNavbarBenchColor] || transparentNavbarBenchColor || colors.mustard
        }
        transparentNavbarTextColor={
          colors[transparentNavbarTextColor] || transparentNavbarTextColor || colors.charcoal
        }
      />
    </StickyNavContainer>
  );
};

export default StickyNav;

StickyNav.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  navbarAlwaysOpaque: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  simpleNavbar: PropTypes.bool.isRequired,
  transparentNavbarBenchColor: PropTypes.string.isRequired,
  transparentNavbarTextColor: PropTypes.string.isRequired,
};
