import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import config from '../../config';
import { contentfulPrefix, contentfulQueryBuilder } from '../responsive/contentful-responsive';
import { imgixPrefix, imgixQueryBuilder } from './imgix-responsive';
import ResponsiveImage from './ResponsiveImage';

const mobileBreakpoints = [
  { name: 'imgSm', pixelMin: 0, pixelMax: 320 },
  { name: 'imgMd', pixelMin: 321, pixelMax: 375 },
  { name: 'imgLg', pixelMin: 376, pixelMax: 414 },
  { name: 'imgTablet', pixelMin: 415, pixelMax: 800 },
];
const desktopBreakpoints = [
  { name: 'imgMacbook', pixelMin: 801, pixelMax: 1440 },
  { name: 'imgDesktop', pixelMin: 1441, pixelMax: 1920 },
];
const allBreakpoints = [...mobileBreakpoints, ...desktopBreakpoints];

const makeDesktopImageProps = (imageProps) => ({
  autoFormat: imageProps.autoFormat,
  maxWidth: imageProps.maxWidth,
  naturalHeight: imageProps.naturalHeight,
  naturalWidth: imageProps.naturalWidth,
  noRetina: imageProps.noRetina,
  src: imageProps.src,
  webp: imageProps.webp,
});

const makeMobileImageProps = (imageProps) => ({
  autoFormat: imageProps.autoFormat,
  maxWidth: imageProps.mobileMaxWidth || imageProps.maxWidth,
  naturalHeight: imageProps.mobileSrc ? imageProps.mobileNaturalHeight : imageProps.naturalHeight,
  naturalWidth: imageProps.mobileSrc ? imageProps.mobileNaturalWidth : imageProps.naturalWidth,
  noRetina: imageProps.noRetina,
  src: imageProps.mobileSrc || imageProps.src,
  webp: imageProps.webp,
});

const mergePictureTagDefinitions = (mobilePTD, desktopPTD) => ({
  srcSets: [...mobilePTD.srcSets, ...desktopPTD.srcSets],
  default: desktopPTD.default,
});

const makeDefaultPictureTagDefinition = (src) => ({
  srcSets: [],
  default: src,
});

const getQueryBuilder = (fromContentful, onImgix) =>
  (fromContentful && contentfulQueryBuilder) || (onImgix && imgixQueryBuilder);

const getPrefix = (fromContentful, onImgix) =>
  (fromContentful && contentfulPrefix) || (onImgix && imgixPrefix);

const getPictureDefinition = (imageProps) => {
  const { fromContentful, src, mobileMaxWidth, mobileSrc } = imageProps;

  // If we pass paths we assume they're for imgix
  const onImgix = !/^http/.test(src);

  // We can't build responsive image queries for images hosted on unknown sites, so just return a
  // picture tag definition with a default.
  if (!fromContentful && !onImgix) {
    return makeDefaultPictureTagDefinition(src);
  }

  const queryBuilder = getQueryBuilder(fromContentful, onImgix);
  const prefix = getPrefix(fromContentful, onImgix);

  // If either a mobile source or a mobile max width is set, then source sets for mobile breakpoints
  // need to be created separately and combined with the desktop source sets.
  const shouldMakeMobilePictureTag = !!mobileSrc || !!mobileMaxWidth;

  // Get the desktop picture tag definition using appropriate breakpoints depending on whether there
  // is a mobile alternate image source.
  const desktopResponsiveImage = new ResponsiveImage(
    makeDesktopImageProps(imageProps),
    queryBuilder,
    prefix,
    shouldMakeMobilePictureTag ? desktopBreakpoints : allBreakpoints,
  );
  const desktopPictureTagDefinition = desktopResponsiveImage.getPictureTagDefinition();

  // We can stop here if there's no alternate image for mobile. The desktopPictureTagDefinition
  // contains breakpoints for both mobile and desktop when no mobileSrc is given.
  if (!shouldMakeMobilePictureTag) {
    return desktopPictureTagDefinition;
  }

  // Get the mobile picture tag definition
  const mobileResponsiveImage = new ResponsiveImage(
    makeMobileImageProps(imageProps),
    queryBuilder,
    prefix,
    mobileBreakpoints,
  );
  const mobilePictureTagDefinition = mobileResponsiveImage.getPictureTagDefinition();

  // Merge together the mobile and desktop picture tag definitions
  return mergePictureTagDefinitions(mobilePictureTagDefinition, desktopPictureTagDefinition);
};

const pageIsDoneLoading = () => {
  // Gatsby SSR has no access to window object, so check that first
  return typeof window !== 'undefined' && window.document.readyState === 'complete';
};

const StyledImage = styled.img`
  transition: opacity 0.3s;
  opacity: ${({ isHidden }) => (isHidden ? '0' : 1)};
`;

class Image extends Component {
  constructor() {
    super();
    this.state = { shouldLoadImage: false };
  }

  componentDidMount() {
    const boundImageLoadTrigger = this.triggerImageLoad.bind(this);

    if (pageIsDoneLoading()) {
      boundImageLoadTrigger();
      return;
    }

    window.addEventListener('load', boundImageLoadTrigger, false);

    // Desperate fallback in case the above fails
    setTimeout(boundImageLoadTrigger, 10000);
  }

  triggerImageLoad = () => {
    if (this.state.shouldLoadImage) return;
    this.setState({ shouldLoadImage: true });
  };

  render() {
    const {
      alt,
      autoFormat,
      className,
      fromContentful,
      height,
      isAboveFold,
      maxWidth,
      mobileMaxWidth,
      mobileNaturalHeight,
      mobileNaturalWidth,
      mobileSrc,
      naturalHeight,
      naturalWidth,
      noRetina,
      src,
      webp,
    } = this.props;

    // Throw an Error during server-side rendering if
    // src is not set and image is not from Contentful
    if (
      (typeof window === 'undefined' || typeof document === 'undefined') &&
      !src &&
      !fromContentful
    ) {
      throw new Error(
        `props.src is ${src} for the image with props: ${JSON.stringify(this.props)}`,
      );
    }

    const { shouldLoadImage } = this.state;

    const isInlined = /^data:/.test(src);
    const isSvg = /\.svg$/.test(src);
    const shouldLoadImageThisRender = isInlined || isAboveFold || shouldLoadImage;

    if (isInlined || isSvg) {
      // If an absolute path is given -- a slash followed by non-slash, such as "/img/cat.jpg" and
      // not "//host/img/cat.jpg" which is a scheme relative URL -- then use imgix domain
      const imageUrl = /^\/[^/]/.test(src) ? `${config.IMGIX_ORIGIN}${src}` : src;
      return (
        <StyledImage
          alt={alt}
          className={className}
          height={height}
          isHidden={!shouldLoadImageThisRender}
          src={shouldLoadImageThisRender ? imageUrl : null}
          width={Math.max(maxWidth, mobileMaxWidth) || null}
        />
      );
    }

    const pictureDefinition = getPictureDefinition({
      autoFormat,
      fromContentful,
      maxWidth,
      mobileMaxWidth,
      mobileNaturalHeight,
      mobileNaturalWidth,
      mobileSrc,
      naturalHeight,
      naturalWidth,
      noRetina,
      src,
      webp,
    });

    // For screens larger than the largest responsive breakpoint, apply the `srcSet` for the largest
    // breakpoint in the picture tag definition as the `srcSet` prop for the `<img>` tag.
    const largestBreakpointSrcSet = pictureDefinition.srcSets.reduce((currentLargest, srcSet) => {
      return !currentLargest || srcSet.breakpoint.pixelMax > currentLargest.breakpoint.pixelMax
        ? srcSet
        : currentLargest;
    }, null);
    const imgSrcSet = largestBreakpointSrcSet && largestBreakpointSrcSet.srcSet;
    return (
      <picture className={className}>
        {pictureDefinition.srcSets.map(({ media, srcSet }) => (
          <source
            key={`${srcSet}${media}`}
            media={media}
            srcSet={shouldLoadImageThisRender ? srcSet : null}
          />
        ))}
        {isAboveFold && (
          <Helmet>
            {pictureDefinition.srcSets.map(({ media, srcSet }) => {
              const href = srcSet.split(',')[0];
              return (
                <link as="image" href={href} imagesrcset={srcSet} media={media} rel="preload" />
              );
            })}
          </Helmet>
        )}
        <StyledImage
          alt={alt}
          isHidden={!shouldLoadImageThisRender}
          src={shouldLoadImageThisRender ? pictureDefinition.default : null}
          srcSet={shouldLoadImageThisRender ? imgSrcSet : null}
        />
      </picture>
    );
  }
}

Image.propTypes = {
  alt: PropTypes.string,
  autoFormat: PropTypes.bool,
  className: PropTypes.string,
  fromContentful: PropTypes.bool,
  height: PropTypes.number,
  isAboveFold: PropTypes.bool,
  maxWidth: PropTypes.number,
  mobileMaxWidth: PropTypes.number,
  mobileNaturalHeight: PropTypes.number,
  mobileNaturalWidth: PropTypes.number,
  mobileSrc: PropTypes.string,
  naturalHeight: PropTypes.number,
  naturalWidth: PropTypes.number,
  noRetina: PropTypes.bool,
  src: PropTypes.string.isRequired,
  webp: PropTypes.bool,
};

Image.defaultProps = {
  alt: null,
  autoFormat: false,
  fromContentful: false,
  isAboveFold: false,
  height: null,
  maxWidth: null,
  mobileMaxWidth: null,
  mobileNaturalHeight: null,
  mobileNaturalWidth: null,
  mobileSrc: null,
  naturalHeight: null,
  naturalWidth: null,
  noRetina: false,
  className: null,
  webp: false,
};

export default Image;
