import { css } from 'styled-components';
import colors from '../constants/colors';

const buttonStyles = css`
  align-items: center;
  border: solid 2px;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.06em;
  line-height: 1.5rem;
  margin: 0.25rem 0;
  padding: 0.75rem 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: ${({ width }) => width};

  ${({ styleType }) =>
    styleType === 'primary' &&
    css`
      background: ${colors.electricBlue};
      border: solid 2px ${colors.electricBlue};
      color: ${colors.white};

      &:hover,
      &:focus {
        background: ${colors.midnightBlue};
        border: solid 2px ${colors.midnightBlue};
      }
    `}

  ${({ styleType }) =>
    styleType === 'secondary-black' &&
    css`
      background: transparent
      border: solid 2px ${colors.eerieBlack};
      color: ${colors.eerieBlack};

      &:hover,
      &:focus {
        color: ${colors.white};
        background: ${colors.eerieBlack};
        border: solid 2px ${colors.eerieBlack};
      }
    `};

  ${({ styleType }) =>
    styleType === 'secondary-white' &&
    css`
    background: transparent
    border: solid 2px ${colors.white};
    color: ${colors.white};

    &:hover,
    &:focus {
      color: ${colors.white};
      background: ${colors.eerieBlack};
      border: solid 2px ${colors.eerieBlack};
    }
  `};
`;

export default buttonStyles;
