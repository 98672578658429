import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import buildArticleUrl from '../helpers/buildArticleUrl';
import config from '../../../config';
import getQueryFromLocation from '../../helpers/getQueryFromLocation';
import isGatsbyLink from '../../../ui/buttons/isGatsbyLink';
import SearchResults from '../templates/SearchResults';

const getSearchQuery = () => {
  if (typeof window === 'undefined') {
    return '';
  }
  const parsedQueryString = getQueryFromLocation(window.location);
  return parsedQueryString.q || '';
};

const makeArticleSearchOptions = (query) => ({
  baseURL: `https://${config.CONTENTFUL_HOST}`,
  url: `/spaces/${config.CONTENTFUL_BLOG_SPACE_ID}/environments/master/entries`,
  params: {
    access_token: config.CONTENTFUL_BLOG_ACCESS_TOKEN,
    content_type: 'article',
    query,
    include: 2,
    select: [
      'fields.articleHeaderBackgroundColor',
      'fields.articleHeaderImage',
      'fields.category',
      'fields.contentCardDescription',
      'fields.contentCardImage',
      'fields.slug',
      'fields.title',
      'sys.id',
    ].join(),
  },
});

const findAsset = (assets, link) => {
  if (!assets || !assets.length || !link) {
    return null;
  }
  const rawAsset = assets.find((asset) => asset.sys.id === link.sys.id);
  if (!rawAsset) {
    return null;
  }
  return {
    ...rawAsset.fields,
    ...rawAsset.sys,
  };
};

const apiResponseItemToGraphQLResult =
  (assets) =>
  ({ fields, sys }) => {
    // Newly created articles in preview will have undefined fields
    if (!fields) return null;

    // Exclude all results in the "Dev" category
    if (fields.category && fields.category.toLowerCase() === 'dev') return null;

    // Exclude published pages that have not been built
    if (!isGatsbyLink(buildArticleUrl(fields))) return null;

    const articleHeaderImage = findAsset(assets, fields.articleHeaderImage);
    const contentCardImage = findAsset(assets, fields.contentCardImage);

    return {
      ...fields,
      ...sys,
      articleHeaderImage,
      contentCardDescription: fields.contentCardDescription && {
        contentCardDescription: fields.contentCardDescription,
      },
      contentCardImage,
    };
  };

const BlogSearch = ({ pageContext }) => {
  const [searchError, setSearchError] = useState(false);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  const fetchSearchResults = () => {
    const query = getSearchQuery();
    if (!query) {
      setSearchInProgress(false);
      setSearchResults(null);
      setSearchError(false);
      return;
    }

    setSearchInProgress(true);
    setSearchResults(null);

    setSearchError(false);

    axios(makeArticleSearchOptions(query))
      .then(({ data }) => {
        const { includes, items } = data;
        const assets = (includes && includes.Asset) || [];

        const filteredResults = items.map(apiResponseItemToGraphQLResult(assets)).filter((x) => x);
        setSearchInProgress(false);
        setSearchResults(filteredResults);
      })
      .catch((err) => {
        console.error(err);
        setSearchInProgress(false);
        setSearchResults(null);
        setSearchError(true);
      });
  };

  useEffect(() => {
    fetchSearchResults();
  }, []);

  const { pageData } = pageContext;
  return (
    <SearchResults
      {...pageData}
      query={getSearchQuery()}
      searchError={searchError}
      searchInProgress={searchInProgress}
      searchResults={searchResults}
    />
  );
};

BlogSearch.propTypes = {
  /** Provided by router */
  location: PropTypes.object.isRequired,
  /** Provided by `createPage` in gatsby-node-blog.js */
  pageContext: PropTypes.shape({
    pageData: PropTypes.object,
  }).isRequired,
};

export default BlogSearch;
