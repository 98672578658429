import PropTypes from 'prop-types';
import React from 'react';
import colors from '../../ui/constants/colors';
import LinkButton from '../../ui/buttons/LinkButton';
import FilledInCheckMark from '../../ui/icons/FilledInCheckMark';
import {
  AnnualContractOnlyText,
  LinkButtonWrapper,
  MonthSubText,
  PlanDetail,
  PayAsYouGoPremiumCardDisable,
  PlanDetailsCheckWrapper,
  PlanDetailsWrapper,
  PlanPrice,
  PricingCardHeadingWrapper,
  PricingCardPlanDescription,
  PricingCardPlanName,
  PriceWrapper,
  PromoCardWrapper,
  RegularPromoPrice,
  SinglePricingCard,
} from './PricingCardStyles.styles';

const PricingCardV3 = ({ data, isAnnual }) => {
  const {
    isPlanOne,
    promoPrice,
    regularPrice,
    payAsYouGoPrice,
    planName,
    planDescription,
    planBulletPoints,
    cta,
  } = data;

  const notAnnualOrPlanOne = !isAnnual && !isPlanOne;

  return (
    <SinglePricingCard isAnnual={isAnnual} isPlanOne={isPlanOne}>
      {notAnnualOrPlanOne && <PayAsYouGoPremiumCardDisable />}
      {notAnnualOrPlanOne && <AnnualContractOnlyText>Annual Contract Only</AnnualContractOnlyText>}
      <PricingCardHeadingWrapper isAnnual={isAnnual} isPlanOne={isPlanOne}>
        <PricingCardPlanName>{planName}</PricingCardPlanName>
        <PricingCardPlanDescription>{planDescription}</PricingCardPlanDescription>
        <PromoCardWrapper>
          <PriceWrapper>
            <PlanPrice>${promoPrice && isAnnual ? promoPrice : payAsYouGoPrice}</PlanPrice>
            <MonthSubText>/mo</MonthSubText>
          </PriceWrapper>
          {isAnnual && (
            <RegularPromoPrice>
              <p>reg. ${regularPrice}</p>
            </RegularPromoPrice>
          )}
        </PromoCardWrapper>
        {isAnnual ? <p>billed annually</p> : <p>billed monthly</p>}
      </PricingCardHeadingWrapper>
      <PlanDetailsWrapper isAnnual={isAnnual} isPlanOne={isPlanOne}>
        {planBulletPoints.map((detail, detailIndex) => (
          <PlanDetailsCheckWrapper key={detailIndex} isAnnual={isAnnual} isPlanOne={isPlanOne}>
            {isPlanOne ? (
              <FilledInCheckMark fill={colors.eerieBlack} />
            ) : (
              <FilledInCheckMark fill={notAnnualOrPlanOne ? colors.tulleGrey : colors.white} />
            )}
            <PlanDetail key={detailIndex}>{detail}</PlanDetail>
          </PlanDetailsCheckWrapper>
        ))}
      </PlanDetailsWrapper>
      <LinkButtonWrapper>
        {notAnnualOrPlanOne ? (
          <LinkButton
            backgroundColor={colors.lunarRays}
            borderColor={colors.charcoal}
            linkURL="/signup/"
            textColor={colors.charcoal}
            width="100%"
          >
            {cta}
          </LinkButton>
        ) : (
          <LinkButton
            backgroundColor={colors.water}
            linkURL="/signup/"
            textColor={colors.white}
            width="100%"
          >
            {cta}
          </LinkButton>
        )}
      </LinkButtonWrapper>
    </SinglePricingCard>
  );
};

PricingCardV3.propTypes = {
  data: PropTypes.shape({
    billedMonthlyPrice: PropTypes.number,
    cta: PropTypes.string,
    isPlanOne: PropTypes.bool,
    payAsYouGoMonthlyPrice: PropTypes.number,
    payAsYouGoPrice: PropTypes.number,
    planBulletPoints: PropTypes.arrayOf(PropTypes.string),
    planDescription: PropTypes.string,
    planName: PropTypes.string,
    promoPrice: PropTypes.number,
    regularPrice: PropTypes.number,
  }).isRequired,
  isAnnual: PropTypes.bool.isRequired,
};

export default PricingCardV3;
