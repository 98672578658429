import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../ui/constants/colors';
import { mediaQueries } from '../../ui/constants/grid';

import Link from '../../ui/buttons/Link';
import SocialIcon from '../SocialIcon';

const WidgetSocialShareContainer = styled.div`
  color: ${colors.ash};
  font-size: 14px;
  text-align: ${({ alignment }) => alignment};

  ${mediaQueries.mobile`
    ${({ hideOnMobile }) => hideOnMobile && `display: none;`}
  `}
`;

const ImageList = styled.ul`
  li {
    display: inline-block;
    padding: 1rem;

    :first-child {
      padding-left: ${({ alignment }) => (alignment === 'left' ? 0 : '1rem')};
    }
  }
`;

const LinkCopiedText = styled.p`
  color: ${colors.bonsai};
  font-size: 11px;
`;

const WidgetSocialShare = ({ alignment, hideOnMobile, path, twitterText }) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const copyPageURL = () => {
    navigator.clipboard.writeText(`${window.location}`);

    setIsLinkCopied(!isLinkCopied);

    setTimeout(() => {
      setIsLinkCopied(false);
    }, 2000);
  };

  const emailCurrentPage = () => {
    window.location.href = `mailto:?subject=${document.title}&body=${encodeURI(
      window.location.href,
    )}`;
  };

  const openFacebookShareDialog = (e) => {
    e.preventDefault();

    if (window.FB) {
      const normalizedPath = /^\//.test(path) ? path : `/${path}`;
      window.FB.ui({
        method: 'share',
        href: `https://bench.co/blog${normalizedPath}`,
      });
    }
  };

  const openTwitterCenteredPopup = (e) => {
    e.preventDefault();

    const width = 500;
    const height = 400;

    const left = window.innerWidth / 2 - width / 2 + window.screenX;
    const top = window.innerHeight / 2 - height / 2 + window.screenY;

    const twitterPopup = window.open(
      `http://twitter.com/share?text=${encodeURIComponent(document.title)}&url=${encodeURIComponent(
        window.location.href,
      )}`,
      'Share on Twitter',
      `scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`,
    );

    if (window.focus) {
      twitterPopup.focus();
    }
  };

  return (
    <WidgetSocialShareContainer alignment={alignment} hideOnMobile={hideOnMobile}>
      <p>Friends don&rsquo;t let friends do their own bookkeeping. Share this article.</p>
      <ImageList alignment={alignment}>
        <li>
          <Link
            aria-label="LinkedIn Icon"
            linkURL="https://www.linkedin.com/sharing/share-offsite/?url=https://bench.co/blog/bench-news/stripe-app-marketplace-launch/"
            target="_blank"
          >
            <SocialIcon color="#64c9ba" height={25} type="linkedIn" width={20} />
          </Link>
        </li>
        <li>
          <Link
            aria-label="Facebook Icon"
            linkURL="#"
            onClick={(e) => openFacebookShareDialog(e, path)}
          >
            <SocialIcon color="#64c9ba" height={17} type="facebook" width={9} />
          </Link>
        </li>
        <li>
          <Link
            aria-label="Twitter Icon"
            linkURL="#"
            onClick={(e) => openTwitterCenteredPopup(e, twitterText)}
          >
            <SocialIcon color="#64c9ba" height={14} type="twitter" width={17} />
          </Link>
        </li>
        <li>
          <Link aria-label="Email Icon" onClick={emailCurrentPage}>
            <SocialIcon color="#64c9ba" height={27} type="email" width={15} />
          </Link>
        </li>
        <li>
          <Link aria-label="Link Icon" onClick={copyPageURL}>
            {isLinkCopied ? (
              <LinkCopiedText>Copied!</LinkCopiedText>
            ) : (
              <SocialIcon color="#64c9ba" height={27} type="copyLink" width={15} />
            )}
          </Link>
        </li>
      </ImageList>
    </WidgetSocialShareContainer>
  );
};

WidgetSocialShare.propTypes = {
  alignment: PropTypes.string,
  hideOnMobile: PropTypes.bool,
  path: PropTypes.string.isRequired,
  twitterText: PropTypes.string,
};

WidgetSocialShare.defaultProps = {
  alignment: 'center',
  hideOnMobile: false,
  twitterText: null,
};

export default WidgetSocialShare;
