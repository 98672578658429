import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import colors from '../../ui/constants/colors';
import Image from '../../ui/images/Image';
import Link from '../../ui/buttons/Link';
import { mediaQueries } from '../../ui/constants/grid';
import { fontSizingCSS, pxToRem } from '../../ui/constants/typography';
import WidgetLabel from './WidgetLabel';
import widont from '../helpers/widont';

const QuoteV2Container = styled.div`
  padding: 0 40px 0 60px;

  ${mediaQueries.desktop`
    &.-large {
      margin: 0;
      padding: 0 ${(150 / 1170) * 120}%;
    }
  `};

  ${mediaQueries.mobile`
  padding: 0 08px 0 08px;
`};
`;

const QuoteV2Inner = styled.div`
  ${mediaQueries.desktop`
    .-large & {
      padding: 0 15px;
    }
  `};
`;

const Quote = styled.div`
  color: ${({ fontColor }) => fontColor || colors.charcoal};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: ${pxToRem(27)}rem;
  padding-top: 35px;
  position: relative;
  ${({ quoteTextAlign }) => quoteTextAlign && `text-align: ${quoteTextAlign}`};
  /* white-space: pre-wrap; tells the browser to honour any newlines (\n) in text */
  white-space: pre-wrap;

  ${mediaQueries.desktop`
    .-large & {
      font-size: ${pxToRem(24)}rem;
      line-height: ${pxToRem(34)}rem;
    }
  `};

  ${mediaQueries.mobile`
  ${({ mobileQuoteFontSize }) => mobileQuoteFontSize && `font-size: ${mobileQuoteFontSize}`};

`};
`;

const AuthorWrap = styled.div`
  ${fontSizingCSS(8)};
  display: flex;
  align-items: center;
  font-weight: 450;
  margin: 1rem 0;
  text-transform: uppercase;
  ${({ authorWrapPadding }) => authorWrapPadding && `padding-left: ${authorWrapPadding}`};
  text-align: left;
  /* authorWrapPadding: used to center author image, name */

  ${mediaQueries.desktop`
    ${fontSizingCSS(11)};
    letter-spacing: 1.2px;

    .-large & {
      font-weight: normal;
      min-height: 86px;
    }
  `};

  ${mediaQueries.mobile`
    letter-spacing: 0.6px;
    min-height: 65px;
    ${({ mobileAuthorWrapPadding }) =>
      mobileAuthorWrapPadding && `padding-left: ${mobileAuthorWrapPadding}`};


  `};
`;

const AuthorImage = styled.div`
  border-radius: 50%;
  margin-right: 30px;
  overflow: hidden;

  img {
    width: 100%;
  }

  ${mediaQueries.desktop`
    flex-basis: 86px;
    min-width: 86px;
    `};

  ${mediaQueries.mobile`
    flex-basis: 65px;
    min-width: 65px;
  `};
`;

const AuthorName = styled.span`
  color: ${({ fontColor }) => fontColor || colors.greenTea};
  color: ${({ highlightColor }) => highlightColor || colors.charcoal};
  font-size: 14px;

  ${mediaQueries.mobile`
  ${({ mobileAttributionFontSize }) =>
    mobileAttributionFontSize && `font-size: ${mobileAttributionFontSize}`};
`};
`;

const AuthorTitle = styled.span`
  color: ${({ fontColor }) => fontColor || colors.greenTea};
  font-size: 14px;

  ${mediaQueries.mobile`
  ${({ mobileAttributionFontSize }) =>
    mobileAttributionFontSize && `font-size: ${mobileAttributionFontSize}`};
`};
`;

const WidgetQuoteV2 = (props) => {
  const {
    author,
    authorImage,
    authorTitle,
    authorWrapPadding,
    fontColor,
    quoteFontFamily,
    quoteFontSize,
    highlightColor,
    link,
    maxWidth,
    quote,
    label,
    quoteTextAlign,
    mobileAttributionFontSize,
    mobileQuoteFontSize,
    mobileAuthorWrapPadding,
  } = props;

  return (
    <QuoteV2Container className={maxWidth > 600 ? '-large' : ''}>
      <QuoteV2Inner>
        <WidgetLabel
          alignment={label.alignment}
          color={label.color}
          size={label.size}
          style={label.style}
          text={label.text}
        />
        <Quote
          fontColor={fontColor}
          fontFamily={quoteFontFamily}
          fontSize={quoteFontSize}
          mobileQuoteFontSize={mobileQuoteFontSize}
          quoteTextAlign={quoteTextAlign}
        >
          {widont(quote)}
        </Quote>
        <AuthorWrap
          authorWrapPadding={authorWrapPadding}
          mobileAuthorWrapPadding={mobileAuthorWrapPadding}
        >
          {authorImage && (
            <AuthorImage>
              <Image {...authorImage} maxWidth={86} mobileMaxWidth={65} />
            </AuthorImage>
          )}
          {link && (
            <Link linkURL={link}>
              <AuthorName
                highlightColor={highlightColor}
                mobileAttributionFontSize={mobileAttributionFontSize}
              >
                {author}
              </AuthorName>{' '}
              <AuthorTitle
                fontColor={fontColor}
                mobileAttributionFontSize={mobileAttributionFontSize}
              >
                {authorTitle}
              </AuthorTitle>
            </Link>
          )}
          {!link && (
            <span>
              <AuthorName highlightColor={highlightColor}>{author}</AuthorName>{' '}
              <AuthorTitle fontColor={fontColor}>{authorTitle}</AuthorTitle>
            </span>
          )}
        </AuthorWrap>
      </QuoteV2Inner>
    </QuoteV2Container>
  );
};

WidgetQuoteV2.propTypes = {
  author: PropTypes.string,
  authorImage: PropTypes.shape({
    ...Image.propTypes,
  }),
  authorTitle: PropTypes.string,
  authorWrapPadding: PropTypes.string,
  fontColor: PropTypes.string,
  highlightColor: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string,
  maxWidth: PropTypes.number,
  mobileAttributionFontSize: PropTypes.string,
  mobileAuthorWrapPadding: PropTypes.string,
  mobileQuoteFontSize: PropTypes.string,
  quote: PropTypes.string,
  quoteFontFamily: PropTypes.string,
  quoteFontSize: PropTypes.number,
  quoteTextAlign: PropTypes.string,
  showQuoteIcon: PropTypes.bool,
};

WidgetQuoteV2.defaultProps = {
  author: null,
  authorImage: null,
  authorTitle: null,
  authorWrapPadding: null,
  fontColor: null,
  quoteFontFamily: null,
  quoteFontSize: 18,
  showQuoteIcon: true,
  highlightColor: null,
  label: null,
  link: null,
  maxWidth: null,
  mobileQuoteFontSize: null,
  mobileAttributionFontSize: null,
  mobileAuthorWrapPadding: null,
  quote: null,
  quoteTextAlign: null,
};

export default WidgetQuoteV2;
