import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const FallbackUILayout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const FallbackUIText = styled.div`
  font-size: 16px;
  line-height: 24px;
  max-width: 400px;
  padding-top: 14px;
  text-align: center;
`;

const FallbackUI = () => (
  <FallbackUILayout>
    <FallbackUIText>
      Oops, it looks like something went wrong. We’re working on fixing this issue as quickly as we
      can.
    </FallbackUIText>
  </FallbackUILayout>
);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    if (window.Rollbar && window.Rollbar.error) {
      window.Rollbar.error(error, { ...info });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <FallbackUI />;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
