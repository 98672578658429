import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import colors from '../../ui/constants/colors';
import Image from '../../ui/images/Image';
import LinkButton from '../../ui/buttons/LinkButton';
import serviceTypes from './serviceTypes';

const planImages = {
  Starter: {
    alt: 'Illustration of a desk and a cat',
    naturalHeight: 74,
    naturalWidth: 46,
    src: '/img/icons/c16_pricing_starter.svg',
  },
  Micro: {
    alt: 'Illustration of a small storefront',
    naturalHeight: 74,
    naturalWidth: 54,
    src: '/img/icons/c16_pricing_micro.svg',
  },
  Boutique: {
    alt: 'Illustration of a two story shop',
    naturalHeight: 74,
    naturalWidth: 68,
    src: '/img/icons/c16_pricing_boutique.svg',
  },
  Venture: {
    alt: 'Illustration of a multi-story store',
    naturalHeight: 74,
    naturalWidth: 82,
    src: '/img/icons/c16_pricing_venture.svg',
  },
  Corporate: {
    alt: 'Illustration of an office building',
    naturalHeight: 74,
    naturalWidth: 98,
    src: '/img/icons/c16_pricing_corporate.svg',
  },
};

const PricingCard = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  box-shadow: 1px 1px 18px rgba(0, 0, 0, 0.1);
  color: ${colors.charcoal};
  margin: 16px 0;
  padding: 32px 25px 16px 25px;
  position: relative;
  text-align: center;

  ${({ isBestFit }) =>
    isBestFit &&
    css`
      @media (min-width: 801px) {
        margin: -5px 0;
        position: sticky;
        transition: all 0.2s ease;
      }
    `};

  ${({ isMuted }) =>
    isMuted &&
    css`
      @media (max-width: 800px) {
        display: none;
      }

      @media (min-width: 801px) {
        &:hover {
          &::before {
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;
          }
        }

        &::before {
          background-color: rgba(246, 246, 246, 0.8);
          content: '';
          height: 100%;
          left: 0;
          opacity: 1;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    `};
`;

const BestFitHeader = styled.div`
  background: #e0b170;
  border-radius: 0 5px 5px 0;
  color: white;
  font-size: 13px;
  font-weight: bold;
  left: 0;
  padding: 6px 8px;
  position: absolute;
  top: 20px;
  width: 30%;

  @media (min-width: 801px) {
    border-radius: 5px 5px 0 0;
    font-size: 15px;
    top: 0;
    padding: 3px 0;
    width: 100%;
  }
`;

const ImageLayout = styled.div`
  align-items: flex-end;
  display: flex;
  height: 90px;
  margin: 8px auto;
  width: 70px;
`;

const PlanName = styled.h2`
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin: 16px 0 0;
`;

const PriceBilled = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
`;

const DiscountedPriceLayout = styled.div`
  text-align: center;
`;

const DiscountedDetails = styled.div`
  color: ${colors.smoke};
  font-size: 13px;
  line-height: 1.46;
`;

const DiscountedPrice = styled.div`
  color: ${colors.malachite};
  font-size: 28px;
  font-weight: bold;
  line-height: 1.14;
`;

const RegularPrice = styled.div`
  color: ${colors.smoke};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  text-decoration: line-through;
`;

const DiscountedMonthlyPrice = styled.div`
  font-size: 13px;
  line-height: 1.31;
  margin-bottom: 16px;
  margin-top: 17px;
  text-align: center;
`;

const DiscountedDetailsMonthly = styled.div`
  color: ${colors.smoke};
`;

const DiscountedBilledMonthly = styled.div`
  color: ${colors.malachite};
  font-weight: bold;
`;

const PricingDetails = styled.div`
  color: ${colors.cloud};
  font-size: 14px;
  margin: 16px 0;
`;

const PricingDetailsAnnually = styled.div`
  color: ${colors.ash};
`;

const PricingDetailsMonthly = styled.div`
  color: ${colors.dust};
`;

const ExpensesDetails = styled.div`
  color: ${colors.ash};
  font-size: 14px;
  margin: 16px 0;
`;

// Some browsers don't show the pointer on hover, so adding extra reassurance
const LinkButtonLayout = styled.div`
  cursor: pointer;
`;

const DiscountDetails = styled.div`
  color: ${colors.malachite};
  font-size: 13px;
  font-weight: bold;
  line-height: 1.31;
  margin-bottom: 16px;
  margin-top: 17px;
  text-align: center;
`;

const DiscountPricing = (props) => {
  const { annual, annualDiscount, currencySymbol, monthlyDiscount, monthsText } = props;
  return (
    <>
      <DiscountedPriceLayout>
        <DiscountedDetails>Billed annually</DiscountedDetails>
        <DiscountedPrice>
          {currencySymbol}${annualDiscount}
          /m
        </DiscountedPrice>
        <RegularPrice>
          {currencySymbol}${annual}
          /m
        </RegularPrice>
      </DiscountedPriceLayout>
      <DiscountedMonthlyPrice>
        <DiscountedDetailsMonthly>Billed monthly</DiscountedDetailsMonthly>
        <DiscountedBilledMonthly>
          {currencySymbol}${monthlyDiscount}
          /m for first {monthsText}
        </DiscountedBilledMonthly>
      </DiscountedMonthlyPrice>
    </>
  );
};

DiscountPricing.propTypes = {
  annual: PropTypes.number.isRequired,
  annualDiscount: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  monthlyDiscount: PropTypes.number.isRequired,
  monthsText: PropTypes.string.isRequired,
};

const Pricing = (props) => {
  const { annual, currencySymbol, monthly } = props;
  return (
    <>
      <PriceBilled>
        {currencySymbol}${annual}
        /m
      </PriceBilled>
      <PricingDetails>
        <PricingDetailsAnnually>If billed annually</PricingDetailsAnnually>
        <PricingDetailsMonthly>
          {currencySymbol}${monthly}
          /m if billed monthly
        </PricingDetailsMonthly>
      </PricingDetails>
    </>
  );
};

Pricing.propTypes = {
  annual: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  monthly: PropTypes.number.isRequired,
};

const CatchUpDiscountPricing = (props) => {
  const { currencySymbol, monthly, monthlyDiscount, monthsText } = props;
  return (
    <>
      <DiscountedPriceLayout>
        <DiscountedPrice>
          {currencySymbol}${monthlyDiscount}
        </DiscountedPrice>
        <RegularPrice>
          {currencySymbol}${monthly}
        </RegularPrice>
      </DiscountedPriceLayout>
      <DiscountDetails>For the first {monthsText}</DiscountDetails>
    </>
  );
};

CatchUpDiscountPricing.propTypes = {
  currencySymbol: PropTypes.string.isRequired,
  monthly: PropTypes.number.isRequired,
  monthlyDiscount: PropTypes.number.isRequired,
  monthsText: PropTypes.string.isRequired,
};

const CatchUpPricing = (props) => {
  const { currencySymbol, monthly } = props;
  return (
    <>
      <PriceBilled>
        {currencySymbol}${monthly}
      </PriceBilled>
      <PricingDetails>per historical month</PricingDetails>
    </>
  );
};

CatchUpPricing.propTypes = {
  currencySymbol: PropTypes.string.isRequired,
  monthly: PropTypes.number.isRequired,
};

const serviceDetails = {
  catchUp: {
    ctaCorporateLink: '/catch-up-signup/',
    ctaLink: '/catch-up-signup/',
    ctaText: 'Get Started',
    dataHeapId: 'get-started-catchup-',
    expensesText: 'Historical monthly expenses',
  },
  keepUp: {
    ctaCorporateLink: '/signup/',
    ctaLink: '/signup/',
    ctaText: 'Start Free Trial',
    dataHeapId: 'start-free-trial-',
    expensesText: 'Monthly expenses',
  },
  benchTax: {
    ctaCorporateLink: '/signup/',
    ctaLink: '/signup/',
    ctaText: 'Start Free Trial',
    dataHeapId: 'get-started-benchtax-',
    expensesText: 'Monthly expenses',
  },
};

const PricingPlan = (props) => {
  const {
    expenses,
    name,
    currency,
    discountNumMonths,
    price,
    hideCta,
    isBestFit,
    isMuted,
    serviceType,
  } = props;
  const corporatePlan = name === 'Corporate';
  const isDiscounted = price.annual > price.annualDiscount;
  const currencySymbol = currency === 'cad' ? 'C' : '';
  const discountMonthsText = () => {
    if (discountNumMonths === 1) {
      return 'month';
    }
    return discountNumMonths < 12 ? `${discountNumMonths} months` : '12 months';
  };
  const detailsText = serviceDetails[serviceType];

  return (
    <PricingCard isBestFit={isBestFit} isMuted={isMuted}>
      {isBestFit && <BestFitHeader>Best Fit</BestFitHeader>}
      <ImageLayout {...props}>
        <Image {...planImages[name]} />
      </ImageLayout>
      <PlanName>{name}</PlanName>
      {isDiscounted && serviceType === serviceTypes.CATCH_UP && (
        <CatchUpDiscountPricing
          currencySymbol={currencySymbol}
          monthly={price.monthly}
          monthlyDiscount={price.monthlyDiscount}
          monthsText={discountMonthsText()}
        />
      )}
      {isDiscounted &&
        (serviceType === serviceTypes.KEEP_UP || serviceType === serviceTypes.BENCH_TAX) && (
          <DiscountPricing
            annual={price.annual}
            annualDiscount={price.annualDiscount}
            currencySymbol={currencySymbol}
            monthlyDiscount={price.monthlyDiscount}
            monthsText={discountMonthsText()}
          />
        )}
      {!isDiscounted && serviceType === serviceTypes.CATCH_UP && (
        <CatchUpPricing currencySymbol={currencySymbol} monthly={price.monthly} />
      )}
      {!isDiscounted &&
        (serviceType === serviceTypes.KEEP_UP || serviceType === serviceTypes.BENCH_TAX) && (
          <Pricing annual={price.annual} currencySymbol={currencySymbol} monthly={price.monthly} />
        )}
      {!hideCta && (
        <LinkButtonLayout>
          <LinkButton
            backgroundColor={colors.water}
            className="pricing-cta"
            data-heap-id={detailsText.dataHeapId + name}
            linkURL={corporatePlan ? detailsText.ctaCorporateLink : detailsText.ctaLink}
            textColor={colors.pearl}
          >
            {detailsText.ctaText}
          </LinkButton>
        </LinkButtonLayout>
      )}
      <ExpensesDetails>
        {detailsText.expensesText}
        <br />
        {expenses}
      </ExpensesDetails>
    </PricingCard>
  );
};

PricingPlan.propTypes = {
  accounts: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  discountNumMonths: PropTypes.number,
  expenses: PropTypes.string.isRequired,
  hideCta: PropTypes.bool,
  isBestFit: PropTypes.bool,
  isMuted: PropTypes.bool,
  name: PropTypes.string.isRequired,
  price: PropTypes.shape({
    annual: PropTypes.number.isRequired,
    annualDiscount: PropTypes.number.isRequired,
    monthly: PropTypes.number.isRequired,
    monthlyDiscount: PropTypes.number.isRequired,
  }).isRequired,
  range: PropTypes.shape({
    high: PropTypes.number.isRequired,
    low: PropTypes.number.isRequired,
  }),
  serviceType: PropTypes.string.isRequired,
};

PricingPlan.defaultProps = {
  hideCta: false,
  range: null,
  isBestFit: false,
  isMuted: false,
  discountNumMonths: 0,
};

export default PricingPlan;
