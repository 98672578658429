import markdownIt from 'markdown-it';

const toKebabCase = (string) => {
  return string
    .trim()
    .replace(/<(?:.|\n)*?>/gm, '')
    .replace(/[^A-Za-z0-9\s]/g, '')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase();
};

export const addAnchorsToH2ElementsInHtml = (html, anchorClass) => {
  const lines = html
    .split('\n')
    .filter((v) => v)
    .map((line) => {
      const cleanLine = line.trim();
      const content = cleanLine.match(/^<h2>(.+)<\/h2>$/);

      if (!content) {
        return cleanLine;
      }

      const headerText = content[1];
      return `<div id="${toKebabCase(
        headerText,
      )}" class="${anchorClass}"></div><h2>${headerText}</h2>`;
    });

  return lines.join('\n');
};

export const buildTocMarkdownFromArticleMarkdown = (markdown) => {
  const lines = markdown
    .split('\n')
    .filter((v) => v)
    .reduce((tocLines, line) => {
      const cleanLine = line.trim();
      const content = cleanLine.match(/^##\s+(.+)/);

      if (!content) {
        return tocLines;
      }

      const headerText = content[1];
      const headerWithoutHtml = markdownIt()
        .render(headerText)
        .replace(/<(?:.|\n)*?>/gm, '')
        .trim();

      return [...tocLines, `- [${headerWithoutHtml}](#${toKebabCase(headerText)})`];
    }, []);

  return lines.join('\n');
};
