import markdownIt from 'markdown-it';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WidgetSocialShare from './WidgetSocialShare';

import { buildTocMarkdownFromArticleMarkdown } from '../../gatsby/blog/helpers/toc';
import colors from '../../ui/constants/colors';
import { ONE_SIXTIETH_OF_A_SECOND, throttle } from '../../gatsby/helpers/throttle';
import grid, { mediaQueries } from '../../ui/constants/grid';
import HorizontalRule from '../../ui/HorizontalRule';
import Link from '../../ui/buttons/Link';
import { navbarMinHeight } from '../nav/NavContainer';
import { pxToRem } from '../../ui/constants/typography';
import ScrollSpy from '../helpers/ScrollSpy';

const SidebarContainer = styled.nav`
  box-sizing: border-box;

  &,
  a {
    color: ${colors.ash};
    font-size: ${pxToRem(16)}rem;
    line-height: ${pxToRem(20)}rem;
  }

  ${mediaQueries.desktop`
    ${(props) =>
      props.position !== 'static' &&
      `
      max-height: calc(100vh - ${navbarMinHeight}px);
    `};
    max-width: 340px;
    overflow: auto;
    position: ${(props) => props.position};
    top: ${(props) => props.top};
    width: ${(props) => props.width};
  `};
`;

const SectionContainer = styled.div`
  background: #fafafa;
  padding: 1rem ${grid.gutterWidth}px;
  margin-bottom: 2rem;

  ${mediaQueries.mobile`
    ${({ hideOnMobile }) => hideOnMobile && `display: none;`}
  `}
`;

const SidebarHeader = styled.h5`
  font-size: ${pxToRem(18)}rem;
  font-weight: bold;
  margin: 2rem 0;
`;

const TableOfContents = styled.div`
  margin: 2rem 0 2.5rem;

  a {
    display: block;
    margin: 1rem 0;

    &.active {
      color: ${colors.water};
    }
  }
`;

const Upsell = styled.div`
  margin: 2.5rem 0;

  a {
    border-bottom: 2px solid #0fa1a2;
    display: inline-block;
    font-weight: bold;
    line-height: ${pxToRem(30)}rem;
    margin-top: 1rem;
  }
`;

const CallToAction = styled(Upsell)``;

const handleTocClick = (e) => {
  if (!(e.target instanceof HTMLAnchorElement)) return;

  const headerId = e.target.hash.replace('#', '');
  if (!headerId) return;

  const header = document.getElementById(headerId);
  if (!header) return;

  e.preventDefault();

  const headerRect = header.getBoundingClientRect();

  window.scrollTo(0, headerRect.top + window.pageYOffset);
};

class WidgetMarkdownTableOfContents extends Component {
  constructor({ isSidebarSticky }) {
    super();

    this.state = {
      position: isSidebarSticky ? 'absolute' : 'static',
      top: 0,
    };

    this.throttledHandleResize = throttle(() => this.handleResize(), ONE_SIXTIETH_OF_A_SECOND);
    this.throttledHandleScroll = throttle(() => this.handleScroll(), ONE_SIXTIETH_OF_A_SECOND);
  }

  componentDidMount() {
    this.setSidebarWidth();
    this.initializeScrollSpy();
    window.addEventListener('scroll', this.throttledHandleScroll, { passive: true });
    window.addEventListener('resize', this.throttledHandleResize, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledHandleScroll);
    window.removeEventListener('resize', this.throttledHandleResize);
  }

  handleResize() {
    this.setSidebarWidth();
    this.setSidebarPosition();
  }

  handleScroll() {
    this.setSidebarPosition();
    this.scrollSpy.onScroll();
  }

  setSidebarWidth() {
    if (!this.sidebarContainerEl) return;
    const sidebarParentRect = this.sidebarContainerEl.parentNode.getBoundingClientRect();
    this.setState({ width: `${sidebarParentRect.width}px` });
  }

  setSidebarPosition() {
    if (!this.props.isSidebarSticky) {
      if (this.state.position !== 'static') {
        this.setState({ position: 'static' });
      }
      return;
    }

    const articleBodyEl = document.getElementById('article-body');
    if (!articleBodyEl) return;

    const articleBodyRect = articleBodyEl.getBoundingClientRect();
    const sidebarRect = this.sidebarContainerEl.getBoundingClientRect();

    const articleBodyTopDelta = articleBodyRect.top - navbarMinHeight;
    const articleBodyTopBelowNavbar = articleBodyTopDelta >= 0;

    const articleBodyBottomDelta = articleBodyRect.bottom - sidebarRect.height - navbarMinHeight;
    const articleBodyBottomAboveScreen = articleBodyBottomDelta <= 0;

    const newState = {};

    if (articleBodyTopBelowNavbar) {
      newState.position = 'absolute';
      newState.top = 0;
    } else if (articleBodyBottomAboveScreen) {
      newState.position = 'absolute';
      newState.top = `${articleBodyRect.height - sidebarRect.height}px`;
    } else {
      newState.position = 'fixed';
      newState.top = `${navbarMinHeight}px`;
    }

    this.setState(newState);
  }

  initializeScrollSpy() {
    if (!this.tocEl) return;
    this.scrollSpy = new ScrollSpy(this.tocEl, {
      offset: navbarMinHeight,
      menuActiveTarget: 'a',
      sectionSelector: '.toc-anchor',
      activeClass: 'active',
    });
  }

  generateMarkdownToc() {
    const { markdown, path } = this.props;
    const tocMarkdown = buildTocMarkdownFromArticleMarkdown(markdown);

    try {
      return markdownIt().render(tocMarkdown);
    } catch (e) {
      throw new Error(
        `An error occurred parsing WidgetMarkdownTableOfContents markdown for ${path}`,
      );
    }
  }

  render() {
    const { upsellCopy, upsellHeader, upsellLinkURL } = this.props;

    const { position, top, width } = this.state;

    if (!this.tocHtml) {
      this.tocHtml = this.generateMarkdownToc();
    }

    return (
      <SidebarContainer
        ref={(el) => {
          this.sidebarContainerEl = el;
        }}
        position={position}
        top={top}
        width={width}
      >
        <SectionContainer hideOnMobile id="toc-top-cta">
          <SidebarHeader>What&apos;s Bench?</SidebarHeader>
          <CallToAction>
            <p>Online bookkeeping and tax filing powered by real humans.</p>
            <p>
              <Link linkURL="/">Learn More</Link>
            </p>
          </CallToAction>
        </SectionContainer>
        <SectionContainer>
          <WidgetSocialShare alignment="left" hideOnMobile path={this.props.path} />
          <SidebarHeader>Contents</SidebarHeader>
          <TableOfContents
            ref={(el) => {
              this.tocEl = el;
            }}
            dangerouslySetInnerHTML={{ __html: this.tocHtml }}
            onClick={handleTocClick}
          />
          <HorizontalRule color="black" thickness={2} />
          <div id="toc-bottom-cta">
            <Upsell>
              <p>{upsellHeader || 'Tired of doing your own books?'}</p>
              <p>
                <Link linkURL={upsellLinkURL || '/signup/'}>{upsellCopy || 'Try Bench.'}</Link>
              </p>
            </Upsell>
          </div>
        </SectionContainer>
      </SidebarContainer>
    );
  }
}

WidgetMarkdownTableOfContents.propTypes = {
  isSidebarSticky: PropTypes.bool,
  markdown: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  upsellCopy: PropTypes.string,
  upsellHeader: PropTypes.string,
  upsellLinkURL: PropTypes.string,
};

WidgetMarkdownTableOfContents.defaultProps = {
  isSidebarSticky: false,
  upsellCopy: 'Try Bench.',
  upsellHeader: 'Tired of doing your own books?',
  upsellLinkURL: '/signup/',
};

export default WidgetMarkdownTableOfContents;
