import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import GridColumn from '../../ui/grid/GridColumn';
import Link from '../../ui/buttons/Link';
import { mediaQueries } from '../../ui/constants/grid';
import WidgetImage from './WidgetImage';
import WidgetValueProp from './WidgetValueProp';
import { TEXT_ALIGNMENTS } from '../../ui/constants/typography';

const IMAGE_ALIGNMENT_CENTER = 'center';
const IMAGE_ALIGNMENT_LEFT = 'left';
const IMAGE_ALIGNMENT_RIGHT = 'right';

const ValuePropWithIconContainer = styled.div`
  align-items: flex-start;
  display: flex;
  margin: 10px 0;

  img {
    left: 30px;
    margin-top: 11px;
    position: relative;
    width: 100%;
  }

  ${mediaQueries.mobile`
    flex-direction: column;

    div {
      padding: 5px 0;
    }

    h3 {
      margin-bottom: 0;
    }

    img {
      left: 0px;
      position: relative;
    }
  `};
`;

const ValuePropWithIconLinkWrapper = styled.p`
  color: ${colors.water};
  padding-top: 8px;

  ${mediaQueries.mobile`
    font-size: 14px;
  `};
`;

const WidgetValuePropWithIcon = ({
  description,
  fontSize,
  fontSizeMobile,
  header,
  headerFontSize,
  headerFontSizeMobile,
  headerLineHeight,
  headerLineHeightMobile,
  icon,
  lineHeight,
  lineHeightMobile,
  linkText,
  linkURL,
}) => (
  <ValuePropWithIconContainer>
    <GridColumn span={2} spanMobile={12}>
      <WidgetImage imageAlignment="left" maxWidth={40} mobileMaxWidth={40} {...icon} />
    </GridColumn>
    <GridColumn span={10} spanMobile={12}>
      <WidgetValueProp
        description={description}
        fontSize={fontSize}
        fontSizeMobile={fontSizeMobile}
        header={header}
        headerFontSize={headerFontSize}
        headerFontSizeMobile={headerFontSizeMobile}
        headerFontWeight={600}
        headerLineHeight={headerLineHeight}
        headerLineHeightMobile={headerLineHeightMobile}
        lineHeight={lineHeight}
        lineHeightMobile={lineHeightMobile}
        textAlignmentMobile="left"
      />
      {linkText && linkURL && (
        <ValuePropWithIconLinkWrapper>
          <Link linkURL={linkURL}>{linkText}</Link>
        </ValuePropWithIconLinkWrapper>
      )}
    </GridColumn>
  </ValuePropWithIconContainer>
);

WidgetValuePropWithIcon.propTypes = {
  alt: PropTypes.string,
  autoFormat: PropTypes.bool,
  bodyTextUpperCase: PropTypes.bool,
  className: PropTypes.string,
  description: PropTypes.string,
  descriptionWidont: PropTypes.bool,
  fontColor: PropTypes.string,
  fontSize: PropTypes.number,
  fontSizeMobile: PropTypes.number,
  fontWeight: PropTypes.number,
  fromContentful: PropTypes.bool,
  header: PropTypes.string,
  headerFontColor: PropTypes.string,
  headerFontFamily: PropTypes.string,
  headerFontSize: PropTypes.number,
  headerFontSizeMobile: PropTypes.number,
  headerFontWeight: PropTypes.number,
  headerLineHeight: PropTypes.number,
  headerLineHeightMobile: PropTypes.number,
  headerTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  headerTextAlignment: PropTypes.oneOf(TEXT_ALIGNMENTS),
  headerWidont: PropTypes.bool,
  height: PropTypes.number,
  icon: PropTypes.shape({
    alt: PropTypes.string,
    autoFormat: PropTypes.bool,
    className: PropTypes.string,
    fromContentful: PropTypes.bool,
    height: PropTypes.number,
    isAboveFold: PropTypes.bool,
    maxWidth: PropTypes.number,
    mobileMaxWidth: PropTypes.number,
    mobileNaturalHeight: PropTypes.number,
    mobileNaturalWidth: PropTypes.number,
    mobileSrc: PropTypes.string,
    naturalHeight: PropTypes.number,
    naturalWidth: PropTypes.number,
    noRetina: PropTypes.bool,
    src: PropTypes.string.isRequired,
    webp: PropTypes.bool,
  }).isRequired,
  imageAlignment: PropTypes.oneOf([
    IMAGE_ALIGNMENT_CENTER,
    IMAGE_ALIGNMENT_LEFT,
    IMAGE_ALIGNMENT_RIGHT,
  ]),
  isAboveFold: PropTypes.bool,
  lineHeight: PropTypes.number,
  lineHeightMobile: PropTypes.number,
  linkText: PropTypes.string,
  linkURL: PropTypes.string,
  marginAfterHeader: PropTypes.number,
  maxWidth: PropTypes.number,
  mobileImageAlignment: PropTypes.oneOf([
    IMAGE_ALIGNMENT_CENTER,
    IMAGE_ALIGNMENT_LEFT,
    IMAGE_ALIGNMENT_RIGHT,
  ]),
  mobileMaxWidth: PropTypes.number,
  mobileNaturalHeight: PropTypes.number,
  mobileNaturalWidth: PropTypes.number,
  mobileSrc: PropTypes.string,
  naturalHeight: PropTypes.number,
  naturalWidth: PropTypes.number,
  noRetina: PropTypes.bool,
  src: PropTypes.string.isRequired,
  textAlignment: PropTypes.oneOf(TEXT_ALIGNMENTS),
  textAlignmentMobile: PropTypes.oneOf(TEXT_ALIGNMENTS),
  webp: PropTypes.bool,
};

WidgetValuePropWithIcon.defaultProps = {
  alt: null,
  autoFormat: false,
  bodyTextUpperCase: null,
  className: null,
  description: null,
  descriptionWidont: false,
  fontColor: null,
  fontSize: 16,
  fontSizeMobile: null,
  fontWeight: 500,
  fromContentful: false,
  header: '',
  headerFontColor: null,
  headerFontFamily: null,
  headerFontSize: 32,
  headerFontSizeMobile: null,
  headerFontWeight: 700,
  headerLineHeight: null,
  headerLineHeightMobile: null,
  headerTag: 'h3',
  headerTextAlignment: null,
  headerWidont: false,
  height: null,
  imageAlignment: IMAGE_ALIGNMENT_CENTER,
  isAboveFold: false,
  lineHeight: null,
  lineHeightMobile: null,
  linkText: undefined,
  linkURL: null,
  marginAfterHeader: null,
  maxWidth: null,
  mobileImageAlignment: null,
  mobileMaxWidth: null,
  mobileNaturalHeight: null,
  mobileNaturalWidth: null,
  mobileSrc: null,
  naturalHeight: null,
  naturalWidth: null,
  noRetina: false,
  textAlignment: null,
  textAlignmentMobile: null,
  webp: false,
};

export default WidgetValuePropWithIcon;
