import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css, keyframes } from 'styled-components';

import config from '../../config';
import externalAssetLoading, {
  ASSET_STATUS_LOADED,
  ASSET_STATUS_FAILED,
} from './externalAssetLoading';
import { loadScript, loadStylesheet } from '../helpers/loadAsset';

const MARKETO_STYLESHEET_HREF = '/stylesheets/marketo-form.css';
const MARKETO_FORMS_SCRIPT = `${config.MARKETO_BASE_URL}/js/forms2/js/forms2.min.js`;
const MARKETO_FORMS_JS_API_GLOBAL = 'MktoForms2';
const MARKETO_FORMS_SUCCESS_REDIRECTION_DELAY = 300;

const StyledForm = styled.form`
  ${({ formSubmitted }) => formSubmitted && `display: none;`}
`;

const loadingPlaceholderKeyframes = keyframes`
  0% {
    background-color: rgba(0,0,0,0.05);
  }
  50% {
    background-color: rgba(0,0,0,0.1);
  }
  100% {
    background-color: rgba(0,0,0,0.05);
  }
`;

const loadingPlaceholderAnimation = css`
  animation: ${loadingPlaceholderKeyframes} 1s infinite;
`;

const FieldPlaceholder = styled.div`
  ${loadingPlaceholderAnimation};
  border-radius: 4px;
  height: 64px;
  margin: 0 0 16px;
`;

const ButtonPlaceholder = styled.div`
  ${loadingPlaceholderAnimation};
  border-radius: 4px;
  height: 55px;
  margin-top: 24px;
  width: 200px;
`;

const makePlaceholderFields = (numFields) => {
  const fieldPlaceholders = [];
  for (let i = 0; i < numFields; i++) {
    fieldPlaceholders.push(<FieldPlaceholder key={i} />);
  }
  return fieldPlaceholders;
};

class MarketoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmitted: false,
      successMessage: null,
    };
    this.onFormSuccess = this.onFormSuccess.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { assetStatus } = this.props;
    if (
      !this.formLoaded &&
      assetStatus !== prevProps.assetStatus &&
      assetStatus === ASSET_STATUS_LOADED
    ) {
      this.loadForm();
      this.formLoaded = true;
    }
  }

  onFormSuccess(formData, followUpURL) {
    this.setState({ formSubmitted: true });

    if (window.analytics) {
      window.analytics.track('Marketo Form Submitted');
      window.analytics.track(`Marketo Form ${this.props.formId} Submitted`);
    }

    if (window.heap && (formData.email || formData.Email)) {
      window.heap.addUserProperties({ _email: formData.email || formData.Email });
    }

    if (typeof this.props.onFormSuccess === 'function') {
      this.props.onFormSuccess();

      return false;
    }

    // Redirecting leads to a follow-up url too early may prevent tracking requests above from
    // completing. By delaying this redirection we attempt to prevent this from happening.
    if (!this.state.successMessage && followUpURL) {
      setTimeout(() => {
        window.location.href = followUpURL;
      }, MARKETO_FORMS_SUCCESS_REDIRECTION_DELAY);
    }

    // If a success message has been provided then return `false` to prevent the submission
    // handler from taking the lead to the follow-up url. This gives the user in Marketo the
    // option to switch between the two behaviours by adding or removing the success message.
    // http://developers.marketo.com/javascript-api/forms/api-reference/
    return false;
  }

  loadForm = () => {
    // The Marketo forms JS API global is set by a third-party script. Since we can't trust that the
    // script hasn't changed, avoid throwing an error if the global didn't get exported.
    if (!(MARKETO_FORMS_JS_API_GLOBAL in window)) return;
    window[MARKETO_FORMS_JS_API_GLOBAL].loadForm(
      config.MARKETO_BASE_URL,
      config.MARKETO_ID,
      this.props.formId,
      this.configureForm.bind(this),
    );
  };

  populateHiddenValues(form) {
    // The campaign id comes from the dynamic page and gated asset templates on Contentful
    // It is passed down through a React Context Provider (WebsitePage.js) to the Consumer
    // (externalAssetLoading.js) for dynamic pages and passed down as a prop from GatedAsset.js
    // down to externalAsset loading for gated asset pages.
    const { campaignId, customHiddenValues } = this.props;
    const $form = form.getFormElem();
    const hiddenValues = {
      ...customHiddenValues,
      SFDC_Campaign_ID__c: campaignId,
    };

    Object.keys(hiddenValues).forEach((name) => {
      const value = hiddenValues[name];
      // We get the first index because $form.find will always return a collection
      const $field = $form.find(`input[name="${name}"]`)[0];
      if ($field && value) {
        $field.value = value;
      }
    });
  }

  configureForm(form) {
    this.populateHiddenValues(form);
    const $form = form.getFormElem();
    // Hide entire rows that only contain hidden inputs
    const $hidden = $form.find('input[type="hidden"]');
    $hidden.closest('.mktoFormRow').addClass('is-hidden');
    // Add wrapper element with known class to select fields
    const $select = $form.find('select');
    $select.wrap('<div class="c-mktoForm_select"></div>');

    // Remove the useless default label above checkbox lists
    const $checkboxList = $form.find('.mktoCheckboxList');
    $checkboxList.siblings('label').hide();

    // If a success message was provided by Marketo then update it in the state
    const successMessage = $form.find('[name="successMessage"]').val();
    this.setState({ successMessage });

    form.onSuccess(this.onFormSuccess);
  }

  render() {
    const { formSubmitted, successMessage } = this.state;
    const { assetStatus, formId, placeholderFields, retryLoadAssets } = this.props;
    return (
      <div className="c-mktoForm -white">
        {!this.formLoaded && assetStatus !== ASSET_STATUS_FAILED && (
          <div>
            {makePlaceholderFields(placeholderFields)}
            <ButtonPlaceholder />
          </div>
        )}
        <StyledForm formSubmitted={formSubmitted} id={`mktoForm_${formId}`} />
        {formSubmitted && successMessage && (
          <div className="c-mktoForm_success base-p-t-3">{successMessage}</div>
        )}
        {assetStatus === ASSET_STATUS_FAILED && process.env.GATSBY_ENV !== 'production' && (
          <div>
            <span className="base-p-r-3">There was a problem loading a form here.</span>
            <button onClick={retryLoadAssets} type="button">
              Try again
            </button>
          </div>
        )}
      </div>
    );
  }
}

MarketoForm.loadExternalAssets = () => {
  return Promise.all([
    loadStylesheet(MARKETO_STYLESHEET_HREF),
    loadScript(MARKETO_FORMS_SCRIPT, true),
  ]);
};

MarketoForm.propTypes = {
  assetStatus: PropTypes.number.isRequired,
  campaignId: PropTypes.string,
  customHiddenValues: PropTypes.object,
  formId: PropTypes.string.isRequired,
  onFormSuccess: PropTypes.func,
  placeholderFields: PropTypes.number,
  retryLoadAssets: PropTypes.func.isRequired,
};

MarketoForm.defaultProps = {
  campaignId: undefined,
  customHiddenValues: {},
  placeholderFields: 3,
  onFormSuccess: null,
};

export default externalAssetLoading(MarketoForm);
