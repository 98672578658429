import WidgetTrustPilot from '../../../components/widgets/WidgetTrustPilot';

const widgetTrustPilotTranslation = () => {
  return {
    data: {},
    WidgetComponent: WidgetTrustPilot,
  };
};

export default widgetTrustPilotTranslation;
