import contentfulConfig from '../contentfulConfig';

const DEVELOPMENT = 'development';
const PREVIEW = 'preview';
const PRODUCTION = 'production';
const STAGING = 'staging';
const TEST = 'test';

const baseConfig = {
  // These config values don't differ between environments
  CANONICAL_ORIGIN: 'https://bench.co',
  CONTENTFUL_IMAGE_CDN_ORIGIN: '//images.contentful.com',
  IMGIX_ORIGIN: 'https://bench-assets.imgix.net',
  FONT_ORIGIN: 'https://assets.bench.co',
  MARKETO_BASE_URL: '//app-ab17.marketo.com',
  MARKETO_ID: '729-JTY-427',
  ONETRUST_SCRIPT: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
  ONETRUST_DOMAIN_SCRIPT: '8c69ca6c-9d83-4227-8cee-bad336f8243d',
  // String values are defined for all keys that will be overridden by runtime environment; this is
  // a template for the environment variables that differ by runtime environment
  CONTENTFUL_BLOG_ACCESS_TOKEN: '',
  CONTENTFUL_BLOG_SPACE_ID: '',
  CONTENTFUL_HOST: '',
  GOOGLE_ANALYTICS_ID: '',
  SIGNUP_AIRKIT_PARTNER_URL: 'https://join.bench.co/c/trial-ps',
  SIGNUP_AIRKIT_PAID_URL: 'https://join.bench.co/c/trial-p',
};

const configByEnvironment = {
  [DEVELOPMENT]: {
    ...baseConfig,
    CONTENTFUL_BLOG_ACCESS_TOKEN: contentfulConfig.blog.preview.accessToken,
    CONTENTFUL_BLOG_SPACE_ID: contentfulConfig.blog.preview.spaceId,
    CONTENTFUL_HOST: contentfulConfig.blog.preview.host,
  },
  [PREVIEW]: {
    ...baseConfig,
    CONTENTFUL_BLOG_ACCESS_TOKEN: contentfulConfig.blog.preview.accessToken,
    CONTENTFUL_BLOG_SPACE_ID: contentfulConfig.blog.preview.spaceId,
    CONTENTFUL_HOST: contentfulConfig.blog.preview.host,
  },
  [PRODUCTION]: {
    ...baseConfig,
    CONTENTFUL_BLOG_ACCESS_TOKEN: contentfulConfig.blog.cdn.accessToken,
    CONTENTFUL_BLOG_SPACE_ID: contentfulConfig.blog.cdn.spaceId,
    CONTENTFUL_HOST: contentfulConfig.blog.cdn.host,
  },
  [STAGING]: {
    ...baseConfig,
    CONTENTFUL_BLOG_ACCESS_TOKEN: contentfulConfig.blog.cdn.accessToken,
    CONTENTFUL_BLOG_SPACE_ID: contentfulConfig.blog.cdn.spaceId,
    CONTENTFUL_HOST: contentfulConfig.blog.cdn.host,
  },
  [TEST]: Object.keys(baseConfig).reduce(
    (testConfigObject, key) => ({ ...testConfigObject, [key]: `TEST_${key}` }),
    {},
  ),
};

const envIs = {
  // During server-side rendering, the window global is not defined
  ssr: () => typeof window === 'undefined',
  // During runtime (in a browser) the window global is defined
  runtime: () => !envIs.ssr(),
  // The "Gatsby environment" refers to the explicit environment defined as an environment variable
  // when running the `gatsby build` command.
  previewGatsby: () => process.env.GATSBY_ENV === PREVIEW,
  productionGatsby: () => process.env.GATSBY_ENV === PRODUCTION,
  testGatsby: () => process.env.GATSBY_ENV === TEST,
  // Regardless which host the code is being served from, if the code was built using the preview
  // Gatsby environment, then it's the preview runtime
  previewRuntime: () => envIs.runtime() && envIs.previewGatsby(),
  // The production configuration is always used when the code is served from bench.co, and only
  // when the code is served from bench.co
  productionRuntime: () => envIs.runtime() && window.location.hostname === 'bench.co',
  // We use 10sheet.ca for Staging. We also have deploy previews.
  stagingHostname: (hostname) => hostname === '10sheet.ca' || hostname.includes('preview.bench.co'),
  // Use the staging hostname check to determine if we're on a staging environment.
  stagingRuntime: () => envIs.runtime() && envIs.stagingHostname(window.location.hostname),
};

const getConfigForEnvironment = () => {
  // In the test environment, sometimes a browser runtime is imitated, sometimes a Node.js runtime
  // is used; we explicitly set the Gatsby environment to "test" to differentiate it from the other
  // configurations
  if (envIs.testGatsby()) {
    return configByEnvironment[TEST];
  }
  // In order for a configuration variable to be available during server-side rendering, it must be
  // in the base configuration
  if (envIs.ssr()) {
    return baseConfig;
  }
  // Environment variables vary during runtime based on a number of factors
  // See `envIs` above for details
  if (envIs.productionRuntime()) {
    return configByEnvironment[PRODUCTION];
  }
  if (envIs.stagingRuntime()) {
    return configByEnvironment[STAGING];
  }
  if (envIs.previewRuntime()) {
    return configByEnvironment[PREVIEW];
  }
  // During runtime, if no suitable environment could be determined, then the development
  // configuration is used
  return configByEnvironment[DEVELOPMENT];
};

const config = getConfigForEnvironment();

export default config;
