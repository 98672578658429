import trimSlashes from '../../lib/trimSlashes';

const isLinkActive = (linkURL, path) => {
  if (typeof linkURL !== 'string') return false;
  if (typeof path !== 'string') return false;

  // Edge case, nested path in navbar
  if (/blog\/resources/.test(linkURL)) {
    return /blog\/resources/.test(path);
  }

  if (/blog\/?((?!resources))/.test(linkURL) && /blog\/resources/.test(path)) {
    return false;
  }

  const regExp = new RegExp(`^/${trimSlashes(linkURL)}(/.+)?/?$`);
  return regExp.test(path);
};

export default isLinkActive;
