import { css } from 'styled-components';

const constants = {
  breakpoints: {
    // In /src/ui/Image/Image.js, 800px is the largest pixel maximum that is considered mobile
    mobileMaxWidth: 800,
    desktopMinWidth: 801,
  },
  columns: 12,
  containerWidth: 1200,
  gutterWidth: 30,
};

export const mediaQueries = {
  mobile: (...args) => css`
    @media (max-width: ${constants.breakpoints.mobileMaxWidth}px) {
      ${css(...args)}
    }
  `,
  desktop: (...args) => css`
    @media (min-width: ${constants.breakpoints.desktopMinWidth}px) {
      ${css(...args)}
    }
  `,
};

const desktopColumnPixels = constants.containerWidth / constants.columns;
export const getDesktopColumnWidth = (span = 12) => {
  if (typeof span !== 'number') return null;
  return desktopColumnPixels * span - constants.gutterWidth;
};

const mobileContainerWidth = constants.breakpoints.mobileMaxWidth - constants.gutterWidth;
const mobileColumnPixels = mobileContainerWidth / constants.columns;
export const getMobileColumnWidth = (spanMobile = 12) => {
  if (typeof spanMobile !== 'number') return null;
  return mobileColumnPixels * spanMobile - constants.gutterWidth;
};

export default constants;
