import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BenchLogo from '../../ui/logos/BenchLogo';
import GridColumn from '../../ui/grid/GridColumn';
import GridRow from '../../ui/grid/GridRow';
import Link from '../../ui/buttons/Link';
import NavContainer from './NavContainer';
import NavHamburger from './NavHamburger';
import NavLink from './NavLink';
import NavSection from './NavSection';
import colors from '../../ui/constants/colors';
import getDynamicColors from './getDynamicColors';
import navbarLinks from './navbarLinks';
import { ONE_SIXTIETH_OF_A_SECOND, throttle } from '../../gatsby/helpers/throttle';

class Navbar extends Component {
  constructor(props) {
    super(props);

    const { navbarAlwaysOpaque } = props;

    this.state = {
      openOnMobile: false,
      isTransparent: !navbarAlwaysOpaque,
    };

    this.throttledUpdateVisibility = throttle(
      () => this.updateVisibility(),
      ONE_SIXTIETH_OF_A_SECOND,
    );
    this.toggleNav = this.toggleNav.bind(this);
  }

  componentDidMount() {
    this.updateVisibility();
    window.addEventListener('scroll', this.throttledUpdateVisibility, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledUpdateVisibility, { passive: true });
  }

  updateVisibility() {
    const { navbarAlwaysOpaque } = this.props;

    this.setState({
      isTransparent: navbarAlwaysOpaque ? false : window.pageYOffset === 0,
    });
  }

  toggleNav() {
    const { openOnMobile } = this.state;
    this.setState({ openOnMobile: !openOnMobile });
  }

  render() {
    const {
      buttonText,
      buttonUrl,
      email,
      full,
      login,
      path,
      transparentNavbarLogoSymbolColor,
      transparentNavbarTextColor,
    } = this.props;

    const { openOnMobile, isTransparent } = this.state;

    const logoSymbolColors = getDynamicColors(
      openOnMobile,
      isTransparent,
      transparentNavbarLogoSymbolColor,
      colors.mustard,
    );

    const textColors = getDynamicColors(
      openOnMobile,
      isTransparent,
      transparentNavbarTextColor,
      colors.charcoal,
    );

    return (
      <NavContainer
        aria-label="Nav-Container"
        isTransparent={isTransparent}
        linkColor={textColors.color}
        linkColorMobile={textColors.colorMobile}
        openOnMobile={openOnMobile}
      >
        <GridRow alignItems="center" noGutter>
          <GridColumn span={5}>
            <NavSection alignmentOnDesktop="left" showOnMobile={openOnMobile}>
              {!full && email && (
                <>
                  <NavLink
                    linkText="1 (888) 760 1940"
                    linkURL="tel:+1-888-760-1940"
                    noBorderOnMobile
                    path={path}
                    smallOnDesktop
                  />
                </>
              )}

              {full &&
                navbarLinks.map(({ linkText, linkURL, dropdownLinks, linkId }) => (
                  <NavLink
                    key={linkId}
                    dropdownLinks={dropdownLinks}
                    linkText={linkText}
                    linkURL={linkURL}
                    path={path}
                  />
                ))}
            </NavSection>
          </GridColumn>
          <GridColumn orderMobile={-1} span={2}>
            <NavSection alignmentOnDesktop="center" showOnMobile>
              <Link linkURL="/">
                <BenchLogo
                  symbolColor={logoSymbolColors.color}
                  symbolColorMobile={logoSymbolColors.colorMobile}
                  typeColor={textColors.color}
                  typeColorMobile={textColors.colorMobile}
                />
              </Link>
              <NavHamburger
                clickAction={this.toggleNav}
                isActive={openOnMobile}
                linkColorMobile={textColors.colorMobile}
              />
            </NavSection>
          </GridColumn>
          <GridColumn span={5}>
            <NavSection alignmentOnDesktop="right" showOnMobile={openOnMobile}>
              {!full && (
                <NavLink
                  asButton
                  isTransparent={isTransparent}
                  linkColor={textColors.color}
                  linkText={buttonText || 'Start a Free Trial'}
                  linkURL={buttonUrl || '/signup/'}
                />
              )}

              {full && login && (
                <NavLink
                  linkText="Log In"
                  linkURL={
                    typeof window !== 'undefined' && window.location.href.includes('dev.bench.co')
                      ? '/legacy-login/'
                      : '/login/'
                  }
                  path={path}
                  smallOnDesktop
                />
              )}
              {full && (
                <>
                  <NavLink
                    linkText="1 (888) 760 1940"
                    linkURL="tel:+1-888-760-1940"
                    noBorderOnMobile
                    path={path}
                    smallOnDesktop
                  />

                  <NavLink
                    asButton
                    isTransparent={isTransparent}
                    linkColor={textColors.color}
                    linkText={buttonText || 'Start a Free Trial'}
                    linkURL={buttonUrl || '/signup/'}
                  />
                </>
              )}
            </NavSection>
          </GridColumn>
        </GridRow>
      </NavContainer>
    );
  }
}

Navbar.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string,
  email: PropTypes.string,
  full: PropTypes.bool,
  login: PropTypes.bool,
  navbarAlwaysOpaque: PropTypes.bool,
  path: PropTypes.string,
  transparentNavbarLogoSymbolColor: PropTypes.string,
  transparentNavbarTextColor: PropTypes.string,
};

Navbar.defaultProps = {
  buttonUrl: '/signup/',
  email: null,
  full: false,
  login: false,
  navbarAlwaysOpaque: false,
  path: null,
  transparentNavbarLogoSymbolColor: colors.mustard,
  transparentNavbarTextColor: colors.charcoal,
};

export default Navbar;
