import config from '../../config';

export const imgixQueryBuilder = ({ autoFormat, height, width }) => {
  const query = {};

  if (autoFormat) {
    query.auto = 'format';
  }

  if (width) {
    query.w = width;
  }

  if (height) {
    query.h = Math.floor(height);
  }

  return query;
};

export const imgixPrefix = config.IMGIX_ORIGIN;
