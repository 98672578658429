import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';
import SideTabViewComponent from '../../../components/SideTabViewComponent';
import tryGet from '../../../components/helpers/tryGet';

const sideTabViewItemTranslation = ({ header, description, tabId, desktopImage, mobileImage }) => {
  const normalizedDesktopImage = normalizeContentfulImageData(desktopImage);
  return {
    header,
    description: tryGet(['description'], description) || description,
    tabId,
    desktopImage: normalizedDesktopImage,
    mobileImage: mobileImage ? normalizeContentfulImageData(mobileImage) : normalizedDesktopImage,
  };
};

const sideTabViewComponentTranslation = ({
  headerFontColor,
  headerFontSize,
  bodyFontColor,
  bodyFontSize,
  activeTabColor,
  tabItems,
}) => {
  const translatedData = {
    headerFontSize,
    headerFontColor,
    descriptionFontSize: bodyFontSize,
    descriptionFontColor: bodyFontColor,
    activeTabColor,
    tabData: tabItems.map(sideTabViewItemTranslation),
  };
  return {
    data: translatedData,
    WidgetComponent: SideTabViewComponent,
  };
};

export default sideTabViewComponentTranslation;
