import WidgetPricing from '../../../components/widgets/WidgetPricing';
import WidgetPricingRecommendation from '../../../components/widgets/WidgetPricingRecommendation';
import serviceTypes from '../../../components/pricing/serviceTypes';

const widgetPricingTableTranslation = ({
  hideExpenseSelector,
  isCatchUp,
  isBenchTax,
  partnerDiscount,
  partnerDiscountNumMonths,
  showCallToAction,
}) => {
  const serviceType = () => {
    if (isCatchUp) {
      return serviceTypes.CATCH_UP;
    }
    if (isBenchTax) {
      return serviceTypes.BENCH_TAX;
    }
    return serviceTypes.KEEP_UP;
  };

  const translatedData = {
    boutiqueDiscountAmount: partnerDiscount,
    corporateDiscountAmount: partnerDiscount,
    discountNumMonths: partnerDiscountNumMonths,
    hideCta: !showCallToAction,
    serviceType: serviceType(),
    microDiscountAmount: partnerDiscount,
    starterDiscountAmount: partnerDiscount,
    ventureDiscountAmount: partnerDiscount,
  };

  return {
    data: translatedData,
    WidgetComponent: hideExpenseSelector ? WidgetPricing : WidgetPricingRecommendation,
  };
};

export default widgetPricingTableTranslation;
