import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from '../../ui/constants/grid';
import { pxToRem } from '../../ui/constants/typography';
import Button from '../../ui/buttons/Button';
import Card from './Card';
import colors from '../../ui/constants/colors';
import WidgetValueProp from '../widgets/WidgetValueProp';

const HeaderLayout = styled.div`
  margin-bottom: ${pxToRem(55)}rem;
  margin-top: ${pxToRem(16)}rem;
  text-align: left;

  hr {
    margin: ${pxToRem(20)}rem auto;
  }

  h3 {
    margin-bottom: ${pxToRem(22)}rem;
  }

  ${mediaQueries.desktop`
    margin: ${pxToRem(25)}rem 1rem ${pxToRem(55)}rem;

    h3 {
      margin-bottom: ${pxToRem(28)}rem;
    }
  `};
`;

const FooterLayout = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${pxToRem(55)}rem;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  order: ${({ order }) => order};
  width: 47%;

  ${mediaQueries.desktop`
    width: ${pxToRem(122)}rem;
  `};
`;

export const QTCButton = styled(
  ({
    backgroundColorHovered,
    borderStyle,
    borderStyleHovered,
    fontWeight,
    textColorHovered,
    ...otherProps
  }) => <Button {...otherProps} />,
)`
  ${({ borderStyle, fontWeight, backgroundColorHovered, borderStyleHovered, textColorHovered }) => `
    border: ${borderStyle};
    font-weight : ${fontWeight};

    &:hover {
      background-color: ${backgroundColorHovered};
      border: ${borderStyleHovered};
      color: ${textColorHovered};
    }
  `};
`;

const StepWrapper = styled.div`
  color: #232323;
  font-size: ${pxToRem(12)}rem;
  letter-spacing: ${pxToRem(2)}rem;
  order: 4;
  padding-top: ${pxToRem(25)}rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  ${mediaQueries.desktop`
    order: 2;
    width: unset;
    padding-top: unset;
  `};
`;

const StepProgressLayout = (props) => {
  const {
    businessStruct,
    children,
    footerText,
    isBackButtonShown,
    isNextButtonShown,
    onClickBack,
    onClickNext,
  } = props;
  return (
    <Card>
      <HeaderLayout>
        <WidgetValueProp
          description={`---\n${businessStruct || ''}`}
          fontColor={colors.ash}
          fontSize={14}
          fontSizeMobile={14}
          header="Estimated Quarterly Tax Calculator"
          headerFontColor={colors.bonsai}
          headerFontSize={22}
          headerFontSizeMobile={16}
          headerFontWeight={400}
          headerLineHeight={2.27}
          headerLineHeightMobile={3.13}
          lineHeight={1.71}
          textAlignment="left"
          textAlignmentMobile="left"
        />
      </HeaderLayout>
      <div>{children}</div>
      <FooterLayout>
        <ButtonWrapper order={1}>
          {isBackButtonShown && (
            <QTCButton
              backgroundColorHovered={colors.white}
              borderStyle={`solid 1.25px ${colors.cloud}`}
              borderStyleHovered="solid 1.25px #1AADA2"
              buttonPadding={`${pxToRem(11.75)}rem`}
              fontWeight="bold"
              full
              ghost
              onClick={onClickBack}
              textColor={colors.cloud}
              textColorHovered="#1AADA2"
            >
              ← Back
            </QTCButton>
          )}
        </ButtonWrapper>
        {footerText && <StepWrapper>{footerText}</StepWrapper>}
        <ButtonWrapper order={3}>
          {isNextButtonShown && (
            <QTCButton
              backgroundColor="#1AADA2"
              backgroundColorHovered={colors['water-light']}
              buttonPadding={`${pxToRem(13)}rem`}
              fontWeight="bold"
              full
              onClick={onClickNext}
              textColor={colors.white}
              textColorHovered={colors.white}
            >
              Next →
            </QTCButton>
          )}
        </ButtonWrapper>
      </FooterLayout>
    </Card>
  );
};

StepProgressLayout.propTypes = {
  businessStruct: PropTypes.string,
  children: PropTypes.node,
  footerText: PropTypes.string,
  isBackButtonShown: PropTypes.bool,
  isNextButtonShown: PropTypes.bool,
  onClickBack: PropTypes.func,
  onClickNext: PropTypes.func,
};

StepProgressLayout.defaultProps = {
  businessStruct: null,
  children: null,
  footerText: null,
  isBackButtonShown: false,
  isNextButtonShown: true,
  onClickBack: null,
  onClickNext: null,
};

export default StepProgressLayout;
