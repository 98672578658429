import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import { pxToRem } from '../../ui/constants/typography';

const FooterContainer = styled.footer`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${colors.charcoal};
  font-size: ${({ fontSize }) => pxToRem(fontSize)}rem;
  padding: ${({ noPadding }) => (noPadding ? '0' : `${pxToRem(64)}rem 0`)};
  text-align: ${({ textAlignment }) => textAlignment || 'inherit'};

  a {
    color: ${colors.smoke};
  }

  && a:hover {
    color: ${colors.water};
  }

  && a.-muted:hover {
    color: ${colors.dust};
    text-decoration: underline;
  }

  .-disguised {
    color: ${colors.charcoal};
  }

  .-eyecatching {
    color: ${colors.water};
  }

  .-muted {
    color: ${colors.dust};
  }
`;

FooterContainer.propTypes = {
  backgroundColor: PropTypes.string,
  fontSize: PropTypes.number,
  noPadding: PropTypes.bool,
};

FooterContainer.defaultProps = {
  backgroundColor: colors.sand,
  fontSize: 15,
  noPadding: false,
};

export default FooterContainer;
