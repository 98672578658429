import PropTypes from 'prop-types';
import styled from 'styled-components';

import { mediaQueries } from './constants/grid';

const SPACING_UNIT = 8;

const getSpacingRule = (props, type, side, device) => {
  const propsToTry = [`${type}${side}${device}`, `${type}${side}`, `${type}${device}`, `${type}`];
  // eslint-disable-next-line no-restricted-syntax
  for (const prop of propsToTry) {
    const value = props[prop];
    if (value !== null) {
      return `${type}-${side}: ${value * SPACING_UNIT}px;`;
    }
  }
  return '';
};

const types = ['margin', 'padding'];

const sides = ['Bottom', 'Top'];

const getSpacingRuleSection = (props, device) => {
  return types
    .map((type) => sides.map((side) => getSpacingRule(props, type, side, device)).join(''))
    .join('');
};

const Spacer = styled.div`
  ${mediaQueries.mobile`
    ${(props) => getSpacingRuleSection(props, 'Mobile')}
  `}

  ${mediaQueries.desktop`
    ${(props) => getSpacingRuleSection(props, 'Desktop')}
  `}
`;

Spacer.propTypes = {
  margin: PropTypes.number,
  marginBottom: PropTypes.number,
  marginBottomDesktop: PropTypes.number,
  marginBottomMobile: PropTypes.number,
  marginDesktop: PropTypes.number,
  marginMobile: PropTypes.number,
  marginTop: PropTypes.number,
  marginTopDesktop: PropTypes.number,
  marginTopMobile: PropTypes.number,
  padding: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingBottomDesktop: PropTypes.number,
  paddingBottomMobile: PropTypes.number,
  paddingDesktop: PropTypes.number,
  paddingMobile: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingTopDesktop: PropTypes.number,
  paddingTopMobile: PropTypes.number,
};

Spacer.defaultProps = {
  margin: null,
  marginBottom: null,
  marginBottomDesktop: null,
  marginBottomMobile: null,
  marginDesktop: null,
  marginMobile: null,
  marginTop: null,
  marginTopDesktop: null,
  marginTopMobile: null,
  padding: null,
  paddingBottom: null,
  paddingBottomDesktop: null,
  paddingBottomMobile: null,
  paddingDesktop: null,
  paddingMobile: null,
  paddingTop: null,
  paddingTopDesktop: null,
  paddingTopMobile: null,
};

export default Spacer;
