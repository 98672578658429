class ScrollSpy {
  constructor(menu, options = {}) {
    if (!menu) {
      throw new Error('First argument is query selector to your navigation.');
    }

    if (typeof options !== 'object') {
      throw new Error('Second argument must be instance of Object.');
    }

    const defaultOptions = {
      offset: 0,
      menuActiveTarget: 'li > a',
      sectionSelector: '.scrollspy',
      activeClass: 'active',
    };

    this.menuList = menu instanceof HTMLElement ? menu : document.querySelector(menu);
    this.options = { ...defaultOptions, ...options };
    this.sections = document.querySelectorAll(this.options.sectionSelector);
  }

  onScroll() {
    for (let i = 0; i < this.sections.length; i++) {
      const section = this.sections[i];
      const sectionHeaderPosition = section.getBoundingClientRect().top;
      const isInView = sectionHeaderPosition <= this.options.offset;

      if (isInView) {
        const menuItemID = section.getAttribute('id');
        const activeItem = this.menuList.querySelector(`[href="#${menuItemID}"]`);
        if (!activeItem) {
          return;
        }

        this.removeCurrentActive();
        this.setActive(activeItem);
      }
    }
  }

  setActive(activeItem) {
    activeItem.classList.add(this.options.activeClass);
  }

  removeCurrentActive() {
    const menuItems = this.menuList.querySelectorAll(this.options.menuActiveTarget);

    for (let i = 0; i < menuItems.length; i++) {
      const item = menuItems[i];
      item.classList.remove(this.options.activeClass);
    }
  }
}

export default ScrollSpy;
