/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Link from './Link';
import colors from '../constants/colors';

const LinkButton = styled(
  ({
    // Omit button-related props from `otherProps` being passed to `Link`
    backgroundColor,
    borderColor,
    hoverColor,
    hoverTextColor,
    textColor,
    width,
    ...otherProps
  }) => <Link {...otherProps} />,
)`
  background: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border: solid 1px ${({ borderColor }) => borderColor || 'transparent'};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${({ textColor }) => textColor || 'inherit'};
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0.25rem 0;
  padding: 0.75rem 1rem;
  text-align: center;
  text-decoration: none;
  ${({ width }) => width && `width: ${width}`};
  ${({ borderColor, backgroundColor, hoverColor, hoverTextColor }) =>
    ((borderColor && !backgroundColor) || hoverColor || hoverTextColor) &&
    `
    &:hover {
      background: ${hoverColor || colors.charcoal};
      color: ${hoverTextColor || colors.white};
    }
  `};
`;

LinkButton.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  className: PropTypes.string,
  hoverColor: PropTypes.string,
  hoverTextColor: PropTypes.string,
  linkURL: PropTypes.string,
  onClick: PropTypes.func,
  textColor: PropTypes.string,
  width: PropTypes.string,
};

LinkButton.defaultProps = {
  backgroundColor: null,
  borderColor: null,
  className: null,
  hoverColor: null,
  hoverTextColor: null,
  linkURL: null,
  onClick: null,
  textColor: null,
  width: null,
};

export default LinkButton;
