import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { navbarMinHeight } from './nav/NavContainer';

// Offset the div with the fragment identifier id by the height of the fixed navbar so that it isn't
// partially hidden when navigated to.

const HashDiv = styled.div`
  position: relative;
  top: -${navbarMinHeight}px;
`;

const Section = ({ data, isFirstSection }) => {
  const { SectionComponent, ...sectionProps } = data;
  if (typeof SectionComponent !== 'function') {
    throw new TypeError(
      `props.data.SectionComponent must be a React component function, but got ${JSON.stringify(
        SectionComponent,
        null,
        2,
      )}`,
    );
  }
  const sectionElement = <SectionComponent {...sectionProps} isFirstSection={isFirstSection} />;
  if (data.hash) {
    return (
      <div>
        <HashDiv id={data.hash} />
        {sectionElement}
      </div>
    );
  }
  return sectionElement;
};

Section.propTypes = {
  data: PropTypes.object.isRequired,
  isFirstSection: PropTypes.bool,
};

Section.defaultProps = {
  isFirstSection: false,
};

export default Section;
