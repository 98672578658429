import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import blogSignupSectionProps from '../helpers/blogSignupSectionProps';
import makeTitleTagContent from '../../helpers/makeTitleTagContent';
import colors from '../../../ui/constants/colors';
import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';
import Page from '../../../components/Page';
import tryGet from '../../../components/helpers/tryGet';
import WidgetCallToAction from '../../../components/widgets/WidgetCallToAction';
import WidgetGatedVideo from '../../../components/widgets/WidgetGatedVideo';
import WidgetImage from '../../../components/widgets/WidgetImage';
import WidgetSocialShare from '../../../components/widgets/WidgetSocialShare';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';

import '../../../scss/styles-website.scss';

const Y_SCROLL_BUFFER_SIZE = 200;

const handleCtaClick = (e, marketoFormId) => {
  const form = document.getElementById(`mktoForm_${marketoFormId}`);

  if (form) {
    e.preventDefault();
    const formRect = form.getBoundingClientRect();
    window.scrollTo(0, formRect.top + (window.pageYOffset - Y_SCROLL_BUFFER_SIZE));
  }
};

const GatedVideoCta = ({ callToActionText, marketoFormId }) => (
  <WidgetCallToAction
    backgroundColor="#0fa1a2"
    buttonAligment="left"
    buttonText={callToActionText}
    fontSize={20}
    onClick={(e) => handleCtaClick(e, marketoFormId)}
    textColor={colors.pearl}
  />
);

GatedVideoCta.propTypes = {
  callToActionText: PropTypes.string.isRequired,
  marketoFormId: PropTypes.string.isRequired,
};

const CustomKeyLearningsList = styled.div`
  ul {
    list-style: none;
    padding-left: 12px;
    margin: 0;

    li {
      padding-left: 1em;
      text-indent: -10px;

      &::before {
        width: 0;
        height: 0;
        margin-right: 10px;
        content: '• ';
        color: ${colors.water};
      }
    }
  }
`;

const KeyLearningsList = ({ description }) => (
  <CustomKeyLearningsList>
    <WidgetValueProp description={description} />
  </CustomKeyLearningsList>
);

KeyLearningsList.propTypes = {
  description: PropTypes.string.isRequired,
};

const Webinar = (props) => {
  const { pageContext } = props;
  const { webinarData } = pageContext;
  const {
    callToActionButtonText,
    path,
    speakerImage,
    keyLearningsBody,
    keyLearningsHeader,
    metaDescription,
    marketoFormId,
    naturalHeight,
    naturalWidth,
    placeholderFields,
    speakerName,
    speakerTitle,
    videoDescription,
    videoPlaceholderImage,
    webinarSubTitle,
    webinarTitle,
    wistiaVideoId,
  } = webinarData;

  const pageData = {
    description: metaDescription,
    enableAbTesting: false,
    footerType: 'full',
    hideNavbar: false,
    navbarType: 'full',
    path,
    title: makeTitleTagContent(webinarTitle),
    sections: [
      {
        backgroundColor: colors.white,
        columns: [
          {
            span: 8,
            widgets: [
              {
                data: {
                  header: webinarTitle,
                  headerFontColor: colors.charcoal,
                  headerFontFamily: 'LeituraDisplay',
                  headerFontSize: 40,
                  headerFontSizeMobile: 32,
                  headerFontWeight: 400,
                  headerLineHeight: 50,
                  headerLineHeightMobile: 44,
                  headerTag: 'h1',
                  textAlignment: 'center',
                },
                marginBottom: 0,
                marginTopDesktop: 12,
                WidgetComponent: WidgetValueProp,
              },
            ],
          },
        ],
        padding: 0,
      },
      {
        backgroundColor: colors.white,
        columns: [
          {
            span: 5,
            widgets: [
              {
                data: {
                  description: webinarSubTitle,
                  textAlignment: 'center',
                },
                marginBottom: 5,
                marginBottomMobile: 2,
                marginTop: 0,
                WidgetComponent: WidgetValueProp,
              },
            ],
          },
        ],
        padding: 0,
      },
      {
        backgroundColor: colors.white,
        columns: [
          {
            span: 10,
            widgets: [
              {
                data: {
                  formId: marketoFormId,
                  formHeader: 'Enter your details to watch this video course',
                  naturalHeight,
                  naturalWidth,
                  placeholderFields,
                  videoId: wistiaVideoId,
                  videoPlaceholderImage:
                    videoPlaceholderImage && normalizeContentfulImageData(videoPlaceholderImage),
                },
                marginTopDesktop: 0,
                marginBottom: 0,
                WidgetComponent: WidgetGatedVideo,
              },
            ],
          },
        ],
        padding: 0,
      },
      {
        backgroundColor: colors.white,
        columns: [
          {
            span: 6,
            widgets: [
              {
                data: {
                  header: videoDescription,
                  headerFontColor: colors.charcoal,
                  headerFontSize: 24,
                  headerFontSizeMobile: 18,
                  headerFontWeight: 600,
                  headerLineHeight: 30,
                  headerLineHeightMobile: 28,
                  headerTag: 'h2',
                  textAlignment: 'center',
                },
                marginBottom: 9,
                marginTopDesktop: 9,
                WidgetComponent: WidgetValueProp,
              },
            ],
          },
        ],
        padding: 0,
      },
      {
        backgroundColor: '#e3efef',
        columns: [
          {
            span: 3,
            widgets: [
              {
                data: {
                  header: 'Our Speaker',
                  headerFontFamily: 'LeituraDisplay',
                  headerFontColor: '#0fa1a2',
                  headerFontSize: 30,
                  headerFontSizeMobile: 26,
                  headerFontWeight: 400,
                  headerTag: 'h2',
                  textAlignment: 'center',
                },
                marginBottom: 0,
                marginTopDesktop: 5,
                marginTopMobile: 10,
                WidgetComponent: WidgetValueProp,
              },
              {
                data: {
                  ...normalizeContentfulImageData(speakerImage),
                },
                WidgetComponent: WidgetImage,
              },
              {
                data: {
                  header: speakerName,
                  headerFontColor: '#0fa1a2',
                  headerFontSize: 24,
                  headerFontSizeMobile: 22,
                  headerFontWeight: 600,
                  headerLineHeight: 32,
                  headerTag: 'h2',
                  textAlignment: 'center',
                },
                marginBottom: 0,
                marginTopDesktop: 0,
                WidgetComponent: WidgetValueProp,
              },
              {
                data: {
                  header: speakerTitle,
                  headerFontColor: '#0fa1a2',
                  headerFontSize: 18,
                  headerFontSizeMobile: 18,
                  headerFontWeight: 600,
                  headerLineHeight: 23,
                  headerTag: 'h2',
                  textAlignment: 'center',
                },
                marginBottom: 0,
                marginTopDesktop: 0,
                WidgetComponent: WidgetValueProp,
              },
            ],
            orderMobile: 2,
          },
          {
            span: 1,
          },
          {
            span: 8,
            widgets: [
              {
                data: {
                  header: keyLearningsHeader,
                  headerFontColor: colors.ash,
                  headerFontSize: 30,
                  headerFontSizeMobile: 30,
                  headerFontWeight: 600,
                  headerLineHeight: 38,
                  headerTag: 'h2',
                },
                marginBottom: 2,
                marginTopDesktop: 5,
                WidgetComponent: WidgetValueProp,
              },
              {
                data: {
                  description: tryGet(['keyLearningsBody'], keyLearningsBody) || keyLearningsBody,
                },
                marginBottom: 5,
                WidgetComponent: KeyLearningsList,
              },
              {
                data: {
                  callToActionText: callToActionButtonText,
                  marketoFormId,
                },
                marginBottom: 0,
                WidgetComponent: GatedVideoCta,
              },
            ],
            orderMobile: 1,
          },
        ],
      },
      {
        backgroundColor: '#e3efef',
        columns: [
          {
            span: 12,
            widgets: [
              {
                data: {
                  path,
                  twitterText: webinarTitle,
                },
                marginTopDesktop: 0,
                WidgetComponent: WidgetSocialShare,
              },
            ],
          },
        ],
        padding: 0,
      },
      blogSignupSectionProps,
    ],
  };

  return <Page {...pageData} />;
};

Webinar.propTypes = {
  pageContext: PropTypes.shape({
    webinarData: PropTypes.object,
  }).isRequired,
};

export default Webinar;
