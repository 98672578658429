export const tierPricingData = [
  {
    name: 'Starter',
    monthly: {
      catchUp: {
        usd: 189,
      },
      keepUp: {
        usd: 189,
      },
      benchTax: {
        usd: 318,
      },
    },
    annual: {
      catchUp: {
        usd: 159,
      },
      keepUp: {
        usd: 159,
      },
      benchTax: {
        usd: 258,
      },
    },
    expenses: 'below $3k',
    accounts: 5,
  },
  {
    name: 'Micro',
    monthly: {
      catchUp: {
        usd: 219,
      },
      keepUp: {
        usd: 219,
      },
      benchTax: {
        usd: 348,
      },
    },
    annual: {
      catchUp: {
        usd: 199,
      },
      keepUp: {
        usd: 199,
      },
      benchTax: {
        usd: 298,
      },
    },
    expenses: 'from $3k to $20k',
    accounts: 5,
  },
  {
    name: 'Boutique',
    monthly: {
      catchUp: {
        usd: 279,
      },
      keepUp: {
        usd: 279,
      },
      benchTax: {
        usd: 408,
      },
    },
    annual: {
      catchUp: {
        usd: 249,
      },
      keepUp: {
        usd: 249,
      },
      benchTax: {
        usd: 348,
      },
    },
    expenses: 'from $20k to $50k',
    accounts: 7,
  },
  {
    name: 'Venture',
    monthly: {
      catchUp: {
        usd: 359,
      },
      keepUp: {
        usd: 359,
      },
      benchTax: {
        usd: 488,
      },
    },
    annual: {
      catchUp: {
        usd: 299,
      },
      keepUp: {
        usd: 299,
      },
      benchTax: {
        usd: 398,
      },
    },
    expenses: 'from $50k to $100k',
    accounts: 9,
  },
  {
    name: 'Corporate',
    monthly: {
      catchUp: {
        usd: 499,
      },
      keepUp: {
        usd: 499,
      },
      benchTax: {
        usd: 628,
      },
    },
    annual: {
      catchUp: {
        usd: 419,
      },
      keepUp: {
        usd: 419,
      },
      benchTax: {
        usd: 518,
      },
    },
    expenses: 'from $100k to $1M',
    accounts: 20,
  },
];

const calculateDiscountedPrice = (originalPrice, percentDiscount) => {
  if (!percentDiscount) {
    return originalPrice;
  }

  const convertedPercentage = percentDiscount * 0.01;
  const discountedAmount = originalPrice * convertedPercentage;

  return Math.ceil(originalPrice - discountedAmount);
};

const getMonthlyDiscountedPrice = (originalMonthlyPrice, percentDiscount, discountNumMonths) => {
  const isDiscounted =
    percentDiscount && discountNumMonths && percentDiscount > 0 && discountNumMonths > 0;
  if (!isDiscounted) {
    return originalMonthlyPrice;
  }
  return calculateDiscountedPrice(originalMonthlyPrice, percentDiscount);
};

export const getAnnualDiscountedPrice = (
  originalAnnualPrice,
  percentDiscount,
  discountNumMonths,
) => {
  const isDiscounted =
    percentDiscount && discountNumMonths && percentDiscount > 0 && discountNumMonths > 0;
  if (!isDiscounted) {
    return originalAnnualPrice;
  }
  const priceAfterDiscount = calculateDiscountedPrice(originalAnnualPrice, percentDiscount);

  if (discountNumMonths < 12) {
    const totalAnnualPrice =
      priceAfterDiscount * discountNumMonths + (12 - discountNumMonths) * originalAnnualPrice;
    return Math.ceil(totalAnnualPrice / 12);
  }
  return priceAfterDiscount;
};

const generatePricingData = (serviceType, { discountNumMonths, ...discounts }) => {
  return tierPricingData.map(({ name, monthly, annual, expenses, accounts }) => {
    const currency = 'usd';
    return {
      name,
      expenses,
      accounts,
      currency,
      price: {
        annual: annual[serviceType][currency],
        monthly: monthly[serviceType][currency],
        annualDiscount: getAnnualDiscountedPrice(
          annual[serviceType][currency],
          discounts[name.toLowerCase()],
          discountNumMonths,
        ),
        monthlyDiscount: getMonthlyDiscountedPrice(
          monthly[serviceType][currency],
          discounts[name.toLowerCase()],
          discountNumMonths,
        ),
      },
    };
  });
};

export default generatePricingData;
