import PropTypes from 'prop-types';
import React from 'react';

const Widget = ({ WidgetComponent, data }) => {
  const widgetComponentType = typeof WidgetComponent;
  if (widgetComponentType !== 'function' && widgetComponentType !== 'object') {
    throw new TypeError(
      `props.WidgetComponent must be a React component function, but got ${JSON.stringify(
        WidgetComponent,
        null,
        2,
      )}`,
    );
  }

  return <WidgetComponent {...data} />;
};

Widget.propTypes = {
  data: PropTypes.object,
  WidgetComponent: PropTypes.any.isRequired,
};

Widget.defaultProps = {
  data: null,
};
export default Widget;
