import WidgetPricingCardsV2 from '../../../components/widgets/WidgetPricingCardsV2';

const widgetPricingCardsTranslationV2 = ({
  firstPlanName,
  firstPlanDescription,
  secondPlanName,
  secondPlanDescription,
  savings,
  numberOfFreeMonths,
  firstPlanFirstBulletPoint,
  firstPlanSecondBulletPoint,
  firstPlanThirdBulletPoint,
  firstPlanFourthBulletPoint,
  secondPlanFirstBulletPoint,
  secondPlanSecondBulletPoint,
  secondPlanThirdBulletPoint,
  secondPlanFourthBulletPoint,
  firstPlanCta,
  secondPlanCta,
}) => {
  const data = {
    planOne: {
      planName: firstPlanName || '',
      planDescription: firstPlanDescription || '',
      planBulletPoints:
        firstPlanFirstBulletPoint &&
        firstPlanSecondBulletPoint &&
        firstPlanThirdBulletPoint &&
        firstPlanFourthBulletPoint
          ? [
              firstPlanFirstBulletPoint,
              firstPlanSecondBulletPoint,
              firstPlanThirdBulletPoint,
              firstPlanFourthBulletPoint,
            ]
          : [],
      cardColor: 'ghostWhite',
      regularPrice: 299,
      payAsYouGoDiscountedPrice: 349,
      discountedPrice: 249,
      fontColor: 'eerieBlack',
      isPlanOne: true,
      cta: firstPlanCta || 'Start a Free Trial',
    },
    planTwo: {
      planName: secondPlanName || '',
      planDescription: secondPlanDescription || '',
      planBulletPoints:
        secondPlanFirstBulletPoint &&
        secondPlanSecondBulletPoint &&
        secondPlanThirdBulletPoint &&
        secondPlanFourthBulletPoint
          ? [
              secondPlanFirstBulletPoint,
              secondPlanSecondBulletPoint,
              secondPlanThirdBulletPoint,
              secondPlanFourthBulletPoint,
            ]
          : [],
      cardColor: 'deepSapphire',
      discountedPrice: 399,
      regularPrice: 499,
      isPlanOne: false,
      cta: secondPlanCta || 'Start a Free Trial',
    },
    savings: savings || undefined,
    numberOfFreeMonths: numberOfFreeMonths || '',
  };

  return {
    data,
    WidgetComponent: WidgetPricingCardsV2,
  };
};

export default widgetPricingCardsTranslationV2;
