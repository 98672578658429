import styled from 'styled-components';

import colors from '../ui/constants/colors';
import { fontSizingCSS, pxToRem, responsiveFontSizingCSS } from '../ui/constants/typography';
import { mediaQueries } from '../ui/constants/grid';
import { navbarMinHeight } from './nav/NavContainer';

const ArticleBody = styled.div`
  ${fontSizingCSS(18, 28)};
  color: ${colors.ash};

  .toc-anchor {
    position: relative;
    top: -${navbarMinHeight}px;
  }

  dl {
    background: #f6f6f6;
    border-radius: 15px;
    color: #4a4b4e;
    margin-bottom: ${pxToRem(40)}rem;
    margin-top: ${pxToRem(40)}rem;
    padding: 40px 102px 40px 48px;

    ${mediaQueries.mobile`
      padding: 45px 33px 49px 35px;
    `};

    dt {
      border-left: solid 3px #0fa1a2;
      ${fontSizingCSS(18, 28)};
      font-weight: 600;
      padding-bottom: 1.5rem;
      padding-left: 34px;

      ${mediaQueries.mobile`
        padding-left: 18px;
      `};

      &::before {
        content: '“';
      }

      &::after {
        content: '”';
      }
    }

    dd {
      border-left: solid 3px #0fa1a2;
      margin: 0;
      padding-left: 1.5rem;
      ${fontSizingCSS(18, 28)};
      font-weight: normal;
      font-style: normal;

      &::before {
        content: '- ';
      }
    }
  }

  blockquote {
    color: #0fa1a2;
    font-family: LeituraDisplay, serif;
    font-weight: normal;
    max-width: 390px;
    position: relative;
    quotes: none;
    text-indent: 1rem;
    margin-bottom: ${pxToRem(56)}rem;
    margin-top: ${pxToRem(56)}rem;

    ${mediaQueries.mobile`
      margin-right: 1rem;
    `};

    p {
      color: #64c9ba;
      display: inline;
      ${responsiveFontSizingCSS({
        fontSizePixels: 28,
        lineHeightPixels: 36,
        mobileFontSizePixels: 24,
        mobileLineHeightPixels: 34,
      })}
    }

    &::before {
      content: '“';
      font-size: 4em;
      position: absolute;
      transform: translate(-140%, -25%);
    }

    &::after {
      content: '”';
      font-size: 4em;
      position: absolute;
      right: 0;
      transform: translate(75%, 70%);
      bottom: 0;

      ${mediaQueries.mobile`
        transform: translate(50%,70%);
      `};
    }
  }

  p {
    margin-bottom: ${pxToRem(28)}rem;
  }

  hr {
    background: rgba(0, 0, 0, 0.1);
    border: 0 none;
    display: block;
    height: 1px;
    margin: ${pxToRem(28)}rem auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  h1,
  h2 {
    ${responsiveFontSizingCSS({
      fontSizePixels: 30,
      lineHeightPixels: 38,
      mobileFontSizePixels: 26,
      mobileLineHeightPixels: 38,
    })};
    margin-top: ${pxToRem(48)}rem;
    margin-bottom: ${pxToRem(32)}rem;
  }

  h3 {
    ${responsiveFontSizingCSS({
      fontSizePixels: 24,
      lineHeightPixels: 32,
      mobileFontSizePixels: 22,
      mobileLineHeightPixels: 32,
    })};
    margin-top: ${pxToRem(32)}rem;
    margin-bottom: ${pxToRem(8)}rem;
  }

  h4,
  h5 {
    ${fontSizingCSS(18, 28)};
    font-weight: bold;
    margin-top: ${pxToRem(28)}rem;
    margin-bottom: ${pxToRem(4)}rem;
  }

  h6 {
    ${fontSizingCSS(16, 24)};
    margin-top: ${pxToRem(24)}rem;
  }

  img {
    margin: ${pxToRem(28)}rem auto;
  }

  ol {
    list-style-position: outside;
    list-style-type: decimal;
    margin: ${pxToRem(28)}rem 0;
    padding-left: 40px;

    ol {
      li {
        list-style-type: lower-alpha;
      }
    }
  }

  ul {
    list-style-position: outside;
    list-style-type: disc;
    margin: ${pxToRem(28)}rem 0;
    padding-left: 40px;

    ul {
      li {
        list-style-type: circle;
      }
    }
  }

  li {
    ${fontSizingCSS(18, 28)};
    padding-left: 0.5rem;
    margin: ${pxToRem(10)}rem 0;

    ol,
    ul {
      margin: 0;
      padding-left: 20px;
    }
  }

  a {
    color: ${colors.water};

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  b,
  strong {
    font-weight: bold;
  }

  em,
  i {
    font-style: italic;
  }

  table {
    background: #ffffff;
    border: solid 1px #d4d2ce;
    margin-bottom: 2rem;
    table-layout: fixed;
    width: 100%;
  }

  td,
  th {
    border-left: solid 1px #d4d2ce;
    border-right: solid 1px #d4d2ce;
    padding: 1rem 1.5rem;
    font-size: 14px;
    text-align: left;

    ${mediaQueries.mobile`
      padding: .5rem;
    `};
  }

  td {
    line-height: 1.5;
    color: #4a4b4e;
  }

  th {
    background-color: #f6f6f6;
    border-bottom: solid 4px #0fa1a2;
    font-weight: 600;
    line-height: 1.5;
    color: #0fa1a2;
  }

  tr:nth-child(even) {
    background-color: #f6f6f6;
  }
`;

export default ArticleBody;
