export const contentfulQueryBuilder = ({ height, quality, webp, width }) => {
  const query = {};

  if (webp) {
    query.fm = 'webp';
  }

  if (height) {
    query.h = Math.floor(height);
  }

  if (width) {
    query.w = width;
  }

  if (quality) {
    query.q = quality;
  }

  return query;
};

export const contentfulPrefix = 'https:';
