import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import colors from '../constants/colors';

const StyledSVG = styled.svg`
  fill: ${({ color }) => color};
  height: 24px;
  width: 24px;
`;

const ArrowNarrow = ({ color, size }) => (
  <StyledSVG color={color} size={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M19.53033,6.53033l-5,5a.75.75,0,0,1-1.06066-1.06066L17.18933,6.75H1.59412a.75.75,0,0,1,0-1.5H17.18933L13.46967,1.53033A.75.75,0,0,1,14.53033.46967l5,5A.75.75,0,0,1,19.53033,6.53033Z"
        transform="translate(-0.84412 -0.24998)"
      />
    </g>
  </StyledSVG>
);

ArrowNarrow.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

ArrowNarrow.defaultProps = {
  color: colors.charcoal,
  size: 24,
};

export default ArrowNarrow;
