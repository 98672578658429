import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import copyrightText from '../helpers/copyrightText';
import FooterContainer from './FooterContainer';
import Link from '../../ui/buttons/Link';
import { mediaQueries } from '../../ui/constants/grid';

const MinimalFooterSeparator = styled.span`
  ${mediaQueries.desktop`
    &::before {
      color: ${colors.dust};
      content: "\\2014";
      margin: 0 8px;
    }
  `} ${mediaQueries.mobile`
    display: block;
  `};
`;

const MinimalFooter = ({ backgroundColor, noPadding }) => (
  <FooterContainer
    backgroundColor={backgroundColor}
    fontSize={12}
    noPadding={noPadding}
    textAlignment="center"
  >
    <span className="-muted">{copyrightText}</span>
    <MinimalFooterSeparator />
    <Link className="-muted" linkURL="/terms/">
      Terms of Service
    </Link>
    <MinimalFooterSeparator />
    <Link className="-muted" linkURL="/privacy/">
      Privacy Policy
    </Link>
    <MinimalFooterSeparator />
    <Link className="-muted" linkURL="/terms-use/">
      Terms of Use
    </Link>
    <MinimalFooterSeparator />
    <Link className="-muted" linkURL="/cookie-policy/">
      Cookie Policy
    </Link>
    <MinimalFooterSeparator />
    <Link className="-muted" linkURL="/security-practices/">
      Security Practices
    </Link>
  </FooterContainer>
);

MinimalFooter.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  noPadding: PropTypes.bool,
};

MinimalFooter.defaultProps = {
  noPadding: false,
};

export default MinimalFooter;
