import React, { createRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { createPopper } from '@popperjs/core';
import NavDropdownLinks from './NavDropdownLinks';
import CaretUp from '../../ui/icons/CaretUp';
import CaretDown from '../../ui/icons/CaretDown';
import colors from '../../ui/constants/colors';
import Link from '../../ui/buttons/Link';
import { mediaQueries } from '../../ui/constants/grid';

const NavLinkDropdownTextWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

const NavLinkDropdownText = ({ linkText, isOpen }) => (
  <NavLinkDropdownTextWrapper>
    <div>{linkText}</div>
    {isOpen ? <CaretUp /> : <CaretDown />}
  </NavLinkDropdownTextWrapper>
);

NavLinkDropdownText.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  linkText: PropTypes.string.isRequired,
};

const DesktopNavLinkDropdown = styled.div`
  ${mediaQueries.mobile`
    display: none;
  `};
`;

const MobileNavLinkDropdown = styled.div`
  ${mediaQueries.desktop`
    display: none;
  `};
`;

const Bubble = styled.div`
  width: 400px;
  margin-top: 8px;
  padding: 8px 20px;
  border-radius: 6px;
  box-shadow: 1px 1px 18px 0 rgba(0, 0, 0, 0.15);
  background-color: ${colors.white};
  position: relative;
  z-index: 2;
`;

const BubbleWrapper = styled.div`
  ${mediaQueries.mobile`
      display: none;
  `};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  display: ${({ popperLoaded }) => (popperLoaded ? 'initial' : 'none')};
`;

const NavLinkDropdown = ({ linkText, dropdownLinks }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [popperLoaded, setPopperLoaded] = useState(false);
  const [popper, setPopper] = useState(null);

  const anchorRef = createRef();
  const popupRef = createRef();

  const createPopperFromRef = () => {
    setPopper(
      createPopper(anchorRef.current, popupRef.current, {
        placement: 'bottom-start',
      }),
    );
  };

  useLayoutEffect(() => {
    if (popper) {
      popper.update();
    }

    createPopperFromRef();
    setPopperLoaded(true);

    return () => {
      if (popper) {
        popper.destroy();
      }
    };
  }, []);

  const openDropdown = () => {
    setIsOpen(true);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  return (
    <>
      <DesktopNavLinkDropdown onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
        <div ref={anchorRef}>
          <Link onClick={isOpen ? closeDropdown : openDropdown}>
            <NavLinkDropdownText isOpen={isOpen} linkText={linkText} />
          </Link>
          <BubbleWrapper ref={popupRef} isOpen={isOpen} popperLoaded={popperLoaded}>
            <Bubble>{isOpen && <NavDropdownLinks dropdownLinks={dropdownLinks} />}</Bubble>
          </BubbleWrapper>
        </div>
      </DesktopNavLinkDropdown>
      <MobileNavLinkDropdown>
        <Link onClick={isOpen ? closeDropdown : openDropdown}>
          <NavLinkDropdownText isOpen={isOpen} linkText={linkText} />
        </Link>
        {isOpen && <NavDropdownLinks dropdownLinks={dropdownLinks} />}
      </MobileNavLinkDropdown>
    </>
  );
};

NavLinkDropdown.propTypes = {
  dropdownLinks: PropTypes.arrayOf(
    PropTypes.shape({
      dropdownLinkDescription: PropTypes.string,
      dropdownLinkText: PropTypes.string,
      dropdownLinkURL: PropTypes.string,
    }),
  ).isRequired,
  linkText: PropTypes.string.isRequired,
};

export default NavLinkDropdown;
