import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../../../ui/constants/colors';
import Page from '../../../components/Page';
import WidgetArticleBody from '../../../components/widgets/WidgetArticleBody';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';
import policyCopyrightText from '../helpers/policyCopywriteText';

const md = `
These terms of use (the “**Agreement**”) form an agreement between you
(“**You**” or “**Customer**”) and Bench Accounting Inc. (the
“**Company**”, “**We**” or “**Us**”). The effective date of this
Agreement is when You accept or are deemed to accept this Agreement as
discussed below.

You hereby consent to the exchange of information and documents between
You and Us electronically over the Internet or by email and that this
electronic Agreement shall be the equivalent of a written paper
agreement between You and Us.

### 1.  Ownership and Copyright.

You acknowledge that any and all
information, content, reports, data, databases, graphics,
interfaces, web pages, text, files, software, product names, company
names, trade-marks, logos and trade-names contained on this website
(collectively the “**Content**”), including the manner in which the
Content is presented or appears and all information relating
thereto, are the property of their respective owners as indicated,
the Company or its licensors, as the case may be.

### 2.  Permitted Use.

The Company hereby grants to You a limited,
revocable, personal, non-transferable and non-exclusive license to
access, read and download one copy of the Content solely for the
purpose of evaluating the services offered by us (“**Services**”).

### 3.  Restrictions On Use.

You agree that You will not:

1.  distribute the Content for any purpose, including, without
    limitation, compiling an internal database, redistributing or
    reproducing the Content by way of the press or media or through
    any commercial network, cable or satellite system; or

2.  create derivative works of, reverse engineer, decompile,
    disassemble, adapt, translate, transmit, arrange, modify, copy,
    bundle, sell, sublicense, export, merge, transfer, adapt, loan,
    rent, lease, assign, share, outsource, host, publish, make
    available to any person or otherwise use, either directly or
    indirectly, the Content in whole or in part, in any form or by
    any means whatsoever, be they physical, electronic or otherwise.
    You shall not permit, allow or do anything that would infringe
    or otherwise prejudice the proprietary rights of the Company or
    its licensors or allow any third party to access the Content.
    The restrictions set out in this Agreement shall not apply to
    the limited extent the restrictions are prohibited by applicable
    law.

### 4.  License to Use Your Information.

With the exception of your
non-public personal information, You hereby grant to the Company the
perpetual, unlimited, royalty-free, worldwide, non-exclusive,
irrevocable, transferable license to run, display, copy, reproduce,
publish, bundle, distribute, market, create derivative works of,
adapt, translate, transmit, arrange, modify, sublicense, export,
merge, transfer, loan, rent, lease, assign, share, outsource, host,
make available to any person or otherwise use any information or
other content You provide on or through this website or which is
sent to the Company by email or other correspondence, including,
without limitation, any ideas, concepts, inventions, know-how,
techniques or any intellectual property contained therein, for any
purpose whatsoever. The Company shall not be subject to any
obligations of confidentiality regarding any such information unless
specifically agreed to by the Company in writing or required by law.
You represent and warrant that you have the right to grant the
license set out above.

### 5.  Personal Information.

The Company may from time to time, but is not
obligated to, monitor your use of the website and collect, store,
use and disclose information concerning you to solicit you to offer
you its Services, and You hereby consent to such collection,
storage, use and disclosure subject to the Bench Privacy Policy,
posted here [https://bench.co/privacy](https://bench.co/privacy),
and waive any right of privacy You may have in it.

### 6.  Service Agreement.

If You procure Services from the Company or one
of its suppliers or sponsors, then such Services shall be delivered
pursuant to a separate agreement, such as the Bench Service Terms of
Use, and are not provided hereunder and You shall have no rights or
claims in respect of such Services hereunder.

### 7.  Limitations on Liability and Disclaimers.

THERE IS NO GUARANTEE THAT PERSONAL INFORMATION AND TRANSACTIONS ON
THIS WEBSITE OR ON THE INTERNET WILL BE MAINTAINED CONFIDENTIAL AND
SECURE. THE USE OF THIS WEBSITE AND THE CONTENT IS AT YOUR OWN RISK,
AND THE COMPANY ASSUMES NO LIABILITY OR RESPONSIBILITY PERTAINING TO
THE CONTENT, YOUR USE OF THE WEBSITE OR THE RECEIPT, STORAGE,
TRANSMISSION OR OTHER USE OF YOUR PERSONAL INFORMATION.

This website may contain links to other sites. The Company does not
assume responsibility for the accuracy or appropriateness of the
information, data, opinions, advice or statements contained at such
sites, and when You access such sites, You are doing so at Your own
risk. In providing links to the other sites, the Company is in no way
acting as a publisher or disseminator of the material contained on
those other sites and does not seek to monitor or control such sites.
A link to another site should not be construed to mean that the
Company is affiliated or associated with same. THE COMPANY DOES NOT
RECOMMEND OR ENDORSE ANY OF THE CONTENT, INCLUDING WITHOUT LIMITATION
ANY HYPERLINKS TO, OR CONTENT FOUND, ON OTHER WEBSITES. The mention of
another party or its product or service on this website should not be
construed as an endorsement of that party or its product or service.

The Company will not be responsible for any damages You or any third
party may suffer as a result of the transmission, storage or receipt
of confidential or proprietary information that You make or that You
expressly or implicitly authorize the Company to make, or for any
errors or any changes made to any transmitted, stored or received
information.

You are solely responsible for the retrieval and use of the Content.
You should apply Your own judgment in making any use of any Content,
including, without limitation, the use of the information as the basis
for any conclusions.

THE CONTENT MAY NOT BE ACCURATE, UP TO DATE, COMPLETE OR UNTAMPERED,
AND IS NOT TO BE RELIED UPON.

THE CONTENT IS PROVIDED FOR INFORMATIONAL PURPOSES ONLY AND SHOULD NOT
BE INTERPRETED AS A RECOMMENDATION FOR ANY SPECIFIC PRODUCT OR
SERVICE, USE OR COURSE OF ACTION. ADDITIONALLY, ANY OPINIONS EXPRESSED
ON THIS WEBSITE ARE THE OPINIONS OF THE PARTICULAR AUTHOR AND MAY NOT
REFLECT THE OPINIONS OF ANY LICENSED LEGAL, TAX, ACCOUNTING OR OTHER
PROFESSIONAL EMPLOYED BY BENCH.  AS SUCH, YOU SHOULD NOT ACT BASED ON
THE CONTENT BEFORE OBTAINING ADVICE FROM PROFESSIONAL COUNSEL QUALIFIED
IN THE APPLICABLE SUBJECT MATTER AND JURISDICTION.

EXCEPT AS EXPRESSLY PROVIDED IN A SEPARATE AGREEMENT WITH YOU, THIS
WEBSITE AND ALL CONTENT, PRODUCTS, SERVICES AND SOFTWARE ON THIS
WEBSITE OR MADE AVAILABLE THROUGH THIS WEBSITE ARE PROVIDED “AS IS”
WITHOUT ANY REPRESENTATIONS, WARRANTIES, GUARANTEES OR CONDITIONS, OF
ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
INCLUDING, BUT NOT LIMITED TO, WARRANTIES AS TO UNINTERRUPTED OR
ERROR-FREE OPERATION, AVAILABILITY, ACCURACY, COMPLETENESS,
RELIABILITY, TIMELINESS, LEGALITY, SUITABILITY, PRIVACY, SECURITY,
MERCHANTABILITY, QUALITY, TITLE, NON-INFRINGEMENT OR FITNESS FOR A
PARTICULAR PURPOSE, OR THOSE ARISING OUT OF A COURSE OF DEALING OR
USAGE OF TRADE.

IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, AGENTS, LICENSORS,
SUPPLIERS OR THEIR RESPECTIVE DIRECTORS, OFFICERS OR EMPLOYEES BE
LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY,
AGGRAVATED, ECONOMIC OR CONSEQUENTIAL DAMAGES, HOWSOEVER CAUSED,
INCLUDING BUT NOT LIMITED TO: DAMAGES FOR LOSS OF USE, LOST PROFITS OR
LOST SAVINGS, EVEN IF THE COMPANY OR ANY OF ITS LAWFUL AGENTS OR
EMPLOYEES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR
CLAIM.

IN NO EVENT WILL COMPANY, ITS AFFILIATES, AGENTS, LICENSORS, SUPPLIERS
OR THEIR RESPECTIVE DIRECTORS, OFFICERS OR EMPLOYEES BE LIABLE FOR
DAMAGES OR LOSSES RESULTING FROM: VIRUSES, DATA CORRUPTION, FAILED
MESSAGES, TRANSMISSION ERRORS OR PROBLEMS; TELECOMMUNICATIONS SERVICE
PROVIDERS; LINKS TO THIRD PARTY WEBSITES; THE INTERNET BACKBONE;
PERSONAL INJURY; THIRD PARTY CONTENT, PRODUCTS OR SERVICES; DAMAGES OR
LOSSES CAUSED BY YOU OR YOUR RESPECTIVE EMPLOYEES, AGENTS OR
SUBCONTRACTORS; LOSS OF USE OR LACK OF AVAILABILITY OF FACILITIES,
INCLUDING COMPUTER RESOURCES, ROUTERS AND STORED DATA; THE USE OR
INABILITY TO USE THIS WEBSITE OR THE CONTENT; ANY OTHER WEBSITE
ACCESSED TO OR FROM THIS WEBSITE; OR EVENTS BEYOND THE REASONABLE
CONTROL OF THE COMPANY, EVEN IF THE COMPANY OR ANY OF ITS LAWFUL
AGENTS OR EMPLOYEES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
DAMAGES OR CLAIM.

IN NO CASE WILL THE COMPANY'S, ITS AFFILIATES', AGENTS', LICENSORS',
SUPPLIERS' AND THEIR RESPECTIVE DIRECTORS', OFFICERS' AND EMPLOYEES'
CUMULATIVE TOTAL LIABILITY ARISING UNDER ANY CAUSE WHATSOEVER
(INCLUDING WITHOUT LIMITATION BREACH OF CONTRACT, TORT, NEGLIGENCE,
GROSS NEGLIGENCE OR OTHERWISE) BE FOR MORE THAN TWENTY DOLLARS $20.00.

THE COMPANY ASSUMES NO OBLIGATION TO UPDATE THE CONTENT ON THIS SITE.
THE CONTENT ON THIS SITE MAY BE CHANGED WITHOUT NOTICE TO YOU. THE
COMPANY IS NOT RESPONSIBLE FOR ANY CONTENT OR INFORMATION THAT YOU MAY
FIND UNDESIRABLE OR OBJECTIONABLE. THE COMPANY DISCLAIMS ANY LIABILITY
FOR UNAUTHORIZED USE OR REPRODUCTION OF ANY PORTION OF THE WEBSITE.
ACCESSING THE CONTENT FROM TERRITORIES WHERE IT MAY BE ILLEGAL IS
PROHIBITED.

### 8.  Termination.

This Agreement is effective until terminated by the
Company, with or without cause, in the Company's sole and unfettered
discretion. The Company may terminate this Agreement without notice
to You for any reason or for no reason. Any such termination by the
Company shall be in addition to and without prejudice to such rights
and remedies as may be available to the Company, including
injunction and other equitable remedies.

The disclaimers, limitations on liability, ownership, termination,
interpretation, Your license to the Company, Your warranty and the
indemnity provisions of this Agreement shall survive the termination
or expiry of this Agreement.

### 9.  Indemnity.

You agree at all times to indemnify, defend and hold
harmless the Company, its agents, suppliers, licensors, affiliates
and their respective directors and employees against all actions,
proceedings, costs, claims, damages, demands, liabilities and
expenses whatsoever (including legal and other fees and
disbursements) sustained, incurred or paid by the Company directly
or indirectly in respect of:

1.  any information or other content You provide on or through this
    website or which is sent to the Company by email or other
    correspondence; or

2.  Your use or misuse of the Content or this website, including
    without limitation infringement claims.

### 10.  Governing Law and Arbitration.

PLEASE READ THIS SECTION CAREFULLY,
AS IT LIMITS THE MANNER IN WHICH CUSTOMER MAY SEEK RELIEF, AND
REQUIRES YOU ARBITRATE DISPUTES WITH BENCH. If Customer has a
dispute with Bench, Bench will first seek to resolve such a dispute
through our support team.

All disputes arising under or in connection with the Agreement will be
submitted to binding arbitration in Wilmington, Delaware, USA pursuant
to the Commercial Arbitration Rules of the American Arbitration
Association (“AAA”) and the procedures set forth below.

Arbitration. All disputes that cannot be resolved pursuant to the
internal issue resolution process identified above will be submitted to
and settled by final and binding arbitration. The arbitration will take
place in Wilmington, Delaware, USA and will apply the governing law of
this Agreement. The final and binding arbitration will be performed by a
single arbitrator who is a practicing commercial lawyer in English and
in accordance with and subject to the Commercial Arbitration Rules of
the AAA then in effect. The decision of the arbitrator will be final and
binding, and judgment on the award may be entered in any court of
competent jurisdiction. The arbitrator will be bound by the warranties,
limitations of liability, and other provisions of this Agreement.
Notwithstanding the foregoing, each party may seek injunctive relief in
a court of competent jurisdiction, where appropriate, to protect its
rights pending the outcome of the arbitration.

Jurisdiction/Venue; Governing Law. With respect to Customers located in
the U.S., this Agreement shall be governed by and construed in
accordance with the laws of the State of Delaware (irrespective of its
choice of law principles). The parties hereby agree that any suit to
enforce any provision of this Agreement or arising out of or based upon
this Agreement or the business relationship between the parties hereto
shall be brought in federal or state court in Wilmington, Delaware, USA.
Each party hereby agrees that such courts shall have exclusive personal
jurisdiction and venue with respect to such party, and each party hereby
submits to the exclusive personal jurisdiction and venue of such courts.

NOTICE: BOTH CUSTOMER AND BENCH AGREE TO HAVE ALL DISPUTES, CLAIMS OR
CONTROVERSIES ARISING OUT OF OR RELATING TO THIS AGREEMENT DECIDED BY
NEUTRAL BINDING ARBITRATION AND ARE GIVING UP ANY RIGHTS IT MIGHT
POSSESS TO HAVE THOSE MATTERS LITIGATED IN A COURT OR JURY TRIAL. BY
AGREEING TO THIS AGREEMENT, BOTH CUSTOMER AND Bench ARE GIVING UP THEIR
RESPECTIVE JUDICIAL RIGHTS TO DISCOVERY AND APPEAL EXCEPT TO THE EXTENT
THAT THEY ARE SPECIFICALLY PROVIDED FOR UNDER THIS AGREEMENT. IF ANY
PARTY REFUSES TO SUBMIT TO ARBITRATION AFTER AGREEING TO THIS PROVISION,
THAT PARTY MAY BE COMPELLED TO ARBITRATE UNDER FEDERAL, STATE OR
PROVINCIAL LAW. BOTH CUSTOMER AND BENCH CONFIRM THEIR AGREEMENT TO THIS
ARBITRATION PROVISION IS VOLUNTARY.

### 11.  Interpretation.
 
The division of this Agreement into sections and the
insertion of headings are for convenience of reference only and
shall not affect the construction or interpretation of this
Agreement. In this Agreement, words importing the singular number
include the plural and vice versa; words importing gender include
all genders; and words importing persons include individuals, sole
proprietors, partnerships, corporations, trusts and unincorporated
associations.

### 12.  Entire Agreement.

This Agreement as it may be amended from time to
time in accordance with the provisions of Section 12, and any and
all other legal notices and policies on this website, constitutes
the entire agreement between You and the Company with respect to the
use of this website and the Content.

### 13.  Amendment and Waiver.

The Company reserves the right, in its
discretion, to amend this Agreement at any time by posting
amendments on this website. You are responsible for periodically
reviewing the amendments on this website, and You are deemed to be
aware of such amendments. If You do not agree to the amended terms
and conditions, You shall immediately stop using this website.
Access to this website or use of this website after any amendments
have been posted shall constitute Your acknowledgement and
acceptance of the amended terms and conditions. No supplement,
modification or amendment to this Agreement and no waiver of any
provision of this Agreement shall be binding on the Company unless
executed by the Company in writing. No waiver of any of the
provisions of this Agreement shall be deemed to be or shall
constitute a waiver of any other provision (whether or not similar)
nor shall such waiver constitute a continuing waiver unless
otherwise expressly provided.

### 14.  Severability.

Any provision of this Agreement which is held by a
court of competent jurisdiction to be illegal, invalid or
unenforceable in such jurisdiction shall, as to that jurisdiction,
be ineffective to the extent of such illegality, invalidity or
unenforceability and shall otherwise be enforced to the maximum
extent permitted by law, all without affecting the remaining
provisions of this Agreement or affecting the legality, validity or
enforceability of such provision in any other jurisdiction.

### 15.  Enurement.

This Agreement shall inure to the benefit of and be
binding upon each of us and our respective successors and permitted
assigns. You acknowledge having read this Agreement before accepting
it, having the authority to accept this Agreement and having
received a copy of this Agreement.

${policyCopyrightText}
`;

const TermsUseBodyContainer = styled.div`
  && {
    dt::before,
    dt:after,
    dd::before {
      display: none;
    }

    dt {
      font-weight: normal;
    }

    dd {
      font-weight: 700;
    }

    ol {
      list-style-type: lower-alpha;

      ol li {
        list-style-type: decimal;
      }
    }
  }
`;

const TermsUseBody = ({ markdown, path }) => (
  <TermsUseBodyContainer>
    <WidgetArticleBody markdown={markdown} path={path} />
  </TermsUseBodyContainer>
);

TermsUseBody.propTypes = {
  markdown: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

const pageData = {
  description: '',
  enableAbTesting: false,
  footerType: 'full',
  navbarType: 'full',
  path: '/terms-use/',
  title: 'Terms of Use — Bench',
  sections: [
    {
      columns: [
        {
          span: 5,
          widgets: [
            {
              data: {
                description: 'Last updated: December 16, 2020',
                header: 'Bench Accounting Inc.\nWebsite Terms of Use',
                textAlignment: 'center',
              },
              WidgetComponent: WidgetValueProp,
            },
          ],
        },
      ],
      paddingBottom: 0,
      margin: 0,
    },
    {
      backgroundColor: colors.white,
      columns: [
        {
          span: 9,
          widgets: [
            {
              data: {
                markdown: md,
                path: '/terms-use/',
              },
              WidgetComponent: TermsUseBody,
            },
          ],
        },
      ],
      paddingTop: 0,
    },
  ],
};

const TermsUsePage = () => <Page {...pageData} />;

export default TermsUsePage;
