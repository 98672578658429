import tryGet from '../../components/helpers/tryGet';

const normalizeContentfulImageData = (imageData, mobile = false) => ({
  alt: tryGet(['description'], imageData),
  fromContentful: true,
  [mobile ? 'mobileNaturalHeight' : 'naturalHeight']: tryGet(
    ['file', 'details', 'image', 'height'],
    imageData,
  ),
  [mobile ? 'mobileNaturalWidth' : 'naturalWidth']: tryGet(
    ['file', 'details', 'image', 'width'],
    imageData,
  ),
  [mobile ? 'mobileSrc' : 'src']: tryGet(['file', 'url'], imageData),
});

export default normalizeContentfulImageData;
