import url from 'url';

const isGatsbyLink = (linkURL) => {
  if (typeof linkURL !== 'string') {
    return false;
  }

  const parsedURL = url.parse(linkURL);
  const hasProtocol = !!parsedURL.protocol;
  if (hasProtocol) {
    return false;
  }
  const isRelativePath = parsedURL.pathname === null || parsedURL.pathname[0] !== '/';
  if (isRelativePath) {
    return false;
  }

  const regex = new RegExp('^\\/([-a-zA-Z\\d]+(\\/|.pdf|\\/?.+))*$');
  return regex.test(linkURL);
};

export default isGatsbyLink;
