import { makeGetter } from '../../../components/helpers/tryGet';
import WidgetImage from '../../../components/widgets/WidgetImage';

const getImageURL = makeGetter('file', 'url');
const getImageHeight = makeGetter('file', 'details', 'image', 'height');
const getImageWidth = makeGetter('file', 'details', 'image', 'width');
const getImageDescription = makeGetter('description');

const widgetBasicImageTranslation = ({
  desktopMaxWidth,
  imageAsset,
  mobileImageAsset,
  linkUrl,
  imageAlignmentInColumn,
}) => {
  const translatedData = {
    alt: getImageDescription(imageAsset),
    fromContentful: true,
    imageAlignment: imageAlignmentInColumn,
    linkURL: linkUrl,
    mobileNaturalHeight: getImageHeight(mobileImageAsset),
    mobileNaturalWidth: getImageWidth(mobileImageAsset),
    mobileSrc: getImageURL(mobileImageAsset),
    naturalHeight: getImageHeight(imageAsset),
    naturalWidth: getImageWidth(imageAsset),
    src: getImageURL(imageAsset),
  };

  if (desktopMaxWidth) {
    translatedData.maxWidth = desktopMaxWidth;
    translatedData.mobileMaxWidth = desktopMaxWidth;
  }

  return {
    data: translatedData,
    WidgetComponent: WidgetImage,
  };
};

export default widgetBasicImageTranslation;
