import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const scrollToTopOfPage = () => window.scrollTo(0, 0);

const LinearFlowForm = ({ defaultFormData, views }) => {
  const [currentViewIndex, setCurrentViewIndex] = useState(0);
  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    scrollToTopOfPage();
  }, [currentViewIndex, formData]);

  const goToNextView = (collectedFormData) => {
    setCurrentViewIndex(Math.min(currentViewIndex + 1, views.length - 1));
    setFormData({ ...formData, ...collectedFormData });
  };

  const goToPreviousView = (collectedFormData) => {
    setCurrentViewIndex(Math.max(currentViewIndex - 1, 0));
    setFormData({ ...formData, ...collectedFormData });
  };

  if (views.length === 0) {
    return null;
  }
  const { props, ViewComponent } = views[currentViewIndex];
  return (
    <ViewComponent
      key={`viewComponent_${currentViewIndex}`}
      {...props}
      formData={formData}
      goToNextView={goToNextView}
      goToPreviousView={goToPreviousView}
    />
  );
};

LinearFlowForm.propTypes = {
  defaultFormData: PropTypes.object.isRequired,
  views: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.object,
      ViewComponent: PropTypes.any,
    }),
  ).isRequired,
};

export default LinearFlowForm;
