import WidgetCallToActionV2 from '../../../components/widgets/WidgetCallToActionV2';

const widgetCallToActionV2Translation = ({
  buttonAlignmentInColumn,
  buttonText,
  linkUrl,
  styleType,
  width,
}) => {
  const translatedData = {
    buttonAlignment: buttonAlignmentInColumn || 'left',
    buttonText: buttonText || 'Button Text Template',
    linkURL: linkUrl || '/',
    styleType: styleType || 'primary',
    width: width === 'standard' ? 'auto' : '100%',
  };

  return {
    data: translatedData,
    WidgetComponent: WidgetCallToActionV2,
  };
};

export default widgetCallToActionV2Translation;
