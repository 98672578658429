import PropTypes from 'prop-types';
import React from 'react';
import colors from '../constants/colors';

const FilledInCheckMark = ({ fill }) => (
  <svg
    height="16px"
    version="1.1"
    viewBox="0 0 17 16"
    width="17px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd" id="202107-(DEBUNDLE-™)" stroke="none" strokeWidth="1">
      <g id="2.1_cardDesktop" transform="translate(-334.000000, -928.000000)">
        <g id="Group" transform="translate(334.000000, 928.000000)">
          <rect height="16" id="Rectangle" width="16" x="0" y="0" />
          <rect height="12" id="Rectangle" width="16" x="0" y="2" />
          <g
            fill={fill}
            fillRule="nonzero"
            id="check-(2)"
            transform="translate(0.145924, 2.686803)"
          >
            <path
              d="M13.8992722,0.330143199 C14.3947377,-0.123994858 15.1808917,-0.107569935 15.6551964,0.366829291 C16.0978807,0.809601902 16.1124497,1.49482997 15.7095929,1.95345689 L15.6168812,2.04809477 L6.21052292,10.6698556 C5.78577207,11.0591773 5.14010948,11.1064813 4.6614239,10.7994779 L4.55409865,10.7223359 L0.444331082,7.42518778 C-0.0814056108,7.00340438 -0.150492877,6.25340857 0.290020354,5.75002526 C0.701166037,5.28020083 1.41091737,5.19125537 1.93116697,5.52440962 L2.03957061,5.60227554 L5.29657607,8.21537474 L13.8992722,0.330143199 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

FilledInCheckMark.propTypes = {
  fill: PropTypes.string,
};

FilledInCheckMark.defaultProps = {
  fill: colors.bonsai,
};

export default FilledInCheckMark;
