import styled from 'styled-components';
import grid from '../constants/grid';

const GridContainer = styled.div`
  margin: 0 auto;
  max-width: ${grid.containerWidth + grid.gutterWidth}px;
  padding: 0 ${grid.gutterWidth}px;

  & & {
    margin: 0;
    max-width: none;
    padding: 0;
  }
`;

export default GridContainer;
