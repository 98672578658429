import styled from 'styled-components';

const UnstyledButton = styled.button`
  background: transparent;
  border: 0 none;
  border-radius: 0;
  display: inline-block;
  font-family: LLCircularWeb, sans-serif;
  padding: 0;
`;

export default UnstyledButton;
