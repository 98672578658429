import translateWidgetData from './translateWidgetData';

export const MARGIN_UNITS = {
  FULL: 'full',
  HALF: 'half',
  NONE: 'none',
};

const getUnitsFromString = (humanReadableSize) => {
  switch (humanReadableSize) {
    case MARGIN_UNITS.FULL:
      return 3;
    case MARGIN_UNITS.HALF:
      return 1.5;
    case MARGIN_UNITS.NONE:
      return 0;
    default:
      return 0;
  }
};

const translateColumnData = ({
  backgroundColor,
  borderRadius,
  boxShadow,
  height,
  mobileSpan,
  padding,
  span,
  widgets,
  widgetSpacing,
}) => {
  const translatedData = {
    backgroundColor,
    borderRadius: borderRadius === 'border' ? '12px' : 'none',
    boxShadow,
    height,
    padding: padding === 'padding' ? '30px' : 'none',
    spanMobile: mobileSpan,
    span,
    widgets: widgets ? widgets.map(translateWidgetData).filter((w) => w) : [],
  };

  if (widgetSpacing) {
    translatedData.widgetMargin = getUnitsFromString(widgetSpacing);
  }
  return translatedData;
};

export default translateColumnData;
