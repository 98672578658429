import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Link from './Link';
import buttonStyles from './ButtonStyles';

const LinkButtonV2 = styled(
  ({
    // Omit button-related props from `otherProps` being passed to `Link`
    styleType,
    width,
    ...otherProps
  }) => <Link {...otherProps} />,
)`
  ${buttonStyles}
`;

LinkButtonV2.propTypes = {
  linkURL: PropTypes.string,
  onClick: PropTypes.func,
  styleType: PropTypes.string,
};

LinkButtonV2.defaultProps = {
  linkURL: null,
  onClick: null,
  styleType: 'primary',
};

export default LinkButtonV2;
