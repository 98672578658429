import PropTypes from 'prop-types';
import React from 'react';

import { addAnchorsToH2ElementsInHtml } from '../../gatsby/blog/helpers/toc';
import ArticleBody from '../ArticleBody';
import markdownToHTML from '../helpers/markdownToHTML';
import QuestionAndAnswer from '../QuestionAndAnswer';
import Spacer from '../../ui/Spacer';
import Widget from '../Widget';

const convertStringsToMarkdownArticleBodies = (articleParts) =>
  articleParts.map((articlePart) => {
    if (typeof articlePart !== 'string') {
      return articlePart;
    }
    const html = markdownToHTML(articlePart);
    const htmlWithH2Anchors = addAnchorsToH2ElementsInHtml(html, 'toc-anchor');
    return <ArticleBody dangerouslySetInnerHTML={{ __html: htmlWithH2Anchors }} />;
  });

const wrapElementsInDivsWithKeys = (articleParts) =>
  articleParts.map((element, elementIndex) => <div key={elementIndex}>{element}</div>);

// If there's a resource that exists with an ID that matches something separated by curly brackets,
// then interpolate it into the article.
const interpolateEmbeds = (articleParts, resources, maxWidth, mobileMaxWidth) =>
  articleParts.reduce((accumulatedArticleParts, articlePart) => {
    if (typeof articlePart !== 'string') {
      return [...accumulatedArticleParts, articlePart];
    }
    const parts = articlePart.split(/[{}]/).map((articlePartFragment) => {
      // HACK Sometimes Gatsby/Contentful will prepend "c" to the ID's of resources.
      const resource =
        resources && (resources[articlePartFragment] || resources[`c${articlePartFragment}`]);
      if (resource) {
        // Augment the widget's props with the maxWidth so that it can adapt its layout accordingly
        const widgetProps = {
          ...resource,
          data: {
            maxWidth,
            mobileMaxWidth,
            ...resource.data,
          },
        };

        return (
          <Spacer margin={4}>
            <Widget data={widgetProps.data} WidgetComponent={widgetProps.WidgetComponent} />
          </Spacer>
        );
      }
      return articlePartFragment;
    });
    return [...accumulatedArticleParts, ...parts];
  }, []);

const qaStartTag = '[question]';
const qaEndTag = '[/answer]';
const qaRegex = /\[question]([\s\S]*)\[\/question]\s*\[answer]([\s\S]*)\[\/answer]/i;

const interpolateQuestionsAnswers = (articleParts) =>
  articleParts.reduce((accumulator, currentValue) => {
    if (typeof currentValue !== 'string') {
      return [...accumulator, currentValue];
    }
    let textAndQAs = [];
    let tail = currentValue;
    while (qaRegex.test(tail)) {
      const tailLower = tail.toLowerCase();
      const qaStartIndex = tailLower.indexOf(qaStartTag);
      const qaEndIndex = tailLower.indexOf(qaEndTag) + qaEndTag.length;
      const head = tail.substr(0, qaStartIndex);
      const qaString = tail.substr(qaStartIndex, qaEndIndex - qaStartIndex);
      const parsingResults = qaRegex.exec(qaString);
      const answerMarkdown = parsingResults ? parsingResults[2] : '';
      const questionText = parsingResults ? parsingResults[1] : '';
      const qa = <QuestionAndAnswer answerMarkdown={answerMarkdown} questionText={questionText} />;
      textAndQAs = [...textAndQAs, head, qa];
      tail = tail.substr(qaEndIndex);
    }
    return [...accumulator, ...textAndQAs, tail];
  }, []);

const WidgetArticleBody = ({ markdown, maxWidth, mobileMaxWidth, path, resources }) => {
  if (markdown === null) {
    return null;
  }
  let withQuestionsAnswers;
  try {
    withQuestionsAnswers = interpolateQuestionsAnswers([markdown]);
  } catch (e) {
    throw new Error(`An error occurred parsing Q&A markdown in WidgetArticleBody at ${path}`);
  }
  const withEmbeds = interpolateEmbeds(withQuestionsAnswers, resources, maxWidth, mobileMaxWidth);
  let stringsAsArticleBodies;
  try {
    stringsAsArticleBodies = convertStringsToMarkdownArticleBodies(withEmbeds);
  } catch (e) {
    throw new Error(`An error occurred parsing markdown in WidgetArticleBody at ${path}`);
  }
  const keyedArticleParts = wrapElementsInDivsWithKeys(stringsAsArticleBodies);
  return <div id="article-body">{keyedArticleParts}</div>;
};

WidgetArticleBody.propTypes = {
  markdown: PropTypes.string,
  maxWidth: PropTypes.number,
  mobileMaxWidth: PropTypes.number,
  path: PropTypes.string,
  resources: PropTypes.object,
};

WidgetArticleBody.defaultProps = {
  markdown: null,
  maxWidth: null,
  mobileMaxWidth: null,
  path: null,
  resources: null,
};

export default WidgetArticleBody;
