import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import colors from '../ui/constants/colors';
import { mediaQueries } from '../ui/constants/grid';
import Modal from './Modal';

const MODAL_WIDTH = 320;

const Term = styled.span`
  text-decoration: underline;
  cursor: help;
`;

const DefinitionModal = styled.div`
  background: ${colors.white};
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  left: 50%;
  padding: 1.5rem;
  position: absolute;
  top: ${({ top }) => top}px;
  transform: translate(-50%, -50%);
  width: ${MODAL_WIDTH}px;
  z-index: 1;

  ${mediaQueries.desktop`
    left: ${({ left }) => left}px;
    transform: none;
  `};
`;

const GlossaryTerm = ({ definition, term }) => {
  const [isDefinitionShown, setIsDefinitionShown] = useState(false);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);

  const closeModal = () => {
    setIsDefinitionShown(false);
  };

  const openModal = (e) => {
    const rightmostPosition = document.body.offsetWidth - MODAL_WIDTH;
    const willBleedOver = e.pageX > rightmostPosition;
    setIsDefinitionShown(true);
    setLeft(willBleedOver ? rightmostPosition : e.pageX);
    setTop(e.pageY);
  };

  useEffect(() => {
    // By using capture on the "click anywhere to close" handler, when the user clicks a term to
    // open the definition, it will close all the other definitions on the page first. This prevents
    // multiple definitions from being open at a time.
    window.addEventListener('click', closeModal, true);
    window.addEventListener('resize', closeModal, { passive: true });
    return () => {
      window.removeEventListener('click', closeModal, true);
      window.removeEventListener('resize', closeModal);
    };
  }, []);

  return (
    <>
      <Term onClick={openModal}>{term}</Term>
      {isDefinitionShown && (
        <Modal>
          <DefinitionModal left={left} top={top}>
            {definition}
          </DefinitionModal>
        </Modal>
      )}
    </>
  );
};

GlossaryTerm.propTypes = {
  definition: PropTypes.node.isRequired,
  term: PropTypes.node.isRequired,
};

export default GlossaryTerm;
