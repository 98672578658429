import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import BenchHelmet from './BenchHelmet';
import colors from '../ui/constants/colors';
import config from '../config';
import Footer from './footer/Footer';
import loadAndInitMunchkin from '../resources/vendor/marketo';
import StickyNav from './nav/StickyNav';
import trimSlashes from '../lib/trimSlashes';
import loadOneTrustScript from '../resources/vendor/oneTrust';

const AB_TEST_INTERVAL = 100;
const AB_TEST_MAX_ATTEMPTS = 35;
const AB_TEST_RENDER_ALLOWANCE = 200;

const Root = styled.div`
  ${({ background }) => background && `background: ${background}`};
`;

const MainWrapper = styled.main`
  opacity: ${({ asyncHide }) => (asyncHide ? 0 : 1)};
`;

const TemplateWrapper = ({
  background,
  buttonText,
  buttonUrl,
  children,
  description,
  enableAbTesting,
  footerBackground,
  footerType,
  hideNavbar,
  navbarAlwaysOpaque,
  navbarType,
  path,
  robotMetaContent,
  socialImage,
  testId,
  title,
  transparentNavbarBenchColor,
  transparentNavbarTextColor,
}) => {
  const [hideWhileActivatingTest, setHideWhileActivatingTest] = useState(enableAbTesting);

  const activateOptimizeTest = () => {
    window.dataLayer.push({ event: 'optimize.activate' });

    setTimeout(() => {
      setHideWhileActivatingTest(false);

      if (!window.gaData) {
        return;
      }
      const googleAnalyticsData = window.gaData[config.GOOGLE_ANALYTICS_ID];
      if (!googleAnalyticsData) {
        return;
      }
      const experimentsOnPage = googleAnalyticsData.experiments;
      const experimentId = experimentsOnPage && Object.keys(experimentsOnPage)[0];
      const variationId = experimentsOnPage && experimentsOnPage[experimentId];

      if (experimentId && typeof variationId !== 'undefined') {
        setTimeout(
          () => window.heap && window.heap.addUserProperties({ [experimentId]: variationId }),
          5000,
        );
      }
    }, AB_TEST_RENDER_ALLOWANCE);
  };

  const handleABTestActivation = () => {
    if (!enableAbTesting) {
      return;
    }

    // If ga has already initialized, activate the test
    if (window.dataLayer) {
      activateOptimizeTest();
    } else {
      // Otherwise, check for initialization every 100ms
      let abTestAttempts = 0;

      const abTestInterval = setInterval(() => {
        abTestAttempts += 1;

        if (abTestAttempts > AB_TEST_MAX_ATTEMPTS) {
          setHideWhileActivatingTest(false);
          clearInterval(abTestInterval);
        }

        if (window.dataLayer) {
          activateOptimizeTest();
          clearInterval(abTestInterval);
        }
      }, AB_TEST_INTERVAL);
    }
  };

  useEffect(() => {
    handleABTestActivation();
    loadAndInitMunchkin();
    loadOneTrustScript();
  }, []);

  const simpleNavbar = /simple/.test(navbarType);
  const rootId = `templateWrapper-${trimSlashes(path).replace(/\W+/g, '-')}`;

  return (
    <>
      <Root background={background} data-test-id={testId} id={rootId}>
        <BenchHelmet
          description={description}
          enableAbTesting={enableAbTesting}
          path={path}
          robotMetaContent={robotMetaContent}
          socialImage={socialImage}
          title={title}
        />
        {!hideNavbar && (
          <StickyNav
            buttonText={buttonText}
            buttonUrl={buttonUrl}
            navbarAlwaysOpaque={navbarAlwaysOpaque}
            path={path}
            simpleNavbar={simpleNavbar}
            transparentNavbarBenchColor={transparentNavbarBenchColor}
            transparentNavbarTextColor={transparentNavbarTextColor}
          />
        )}
        <MainWrapper asyncHide={hideWhileActivatingTest}>{children}</MainWrapper>
        <Footer
          backgroundColor={colors[footerBackground] || footerBackground}
          footerType={footerType}
        />
      </Root>
    </>
  );
};

TemplateWrapper.propTypes = {
  background: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  children: PropTypes.node,
  description: PropTypes.string,
  enableAbTesting: PropTypes.bool,
  footerBackground: PropTypes.string,
  footerType: PropTypes.string,
  hideNavbar: PropTypes.bool,
  navbarAlwaysOpaque: PropTypes.bool,
  navbarType: PropTypes.string,
  path: PropTypes.string.isRequired,
  robotMetaContent: PropTypes.string.isRequired,
  socialImage: PropTypes.string,
  testId: PropTypes.string,
  title: PropTypes.string,
  transparentNavbarBenchColor: PropTypes.string,
  transparentNavbarTextColor: PropTypes.string,
};

TemplateWrapper.defaultProps = {
  enableAbTesting: false,
  socialImage: null,
  background: null,
  children: null,
  testId: null,
  description:
    'Bench pairs you with a team of bookkeepers and uses simple software to do your ' +
    'bookkeeping, so you don’t have to. Start a free trial today.',
  footerBackground: 'white',
  footerType: 'simple',
  hideNavbar: false,
  navbarAlwaysOpaque: false,
  navbarType: 'simple',
  title: 'Bench — Online Bookkeeping for Your Small Business',
  transparentNavbarBenchColor: null,
  transparentNavbarTextColor: null,
};

export default TemplateWrapper;
