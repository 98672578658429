import translateSectionData from './translateSectionData';
import tryGet from '../../../components/helpers/tryGet';
import SectionGrid from '../../../components/sections/SectionGrid';
import WidgetThematicBreak from '../../../components/widgets/WidgetThematicBreak';

export const getGridSections = (sections) =>
  sections.reduce((allSections, section) => {
    const translatedContent = translateSectionData(section);
    const gridSections = [translatedContent];

    // When addThematicBreak is set to true, a new section is added to gridSections that contains
    // a single column, with WidgetThematicBreak inside of it, which inherits the background color
    // and the bottom padding of the section above
    if (section.addThematicBreak) {
      gridSections.push({
        backgroundColor: translatedContent.backgroundColor,
        columns: [
          {
            span: 12,
            widgets: [
              {
                data: {
                  color: section.thematicBreakColor,
                },
                margin: 0,
                WidgetComponent: WidgetThematicBreak,
              },
            ],
          },
        ],
        paddingBottom: translatedContent.paddingBottom,
        paddingBottomDesktop: translatedContent.paddingBottomDesktop,
        paddingBottomMobile: translatedContent.paddingBottomMobile,
        paddingDesktop: translatedContent.paddingDesktop,
        paddingMobile: translatedContent.paddingMobile,
        paddingTopDesktop: 0,
        paddingTopMobile: 0,
        SectionComponent: SectionGrid,
      });
    }

    return [...allSections, ...gridSections];
  }, []);

const translatePageData = (data) => ({
  ...data,
  socialImage: tryGet(['socialImage', 'file', 'url'], data),
  sections: data.sections ? getGridSections(data.sections) : [],
});

export default translatePageData;
