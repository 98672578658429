import Gandalf from 'gandalf-validator';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../../ui/buttons/Button';
import FormError from './FormError';
import getQueryFromLocation from '../../helpers/getQueryFromLocation';
import Input from '../../../ui/input/Input';

const loginFormFieldDefinitions = (idSuffix) => [
  {
    name: 'j_username',
    component: Input,
    validators: ['required', 'email'],
    errorPropName: 'errorText',
    props: {
      autoCapitalize: 'off',
      autoComplete: 'email',
      autoFocus: true,
      className: 'js-input__field',
      dataTestId: 'www-j_username-input',
      id: `account-form_email${idSuffix}`,
      placeholder: 'Email',
      required: true,
      type: 'email',
    },
    debounce: 700,
  },
  {
    name: 'j_password',
    component: Input,
    validators: ['required'],
    errorPropName: 'errorText',
    props: {
      autoComplete: 'password',
      className: 'js-input__field',
      dataTestId: 'www-j_password-input',
      id: `account-form_password${idSuffix}`,
      placeholder: 'Password',
      required: true,
      type: 'password',
    },
    debounce: 300,
  },
];

class LoginForm extends Gandalf {
  constructor(props) {
    super(props);
    this.state = {
      // Gandalf initializes the state in the super constructor
      ...this.state,
      isLoginInvalid: false,
      isSessionExpired: false,
      isUnauthorized: false,
      redirectUrl: '',
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.buildFields(loginFormFieldDefinitions(this.props.idSuffix));
  }

  componentDidMount() {
    this.updateStateFromQueryString();
  }

  updateStateFromQueryString = () => {
    const query = getQueryFromLocation(window.location);
    const redirectUrl = query.redirect_url || '';
    const isLoginInvalid = 'error' in query;
    const isSessionExpired = 'session_expired' in query;
    const isUnauthorized = 'insufficient_security' in query;
    this.setState({
      isLoginInvalid,
      isSessionExpired,
      isUnauthorized,
      redirectUrl,
    });
  };

  render() {
    const { fields, isLoginInvalid, isSessionExpired, isUnauthorized, redirectUrl } = this.state;

    return (
      <form
        action="/logon"
        className="js-form js-login-form js-validate"
        method="post"
        noValidate
        onSubmit={(e) => {
          // Validate form fields and prevent submission if there are any validation errors
          this.getCleanFormData();
          if (!this.formIsValid()) {
            e.preventDefault();
          }
        }}
      >
        <FormError shouldDisplay={isLoginInvalid}>
          Oops, looks like that login is incorrect.
        </FormError>
        <FormError shouldDisplay={isSessionExpired}>
          You’ve been inactive for a while, so we logged you out to keep your data safe.
        </FormError>
        <FormError shouldDisplay={isUnauthorized}>
          Looks like you’ve tried to access a restricted area. Please log in with a different
          account, or contact us to change your security settings.
        </FormError>
        <input name="redirect_url" type="hidden" value={redirectUrl} />
        {fields.j_username.element}
        {fields.j_password.element}
        <Button
          className="js-submit"
          data-test-id="www-leadgen-submit"
          full
          type="submit"
          useInputElement
          value="Log In"
        />
      </form>
    );
  }
}

LoginForm.propTypes = {
  // Used to differentiate the form field ID when multiple instances of the form are on one page
  idSuffix: PropTypes.string,
};

LoginForm.defaultProps = {
  idSuffix: '',
};

export default LoginForm;
