import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import MarketoForm from '../embeds/MarketoForm';

const MarketoFormContainer = styled.div`
  background: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  padding: 1.5rem 0;

  ${({ maxWidth }) =>
    maxWidth &&
    `
    max-width: ${maxWidth}px;
  `}
`;

const WidgetMarketoForm = ({
  campaignId,
  customHiddenValues,
  formId,
  onFormSuccess,
  placeholderFields,
  backgroundColor,
  maxWidth,
}) => (
  <MarketoFormContainer backgroundColor={backgroundColor} maxWidth={maxWidth}>
    <MarketoForm
      campaignId={campaignId}
      customHiddenValues={customHiddenValues}
      formId={formId}
      onFormSuccess={onFormSuccess}
      placeholderFields={placeholderFields}
    />
  </MarketoFormContainer>
);

WidgetMarketoForm.propTypes = {
  backgroundColor: PropTypes.string,
  campaignId: PropTypes.string,
  customHiddenValues: PropTypes.object,
  formId: PropTypes.string.isRequired,
  maxWidth: PropTypes.number,
  onFormSuccess: PropTypes.func,
  placeholderFields: PropTypes.number,
};

WidgetMarketoForm.defaultProps = {
  backgroundColor: null,
  campaignId: undefined,
  customHiddenValues: {},
  maxWidth: null,
  onFormSuccess: null,
  placeholderFields: 3,
};
export default WidgetMarketoForm;
