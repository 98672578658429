import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import BenchSwiper from '../BenchSwiper';
import { getDesktopColumnWidth, getMobileColumnWidth } from '../../ui/constants/grid';
import ExperimentHidden from '../../ui/responsive/ExperimentHidden';
import GridColumn from '../../ui/grid/GridColumn';
import GridRow from '../../ui/grid/GridRow';
import ResponsiveHidden, {
  HideOnDesktop,
  HideOnMobile,
} from '../../ui/responsive/ResponsiveHidden';
import SectionContainer from './SectionContainer';
import Spacer from '../../ui/Spacer';
import Widget from '../Widget';

const WidgetStack = styled.div`
  ${({ showOnTop }) =>
    showOnTop &&
    `
    position: relative;
    z-index: 1;
  `}
`;

WidgetStack.propTypes = {
  showOnTop: PropTypes.bool,
};

WidgetStack.defaultProps = {
  showOnTop: false,
};

const WrappedWidget = ({
  data,
  hideOnDesktop,
  hideOnMobile,
  margin,
  marginBottom,
  marginBottomDesktop,
  marginBottomMobile,
  marginDesktop,
  marginMobile,
  marginTop,
  marginTopDesktop,
  marginTopMobile,
  padding,
  paddingBottom,
  paddingBottomDesktop,
  paddingBottomMobile,
  paddingDesktop,
  paddingMobile,
  paddingTop,
  paddingTopDesktop,
  paddingTopMobile,
  WidgetComponent,
}) => (
  <ResponsiveHidden hideOnDesktop={hideOnDesktop} hideOnMobile={hideOnMobile}>
    <WidgetStack showOnTop={WidgetComponent && !WidgetComponent.noContainer}>
      <Spacer
        margin={margin}
        marginBottom={marginBottom}
        marginBottomDesktop={marginBottomDesktop}
        marginBottomMobile={marginBottomMobile}
        marginDesktop={marginDesktop}
        marginMobile={marginMobile}
        marginTop={marginTop}
        marginTopDesktop={marginTopDesktop}
        marginTopMobile={marginTopMobile}
        padding={padding}
        paddingBottom={paddingBottom}
        paddingBottomDesktop={paddingBottomDesktop}
        paddingBottomMobile={paddingBottomMobile}
        paddingDesktop={paddingDesktop}
        paddingMobile={paddingMobile}
        paddingTop={paddingTop}
        paddingTopDesktop={paddingTopDesktop}
        paddingTopMobile={paddingTopMobile}
      >
        <Widget data={data} WidgetComponent={WidgetComponent} />
      </Spacer>
    </WidgetStack>
  </ResponsiveHidden>
);

WrappedWidget.propTypes = {
  data: PropTypes.object,
  hideOnDesktop: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
  margin: PropTypes.number,
  marginBottom: PropTypes.number,
  marginBottomDesktop: PropTypes.number,
  marginBottomMobile: PropTypes.number,
  marginDesktop: PropTypes.number,
  marginMobile: PropTypes.number,
  marginTop: PropTypes.number,
  marginTopDesktop: PropTypes.number,
  marginTopMobile: PropTypes.number,
  padding: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingBottomDesktop: PropTypes.number,
  paddingBottomMobile: PropTypes.number,
  paddingDesktop: PropTypes.number,
  paddingMobile: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingTopDesktop: PropTypes.number,
  paddingTopMobile: PropTypes.number,
  WidgetComponent: PropTypes.any.isRequired,
};

WrappedWidget.defaultProps = {
  data: null,
  hideOnDesktop: false,
  hideOnMobile: false,
  margin: 3,
  marginBottom: null,
  marginBottomDesktop: null,
  marginBottomMobile: null,
  marginDesktop: null,
  marginMobile: null,
  marginTop: null,
  marginTopDesktop: null,
  marginTopMobile: null,
  padding: null,
  paddingBottom: null,
  paddingBottomDesktop: null,
  paddingBottomMobile: null,
  paddingDesktop: null,
  paddingMobile: null,
  paddingTop: null,
  paddingTopDesktop: null,
  paddingTopMobile: null,
};

const SectionGrid = (props) => {
  const {
    swipeColumns,
    swipeColumnsOnMobile,
    hideOnTest,
    hideOnDesktop,
    hideOnMobile,
    testId,
    backgroundColor,
    fillViewport,
    isFirstSection,
    margin,
    marginBottom,
    marginBottomDesktop,
    marginBottomMobile,
    marginDesktop,
    marginMobile,
    marginTop,
    marginTopDesktop,
    marginTopMobile,
    maxHeightDesktop,
    maxHeightMobile,
    navHidden,
    padding,
    paddingBottom,
    paddingBottomDesktop,
    paddingBottomMobile,
    paddingDesktop,
    paddingMobile,
    paddingTop,
    paddingTopDesktop,
    paddingTopMobile,
  } = props;
  return (
    <ExperimentHidden hideOnTest={hideOnTest} testId={testId}>
      <ResponsiveHidden hideOnDesktop={hideOnDesktop} hideOnMobile={hideOnMobile}>
        <SectionContainer
          backgroundColor={backgroundColor}
          fillViewport={fillViewport}
          isFirstSection={isFirstSection}
          margin={margin}
          marginBottom={marginBottom}
          marginBottomDesktop={marginBottomDesktop}
          marginBottomMobile={marginBottomMobile}
          marginDesktop={marginDesktop}
          marginMobile={marginMobile}
          marginTop={marginTop}
          marginTopDesktop={marginTopDesktop}
          marginTopMobile={marginTopMobile}
          maxHeightDesktop={maxHeightDesktop}
          maxHeightMobile={maxHeightMobile}
          navHidden={navHidden}
          padding={padding}
          paddingBottom={paddingBottom}
          paddingBottomDesktop={paddingBottomDesktop}
          paddingBottomMobile={paddingBottomMobile}
          paddingDesktop={paddingDesktop}
          paddingMobile={paddingMobile}
          paddingTop={paddingTop}
          paddingTopDesktop={paddingTopDesktop}
          paddingTopMobile={paddingTopMobile}
        >
          {swipeColumns && (
            <Content Container={BenchSwiper} Element={styled.div``} parentProps={props} />
          )}
          {!swipeColumns && swipeColumnsOnMobile && (
            <HideOnDesktop>
              <Content Container={BenchSwiper} Element={styled.div``} parentProps={props} />
            </HideOnDesktop>
          )}
          {!swipeColumns && swipeColumnsOnMobile && (
            <HideOnMobile>
              <Content Container={GridRow} Element={GridColumn} parentProps={props} />
            </HideOnMobile>
          )}
          {!swipeColumns && !swipeColumnsOnMobile && (
            <Content Container={GridRow} Element={GridColumn} parentProps={props} />
          )}
        </SectionContainer>
      </ResponsiveHidden>
    </ExperimentHidden>
  );
};

SectionGrid.propTypes = {
  backgroundColor: PropTypes.string,
  fillViewport: PropTypes.bool,
  hideOnDesktop: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
  hideOnTest: PropTypes.bool,
  isFirstSection: PropTypes.bool,
  margin: PropTypes.number,
  marginBottom: PropTypes.number,
  marginBottomDesktop: PropTypes.number,
  marginBottomMobile: PropTypes.number,
  marginDesktop: PropTypes.number,
  marginMobile: PropTypes.number,
  marginTop: PropTypes.number,
  marginTopDesktop: PropTypes.number,
  marginTopMobile: PropTypes.number,
  maxHeightDesktop: PropTypes.number,
  maxHeightMobile: PropTypes.number,
  navHidden: PropTypes.bool,
  padding: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingBottomDesktop: PropTypes.number,
  paddingBottomMobile: PropTypes.number,
  paddingDesktop: PropTypes.number,
  paddingMobile: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingTopDesktop: PropTypes.number,
  paddingTopMobile: PropTypes.number,
  swipeColumns: PropTypes.bool,
  swipeColumnsOnMobile: PropTypes.bool,
  testId: PropTypes.string,
};

SectionGrid.defaultProps = {
  backgroundColor: null,
  fillViewport: false,
  isFirstSection: false,
  hideOnDesktop: false,
  hideOnMobile: false,
  hideOnTest: false,
  margin: null,
  marginBottom: null,
  marginBottomDesktop: null,
  marginBottomMobile: null,
  marginDesktop: null,
  marginMobile: null,
  marginTop: null,
  marginTopDesktop: null,
  marginTopMobile: null,
  maxHeightDesktop: null,
  maxHeightMobile: null,
  navHidden: false,
  padding: 8,
  paddingBottom: null,
  paddingBottomDesktop: null,
  paddingBottomMobile: null,
  paddingDesktop: null,
  paddingMobile: null,
  paddingTop: null,
  paddingTopDesktop: null,
  paddingTopMobile: null,
  swipeColumns: false,
  swipeColumnsOnMobile: false,
  testId: null,
};

const Content = ({ Container, parentProps, Element }) => {
  const { columns, swipeColumns, swipeColumnsOnMobile } = parentProps;
  return (
    <Container {...parentProps}>
      {columns &&
        columns.map((column, columnIndex) => {
          const { span, spanMobile, widgets } = column;
          return (
            <Element key={columnIndex} {...column}>
              <ResponsiveHidden {...column}>
                {widgets &&
                  widgets.map((widget, widgetIndex) => {
                    const widgetProps = { ...widget };
                    // Augment the data prop of all widgets in the grid with maxWidth and
                    // mobileMaxWidth so that they can be used for things like responsive image
                    // queries internally. Allow these values to be overridden in the case they
                    // should be set to smaller values than the full width of the column.
                    // If the section has swiping enabled, the widget will be 12 columns wide.
                    widgetProps.data = {
                      maxWidth: swipeColumns
                        ? getDesktopColumnWidth(12)
                        : getDesktopColumnWidth(span),
                      mobileMaxWidth:
                        swipeColumns || swipeColumnsOnMobile
                          ? getMobileColumnWidth(12)
                          : getMobileColumnWidth(spanMobile),
                      isFirstSection: parentProps.isFirstSection,
                      ...widgetProps.data,
                    };

                    // Allow column to dynamically define widget spacing
                    if (typeof column.widgetMargin === 'number') {
                      widgetProps.margin = column.widgetMargin;
                    }

                    return (
                      <WrappedWidget
                        key={widgetIndex}
                        data={widgetProps.data}
                        hideOnDesktop={widgetProps.hideOnDesktop}
                        hideOnMobile={widgetProps.hideOnMobile}
                        margin={widgetProps.margin}
                        marginBottom={widgetProps.marginBottom}
                        marginBottomDesktop={widgetProps.marginBottomDesktop}
                        marginBottomMobile={widgetProps.marginBottomMobile}
                        marginDesktop={widgetProps.marginDesktop}
                        marginMobile={widgetProps.marginMobile}
                        marginTop={widgetProps.marginTop}
                        marginTopDesktop={widgetProps.marginTopDesktop}
                        marginTopMobile={widgetProps.marginTopMobile}
                        padding={widgetProps.padding}
                        paddingBottom={widgetProps.paddingBottom}
                        paddingBottomDesktop={widgetProps.paddingBottomDesktop}
                        paddingBottomMobile={widgetProps.paddingBottomMobile}
                        paddingDesktop={widgetProps.paddingDesktop}
                        paddingMobile={widgetProps.paddingMobile}
                        paddingTop={widgetProps.paddingTop}
                        paddingTopDesktop={widgetProps.paddingTopDesktop}
                        paddingTopMobile={widgetProps.paddingTopMobile}
                        WidgetComponent={widgetProps.WidgetComponent}
                      />
                    );
                  })}
              </ResponsiveHidden>
            </Element>
          );
        })}
    </Container>
  );
};

Content.propTypes = {
  Container: PropTypes.any.isRequired,
  Element: PropTypes.any.isRequired,
  parentProps: PropTypes.shape(SectionGrid.propTypes),
};

Content.defaultProps = {
  parentProps: null,
};

export default SectionGrid;
