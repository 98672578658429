import { useState, useEffect } from 'react';

const useWindowDimensions = () => {
  const [height, setHeight] = useState(undefined);
  const [width, setWidth] = useState(undefined);

  const updateDimensions = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return window.removeEventListener('resize', updateDimensions);
  });

  const isDesktopView = width ? width >= 801 : false;

  return {
    height,
    width,
    isDesktopView,
  };
};

export default useWindowDimensions;
