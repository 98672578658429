import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import colors from '../../ui/constants/colors';
import { HideOnDesktop, HideOnMobile } from '../../ui/responsive/ResponsiveHidden';
import Link from '../../ui/buttons/Link';
import WidgetBlogSearchForm from './WidgetBlogSearchForm';
import WidgetDropdownMenu from './WidgetDropdownMenu';
import blogCategories from '../../gatsby/blog/helpers/blogCategories';

const MagnifyingGlass = styled.span`
  cursor: pointer;
  display: inline-block;
  height: ${({ size }) => size}px;
  position: relative;
  top: ${({ size }) => size * 0.15}px;
  width: ${({ size }) => size}px;

  &::after {
    background: ${colors.smoke};
    bottom: 0;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    transform: rotate(45deg);
    transform-origin: bottom;
    width: 1.5px;
  }

  &::before {
    border: 1.5px solid ${colors.smoke};
    border-radius: 50%;
    content: '';
    display: block;
    height: 60%;
    position: absolute;
    right: 0;
    top: 0;
    width: 60%;
  }
`;

const SearchFormContainer = styled.div`
  max-height: ${({ open }) => (open ? '80px' : '0')};
  margin-top: 1.5rem;
  overflow: hidden;
  transition-duration: 150ms;
`;

const CategoriesContainer = styled.ul`
  text-align: center;
`;

const Category = styled.div`
  display: inline-block;
  padding: 4px 16px;
`;

const CategoryLink = styled(Link)`
  color: ${colors.smoke};
  transition-duration: 100ms;
  display: inline-block;

  &:hover {
    transform: translateY(-3px);
  }
`;

const SelectedCategoryLink = styled(CategoryLink)`
  border-bottom: 2px solid ${colors['water-light']};
  line-height: 1.75rem;
`;

const SearchLink = styled(Category)`
  color: ${colors.smoke};
  padding: 4px 8px;

  &:hover {
    cursor: pointer;
  }
`;

const WidgetCategoryNav = ({ categories }) => {
  const categoryMap = blogCategories.reduce(
    (obj, item) => Object.assign(obj, { [item.url]: item.name }),
    {},
  );

  const isBrowser = () => typeof window !== 'undefined';
  const currentPageCategory = categoryMap[isBrowser() && window.location.pathname];

  const [desktopSearchFormOpen, setDesktopSearchFormOpen] = useState(false);

  const toggleDesktopBlogSearchForm = () => {
    setDesktopSearchFormOpen(!desktopSearchFormOpen);
  };

  return (
    <div>
      <HideOnDesktop>
        <WidgetDropdownMenu
          items={[
            ...categories,
            {
              name: (
                <span>
                  <MagnifyingGlass size={16} /> Search
                </span>
              ),
              url: '/search/',
            },
          ]}
          label="Menu"
        />
      </HideOnDesktop>
      <HideOnMobile>
        <CategoriesContainer>
          {categories &&
            categories.map(({ name, url }) => (
              <Category key={name}>
                {currentPageCategory === name ? (
                  <SelectedCategoryLink linkURL={url}>{name}</SelectedCategoryLink>
                ) : (
                  <CategoryLink linkURL={url}>{name}</CategoryLink>
                )}
              </Category>
            ))}
          <Category onClick={toggleDesktopBlogSearchForm}>
            <MagnifyingGlass size={20} />
            <SearchLink>Search</SearchLink>
          </Category>
        </CategoriesContainer>
        <SearchFormContainer open={desktopSearchFormOpen}>
          <WidgetBlogSearchForm />
        </SearchFormContainer>
      </HideOnMobile>
    </div>
  );
};

WidgetCategoryNav.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default WidgetCategoryNav;
