import styled from 'styled-components';
import colors from '../../ui/constants/colors';
import { mediaQueries } from '../../ui/constants/grid';

const AnnualContractIconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CardLayout = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 40px;
  justify-content: center;
  @media (max-width: 1181px) {
    column-gap: 20px;
  }
  ${mediaQueries.mobile`
     grid-template-columns: 100%;
     row-gap: 40px;
  `};
`;

const FreeMonthsText = styled.p`
  grid-area: FreeMonthsText;
  border: ${({ isAnnual }) =>
    isAnnual ? `1px solid ${colors.solitudeBlue}` : `1px solid ${colors.athensGrey}`};
  background-color: ${({ isAnnual }) => (isAnnual ? colors.solitudeBlue : colors.athensGrey)};
  color: ${({ isAnnual }) => (isAnnual ? colors.deepSapphire : colors.tulleGrey)};
  font-size: 15px;
  font-weight: 500;
  width: max-content;
  padding: 2px 9px;
  border-radius: 100px;
  margin-bottom: 12px;
`;

const PaymentInterval = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;

  ${mediaQueries.mobile`
    padding: 31px 0;
  `};
`;

const PaymentIntervalAndSavingsWrapper = styled.div`
  display: flex;
  margin-right: 30px;
  align-items: center;
`;

const PaymentIntervalHeader = styled.h3`
  color: ${colors.eerieBlack};
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const PaymentIntervalOptions = styled.div`
  display: flex;
  align-items: center;

  ${({ isV2OrV3PricingCard }) =>
    isV2OrV3PricingCard
      ? `
        &:first-child {
            display: flex;
            flex-direction: column;
        }
        `
      : null}
`;

const PaymentIntervalWrapper = styled.div`
  display: grid;
  align-content: stretch;
  align-items: start;

  ${({ isV2PricingCard }) =>
    isV2PricingCard
      ? `
        grid-template-areas:
            'PlanAnnualPaymentOption PlanMonthlyPaymentOption'
            'PlanPercentageSavingsText .'
            'FreeMonthsText .';
    `
      : `  
        grid-template-areas:
            'PlanAnnualPaymentOption PlanMonthlyPaymentOption'
            'PlanPercentageSavingsText .';
`}
`;

const PaymentOptionsButton = styled.input`
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: ${colors.white};
  box-shadow: 0 0 0 1px ${colors.neutral};
  border: 0.2rem solid ${colors.white};
  cursor: pointer;
  transition: ease 0.2s;
  &:checked {
    box-shadow: 0 0 0 1px ${colors.waterLight};
    background-color: ${colors.waterLight};
  }
`;

const PlanAnnualPaymentOption = styled.label`
  color: ${({ isAnnual }) => (isAnnual ? colors.eerieBlack : colors.neutral)};
  font-weight: ${({ isAnnual }) => (isAnnual ? '700' : 'unset')};
  transition: ease 0.2s;
  margin-left: 8px;
  grid-area: PlanAnnualPaymentOption;
  margin-right: 6px;
`;

const PlanMonthlyPaymentOption = styled(PlanAnnualPaymentOption)`
  color: ${({ isAnnual }) => (isAnnual ? colors.neutral : colors.eerieBlack)};
  font-weight: ${({ isAnnual }) => (isAnnual ? 'unset' : '700')};
  grid-area: PlanMonthlyPaymentOption;
`;

const PlanPercentageSavingsText = styled.p`
  margin-left: 25px;
  padding-bottom: 10px;
  font-weight: ${({ isAnnual }) => (isAnnual ? '700' : 'unset')};
  grid-area: PlanPercentageSavingsText;
  transition: ease 0.2s;
  color: ${({ isAnnual, isV1PricingCard }) =>
    isV1PricingCard && isAnnual ? colors.waterLight : colors.tulleGrey};
`;

const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export {
  AnnualContractIconWrapper,
  CardLayout,
  FreeMonthsText,
  PaymentInterval,
  PaymentIntervalAndSavingsWrapper,
  PaymentIntervalHeader,
  PaymentIntervalOptions,
  PaymentIntervalWrapper,
  PaymentOptionsButton,
  PlanAnnualPaymentOption,
  PlanMonthlyPaymentOption,
  PlanPercentageSavingsText,
  WidgetWrapper,
};
