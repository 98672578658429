import WidgetInstagramFeed from '../../../components/widgets/WidgetInstagramFeed';

const widgetInstagramFeedTranslation = () => {
  return {
    data: {},
    WidgetComponent: WidgetInstagramFeed,
  };
};

export default widgetInstagramFeedTranslation;
