import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import markdownToHTML from '../helpers/markdownToHTML';

const ShowWhenOpen = styled.div`
  ${({ open }) => open && `height: auto;`}

  ${({ open }) =>
    !open &&
    `
    height: 0;
    overflow: hidden;
  `}
`;

/**
 * This component behaves similarly to the `<details>` and `<summary>` HTML elements. The details
 * are initially hidden, and are revealed when the summary is clicked. If the summary is clicked
 * again, then the details are hidden once more.
 *
 * Styled components can be passed as props to customize the appearance of each of the elements.
 */
const WidgetDetailsSummary = ({
  details,
  DetailsComponent,
  summary,
  SummaryComponent,
  WrapperComponent,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <WrapperComponent open={isOpen}>
      <SummaryComponent
        onClick={(e) => {
          toggle();
          e.target.blur();
        }}
        open={isOpen}
      >
        {summary}
      </SummaryComponent>
      <ShowWhenOpen open={isOpen}>
        <DetailsComponent
          dangerouslySetInnerHTML={{ __html: markdownToHTML(details) }}
          open={isOpen}
        />
      </ShowWhenOpen>
    </WrapperComponent>
  );
};

WidgetDetailsSummary.propTypes = {
  details: PropTypes.string.isRequired,
  /**
   * The `DetailsComponent` will be used to wrap the `details` content, which is initially hidden
   * and revealed when the summary is clicked. Provide a styled component to control how it appears.
   */
  DetailsComponent: PropTypes.object,
  summary: PropTypes.string.isRequired,
  /**
   * The `SummaryComponent` will be used to wrap the `summary` content, which, when clicked, reveals
   * the details content. Provide a styled component to control how it appears.
   */
  SummaryComponent: PropTypes.object,
  /**
   * The `WrapperComponent` will be used as the root element of the component. Provide a styled
   * component to control how it appears.
   */
  WrapperComponent: PropTypes.object,
};

WidgetDetailsSummary.defaultProps = {
  DetailsComponent: styled.div``,
  SummaryComponent: styled.div``,
  WrapperComponent: styled.div``,
};

export default WidgetDetailsSummary;
