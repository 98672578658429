const currencyFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency',
});

/**
 * Turns an amount in cents (i.e. 14412) into a currency string (i.e. $144.12)
 * @param {number} amountInCents
 * @returns {string}
 */
const formatCentsForDisplay = (amountInCents) => {
  return currencyFormatter.format(amountInCents / 100);
};

export default formatCentsForDisplay;
