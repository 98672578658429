/**
 * Takes an Array of strings that represent the path through a deeply nested object structure and
 * the root of that object structure and returns the value at the end of the traversal if it
 * exists or `undefined` if it can't access the specified path.
 *
 * This is great for replacing the conventional pattern of safely traversing an object structure.
 * For example:
 *
 * ```
 * obj &&
 * obj.prop &&
 * obj.prop[0] &&
 * obj.prop[0].otherProp
 * ```
 *
 * Becomes:
 *
 * ```
 * tryGet(['prop', '0', 'otherProp'], obj)
 * ```
 *
 * @param {string[]} path
 * @param {Object} root
 * @returns {*|undefined}
 */
const tryGet = (path, root) => {
  return path.reduce((obj, propName) => {
    if (typeof obj === 'object' && obj !== null && propName in obj) {
      return obj[propName];
    }
    return undefined;
  }, root);
};

export const makeGetter = (...path) => tryGet.bind(null, path);

export default tryGet;
