import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import colors from '../../ui/constants/colors';
import Image from '../../ui/images/Image';
import Link from '../../ui/buttons/Link';
import { mediaQueries } from '../../ui/constants/grid';
import { fontSizingCSS, pxToRem } from '../../ui/constants/typography';

const TestimonialContainer = styled.div`
  padding: 0 40px 0 60px;

  ${mediaQueries.desktop`
    &.-large {
      margin: 0;
      padding: 0 ${(250 / 1170) * 120}%;
    }
  `};

  ${({ showQuoteIcon }) =>
    !showQuoteIcon &&
    `
    padding: 0;
  `};
`;

const TestimonialInner = styled.div`
  ${mediaQueries.desktop`
    .-large & {
      padding: 0 15px;
    }
  `};
`;

const Quote = styled.div`
  color: ${({ fontColor }) => fontColor || colors.charcoal};
  font-family: ${({ fontFamily }) => fontFamily || 'LeituraDisplay'}, serif;
  font-size: ${({ fontSize }) => pxToRem(fontSize || 18)}rem;
  line-height: ${pxToRem(27)}rem;
  position: relative;
  text-align: left;
  /* white-space: pre-wrap; tells the browser to honour any newlines (\n) in text */
  white-space: pre-wrap;

  ${mediaQueries.desktop`
    .-large & {
      font-size: ${pxToRem(24)}rem;
      line-height: ${pxToRem(34)}rem;
    }
  `};
`;

const OpenQuote = styled.span`
  color: ${({ fontColor }) => fontColor || '#0FA1A2'};
  font-size: ${pxToRem(43)}rem;
  letter-spacing: -3px;
  padding-right: 12px;
  position: absolute;
  right: 100%;
  top: 6px;

  ${mediaQueries.desktop`
    .-large & {
      font-size: ${pxToRem(80)}rem;
      letter-spacing: -6px;
      padding-right: 25px;
      top: 15px;
    }
  `};
`;

const AuthorWrap = styled.div`
  ${fontSizingCSS(8)};
  align-items: center;
  display: flex;
  font-weight: 600;
  margin: 1rem 0;
  text-align: left;
  text-transform: uppercase;

  ${mediaQueries.desktop`
    ${fontSizingCSS(11)};
    letter-spacing: 1.2px;

    .-large & {
      font-weight: normal;
      min-height: 86px;
    }
  `};

  ${mediaQueries.mobile`
    letter-spacing: 0.6px;
    min-height: 65px;
  `};
`;

const AuthorImage = styled.div`
  border-radius: 50%;
  margin-right: 20px;
  overflow: hidden;

  img {
    width: 100%;
  }

  ${mediaQueries.desktop`
    flex-basis: 86px;
    min-width: 86px;
    `};

  ${mediaQueries.mobile`
    flex-basis: 65px;
    min-width: 65px;
  `};
`;

const AuthorName = styled.span`
  color: ${({ fontColor }) => fontColor || '#0FA1A2'};
`;

const AuthorTitle = styled.span`
  color: ${({ fontColor }) => fontColor || colors.charcoal};
`;

const WidgetTestimonial = (props) => {
  const {
    author,
    authorImage,
    authorTitle,
    fontColor,
    quoteFontFamily,
    quoteFontSize,
    showQuoteIcon,
    highlightColor,
    link,
    maxWidth,
    quote,
  } = props;

  return (
    <TestimonialContainer className={maxWidth > 600 ? '-large' : ''} showQuoteIcon={showQuoteIcon}>
      <TestimonialInner>
        <Quote fontColor={fontColor} fontFamily={quoteFontFamily} fontSize={quoteFontSize}>
          {showQuoteIcon ? (
            <>
              <OpenQuote fontColor={highlightColor}>‘‘</OpenQuote>
              {quote}
            </>
          ) : (
            `"${quote}"`
          )}
        </Quote>
        <AuthorWrap>
          {authorImage && (
            <AuthorImage>
              <Image {...authorImage} maxWidth={86} mobileMaxWidth={65} />
            </AuthorImage>
          )}
          {link && (
            <Link linkURL={link}>
              <AuthorName fontColor={highlightColor}>{author}</AuthorName>{' '}
              <AuthorTitle fontColor={fontColor}>{authorTitle}</AuthorTitle>
            </Link>
          )}
          {!link && (
            <span>
              <AuthorName fontColor={highlightColor}>{author}</AuthorName>{' '}
              <AuthorTitle fontColor={fontColor}>{authorTitle}</AuthorTitle>
            </span>
          )}
        </AuthorWrap>
      </TestimonialInner>
    </TestimonialContainer>
  );
};

WidgetTestimonial.propTypes = {
  author: PropTypes.string,
  authorImage: PropTypes.shape({
    ...Image.propTypes,
  }),
  authorTitle: PropTypes.string,
  fontColor: PropTypes.string,
  highlightColor: PropTypes.string,
  link: PropTypes.string,
  maxWidth: PropTypes.number,
  quote: PropTypes.string,
  quoteFontFamily: PropTypes.string,
  quoteFontSize: PropTypes.number,
  showQuoteIcon: PropTypes.bool,
};

WidgetTestimonial.defaultProps = {
  author: null,
  authorImage: null,
  authorTitle: null,
  fontColor: null,
  quoteFontFamily: null,
  quoteFontSize: 18,
  showQuoteIcon: true,
  highlightColor: null,
  link: null,
  maxWidth: null,
  quote: null,
};

export default WidgetTestimonial;
