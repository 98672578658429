import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../ui/constants/colors';
import PricingCardV3 from '../pricing/PricingCardV3';
import SocialIcon from '../SocialIcon';
import {
  AnnualContractIconWrapper,
  CardLayout,
  PaymentInterval,
  PaymentIntervalAndSavingsWrapper,
  PaymentIntervalHeader,
  PaymentIntervalOptions,
  PaymentIntervalWrapper,
  PaymentOptionsButton,
  PlanAnnualPaymentOption,
  PlanMonthlyPaymentOption,
  PlanPercentageSavingsText,
  WidgetWrapper,
} from './WidgetPricingCards.styles';

const PlanPercentageSavingsTextV3 = styled(PlanPercentageSavingsText)`
  color: ${({ isAnnual }) => (isAnnual ? colors.eerieBlack : colors.tulleGrey)};
  background: ${({ isAnnual }) => (isAnnual ? colors.caramel : colors.athensGrey)};
  text-align: center;
  border-radius: 100px;
  margin-left: 17px;
  margin-bottom: 9px;
  padding-bottom: unset;
`;

const WidgetPricingCardsV3 = ({ planOne, planTwo, savings }) => {
  const [isAnnual, setIsAnnual] = useState(true);

  return (
    <WidgetWrapper>
      <PaymentInterval>
        <PaymentIntervalHeader>Choose Plan</PaymentIntervalHeader>
        <PaymentIntervalWrapper>
          <PaymentIntervalOptions isV2OrV3PricingCard>
            <PaymentIntervalAndSavingsWrapper>
              <PaymentOptionsButton
                aria-label="Annual contract button"
                checked={isAnnual}
                name="plan"
                onChange={() => setIsAnnual(true)}
                type="radio"
                value="Annual contract"
              />
              <AnnualContractIconWrapper>
                <PlanAnnualPaymentOption isAnnual={isAnnual}>
                  Annual contract
                </PlanAnnualPaymentOption>
                <SocialIcon
                  color="fff"
                  height={20}
                  type={isAnnual ? 'presentActive' : 'presentInactive'}
                />
              </AnnualContractIconWrapper>
            </PaymentIntervalAndSavingsWrapper>
          </PaymentIntervalOptions>
          {savings && (
            <PlanPercentageSavingsTextV3 isAnnual={isAnnual}>
              {`Save up to ${savings}% off`}
            </PlanPercentageSavingsTextV3>
          )}
          <PaymentIntervalOptions>
            <PaymentOptionsButton
              aria-label="Pay as you go button"
              name="plan"
              onChange={() => setIsAnnual(false)}
              type="radio"
              value="Pay as you go"
            />
            <PlanMonthlyPaymentOption isAnnual={isAnnual}>Pay as you go</PlanMonthlyPaymentOption>
          </PaymentIntervalOptions>
        </PaymentIntervalWrapper>
      </PaymentInterval>
      <CardLayout>
        <PricingCardV3 data={planOne} isAnnual={isAnnual} />
        <PricingCardV3 data={planTwo} isAnnual={isAnnual} />
      </CardLayout>
    </WidgetWrapper>
  );
};

WidgetPricingCardsV3.propTypes = {
  planOne: PropTypes.object.isRequired,
  planTwo: PropTypes.object.isRequired,
  savings: PropTypes.number.isRequired,
};

export default WidgetPricingCardsV3;
