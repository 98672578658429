import { css } from 'styled-components';

import { mediaQueries } from './grid';

export const HEADER_TEXT_SIZES = {
  xlg: {
    headerFontSize: 48,
    headerFontSizeMobile: 24,
    headerLineHeight: 50,
    headerLineHeightMobile: 36,
  },
  lg: {
    headerFontSize: 40,
    headerFontSizeMobile: 24,
    headerLineHeight: 50,
    headerLineHeightMobile: 32,
  },
  m: {
    headerFontSize: 32,
    headerFontSizeMobile: 20,
    headerLineHeight: 40,
    headerLineHeightMobile: 24,
  },
  s: {
    headerFontSize: 24,
    headerFontSizeMobile: 18,
    headerLineHeight: 36,
    headerLineHeightMobile: 28,
  },
  xs: {
    headerFontSize: 20,
    headerFontSizeMobile: 16,
    headerLineHeight: 32,
    headerLineHeightMobile: 24,
  },
};

export const BODY_TEXT_SIZES = {
  xlg: {
    fontSize: 24,
    fontSizeMobile: 18,
    lineHeight: 36,
    lineHeightMobile: 28,
  },
  lg: {
    fontSize: 20,
    fontSizeMobile: 16,
    lineHeight: 32,
    lineHeightMobile: 24,
  },
  m: {
    fontSize: 16,
    fontSizeMobile: 14,
    lineHeight: 24,
    lineHeightMobile: 22,
  },
  s: {
    fontSize: 14,
    fontSizeMobile: 12,
    lineHeight: 24,
    lineHeightMobile: 18,
  },
  xs: {
    fontSize: 12,
    fontSizeMobile: 10,
    lineHeight: 20,
    lineHeightMobile: 16,
  },
};

export const TEXT_ALIGNMENT_CENTER = 'center';
export const TEXT_ALIGNMENT_JUSTIFY = 'justify';
export const TEXT_ALIGNMENT_LEFT = 'left';
export const TEXT_ALIGNMENT_RIGHT = 'right';
export const TEXT_ALIGNMENTS = [
  TEXT_ALIGNMENT_CENTER,
  TEXT_ALIGNMENT_JUSTIFY,
  TEXT_ALIGNMENT_LEFT,
  TEXT_ALIGNMENT_RIGHT,
];

// We assume that 1rem is 16px
export const REM_PIXELS = 16;

export const pxToRem = (pixels) => pixels / REM_PIXELS;

export const fontSizingCSS = (fontSizePixels, lineHeightPixels) =>
  css`
    font-size: ${pxToRem(fontSizePixels)}rem;
    ${lineHeightPixels && `line-height: ${pxToRem(lineHeightPixels)}rem;`}
  `;

export const responsiveFontSizingCSS = ({
  fontSizePixels,
  lineHeightPixels,
  mobileFontSizePixels,
  mobileLineHeightPixels,
}) =>
  css`
    ${fontSizingCSS(fontSizePixels, lineHeightPixels)}
    ${(mobileFontSizePixels || mobileLineHeightPixels) &&
    mediaQueries.mobile`
        ${fontSizingCSS(
          mobileFontSizePixels || fontSizePixels,
          mobileLineHeightPixels || lineHeightPixels,
        )}
      `}
  `;
