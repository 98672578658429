const footerLinks = [
  {
    header: 'Company',
    content: [
      { item: 'About Us', url: '/about/' },
      { item: 'Careers', tagOn: " - We're Hiring!", url: '/careers/' },
      { item: 'Press', url: '/press/' },
    ],
  },
  {
    header: 'Product',
    content: [
      { item: 'How It Works', url: '/how-it-works/' },
      { item: 'Take A Tour', url: '/blog/resources/platform-tour/' },
      { item: 'Pricing', url: '/pricing/' },
      { item: 'Security', url: '/security/' },
      { item: 'What`s New', url: '/whats-new/' },
    ],
  },
  {
    header: 'Resources',
    content: [
      { item: 'Education', url: '/blog/' },
      {
        item: 'Tools & Guides',
        url: process.env.GATSBY_CONTENTFUL_SPACE === 'blog' ? '/resources/' : '/blog/resources/',
      },
      { item: 'FAQ', url: '/faq/' },
      { item: 'Customer Reviews', url: '/customer-reviews/' },
    ],
  },
  {
    header: 'Partners',
    content: [
      { item: 'Refer A Friend', url: '/refer-a-friend/' },
      { item: 'Partner Marketplace', url: '/partner-marketplace/' },
      { item: 'Embedded Bookkeeping', url: '/embedded-bookkeeping/' },
    ],
  },
];

export default footerLinks;
