import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from '../../ui/constants/grid';

const HamburgerContainer = styled.div`
  align-items: center;
  display: flex;
  height: 65px;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 65px;

  ${mediaQueries.desktop`
    display: none !important;
  `};
`;

const HamburgerInner = styled.span`
  &.hamburger-inner {
    background-color: ${({ color }) => color};
    &::before {
      background-color: ${({ color }) => color};
    }
    &::after {
      background-color: ${({ color }) => color};
    }
  }
`;

const NavHamburger = ({ clickAction, isActive, linkColorMobile }) => (
  <HamburgerContainer>
    <button
      className={classnames('hamburger', 'hamburger--squeeze', { 'is-active': isActive })}
      onClick={clickAction}
      type="button"
    >
      <span className="hamburger-box">
        <HamburgerInner className="hamburger-inner" color={linkColorMobile} />
      </span>
    </button>
  </HamburgerContainer>
);

NavHamburger.propTypes = {
  clickAction: PropTypes.func,
  isActive: PropTypes.bool,
  linkColorMobile: PropTypes.string,
};

NavHamburger.defaultProps = {
  clickAction: null,
  isActive: false,
  linkColorMobile: null,
};

export default NavHamburger;
