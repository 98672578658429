import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../../ui/constants/colors';
import Page from '../../../components/Page';
import WidgetArticleBody from '../../../components/widgets/WidgetArticleBody';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';
import policyCopyrightText from '../helpers/policyCopywriteText';

const markdown = `
At Bench, our mission is to help entrepreneurs master their financial
lives with modern bookkeeping solutions. And to fulfill this mission, we
collect information about you, our:

-   **Customers** using Bench to power your business

-   **Accountant Clients** using Bench to streamline bookkeeping for
    your own clients

-   **Visitors** to Bench’s websites, or anyone contacting Bench support

-   **Prospective Benchmates** looking to make a big impact with a
    career at Bench

Your privacy is important to us. This privacy policy (this “**Privacy
Policy**”) outlines how we at Bench (Bench Accounting, Inc. and its
affiliates, collectively “**Bench**”) collect, use, disclose, and safeguard your personal information. We also describe the rights you have over your information.

Please read this Privacy Policy carefully. By using our Services (as
defined below), you acknowledge that you have read, understand and agree
to be legally bound by this Privacy Policy and other policies
incorporated herein by reference.

**This Privacy Policy was last updated and is effective on February 15, 2023.**

Bench reserves the right to modify this Privacy Policy at any time, in our sole discretion. We may modify this Privacy Policy by providing notice via one of the following methods: (i) by email to the email account used by you to access our Services, (ii) by updating the ‘last updated’ date listed above, or (iii) by your acknowledgement as part of your login to our Services. By continuing to use our Services you confirm that you agree to the modified Privacy Policy. If you do not agree with the changes to this Privacy Policy, you must immediately stop using our Services and close your account within 30 days of the change.

[<u>Our services and how this privacy policy
applies</u>](#our-services-and-how-this-privacy-policy-applies)

[<u>What information we collect about you and
why</u>](#what-information-we-collect-about-you-and-why)

[<u>How we use your information</u>](#how-we-use-your-information)

[<u>Your use of others’ personal
information</u>](#your-use-of-others-personal-information)

[<u>Your rights over your
information</u>](#your-rights-over-your-information)

[<u>When and why we share your information with
others</u>](#when-and-why-we-share-your-information-with-others)

[<u>Where your data resides and international data
transfers</u>](#where-your-data-resides-and-international-data-transfers)

[<u>How we protect your
information</u>](#how-we-protect-your-information)

[<u>How we use cookies and other tracking
technologies</u>](#how-we-use-cookies-and-other-tracking-technologies)

[<u>Changes to this Privacy Policy</u>](#changes-to-this-privacy-policy)

[<u>How you can reach us</u>](#how-you-can-reach-us)

Our Services and how this Privacy Policy applies
------------------------------------------------

Bench offers cloud-based bookkeeping, financial information storage and 
reporting, tax preparation and advisory ("Bench Tax"), and related 
communication services to entrepreneurs through its online platform at 
[<u>bench.co</u>](https://bench.co), accessible via our website and mobile 
applications. Through this platform, our Customers and Accountant Clients 
can access the tools they need to power their businesses or the businesses 
of their clients. We refer to our platform, websites and mobile applications 
collectively as our “**Services**”.

This Privacy Policy applies to our Services, and other interactions you may have with Bench, such as visiting our websites, customer service inquiries, attendance at events, etc. By using our Services, or otherwise interacting with Bench you agree to the collection and use of your personal information in accordance with this Privacy Policy. Personal information is any information or data relating to you that may be used to identify you, either directly or indirectly, or to contact you.

Our Services may contain links to other websites, products, or services that we do not own or operate. When using our Services, you may decide to share your data and any related personal information with a third-party service of your choice (for example, your accountant). Any processing of your information by these third parties is governed by their own privacy policies and terms. We recommend you carefully review their privacy policies and terms, as Bench is not responsible for such third-party services.

Some of our Services are made available to you by our affiliates. Personal information supplied by you through our Services to our affiliates will be governed by this Privacy Policy.

Our Services are not intended for use by children and should only be accessed by individuals who are at least 18 years old and are using our Services for business purposes. If we learn that we have inadvertently collected personal information of persons under the age of 18 years old, we shall immediately take steps to delete the information as soon as possible. If you learn that a child has provided us with personal information in violation of this Privacy Policy, then you may alert us at [<u>privacy@bench.co</u>](mailto:privacy@bench.co).

What information we collect about you and why
---------------------------------------------

The following disclosures reflect the information we may have collected about you, depending on your use of our Services, in the preceding 12 months since this Privacy Policy was last updated.

### A. Customers & Accountant Clients

If you are a Customer using Bench to power your business or an Accountant Client using Bench to streamline bookkeeping for your own clients, we collect and use your personal information to provide you with our Services. We collect the following personal information when you sign up for Bench, when you use our Services, or when you otherwise provide us information. We also may collect personal information from other sources, including business partners or referral partners. Generally, we use this information to provide and customize our Services for you.

<table>
<thead>
<tr>
<th><strong>What we collect</strong></th>
<th><strong>Why we collect it</strong></th>
</tr>
</thead>
<tbody>
<tr class="even">
<td>Information you provide us about you and your business, including identifiers and professional or employment-related information, like your name, company name, email address, and phone number.</td>
<td><ul>
<li><p>To set up your account and provide you with the use of our Services (e.g., to confirm your identity, to contact you about issues with our platform, to invoice you)</p></li>
<li><p>To market products or features to you</p></li>
<li><p>To comply with legal requirements</p></li>
</ul></td>
</tr>
<tr class="odd">
<td>Information of others that we process at your direction, such as the names and contact information of your clients, employees, etc.</td>
<td><ul>
<li><p>To provide you with our Services</p></li>
</ul></td>
</tr>
<tr class="even">
<td>Payment and billing information that you provide us, or our third-party payment processor, such as your credit or debit card number.</td>
<td><ul>
<li><p>To charge for our Services, products, and features</p></li>
</ul></td>
</tr>
<tr class="odd">
<td>Commercial information, such as information about products or services you purchased, obtained, or considered.</td>
<td><ul>
<li><p>To provide you use of, and to improve, our platform and other related services (e.g., identifying ways to make our platform easier to use or navigate)</p></li>
<li><p>To personalize the platform for you</p></li>
<li><p>To advertise and market products or features to you</p></li>
</ul></td>
</tr>
<tr class="even">
<td>Information about how you access our Services and your account, including Internet or other electronic network activity information, such as information about the device and browser you use, your network connection, your IP address, and details about how you browse and interact with our websites and platform.</td>
<td><ul>
<li><p>To provide you use of, and to improve, our platform and other related services (e.g., identifying ways to make our platform easier to use or navigate)</p></li>
<li><p>To debug, identify and repair errors that may impact intended functionality</p></li>
<li><p>To detect security incidents and protect against malicious, fraudulent or illegal activity</p></li>
<li><p>To personalize the platform for you</p></li>
<li><p>To advertise and market products or features to you</p></li>
<li><p>To comply with legal requirements</p></li>
</ul></td>
</tr>
<tr class="odd">
<td>Customer support information (such as questions and issues) and general feedback that you choose to provide (for example, app reviews).</td>
<td><ul>
<li><p>To provide you with customer support when using our Services</p></li>
<li><p>To debug, identify and repair errors that may impact intended functionality</p></li>
<li><p>To share with existing or prospective Customers or Accountant Clients your experience and feedback in using our Services</p></li>
</ul></td>
</tr>
<tr class="even">
<td>Audio information (such as recordings when you call us for customer support).</td>
<td><ul>
<li><p>To provide you with customer support when using our Services</p></li>
<li><p>To debug, identify and repair errors that may impact intended functionality</p></li>
<li><p>To share with existing or prospective Customers or Accountant Clients your experience and feedback in using our Services</p></li>
</ul></td>
</tr>
<tr class="odd">
<td>Information, such as demographic information, we receive from other third parties, including referral, promotional, and joint marketing partners and business partners who provide complementary services or products to our Services.</td>
<td><ul>
<li><p>To provide you with our Services</p></li>
<li><p>To provide you use of, and to improve, our platform and other related services (e.g., identifying ways to make our platform easier to use or navigate)</p></li>
<li><p>To personalize the platform for you</p></li>
<li><p>To advertise and market products or features to you </p></li>
</ul></td> 
</tr>
<tr class="even">
<td>If you connect our Services directly to your business bank account and authorize us to receive information directly from your business bank account, we receive your bank institution information (such as bank name and institution ID), business bank account information (for example, balances and last four digit of the account number), and transaction information (such as amount, vendor name, date, and category).</td>
<td><ul>
<li><p>To provide our business customers with a dashboard that displays real-time financial activity to enable our customers to track their businesses’ financial health</p></li>
<li><p>To create reconciliation files so Bench can assist our customers with bookkeeping for their businesses</p></li>
</ul></td> 
</tr>
<tr class="odd">
<td><p>If you use our BenchTax service we may collect more specific information you provide us about you and your business, including:</p>
<ul>
<li><p>characteristics of protected classifications (such as age, date of birth, gender, marital status, nationality)</p></li>
<li><p>sensitive information that are identifiers, including government-issued identification (such as your social security number, driver’s license number, taxpayer identification number, disability compensation records)</p></li>
<li><p>professional and employment information (such as your occupation, employer identification number, corporate-held credit and debit card numbers, employment records and compensation information)</p></li>
</ul></td>
<td><ul>
<li><p>To create and manage your Bench Tax account</p></li>
<li><p>To provide you with the BenchTax Services, including for your tax professional to set up tax payments to IRS and state departments of revenue</p></li>
<li><p>To conduct fraud and risk monitoring</p></li>
<li><p>To comply with legal requirements</p></li>
<li><p>To provide tax documentation</p></li>
</ul></td>
</tr>
<tr class="even">
<td>Copies of government-issued identification about you or your business that you provide to us</td>
<td><ul>
<li><p>If we need to verify your identity (e.g., to protect you against identity theft or fraud)</p></li>
<li><p>To verify that we are speaking with you if you contact us</p></li>
<li><p>To comply with legal requirements</p></li>
</ul></td>
</tr>
</tbody>
</table>

### B. Visitors

We collect the following personal information about you when you visit our website or contact us through our website:

<table>
<thead>
<tr>
<th><strong>What we collect</strong></th>
<th><strong>Why we collect it</strong></th>
</tr>
</thead>
<tbody>
<tr class="even">
<td>Information about you that you provide us about yourself, including identifiers, like your name, address, email address, and phone number.</td>
<td><ul>
<li><p>To communicate with you via email or via chatbot</p></li>
<li><p>To advertise and market products or features to you</p></li>
<li><p>To comply with legal requirements</p></li>
</ul></td>
</tr>
<tr class="odd">
<td>Information about how you access our Services, including Internet or other electronic network activity information, such as information about the device and browser you use, your network connection, your IP address, and details about how you browse through our apps and sites, including search terms you may enter.</td>
<td><ul>
<li><p>To provide you use of, and to improve, our websites and related Services (e.g., identifying ways to make our websites easier to use or navigate)</p></li>
<li><p>To personalize your experience using our websites (e.g., by changing the layout of our webpages)</p></li>
<li><p>To advertise and market products or features to you</p></li>
</ul></td>
</tr>
<tr class="even">
<td>Commercial information, such as information about products or services you purchased, obtained, or considered.</td>
<td><ul>
<li><p>To provide you use of, and to improve, our platform and other related services (e.g., identifying ways to make our platform easier to use or navigate)</p></li>
<li><p>To personalize the platform for you</p></li>
<li><p>To advertise and market products or features to you</p></li>
</ul></td>
</tr>
<tr class="odd">
<td>Information you provide us for surveys, contests and events (such as webinars or in-person events), including identifiers and professional or employment-related information, such as your contact information, your business name and address, and general information about your organization that you choose to provide, such as annual company revenue, number of employees, and industry.</td>
<td><ul>
<li><p>To register you for the survey, contest or event, and provide you with the results or contents of the event</p></li>
<li><p>To advertise and market products or features to you</p></li>
</ul></td>
</tr>
<tr class="even">
<td>Inferences that are drawn from your preferences, characteristics, or behavior while visiting or interacting with our website or our Services.</td>
<td><ul>
<li><p>To provide you use of, and to improve, our platform and other related services (e.g., identifying ways to make our platform easier to use or navigate)</p></li>
<li><p>To personalize the platform for you</p></li>
<li><p>To advertise and market products or features to you</p></li>
</ul></td>
</tr>
</tbody>
</table>

### C. Prospective Benchmates

We collect the following personal information about you when you apply
for a position with Bench:

<table>
<thead>
<tr>
<th><strong>What we collect</strong></th>
<th><strong>Why we collect it</strong></th>
</tr>
</thead>
<tbody>
<tr class="even">
<td>Identifiers, including contact information that you provide us about yourself, like your name, address, email address, and phone number.</td>
<td><ul>
<li><p>To communicate with you during the recruitment process</p></li>
<li><p>To book interviews with you and members of our team</p></li>
<li><p>To verify your identity</p></li>
<li><p>To comply with legal requirements</p></li>
</ul></td>
</tr>
<tr class="odd">
<td>Additional personal information you provide us, such as your professional and employment-related information (such as your resume or CV, past employment experience, occupations and titles, your LinkedIn profile or other social media handles), your nationality, and your education (such as your degrees and schooling, licenses and professional memberships).</td>
<td><ul>
<li><p>To review your suitability for a position and qualify you for a role</p></li>
<li><p>To verify your legal ability to work in Canada</p></li>
</ul></td>
</tr>
</tbody>
</table>

### D. Prospective Customers

In order to offer our Services to prospective Customers who might find our Services useful, we collect personal information, including identifiers, such as names, emails addresses, and phone numbers, from business partners and public sources. We may also collect information about how you access our Services, including Internet or other electronic network activity information about the device and browser you use, your network connection, your IP address, and details about how you browse and interact with our websites and platform.


How we use your information
---------------------------

We use the personal information we collect from you to provide you with our Services, to fulfill a contractual obligation (for example, to process your subscription payments), to improve our Services or where we need your personal information for a legitimate business interest, including to better understand business trends, such as how our products and services are used, and share these insights with our affiliates and business partners. These legitimate interests include:

-   verifying your or your business’ identity

-   preventing risk and fraud (for example, to detect and protect Bench and other third parties against error, negligence, breach of contract, fraud, theft and other illegal activity, and to audit compliance with Bench’s policies and contractual obligations)

-   answering questions or providing other types of support

-   providing, securing, debugging and improving our Services and
    website

-   providing reporting and analytics

-   testing out features or additional services

-   assisting with marketing, advertising, or other communications

-   understanding your needs and eligibility for products and services

-   personalizing your experience on our Services, such as presenting tailored content

-   complying with legal and regulatory requirements

-   enforcing our Terms of Use and / or compliance with this Privacy
    Policy

We may also collect and use your personal information for any other purpose to which you consent.

We may use information that is aggregated or de-identified so that it is no longer reasonably associated with an identified or identifiable natural person for any business purpose.

Your use of others’ personal information
----------------------------------------

In using our Services, you may provide us with the personal information of others (for example, the names and addresses of your accounting clients or employees), and if you are an accounting client or employee of one of our Customers, we may receive your personal information from our Customers or Accountant Clients. In these circumstances, we are only a “service provider” of such information.

As your service provider, we process (collect, use, disclose, etc.) such information as directed by you for the purpose of providing you our Services. For example, we do not use your customers’ or employees’ information to independently market or advertise to them unless they are also using our Services directly.  

You are responsible for the personal information of others that you disclose to us via our Services and for ensuring that your instructions regarding the processing of such information complies with applicable data protection laws.

Your rights over your information
---------------------------------

Depending on your use of our Services and where you reside, you may have the right to request access to, correct, amend, delete, or object to certain uses of certain personal information (for example, by unsubscribing from direct marketing emails).

We strive to provide you with the ability to exercise these rights yourself via our Services. For example, you can access, update and correct most of your information from your account settings pages. If you cannot exercise a particular right via our Services, you can always email our privacy team at
[<u>privacy@bench.co</u>](mailto:privacy@bench.co). Please note that if you send us a request relating to your personal information, we have to make sure that it is you before we can respond. In order to do so, we may ask to see documentation verifying your identity.

Please also note that there is a difference between the information we decide to collect for our own purposes (for example, your account sign-up information) and the information our Customers and Accountant Clients direct us to store or process via our Services (for example, names of employees or clients). If you are an individual who wishes to exercise your rights over information that one of our Customers or Accountant Clients may be storing or processing via our Services for their own purposes, you will need to contact the Customer or Accountant Client directly. In this scenario, we are only a service provider acting at their direction, and we cannot decide how to process their information.

### Your California privacy rights

If you are a California resident, you may exercise the following rights with respect to personal information (as defined by the California Consumer Privacy Act of 2018 (California Civil Code §§ 1798.100 to 1798.199) and its implementing regulations, as amended or superseded from time to time, including the California Privacy Rights Act (“CPRA”), (collectively, the “CCPA”)) that we collect as a business under California law.

### A. Right to know and access personal information

You may submit a verifiable request to be informed about the (1) categories of personal information collected, sold, or disclosed by us, (2) purposes for which categories of personal information are collected or sold by us, (3) categories of sources from which we collect personal information, (4) categories of third parties with whom we disclosed or sold personal information, and (5) specific pieces of personal information that we collected about you during the prior twelve months. 

### B. Right to request deletion of personal information

Subject to certain exceptions, you may submit a verifiable request that we delete the personal information about you that we collected from you. Once we receive your request, we will let you know what, if any, personal information we can delete from our records, and we will direct any service providers with whom we shared your personal information to also delete your personal information from their records.

 There may be circumstances where we cannot delete your personal information or direct service providers to delete your personal information from their records. Some of these instances include, but are not limited to, if we need to: (1) retain your personal information to complete a transaction, provide a good or service, fulfill the terms of a written warranty, or to perform under a contract between us; (2) detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for that activity; and (3) comply with the law.

### C. Right to correct inaccurate personal information

If you believe that the personal information we have about you is incorrect, you may submit a verifiable request for such personal information to be corrected. We shall use commercially reasonable efforts to correct the personal information we hold about you. 

### D. Verification

Requests for access to, deletion of, or correction of personal Information are subject to our ability to reasonably verify your identity in light of the information requested and pursuant to relevant CCPA requirements, limitations, and regulations. To request access, deletion, or correction, you can email us at [<u>privacy@bench.co</u>](mailto:privacy@bench.co), call us, or mail a letter with your name, mailing address, email address, phone number, and the last 4-digits of an account or credit card number in your request and we will reach out to you. To verify your identity based on information previously provided to Bench, we may request additional information. 

### E. Right to opt-out of the sale or sharing of personal information

You have the right to opt-out of the sale of your personal information. Click [<u>here</u>](https://forms.gle/VEsx4t8MEJUoVd2T7) to opt out of the sale or sharing of your personal information.

### F. Right of no retaliation

You have the right not to receive discriminatory treatment by a business for the exercise of your CCPA privacy rights, subject to certain limitations, including if you are an employee, applicant, or independent contractor of our business. 

### G. Right to initiate a private cause of action

You have the right, if certain parts of your personal information are part of a data security breach, to initiate a private cause of action.

### H. Right to limit use and disclosure of sensitive personal information

You have the right to limit our use and disclosure of sensitive personal information (“SPI”) to what is necessary or reasonably expected of us in order to perform the Services. We do not use or disclose your SPI for purposes other than the limited purposes permitted by the CCPA. If we do use SPI beyond what is necessary to provide the Services, we shall provide you notice of the additional purposes for our use or disclosure of SPI and remind you of your right to request that we limit the use and disclosure of the SPI. 

SPI is a subset of personal information that reveals (i) your social security number, driver’s license number, state identification card or passport number; (ii) your account log-in, financial account information, debit or credit card number in combination with any password or access code to grant access; (iii) your precise geolocation; (iv) your racial or ethnic origin, religious or philosophical beliefs, or union membership; (v) the content of your mail, email or text messages unless we are the intended recipient of said communications; and (vi) your genetic data. 

### I. Submit requests

If you are a California resident and would like to exercise your rights under the CCPA, please send your request by email to [<u>privacy@bench.co</u>](mailto:privacy@bench.co) with "Your California Privacy Rights" in the subject field of your email, call us toll-free at 1-888-760-1940, or send your request by mail to our address listed in the “How you can reach us” section below. Once you contact us, we may need to confirm your identity to process the request. 

### J. Authorizing an agent

If you are acting as an authorized agent to make a request to know, delete, or opt out on behalf of a California resident, please send a message to the email in our “How you can reach us section” and attach a written authorization signed by the resident.

### K. Do not track

There is no accepted standard on how to respond to Do Not Track signals, and we do not respond to such signals.



When and why we share your information with others
--------------------------------------------------

We restrict access to your information to authorized employees and contractors. We may share and, in the preceding 12 months since Privacy Policy was last updated, may have shared your personal information with third parties in the following circumstances.

As a Customer or Accountant Client, we may share all of the categories of personal information described in the “What information we collect about you and why” section with service providers, including the following service providers to provide you with our Services:

| **Service provider** | **Service provided**                   | **Location** |
|----------------------|----------------------------------------|--------------|
| AWS                  | Cloud storage and infrastructure       | USA          |
| Sendgrid             | Transactional email API                | USA          |
| Mandrill             | Transactional email API                | USA          |
| DataDog              | Centralized logging                    | USA          |
| Rollbar              | Error monitoring                       | USA          |
| FileThis             | Consumer finance document API          | USA          |
| Plaid                | Technical financial infrastructure API | USA          |
| Stripe               | Payments API                           | USA          |
| Salesforce           | CRM                                    | USA          |

We may also share all of the categories of your personal information described in the “What information we collect about you and why” section with the following categories of third parties for certain other business purposes:


-   **Other Service Providers, Partners, Contractors or Vendors:** for analytics and reporting, marketing and advertising, auditing, communicating with you, managing our customer database, data processing, providing you with professional services, managing our recruitment process and conducting background checks (for Prospective Benchmates).

-   **Successor Entities:** if we merge with or are acquired by another company, are involved in a bankruptcy, or if the ownership or control of all or part of our Services or their assets changes, information about you may be transferred to such entities.

-   **Law Enforcement, Government Agencies, and Professional Advisors:** to prevent or investigate suspected fraud, threats to physical safety, illegal activity, or violations of a contract or our policies, or to comply with a law or regulation, or if we are otherwise legally required to do so, such as in response to a court order or legal process, or to establish, protect, or exercise our legal rights or to defend against legal claims or demands.

We also share certain categories of personal information with third parties to provide personalized online advertising and with third parties for their direct marketing purposes. Under the CCPA, some of this information sharing may be broadly considered a “sale” or “sharing” of Personal Information. Except for this type of sharing, we do not sell your Personal Information. We sell or share and in the preceding 12 months since this Privacy Policy have sold or shared identifiers (such as name, email address, IP address and online identifiers), commercial information, internet or other electronic network activity information, and inferences drawn from personal information with third parties, including advertising providers, analytics providers, social networks, and resellers, for these purposes.  We do not knowingly sell or share Personal Information of consumers under 16 years of age. If you are a California resident, you can opt out of the sale of your personal information as described above.


Where your data resides 
--------------------------------------------------------

Our Services are managed by Bench’s team headquartered in Canada and operating from locations around the world. Our Services are hosted in the United States and individuals located within the United States are permitted to use our Services. If you circumvent Bench’s measures restricting our Services to the United States and use our Services from the European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you are transferring your personal information outside of those regions to the United States for storage and processing. Also, we may transfer your personal information from the United States to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating our Services. By providing any information, including information from or about you or your devices, on or to our Services, you consent to such transfer, storage, and processing.


Data retention
--------------

Bench is a United States company, serving US persons and businesses, and as such, we and our affiliates may be subject to various legal compliance and reporting obligations that require us to retain your personal information for a specific period of time which may be beyond the time when you stop using our Services. When we have no ongoing legitimate business or regulatory need to retain or process your personal data, we will either delete or anonymize the data.

How we protect your information
-------------------------------

We maintain security safeguards to protect your information, which 
are designed to ensure its confidentiality, integrity and availability. 
While we strive to protect your information on our Services, we cannot 
guarantee absolute security of your personal information. To learn more 
about our information security measures, please see our [<u>Security Practices</u>](https://bench.co/security/). We will notify you if there is a breach of our security where required by law or deemed necessary.

How we use cookies and other tracking technologies
--------------------------------------------------

We use cookies and similar tracking technologies on our website and when providing our Services. For more information about how we use these technologies, and an explanation of how you can opt out of certain types of cookies, please see our [<u>Cookie Policy</u>](https://bench.co/cookie-policy/).

Changes to this Privacy Policy
------------------------------

We may make changes to this Privacy Policy at any time to reflect updates to our Services, applicable laws, and other factors. We will notify you as outlined above when we materially change the ways in which we use or share personal information previously collected from you through our Services, but we encourage you to stay informed by reviewing this policy periodically.

Communication Preferences
-------------------------

You may manage your receipt of marketing and communication emails from us by clicking the unsubscribe link located at the bottom of our marketing emails. You may also specify your communication preferences in your account. You may not opt out of receiving emails or notifications for certain elements of the Services, including those that are essential to the Services.

How you can reach us
--------------------

If you would like to ask about, make a request relating to, or complain
about how we process your personal information, you can contact us by
email at [<u>privacy@bench.co</u>](mailto:privacy@bench.co), by calling
us toll-free at 1-888-760-1940, or by sending a letter to:

    Bench Accounting Inc.,
    545 Robson St,
    Vancouver, BC V6B 2B7
    Attn: Bench Privacy Team

${policyCopyrightText}
`;

const PrivacyPolicyBodyContainer = styled.div`
  && {
    dt::before,
    dt:after,
    dd::before {
      display: none;
    }

    dt {
      font-weight: normal;
    }

    dd {
      font-weight: 700;
    }

    ol {
      list-style-type: lower-alpha;

      ol li {
        list-style-type: decimal;
      }
    }
  }
`;

const PrivacyPolicyBody = ({ markdown: policyMarkdown, path }) => (
  <PrivacyPolicyBodyContainer>
    <WidgetArticleBody markdown={policyMarkdown} path={path} />
  </PrivacyPolicyBodyContainer>
);

PrivacyPolicyBody.propTypes = {
  markdown: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

const pageData = {
  description:
    'We take data privacy and security very seriously. ' +
    'Learn about our privacy policy and how we collect and store your information.',
  enableAbTesting: false,
  footerType: 'full',
  navbarType: 'full',
  path: '/privacy/',
  title: 'Privacy Policy — Bench',
  sections: [
    {
      columns: [
        {
          span: 5,
          widgets: [
            {
              data: {
                description: 'Last updated: February 15, 2023',
                header: 'Bench Accounting, Inc.\nPrivacy Policy',
                textAlignment: 'center',
              },
              WidgetComponent: WidgetValueProp,
            },
          ],
        },
      ],
      paddingBottom: 0,
      margin: 0,
    },
    {
      backgroundColor: colors.white,
      columns: [
        {
          span: 9,
          widgets: [
            {
              data: {
                markdown,
                path: '/privacy/',
              },
              WidgetComponent: PrivacyPolicyBody,
            },
          ],
        },
      ],
      paddingTop: 0,
    },
  ],
};

const PrivacyPage = () => <Page {...pageData} />;

export default PrivacyPage;
