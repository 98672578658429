import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import PropTypes from 'prop-types';
import { pxToRem } from '../../ui/constants/typography';
import colors from '../../ui/constants/colors';
import markdownToHTML from '../helpers/markdownToHTML';
import { mediaQueries } from '../../ui/constants/grid';
import WidgetCallToActionV2 from './WidgetCallToActionV2';

const Header = styled.h1`
  color: ${colors.eerieBlack};
  font-weight: 700;
  font-size: 48px;
  padding: 0 20px 60px;

  ${mediaQueries.mobile`
    font-size: 36px;
  `}
`;

const RowWrapper = styled(AccordionItem)`
  background-color: ${({ isOpen }) => (isOpen ? `${colors.ghostWhite}` : '')};
  padding: 18px 20px 18px;
  position: relative;
  border-radius: 8px;
  margin-bottom: 15px;
  color: ${colors.eerieBlack};

  &:hover {
    background-color: ${colors.ghostWhite};
  }
`;

const ContentsLayout = styled(AccordionItemPanel)`
  font-size: 16px;
  font-weight: 450;
  line-height: 20px;
  padding: 18px 0 18px 10px;
`;

const Contents = styled.div`
  h1,
  h2,
  h3 {
    color: ${colors.eerieBlack};
    font-weight: 700;
    line-height: 20px;
    padding-bottom: 11px;
  }

  p {
    color: ${colors.eerieBlack};
    font-size: 16px;
    font-weight: 450;
    margin-right: 16px;
    padding-bottom: 16px;
    line-height: ${pxToRem(25)};
  }

  a {
    color: ${colors.electricBlue};
  }

  ${mediaQueries.mobile`
    p { 
      font-size: 16px;
    }
  `}
`;

const Title = styled(AccordionItemButton)`
  font-size: 20px;
  font-weight: 700;
  padding-left: 33.5px;
  cursor: pointer;

  &::before {
    cursor: pointer;
    color: ${colors.electricBlue};
    content: '+';
    font-size: 24px;
    display: inline-block;
    font-weight: bold;
    height: 24px;
    width: 24px;
    left: 24px;
    line-height: 21px;
    position: absolute;
    text-align: center;
    transform-origin: center;
    ${({ isOpen }) =>
      isOpen ? 'transform: translateY(-50%) rotate(-45deg);' : 'transform: translateY(-50%);'};
    transition: 0.2s;
    margin-top: 16px;
  }
`;

const WidgetAccordion = ({ accordionRows, heading }) => {
  const [openItemIndex, setOpenItem] = useState(undefined);

  const updateOpenItem = (index) => {
    setOpenItem((prev) => {
      return prev === index ? null : index;
    });
  };

  return (
    <>
      <Header aria-level={2}>{heading}</Header>
      <Accordion allowZeroExpanded>
        {accordionRows.map((row, index) => (
          <RowWrapper key={index} isOpen={openItemIndex === index}>
            <AccordionItemHeading
              aria-level={3}
              isOpen={openItemIndex === index}
              onClick={() => {
                updateOpenItem(index);
              }}
            >
              <Title isOpen={openItemIndex === index}>{row.title}</Title>
            </AccordionItemHeading>
            <ContentsLayout>
              {openItemIndex === index && (
                <Contents
                  aria-level={4}
                  dangerouslySetInnerHTML={{
                    __html: markdownToHTML(row.body),
                  }}
                />
              )}
              {row.button &&
                row.button.map(
                  ({ buttonText, styleType, buttonAlignmentInColumn, linkUrl, width }) => (
                    <WidgetCallToActionV2
                      buttonAlignmentInColumn={buttonAlignmentInColumn}
                      buttonText={buttonText}
                      linkURL={linkUrl}
                      mobileButtonAlignment="left"
                      styleType={styleType}
                      width={width}
                    />
                  ),
                )}
            </ContentsLayout>
          </RowWrapper>
        ))}
      </Accordion>
    </>
  );
};

WidgetAccordion.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  accordionRows: PropTypes.array.isRequired,
  heading: PropTypes.string.isRequired,
};

export default WidgetAccordion;
