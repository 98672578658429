import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import Button from '../../ui/buttons/Button';
import grid, { getDesktopColumnWidth, getMobileColumnWidth } from '../../ui/constants/grid';
import GridColumn from '../../ui/grid/GridColumn';
import GridContainer from '../../ui/grid/GridContainer';
import GridRow from '../../ui/grid/GridRow';
import Spacer from '../../ui/Spacer';
import Widget from '../Widget';

const minCardSpan = 4;

const CardContainer = styled.div`
  height: 100%;
  padding-bottom: ${grid.gutterWidth * 0.75}px;
  padding-top: ${grid.gutterWidth * 0.25}px;
  & > * {
    height: 100%;
  }
`;

const LoadMoreButton = styled(Button)`
  padding: 0.5rem;
`;

const makeCardColumns = (cards, span) =>
  cards.reduce((columns, card, cardIndex) => {
    // Augment the card data with the width of the column it's been placed into so that it can adapt
    // its layout for the available width
    const widgetProps = {
      ...card,
      data: {
        ...card.data,
        maxWidth: getDesktopColumnWidth(span),
        mobileMaxWidth: getMobileColumnWidth(),
      },
    };

    const column = (
      <GridColumn key={card.id || cardIndex} span={span}>
        <CardContainer>
          <Widget data={widgetProps.data} WidgetComponent={card.WidgetComponent} />
        </CardContainer>
      </GridColumn>
    );
    return [...columns, column];
  }, []);

const makeCardRows = (cards, layout) =>
  layout.reduce((rows, { numCards }, rowIndex) => {
    // Start at the index that is the sum of the numCards from the previous rows in the layout
    const startAtCard = layout.slice(0, rowIndex).reduce((n, row) => n + row.numCards, 0);
    const endAtCard = startAtCard + numCards;
    const rowCards = cards.slice(startAtCard, endAtCard);
    // Don't create a row if there are no cards in the slice, either because numCards is 0 or all
    // the cards have been used
    if (rowCards.length === 0) {
      return rows;
    }
    const cardSpan = Math.max(minCardSpan, Math.floor(12 / numCards));
    const row = (
      <GridRow key={rowIndex} alignItems="stretch">
        {makeCardColumns(rowCards, cardSpan)}
      </GridRow>
    );
    return [...rows, row];
  }, []);

const WidgetCardList = ({ cards, layout: layoutProp }) => {
  const [layout, setLayout] = useState([...layoutProp]);

  const loadMoreCards = () => {
    const layoutExtension = [{ numCards: 3 }, { numCards: 3 }, { numCards: 3 }];
    setLayout([...layout, ...layoutExtension]);
  };

  const visibleCardCount = layout.reduce((total, { numCards }) => total + numCards, 0);

  // The cards array can be sparse due to invalid data being rejected and set to null by the
  // translation from the CMS data to widget props objects
  const denseCardsArray = cards.filter((x) => x);
  if (denseCardsArray.length === 0) {
    return null;
  }

  return (
    <GridContainer>
      {makeCardRows(denseCardsArray, layout)}
      {denseCardsArray.length > visibleCardCount && (
        <GridRow>
          <GridColumn span={3}>
            <Spacer marginBottom={5} marginTop={3}>
              <LoadMoreButton full ghost onClick={loadMoreCards} textColor="#2f3034" type="button">
                Load More
              </LoadMoreButton>
            </Spacer>
          </GridColumn>
        </GridRow>
      )}
    </GridContainer>
  );
};

WidgetCardList.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  layout: PropTypes.arrayOf(
    PropTypes.shape({
      numCards: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default WidgetCardList;
