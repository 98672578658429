import WidgetImage from '../../../components/widgets/WidgetImage';
import WidgetValueProp from '../../../components/widgets/WidgetValueProp';
import WidgetCallToAction from '../../../components/widgets/WidgetCallToAction';
import colors from '../../../ui/constants/colors';

const blogSignupSectionProps = {
  alignItems: 'flex-end',
  backgroundColor: '#F4CF7F',
  columns: [
    {
      span: 8,
      widgets: [
        {
          data: {
            description:
              'Get a weekly dose of educational guides and resources curated from the experts at Bench to help you confidently make the right decisions to grow your business. No spam. Unsubscribe at any time.',
            header:
              'Join over 140,000 fellow entrepreneurs who receive expert advice for their small business finances',
            headerFontSize: 24,
            headerLineHeight: 32,
          },
          marginTop: 6.75,
          WidgetComponent: WidgetValueProp,
        },
        {
          data: {
            backgroundColor: '#0FA1A2',
            buttonText: 'Subscribe',
            linkURL: '/blog/subscribe/',
            textColor: colors.pearl,
          },
          margin: 3,
          WidgetComponent: WidgetCallToAction,
        },
      ],
    },
    {
      span: 1,
    },
    {
      span: 3,
      widgets: [
        {
          data: {
            alt: 'Decorative patterns',
            imageAlignment: 'right',
            mobileImageAlignment: 'center',
            mobileMaxWidth: 370,
            naturalHeight: 515,
            naturalWidth: 944,
            src: '/img/illustrations/Content_Hub-signup_banner.png',
          },
          margin: 0,
          WidgetComponent: WidgetImage,
        },
      ],
    },
  ],
  justifyContent: 'flex-start',
  padding: 0,
};

export default blogSignupSectionProps;
