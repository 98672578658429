import styled from 'styled-components';

import colors from '../../../ui/constants/colors';
import { mediaQueries } from '../../../ui/constants/grid';

const FormError = styled.div`
  display: ${({ shouldDisplay }) => (shouldDisplay ? 'block' : 'none')};
  font-weight: bold;
  margin: 24px 0;

  &,
  a {
    color: ${colors.copper};
  }

  a {
    text-decoration: underline;
  }

  ${mediaQueries.mobile`
    text-align: center;
  `}
`;

export default FormError;
