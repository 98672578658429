import PropTypes from 'prop-types';
import React from 'react';

import externalAssetLoading from './externalAssetLoading';
import { loadScript } from '../helpers/loadAsset';
import Button from '../../ui/buttons/Button';

const BENCH_JOB_BOARD_EMBED_URL = 'https://boards.greenhouse.io/embed/job_board/js?for=bench';

const GreenhouseApplication = ({ greenhouseId }) => {
  return (
    <div id="grnhse_app">
      <Button onClick={() => window.Grnhse.Iframe.load(greenhouseId)}>Apply Now</Button>
    </div>
  );
};

GreenhouseApplication.propTypes = {
  greenhouseId: PropTypes.string.isRequired,
};

GreenhouseApplication.loadExternalAssets = () => {
  return loadScript(BENCH_JOB_BOARD_EMBED_URL, true).then(() => {
    if (typeof window !== 'undefined' && window.Grnhse) {
      // HACK For the life of me, I can't find any documentation or settings in the Greenhouse UI to
      // turn this off, so manually overriding the global setting.
      window.Grnhse.Settings.scrollOnLoad = false;
    }
  });
};

export default externalAssetLoading(GreenhouseApplication);
