/* eslint-disable camelcase */

import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';
import tryGet from '../../../components/helpers/tryGet';
import WidgetBanner from '../../../components/widgets/WidgetBanner';

const imagePositions = {
  'after text': 'after',
  'before text': 'before',
};

const translateBanner = ({
  backgroundColor,
  backgroundImage,
  buttonBackgroundColor,
  buttonText,
  buttonTextColor,
  description,
  foregroundImage,
  foregroundImagePosition,
  header,
  contentful_id,
  linkUrl,
  textColor,
}) => ({
  data: {
    backgroundColor,
    backgroundImgSrc: tryGet(['file', 'url'], backgroundImage),
    buttonBackgroundColor,
    buttonText,
    buttonTextColor,
    description: tryGet(['description'], description) || description,
    header,
    image: normalizeContentfulImageData(foregroundImage),
    imagePosition: imagePositions[foregroundImagePosition],
    linkURL: linkUrl,
    textColor,
  },
  id: contentful_id,
  WidgetComponent: WidgetBanner,
});

export default translateBanner;
