import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ApprovalCheckmark from '../../ui/icons/ApprovalCheckmark';
import colors from '../../ui/constants/colors';
import Link from '../../ui/buttons/Link';

const Byline = styled.div`
  text-align: center;
`;

const InlineText = styled.span`
  color: ${colors.dust};
`;

const BioLink = styled(Link)`
  color: ${colors.water};
`;

const WidgetByline = (props) => {
  const { author, authorBioUrl, factChecker, factCheckerBioUrl, formattedDatePublished } = props;

  const authorDetails = () => {
    if (authorBioUrl) {
      return <BioLink linkURL={authorBioUrl}>{author}</BioLink>;
    }
    return <InlineText>{author}</InlineText>;
  };

  const factCheckerDetails = () => {
    if (factCheckerBioUrl) {
      return (
        <>
          <InlineText>, Fact-Checked by </InlineText>
          <BioLink linkURL={factCheckerBioUrl}>{factChecker}</BioLink>
        </>
      );
    }
    return <InlineText>{`, fact-checked by ${factChecker}`}</InlineText>;
  };

  const CpaApprovedTagline = () => {
    if (factChecker) {
      return (
        <>
          <InlineText>This article is Tax Professional approved </InlineText>
          <ApprovalCheckmark alt="checked" src="img/icons/checkmark.svg" />
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <Byline>
        <InlineText>By </InlineText>
        {authorDetails()}
        {factChecker && factCheckerDetails()}
        <InlineText> on {formattedDatePublished}</InlineText>
      </Byline>

      <Byline>{CpaApprovedTagline()}</Byline>
    </>
  );
};

WidgetByline.propTypes = {
  author: PropTypes.string.isRequired,
  authorBioUrl: PropTypes.string.isRequired,
  factChecker: PropTypes.string.isRequired,
  factCheckerBioUrl: PropTypes.string.isRequired,
  formattedDatePublished: PropTypes.string.isRequired,
};

export default WidgetByline;
