import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../ui/buttons/Button';
import colors from '../../ui/constants/colors';

const WidgetButton = ({
  backgroundColor,
  buttonPadding,
  disabled,
  fontSize,
  full,
  ghost,
  plain,
  textColor,
  useInputElement,
  buttonText,
  onClick,
}) => (
  <Button
    backgroundColor={backgroundColor}
    buttonPadding={buttonPadding}
    disabled={disabled}
    fontSize={fontSize}
    full={full}
    ghost={ghost}
    onClick={onClick}
    plain={plain}
    textColor={textColor}
    useInputElement={useInputElement}
  >
    {buttonText}
  </Button>
);

WidgetButton.propTypes = {
  backgroundColor: PropTypes.string,
  buttonPadding: PropTypes.string,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  fontSize: PropTypes.number,
  full: PropTypes.bool,
  ghost: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  plain: PropTypes.bool,
  textColor: PropTypes.string,
  useInputElement: PropTypes.bool,
};

WidgetButton.defaultProps = {
  backgroundColor: colors.water,
  buttonPadding: '1rem',
  buttonText: null,
  disabled: false,
  fontSize: 16,
  full: false,
  ghost: false,
  plain: false,
  textColor: colors.white,
  useInputElement: false,
};

export default WidgetButton;
