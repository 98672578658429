import buildAuthorUrl from '../helpers/buildAuthorUrl';
import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';
import WidgetAuthor from '../../../components/widgets/WidgetAuthor';

const translateAuthor = ({
  name,
  title,
  biography,
  facebookLink,
  twitterLink,
  instagramLink,
  image,
  id,
  slug,
}) => ({
  name,
  title,
  biography: biography.biography,
  facebookLink,
  twitterLink,
  instagramLink,
  image: image && image.file && image.file.url && normalizeContentfulImageData(image),
  linkURL: buildAuthorUrl({ slug }),
  id,
  WidgetComponent: WidgetAuthor,
});

export default translateAuthor;
