/* eslint-disable camelcase */

import WidgetEmbed from '../../../components/widgets/WidgetEmbed';

const translateEmbed = ({ embedId, embedType, contentful_id }) => ({
  data: {
    embedId,
    embedType,
  },
  id: contentful_id,
  WidgetComponent: WidgetEmbed,
});

export default translateEmbed;
