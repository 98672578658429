const blogCategories = [
  {
    name: 'Tax Tips',
    url: '/blog/tax-tips/',
  },
  {
    name: 'Accounting',
    url: '/blog/accounting/',
  },
  {
    name: 'Bookkeeping',
    url: '/blog/bookkeeping/',
  },
  {
    name: 'Banking',
    url: '/blog/banking/',
  },
  {
    name: 'Starting a Business',
    url: '/blog/starting-a-business/',
  },
  {
    name: 'Operations',
    url: '/blog/operations/',
  },
  {
    name: 'Small Business Stories',
    url: '/blog/small-business-stories/',
  },
  {
    name: 'Resources',
    url: '/blog/resources/',
  },
  {
    name: 'Bench News',
    url: '/blog/bench-news/',
  },
];

export default blogCategories;
