import querystring from 'querystring';

const getQueryFromLocation = (location) => {
  if (!location || typeof location !== 'object') {
    return {};
  }
  const { search } = location;
  if (!search || typeof search !== 'string') {
    return {};
  }
  const searchWithoutLeadingQuestionMark = search.replace(/^\?/, '');
  return querystring.parse(searchWithoutLeadingQuestionMark);
};

export default getQueryFromLocation;
