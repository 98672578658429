import getQueryFromLocation from '../../helpers/getQueryFromLocation';
import trimSlashes from '../../../lib/trimSlashes';

export const getJobPagePath = (greenhouseId) => `/careers/job?id=${greenhouseId}`;

export const getGreenhouseId = (location, qsParam) => {
  const parsedQueryString = getQueryFromLocation(location);
  const searchGreenhouseId = parsedQueryString[qsParam];
  if (searchGreenhouseId) {
    return searchGreenhouseId;
  }
  const { hash } = location;
  const hashWithoutNumberSign = hash.substr(1);
  const hashWithoutSlashes = trimSlashes(hashWithoutNumberSign);
  if (hashWithoutSlashes !== 'current_openings') {
    return hashWithoutSlashes;
  }
  return false;
};
