import buildArticleUrl from '../helpers/buildArticleUrl';
import colors from '../../../ui/constants/colors';
import getDefaultContentCardImage from './getDefaultContentCardImage';
import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';
import tryGet from '../../../components/helpers/tryGet';
import WidgetContentCard from '../../../components/widgets/WidgetContentCard';

const translateArticleContentCard = ({
  articleHeaderBackgroundColor,
  articleHeaderImage,
  category,
  contentCardDescription,
  contentCardImage,
  id,
  slug,
  title,
  enableAbTesting,
}) => {
  const image = contentCardImage || articleHeaderImage;

  return {
    data: {
      backgroundColor: articleHeaderBackgroundColor || colors.sand,
      category,
      description:
        tryGet(['contentCardDescription'], contentCardDescription) || contentCardDescription,
      header: title,
      image:
        image && image.file && image.file.url
          ? normalizeContentfulImageData(image)
          : getDefaultContentCardImage(category, id),
      linkURL: buildArticleUrl({ category, slug }),
    },
    id,
    enableAbTesting,
    WidgetComponent: WidgetContentCard,
  };
};

export default translateArticleContentCard;
