import WidgetPricingCards from '../../../components/widgets/WidgetPricingCards';

const widgetPricingCardsTranslation = () => {
  return {
    data: {},
    WidgetComponent: WidgetPricingCards,
  };
};

export default widgetPricingCardsTranslation;
