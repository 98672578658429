import { navigate } from 'gatsby-link';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { StyledInput } from '../../ui/input/Input';

const FormContainer = styled.div`
  margin: 0 auto;
  max-width: 970px;
`;

const VisuallyHidden = styled.div`
  max-height: 0;
  overflow: hidden;
`;

const WidgetBlogSearchForm = ({ query }) => {
  const [searchValue, setSearchValue] = useState(query);

  const handleBlogSearchSubmit = () => {
    navigate(`/blog/search/?q=${encodeURIComponent(searchValue).replace(/%20/g, '+')}`);
  };

  return (
    <FormContainer>
      <form action="/blog/search/" method="get" onSubmit={handleBlogSearchSubmit}>
        <StyledInput
          autoFocus
          name="q"
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="I’m looking for…"
          theme="white"
          value={searchValue}
        />
        <VisuallyHidden>
          <button type="button">Search</button>
        </VisuallyHidden>
      </form>
    </FormContainer>
  );
};

WidgetBlogSearchForm.propTypes = {
  query: PropTypes.string,
};

WidgetBlogSearchForm.defaultProps = {
  query: '',
};

export default WidgetBlogSearchForm;
