import normalizeContentfulImageData from '../../helpers/normalizeContentfulImageData';
import tryGet from '../../../components/helpers/tryGet';
import WidgetBannerV2 from '../../../components/widgets/widgetBannerV2';

const imagePositions = {
  'after text': 'after',
  'before text': 'before',
};

const widgetBannerV2Translation = ({
  backgroundColor,
  backgroundImage,
  borderRadius,
  boxShadow,
  ctaTarget,
  description,
  descFontSize,
  descLineHeight,
  descTextAlign,
  header,
  headingFontSize,
  headerLineHeight,
  headerTextAlign,
  foregroundImage,
  foregroundImagePosition,
  maxWidth,
  mobileMaxWidth,
  padding,
  textColor,
  callToActionButton,
  mobileDescTextAlign,
  mobileDescTextSize,
  mobileHeaderTextAlign,
  mobileHeaderTextSize,
}) => {
  const translatedData = {
    backgroundColor,
    backgroundImgSrc: tryGet(['file', 'url'], backgroundImage),
    borderRadius,
    boxShadow,
    callToActionButton,
    ctaTarget,
    description: tryGet(['description'], description) || description,
    descFontSize,
    descLineHeight,
    descTextAlign,
    header,
    headerFontSize: headingFontSize,
    headerLineHeight,
    headerTextAlign,
    image: normalizeContentfulImageData(foregroundImage),
    imagePosition: imagePositions[foregroundImagePosition],
    maxWidth,
    mobileMaxWidth,
    padding,
    textColor,
    mobileDescTextAlign,
    mobileDescTextSize,
    mobileHeaderTextAlign,
    mobileHeaderTextSize,
  };

  return {
    data: translatedData,
    WidgetComponent: WidgetBannerV2,
  };
};

export default widgetBannerV2Translation;
