import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  fill: currentColor;
  height: 16px;
  width: 16px;
`;

const CaretUp = () => (
  <StyledSVG viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M11.221 7.87344 7.20212 12.96892a.93375.93375 0 0 0 .18263 1.33495A1.00062 1.00062 0 0 0 7.98418 14.5l8.03783-.02439a.96675.96675 0 0 0 .978-.9552.93444.93444 0 0 0-.20345-.57762l-4.01891-5.0711a1.001 1.001 0 0 0-1.37578-.17417A.96732.96732 0 0 0 11.221 7.87344Z" />
    </g>
  </StyledSVG>
);

export default CaretUp;
