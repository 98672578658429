import PropTypes from 'prop-types';
import React from 'react';

import Page from '../../../components/Page';
import WidgetBackLink from '../../../components/widgets/WidgetBackLink';
import WidgetAuthor from '../../../components/widgets/WidgetAuthor';
import WidgetCardList from '../../../components/widgets/WidgetCardList';

import translateWidget from '../translations/translateWidget';
import blogSignupSectionProps from '../helpers/blogSignupSectionProps';

const Author = (props) => {
  const { pageContext } = props;
  const { authorData } = pageContext;
  const { authorArticles, name, metaDescription, path } = authorData;

  const pageData = {
    description: metaDescription,
    footerType: 'full',
    navbarType: 'full',
    path,
    sections: [
      {
        backgroundColor: '#F6F6F6',
        columns: [
          {
            span: 12,
            widgets: [
              {
                data: {
                  linkText: 'Back to Bench Blog',
                  linkURL: '/blog/',
                },
                marginBottomDesktop: 0,
                marginBottomMobile: 6,
                marginTopDesktop: 8,
                marginTopMobile: 0,
                WidgetComponent: WidgetBackLink,
              },
            ],
          },
        ],
        padding: 0,
      },
      {
        backgroundColor: '#F6F6F6',
        columns: [
          {
            span: 4,
            widgets: [
              {
                data: translateWidget(authorData),
                WidgetComponent: WidgetAuthor,
              },
            ],
          },
          {
            span: 8,
            widgets: [
              {
                data: {
                  cards: authorArticles ? authorArticles.map(translateWidget) : [],
                  layout: [{ numCards: 2 }, { numCards: 2 }, { numCards: 2 }, { numCards: 2 }],
                },
                WidgetComponent: WidgetCardList,
              },
            ],
          },
        ],
        padding: 0,
      },
      blogSignupSectionProps,
    ],
    title: `${name} | Bench Accounting`,
  };

  return <Page {...pageData} />;
};

Author.propTypes = {
  pageContext: PropTypes.shape({
    authorData: {
      authorArticles: PropTypes.array.isRequired,
      name: PropTypes.string.isRequired,
      metaDescription: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    },
  }).isRequired,
};

export default Author;
