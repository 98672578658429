import React from 'react';

const WidgetTrustPilot = () => {
  return (
    <div
      className="trustpilot-widget"
      data-businessunit-id="540dedb200006400057a3407"
      data-locale="en-US"
      data-review-languages="en"
      data-stars="1,2,3,4,5"
      data-style-height="140px"
      data-style-width="100%"
      data-template-id="53aa8912dec7e10d38f59f36"
      data-theme="light"
    >
      <a
        href="https://www.trustpilot.com/review/bench.co"
        rel="noopener noreferrer"
        target="_blank"
      >
        Trustpilot
      </a>
    </div>
  );
};

export default WidgetTrustPilot;
