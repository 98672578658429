import translateArticleContentCard from './translateArticleContentCard';
import translateBanner from './translateBanner';
import translateEmbed from './translateEmbed';
import translateGatedAssetContentCard from './translateGatedAssetContentCard';
import translateAuthor from './translateAuthor';
import translateArticleLinkCard from './translateArticleLinkCard';

const contentfulNamesToTranslators = {
  ContentfulArticle: translateArticleContentCard,
  ContentfulBanner: translateBanner,
  ContentfulEmbed: translateEmbed,
  ContentfulGatedAsset: translateGatedAssetContentCard,
  ContentfulAuthor: translateAuthor,
  ContentfulArticleLinkCard: translateArticleLinkCard,
};

const translateWidget = (resource) => {
  const translator = contentfulNamesToTranslators[resource.typeName];
  return typeof translator === 'function' ? translator(resource) : null;
};

export default translateWidget;
