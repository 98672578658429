import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import BenchLogoSymbol from '../../ui/logos/BenchLogoSymbol';
import colors from '../../ui/constants/colors';
import copyrightText from '../helpers/copyrightText';
import FooterContainer from './FooterContainer';
import FooterSocialIcons from './FooterSocialIcons';
import grid, { mediaQueries } from '../../ui/constants/grid';
import GridColumn from '../../ui/grid/GridColumn';
import GridContainer from '../../ui/grid/GridContainer';
import GridRow from '../../ui/grid/GridRow';
import Link from '../../ui/buttons/Link';
import Spacer from '../../ui/Spacer';
import { pxToRem } from '../../ui/constants/typography';
import widont from '../helpers/widont';

const SiteMapHeader = styled.div`
  font-size: ${pxToRem(13)}rem;
  font-weight: bold;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

const LogoLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${mediaQueries.desktop`
    margin: 0 .75rem 2rem .75rem;
  `};

  ${mediaQueries.mobile`
    margin: 0 4rem 3rem 4rem;
  `};

  svg {
    width: 70px;
  }

  span {
    margin-left: 10px;
    line-height: 20px;
    color: ${colors.cloud};
  }
`;

const SiteMetaSection = styled.div`
  align-items: flex-start;
  border-top: 1px solid ${colors.stone};
  color: ${colors.dust};
  display: flex;
  font-size: ${pxToRem(13)}rem;
  justify-content: space-between;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
`;

const ContactSection = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -${grid.gutterWidth / 2}px;
`;

const LegalSection = styled.div`
  align-items: flex-start;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0 -${grid.gutterWidth / 2}px;

  ${mediaQueries.mobile`
    flex-direction: column;
  `};
`;

const SocialSection = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
`;

const MetaItem = styled.div`
  margin: 0 0 1rem;
  padding: 0 ${grid.gutterWidth / 2}px;

  ${mediaQueries.mobile`
    min-width: 50%;
  `};
`;

const TagOn = styled.p`
  display: inline;
  color: ${colors.malachite};
  font-weight: bold;
`;

const FullFooter = ({ backgroundColor, footerLinks }) => (
  <FooterContainer backgroundColor={backgroundColor}>
    <GridContainer>
      <GridRow justifyContent="flex-start">
        <GridColumn span={12 - 2 * footerLinks.length} spanMobile={12}>
          <LogoLayout>
            <BenchLogoSymbol color="#B4913B" />
            <span>{widont('Online bookkeeping and tax filing powered by real humans')}</span>
          </LogoLayout>
        </GridColumn>
        {footerLinks.map((sectionData) => (
          <GridColumn key={sectionData.header} span={2} spanMobile={6}>
            <Spacer paddingBottom={3}>
              <SiteMapHeader>{sectionData.header}</SiteMapHeader>
              {sectionData.content.map((data) => (
                <Spacer key={data.item} margin={1.5}>
                  <Link linkURL={data.url}>
                    {data.item}
                    {data.tagOn && <TagOn>{data.tagOn}</TagOn>}
                  </Link>
                </Spacer>
              ))}
            </Spacer>
          </GridColumn>
        ))}
      </GridRow>
      <ContactSection>
        <MetaItem>
          <Link className="-disguised" linkURL="tel:18887601940">
            1 (888) 760 1940
          </Link>
        </MetaItem>
      </ContactSection>
      <SiteMetaSection>
        <LegalSection>
          <MetaItem>{copyrightText}</MetaItem>
          <MetaItem>
            <Link className="-muted" linkURL="/terms/">
              Terms of Service
            </Link>
          </MetaItem>
          <MetaItem>
            <Link className="-muted" linkURL="/privacy/">
              Privacy Policy
            </Link>
          </MetaItem>
          <MetaItem>
            <Link className="-muted" linkURL="/terms-use/">
              Terms of Use
            </Link>
          </MetaItem>
          <MetaItem>
            <Link className="-muted" linkURL="/cookie-policy/">
              Cookie Policy
            </Link>
          </MetaItem>
          <MetaItem>
            <Link className="-muted" linkURL="/security/">
              Security
            </Link>
          </MetaItem>
          <MetaItem>
            <Link
              className="-muted"
              linkURL="https://docs.google.com/forms/d/e/1FAIpQLSc7YogLEp5YKPVjeXr1wnLNm4xPUxvaunohXtSmWKu6mgBQRQ/viewform"
              target="_blank"
            >
              Do Not Sell or Share My Personal Information
            </Link>
          </MetaItem>
        </LegalSection>
        <SocialSection>
          <FooterSocialIcons />
        </SocialSection>
      </SiteMetaSection>
    </GridContainer>
  </FooterContainer>
);

FullFooter.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  footerLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FullFooter;
