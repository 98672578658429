import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Link from '../../ui/buttons/Link';
import colors from '../../ui/constants/colors';
import { mediaQueries } from '../../ui/constants/grid';
import isLinkActive from './isLinkActive';
import NavLinkDropdown from './NavLinkDropdown';

const NavLinkContainer = styled.div`
  ${mediaQueries.desktop`
    font-size: ${({ smallOnDesktop }) => (smallOnDesktop ? 13 : 15)}px;
    padding: 6px ${({ smallOnDesktop }) => (smallOnDesktop ? 8 : 16)}px;
    position: relative;

    a {
      display: block;
    }

    ${({ animateOnHover, asButton }) =>
      animateOnHover &&
      !asButton &&
      `
      a {
        padding-bottom: 2px;
        padding-top: 2px;
        transition-duration: 100ms;

        &:hover {
          padding-bottom: 4px;
          padding-top: 0;
        }
      }
    `}

    ${({ animateOnHover, linkActive, asButton }) =>
      animateOnHover &&
      linkActive &&
      !asButton &&
      `
      a {
        padding-bottom: 4px;
        padding-top: 0;
      }

      a::after {
        border-style: solid;
        border-width: 0 0 1px;
        bottom: 0;
        content: "";
        display: block;
        height: 0;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 40px;
      }
    `}

    ${({ asButton, isTransparent, linkColor }) =>
      asButton &&
      `
      a {
        background: ${isTransparent ? 'transparent' : colors.water};
        border-color: ${isTransparent ? linkColor : colors.water}
        border-radius: 3px;
        border-style: solid;
        border-width: 1px;
        color: ${isTransparent ? linkColor : colors.white};
        padding: 6px 12px;

        &:hover {
          background: ${isTransparent ? '#008E8D' : colors['water-dark']};
          border-color: ${isTransparent ? '#008E8D' : colors['water-dark']};
          color: ${colors.white};
        }
      }
    `}
  `} ${mediaQueries.mobile`
    width: 100%;

    ${({ borderOnMobile, asButton }) =>
      borderOnMobile &&
      !asButton &&
      `
      border-bottom: 1px solid ${colors.stone}
    `};

    a {
      color: ${colors.charcoal};
      display: block;
      font-size: 15px;
      padding: 16px 0;
    }

    ${({ asButton }) =>
      asButton &&
      `
      && a {
        background: ${colors.water};
        border-radius: 3px;
        color: ${colors.white};
        height: 50px;
        line-height: 50px;
        margin: 16px auto;
        padding: 0;
        text-align: center;
        width: 250px;
      }
    `}
  `};
`;

NavLinkContainer.propTypes = {
  animateOnHover: PropTypes.bool,
  asButton: PropTypes.bool,
  borderOnMobile: PropTypes.bool,
  linkActive: PropTypes.bool,
  smallOnDesktop: PropTypes.bool,
};

NavLinkContainer.defaultProps = {
  animateOnHover: false,
  asButton: false,
  borderOnMobile: false,
  linkActive: false,
  smallOnDesktop: false,
};

const NavLink = ({
  asButton,
  isTransparent,
  linkColor,
  linkText,
  linkURL,
  noBorderOnMobile,
  path,
  smallOnDesktop,
  dropdownLinks,
}) => {
  return (
    <NavLinkContainer
      animateOnHover
      asButton={asButton}
      borderOnMobile={!noBorderOnMobile}
      isTransparent={isTransparent}
      linkActive={isLinkActive(linkURL, path)}
      linkColor={linkColor}
      smallOnDesktop={smallOnDesktop}
    >
      {dropdownLinks && dropdownLinks.length ? (
        <NavLinkDropdown dropdownLinks={dropdownLinks} linkText={linkText} />
      ) : (
        <Link linkURL={linkURL}>{linkText}</Link>
      )}
    </NavLinkContainer>
  );
};

NavLink.propTypes = {
  asButton: PropTypes.bool,
  dropdownLinks: PropTypes.arrayOf(
    PropTypes.shape({
      dropdownLinkDescription: PropTypes.string,
      dropdownLinkText: PropTypes.string,
      dropdownLinkURL: PropTypes.string,
    }),
  ),
  isTransparent: PropTypes.bool,
  linkColor: PropTypes.string,
  linkText: PropTypes.string,
  linkURL: PropTypes.string,
  noBorderOnMobile: PropTypes.bool,
  path: PropTypes.string,
  smallOnDesktop: PropTypes.bool,
};

NavLink.defaultProps = {
  asButton: false,
  dropdownLinks: undefined,
  isTransparent: false,
  linkColor: null,
  linkText: null,
  linkURL: null,
  noBorderOnMobile: false,
  path: null,
  smallOnDesktop: false,
};

export default NavLink;
