// Registry of previously loaded assets to prevent loading the same one multiple times.
const loadedAssets = {};

const loadAsset = (makeElement, key, preventDuplicate) => {
  if (preventDuplicate && loadedAssets[key]) {
    return loadedAssets[key];
  }
  const assetPromise = new Promise((resolve, reject) => {
    const element = makeElement();
    element.onload = () => resolve();
    element.onerror = (err) => {
      delete loadedAssets[key];
      reject(err);
    };
    document.head.appendChild(element);
  });
  loadedAssets[key] = assetPromise;
  return assetPromise;
};

/**
 * Load a script by URL into the browser and execute it in the global scope.
 *
 * The optional `asynchronous` parameter specifies whether the script should be loaded
 * asynchronously (like a script tag with the `async` attribute would.)
 *
 * If the optional parameter `preventDuplicate` is set to `true` (which is the default) then if the
 * same `src` is passed to the function again, then it will just return the resolved promise from
 * the initial load.
 *
 * Returns a `Promise` that resolves when the script has loaded and run and rejects if the script
 * failed to load.
 *
 * If the function is called from anywhere other than the browser, such as server-side rendering, it
 * will throw an error. The caller of this function must make efforts not to call it when the DOM
 * API is not available.
 *
 * @param {string} src The URL to load the script from
 * @param {boolean} asynchronous (Optional) The script should load asynchronously. Default `false`
 * @param {boolean} preventDuplicate (Optional) Prevent duplicate loads of same href. Default `true`
 * @param attributes (Optional) Array of objects for unique attributes to be added
 * @return {Promise} A promise that resolves when the script has loaded and run
 * @throws If called in a context where the DOM API is not available, such as server-side rendering
 */
export const loadScript = (src, asynchronous = false, preventDuplicate = true, attributes = []) => {
  if (typeof document === 'undefined' || typeof document.createElement !== 'function') {
    throw new Error('`loadScript` was called without access to DOM API');
  }
  const makeElement = () => {
    const script = document.createElement('script');
    script.type = 'application/javascript';
    script.src = src;
    script.async = asynchronous;
    attributes.map((attribute) => script.setAttribute(attribute.name, attribute.value));
    return script;
  };
  return loadAsset(makeElement, src, preventDuplicate);
};

/**
 * Load a stylesheet by URL into the browser.
 *
 * Returns a `Promise` that resolves when the stylesheet has loaded and rejects if the stylesheet
 * failed to load.
 *
 * If the function is called from anywhere other than the browser, such as server-side rendering, it
 * will throw an error. The caller of this function must make efforts not to call it when the DOM
 * API is not available.
 *
 * @param {string} href The URL to load the stylesheet from
 * @return {Promise} A promise that resolves when the stylesheet has loaded and run
 * @throws If called in a context where the DOM API is not available, such as server-side rendering
 */
export const loadStylesheet = (href) => {
  if (typeof document === 'undefined' || typeof document.createElement !== 'function') {
    throw new Error('`loadStylesheet` was called without access to DOM API');
  }
  const makeElement = () => {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = href;
    return link;
  };
  return loadAsset(makeElement, href, true);
};
