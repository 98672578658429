import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import styled from 'styled-components';

const IFRAME_TITLE_PREFIX = 'youtube-video-';

const YOUTUBE_EMBED_ENDPOINT = 'https://www.youtube.com/embed';

// The VideoWrap creates a region that is 100% wide and the correct aspect ratio of the video. Its
// overflow is set to hidden so that 1px can be cropped from each side by the VideoContainer in the
// case of a rounding error that would result in a black line appearing along the edge of the video.
const VideoWrap = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: ${({ naturalHeight, naturalWidth }) => (naturalHeight / naturalWidth) * 100}%;
  position: relative;
  width: 100%;
`;

const VideoContainer = styled.div`
  bottom: -1px;
  left: -1px;
  position: absolute;
  right: -1px;
  top: -1px;
`;

const VideoEmbed = styled.iframe`
  border: 0 none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const YouTubeVideo = ({ controls, id, allowFullScreen, naturalHeight, naturalWidth }) => {
  const getIframeSrc = () => {
    const qs = queryString.stringify({
      modestbranding: 1,
      showinfo: 0,
      rel: 0,
      controls: Number(controls),
    });
    return `${YOUTUBE_EMBED_ENDPOINT}/${id}?${qs}`;
  };

  const getIframeTitle = () => {
    return `${IFRAME_TITLE_PREFIX}${id}`;
  };

  return (
    <VideoWrap naturalHeight={naturalHeight} naturalWidth={naturalWidth}>
      <VideoContainer>
        <VideoEmbed
          allowFullScreen={allowFullScreen}
          height={naturalHeight}
          src={getIframeSrc()}
          title={getIframeTitle()}
          width={naturalWidth}
        />
      </VideoContainer>
    </VideoWrap>
  );
};

YouTubeVideo.propTypes = {
  allowFullScreen: PropTypes.bool,
  controls: PropTypes.bool,
  id: PropTypes.string.isRequired,
  naturalHeight: PropTypes.number,
  naturalWidth: PropTypes.number,
};

YouTubeVideo.defaultProps = {
  allowFullScreen: false,
  controls: false,
  naturalHeight: 1080,
  naturalWidth: 1920,
};

export default YouTubeVideo;
